import { useNavigate } from "react-router-dom";
import { DbDocument } from "@/state/api/documents";
import { getTimestampDescription } from "@/utils";
import { CalendarIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { Section } from "@ui/section";

export function DocumentsCard({ document }: { document: DbDocument }) {
  const navigate = useNavigate();
  return (
    <Section onClick={() => navigate("/documents/" + document.id)} className="cursor-pointer">
      <div className="flex items-center gap-2">
        <CalendarIcon />
        {getTimestampDescription(document.timestamp)}
        <div className="grow" />
        {document.views} views
      </div>
      <div className="font-semibold leading-none">
        {document.title}
      </div>
      <div className="leading-tight text-foreground-muted">
        {document.description}
      </div>
      <div className="flex items-center gap-2 text-primary">
        Read article <ChevronRightIcon />
      </div>
    </Section>
  );
}
import { Button } from "@ui/button";
import { Input } from "@ui/input";
import { Label } from "@ui/label";
import { selectBlueprint, useAppSelector } from "@/state/store";
import { exportBlueprint } from "@/utils";
import { CameraIcon, CheckIcon, CopyIcon, CornersIcon, EnterFullScreenIcon, MarginIcon, TrashIcon } from "@radix-ui/react-icons";
import { useRef, useState } from "react";
import { TrayModel } from "@/types";
import { OrbitControls, OrthographicCamera } from "@react-three/drei";
import PhotoStage, { PhotoStageHandle } from "../canvas/PhotoStage";
import { DialogClose, DialogCloseDefault, DialogDescription, DialogFooter, DialogTitle } from "@ui/dialog";
import { DndContext } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { useAddTrayMutation } from "@/state/api/trays";
import { Spinner } from "@ui/spinner";
import { SectionLabel } from "@ui/sectionLabel";
import { ReplicadShape, ReplicadShapeHandle } from "../canvas/ReplicadShape";
import { Section } from "@ui/section";
import Canvas, { CanvasHandle } from "../canvas/Canvas";
import { Editor, EditorHandle } from "@ui/editor";
import { SectionDescription } from "@ui/sectionDescription";
import { useReporter } from "@/state/messages";
import { Switch } from "@ui/switch";
import {CanvasCapture } from "../canvas/CanvasCapture";

interface TrayImage {
  id: number,
  source: string,
}

function BlueprintShareImage({ image, isPrimary, deleteImage }: { image: TrayImage, isPrimary: boolean, deleteImage: () => void }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: image.id,
  });
  return (
    <div ref={setNodeRef} style={{
      transform: CSS.Translate.toString(transform),
      transition,
    }} className={"relative border rounded bg-control" + (isPrimary ? " border-primary" : "") + (isDragging ? " z-20" : "")}>
      <img src={image.source} className="h-[128px] w-[192px] rounded bg-control" />
      {isPrimary && (
        <div className="absolute -bottom-px -left-px rounded-es rounded-se bg-primary px-1 font-semibold text-primary-foreground">
          Preview
        </div>
      )}
      <button {...listeners} {...attributes} className="absolute inset-0" />
      <div className="absolute right-1 top-1 flex gap-1">
        <Button variant="destructive" className="size-7 p-0" onClick={() => deleteImage()}>
          <TrashIcon />
        </Button>
      </div>
    </div>
  );
}

function BlueprintShareImages({ images, setImages }: { images: TrayImage[], setImages: (images: TrayImage[]) => void }) {
  return (
    <DndContext autoScroll={false} onDragEnd={e => {
      if (e.over === null) {
        return;
      }
      const activeIndex = images.findIndex(i => i.id == e.active.id);
      const overIndex = images.findIndex(i => i.id == e.over!.id);
      const current = [...images];
      const removed = current.splice(activeIndex, 1);
      current.splice(overIndex, 0, ...removed);
      setImages(current);
    }} modifiers={[]}>
      <SortableContext items={images.map(i => i.id)} id="trays" strategy={rectSortingStrategy}>
        <div className="-mx-4 flex flex-col gap-2 border-y bg-background px-4 py-2">
          {images.length > 0 ? (
            <>
              <div className="flex flex-wrap gap-2">
                {images.map(image => <BlueprintShareImage key={image.id} image={image} isPrimary={image.id === images[0].id} deleteImage={() => setImages(images.filter(i => image.id !== i.id))} />)}
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center gap-1 p-1 text-sm text-foreground-muted">
              Add images using the <CameraIcon /> button.
            </div>
          )}

        </div>
      </SortableContext>
    </DndContext>
  );
}

function Widget({ id, version, name }: { id: number, version: number, name: string }) {
  const [hasCopied, setHasCopied] = useState(false);
  const url = (import.meta.env.MODE == "development" ? "http://localhost:4444" : "https://boardgameinserts.xyz") + "/designer?tray=" + id;
  return (
    <div className="flex w-80 flex-col gap-2">
      <Label htmlFor="url" className="font-normal">
        Tray <span className="font-semibold">{name}@{version}</span> was published
        </Label>
      <div className="flex gap-px">
        <Input id="url" readOnly value={url} className="rounded-r-none"/>
        <Button onClick={async () => {
          await navigator.clipboard.writeText(url);
          setHasCopied(true);
          window.setTimeout(() => setHasCopied(false), 3000);
        }} size="sm" className="rounded-l-none">
          {hasCopied ? <CheckIcon /> : <CopyIcon />}
        </Button>
      </div>
    </div>
  );
}

export default function BlueprintDialogShare({ blueprintId, models }: { blueprintId: number, models: TrayModel[] }) {
  const [addTray] = useAddTrayMutation();
  const [name, setName] = useState("");
  const [images, setImages] = useState<TrayImage[]>([]);
  const library = useAppSelector(s => s.model.blueprints[blueprintId].library);
  const reporter = useReporter();
  const editorRef = useRef<EditorHandle>(null);
  return (
    <>
      <DialogCloseDefault/>
      <Section nested className="shrink-0">
        <DialogTitle>{library === undefined ? "Add to library" : "Update in library"}</DialogTitle>
        <DialogDescription>
          The tray library is an account wide collection of trays which allows reuse across multiple inserts.
          Trays from the library can also be shared with other users.
          Library trays are versioned to control the adoption of changes in existing inserts.
        </DialogDescription>
        {/*<Widget id={0} name="Test" version={0}/>*/}
        <SectionLabel>
          Images
        </SectionLabel>
        <CanvasCapture width={768} height={512} model={models[0]} addImage={image => {
          setImages([...images, {
            id: images.length == 0 ? 1 : (Math.max(...images.map(i => i.id)) + 1),
            source: image,
          }]);
        }}/>
        <BlueprintShareImages images={images} setImages={setImages} />
        <SectionLabel htmlFor="name">
          Name
        </SectionLabel>
        <SectionDescription>
          Note that the tray name can not be changed in future revisions.
        </SectionDescription>
        <Input id="name" value={name} onChange={e => setName(e.target.value)} />
        <SectionLabel>
          Decription
        </SectionLabel>
        <Editor imageResolver={name => ""} className="min-h-52" ref={editorRef} />
      </Section>
      <DialogFooter>
        <DialogClose asChild>
          <Button variant="primary" disabled={name.trim().length == 0 || images.length === 0} onClick={async () => {
            if (editorRef.current !== null) {
              const content = exportBlueprint(selectBlueprint(blueprintId));
              const closeProgress = reporter.widget(<div className="flex items-center gap-2">
                <Spinner />
                Publishing tray {name}
              </div>, false, "neutral");
              const response = await addTray({
                name: name,
                description: editorRef.current.editor.getEditorState().toJSON(),
                content: content,
                images: images.map(i => i.source),
              });
              closeProgress();
              if (response.error === undefined && response.data !== undefined) {
                reporter.widget(<Widget id={response.data.id} name={name} version={library === undefined ? 0 : library.version + 1}/>, true, "success");
              } else {
                reporter.error("Failed to publish tray: " + response.error);
              }
            }
          }}>
            Publish
          </Button>
        </DialogClose>
      </DialogFooter>
    </>
  );
}
import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread
} from "lexical";
import { DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";

const ImageComponent = React.lazy(
  () => import("./ImageComponent")
);

export interface ImagePayload {
  name: string;
  src: string;
  key?: NodeKey;
}

function convertImageElement(domNode: Node): null | DOMConversionOutput {
  if (domNode instanceof HTMLImageElement) {
    const { name, src } = domNode;
    const node = $createImageNode({ name, src });
    return { node };
  }
  return null;
}

export type SerializedImageNode = Spread<{
  name: string;
  src: string;
  type: "image";
  version: 1;
},
SerializedLexicalNode>;

export class ImageNode extends DecoratorNode<JSX.Element> {
  __src: string;
  __name: string;

  static getType(): string {
    return "image";
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(
      node.__src,
      node.__name,
      node.__key
    );
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const {
      name,
      src,
    } = serializedNode;
    const node = $createImageNode({
      name,
      src,
    });
    return node;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("img");
    element.setAttribute("src", this.__src);
    element.setAttribute("alt", `failed to load image: ${this.__name}`);
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: (node: Node) => ({
        conversion: convertImageElement,
        priority: 0,
      })
    };
  }

  constructor(
    src: string,
    name: string,
    key?: NodeKey,
  ) {
    super(key);
    this.__src = src;
    this.__name = name;
  }

  exportJSON(): SerializedImageNode {
    return {
      name: this.getName(),
      src: this.getSrc(),
      type: "image",
      version: 1,
    };
  }

  // View
  createDOM(config: EditorConfig): HTMLElement {
    const span = document.createElement("span");
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }

  updateDOM(): false {
    return false;
  }

  getSrc(): string {
    return this.__src;
  }

  getName(): string {
    return this.__name;
  }

  decorate(): JSX.Element {
    return (
      <Suspense fallback={null}>
        <ImageComponent
          src={this.__src}
          name={this.__name}
          nodeKey={this.getKey()}
          resizable={true}
        />
      </Suspense>
    );
  }
}

export function $createImageNode({
  name,
  src,
  key,
}: ImagePayload): ImageNode {
  return new ImageNode(
    src,
    name,
    key,
  );
}

export function $isImageNode(
  node: LexicalNode | null | undefined
): node is ImageNode {
  return node instanceof ImageNode;
}

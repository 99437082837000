import { Button } from "@ui/button";
import { DialogHeader, DialogTitle } from "@ui/dialog";
import { Input } from "@ui/input";
import { Label } from "@ui/label";
import { GoogleLogin } from "@react-oauth/google";

export function NavigationDialogLogin( { tokenReceived } : {tokenReceived : (token?: string) => void}) {
  return (
    <>
      <DialogHeader>
        <DialogTitle>Select your account</DialogTitle>
      </DialogHeader>
      <GoogleLogin onSuccess={token => tokenReceived(token.credential)} size="large" theme="filled_black" text="signin_with" width={340}/>
      <hr/>
      <div className="flex flex-col gap-1">
        <Label htmlFor="email">E-Mail</Label>
        <Input id="email" disabled/>
        <Label htmlFor="password">Password</Label>
        <Input id="password" disabled/>
        <Button className="mt-1">Login</Button>
      </div>
      {import.meta.env.MODE === "development" && (
        <div className="flex gap-2">
          <Button variant="destructive" onClick={() => tokenReceived("debug-token-1")}>Debug login 1</Button>
          <Button variant="destructive" onClick={() => tokenReceived("debug-token-2")}>Debug login 2</Button>
        </div>
      )}
    </>
  );
}
import { Bounds, BoundsApi, BoundsProps, useBounds } from "@react-three/drei";
import { GroupProps, useThree } from "@react-three/fiber";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { Group } from "three";

export interface PhotoStageHandle {
  bounds: BoundsApi,
  captureCanvas: () => string,
}

const BoundsWrapper = forwardRef<PhotoStageHandle, GroupProps>(({ children, ...props}, ref) => {
  const bounds = useBounds();
  const { gl, scene, camera } = useThree();
  useImperativeHandle(ref, () => ({
    bounds,
    captureCanvas: () => {
      gl.render(scene, camera);
      return gl.domElement.toDataURL();
    },
  }), [ bounds ]);
  return children;
});
BoundsWrapper.displayName = "BoundsWrapper";

const PhotoStage = forwardRef<PhotoStageHandle, BoundsProps>(({ children, ...props}, ref) => {
  return (
    <Bounds fit clip margin={1.01} maxDuration={0.1} {...props}>
      <BoundsWrapper ref={ref}>
        {children}
      </BoundsWrapper>
    </Bounds>
  );
});
PhotoStage.displayName = "PhotoStage";

export default PhotoStage;
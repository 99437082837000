import React from "react";
import { cn } from "@ui/utils";
import { StarIcon, TrashIcon } from "@radix-ui/react-icons";

export interface RatingProps extends React.HTMLAttributes<HTMLDivElement> {
  rating: number,
  deleteValue: number,
}

const Rating = React.forwardRef<HTMLDivElement, RatingProps>(
  ({ children, className, rating, deleteValue, ...props }, ref) => {
    return (
      <div className={cn("flex w-full items-center", className)} ref={ref} {...props}>
        <div className={"rounded border bg-control px-2 text-sm" + (rating < deleteValue / 2 ? " border-red-500" : rating >= -deleteValue ? " border-green-500" : "")}>
          {rating}
        </div>
        <div className="flex-auto">
          <div className="h-[2px] w-full bg-secondary">
            <div className={"h-full" + (rating < 0 ? " bg-red-500" : rating > 0 ? " bg-green-500" : "")} style={{
              width: Math.min(100, Math.abs(rating / deleteValue * 100)) + "%",
            }}/>
          </div>
        </div>
        {rating < 0 ? (
          <TrashIcon className="ml-2 text-red-500"/>
        ) : (
          <StarIcon className="ml-2 text-green-500"/>
        )}
      </div>
    );
  }
);
Rating.displayName = "Rating";

export { Rating }


import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "@/state/store";
import { Button } from "@ui/button";
import { DbDocumentComment, useAddCommentMutation, useDeleteDocumentMutation, useGetDocumentQuery } from "@/state/api/documents";
import { getImageData, getTimestampDescription, imageUrlFromId } from "@/utils";
import { CalendarIcon, EyeOpenIcon, Pencil2Icon, PersonIcon, TrashIcon } from "@radix-ui/react-icons";
import UpdatesMarkup from "./UpdatesMarkup";
import { Textarea } from "@ui/textarea";
import { useEffect, useMemo, useRef, useState } from "react";
import { Spinner } from "@ui/spinner";
import { User } from "@/types";
import { SectionHeader } from "@ui/sectionHeader";
import { Placeholder } from "@ui/placeholder";
import { Icon } from "@ui/icon";
import { Section } from "@ui/section";
import { Page } from "@ui/page";
import { useReporter } from "@/state/messages";
import { Viewer } from "@ui/editor";
import { decompressFromBase64 } from "lz-string";

function PostComment({user, updateId }: { user: User, updateId: number}) {
  const [content, setContent] = useState("");
  const [addComment, status] = useAddCommentMutation();
  const textarea = useRef<HTMLTextAreaElement>(null);
  if(status.isLoading) {
    return (
      <div className="flex w-80 items-center gap-2">
        <Spinner/>
        Posting comment...
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-2">
      <Textarea placeholder={"Post as " + user.name} className="bg-control" ref={textarea} onChange={e => setContent(e.target.value)}/>
      {content !== "" && (
        <div className="flex justify-end gap-2">
          <Button variant="destructive" onClick={() => {
            textarea.current!.value = "";
            setContent("");
          }}>
            Cancel
          </Button>
          <Button variant="outline" onClick={async () => {
            const result = await addComment({
              content: content,
              updateId: updateId,
            });
            if(!result.error) {
              textarea.current!.value = "";
              setContent("");
            }
          }}>
            Post comment
          </Button>
        </div>
      )}
    </div>
  );
}

function Comment({comment}: {comment: DbDocumentComment}) {
  return (
    <div className="flex flex-col rounded border bg-control p-2">
      <div className="flex gap-2 text-sm">
        <div className="font-semibold">
          {comment.ownerName}
        </div>
        <div className="text-foreground-muted">
          {getTimestampDescription(comment.timestamp)}
        </div>
      </div>
      <div className="">
        {comment.comment}
      </div>
    </div>
  );
}

export default function DocumentsDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const reporter = useReporter();
  const session = useAppSelector(s => s.session);
  const { isFetching, isSuccess, data } = useGetDocumentQuery({
    id: parseInt(id!),
  });
  const [ deleteDocument ] = useDeleteDocumentMutation();
  useEffect(() => {
    if(data !== undefined) {
      document.title = data.title;
    }
  }, [ data ]);
  return (
    <Page scrolling>
      <div className="flex gap-2">
        <Link to="/documents">
          <Button variant="outline" className="">
            <Icon name="chevron-left" sm/>
            Back to Updates
          </Button>
        </Link>
        {session.user?.privileges.includes("post") && (
          <>
            <div className="flex-auto"></div>
            <Link to={`/documents/${id}/edit`}>
              <Button variant="outline">
                <Pencil2Icon/>
                Edit
              </Button>
            </Link>
            <Button variant="destructive" onClick={async () => {
              const result = await deleteDocument({
                id: parseInt(id!),
              });
              if(result.error !== undefined) {
                reporter.error("Failed to delete document: " + String(result.error));
              } else {
                navigate("/documents");
              }
            }}>
              <TrashIcon/>
            </Button>
          </>
        )}
      </div>
      <Section>
          {isFetching ? (
            <Placeholder className="-m-4 py-20">
              Loading update details...
            </Placeholder>
          ) : isSuccess && data !== undefined ? (
            <div className="flex flex-col">
              <div className="flex items-center gap-2 text-foreground-muted">
                <CalendarIcon/>
                {getTimestampDescription(data.timestamp)}
                <div className="flex-auto"/>
                <EyeOpenIcon/>
                {data.views} views
              </div>
              <div className="text-2xl mb-2">
                {data.title}
              </div>
              <Viewer initialState={decompressFromBase64(data.content)} imageResolver={name => imageUrlFromId("documents", parseInt(id!), name)}/>
              <div className="mt-2 flex items-center gap-2 text-foreground-muted">
                <PersonIcon/>
                Author: kriho
              </div>
              {data.editTimestamp !== undefined && (
                <div className="mt-2 flex items-center gap-2 text-foreground-muted">
                  <Pencil2Icon/>
                  Last edited: {getTimestampDescription(data.editTimestamp)}
                </div>
              )}
            </div>
          ) : (
            <div>
              Failed to load update.
            </div>
          )}
      </Section>
      <div className="mt-2 flex flex-col gap-2">
        <SectionHeader>
          Comments
        </SectionHeader>
        {session.user !== undefined && (
          <PostComment user={session.user} updateId={parseInt(id!)}/>
        )}
        {data !== undefined && data.comments.length > 0 ? (
          data?.comments.map(c => <Comment key={c.id} comment={c}/>)
        ) : (
          <Placeholder className="border" type="info">
            {session.user ? "No comments yet, be the first to comment!" : "Log in to post the first comment!"}
          </Placeholder>
        )}
      </div>
    </Page>
  );
}
import React from "react"
import { cn } from "./utils"
import { Section } from "./section";
import { ScrollArea } from "./scroll-area";

export interface ScrollSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  slim?: boolean,
}

const ScrollSection = React.forwardRef<HTMLDivElement, ScrollSectionProps>(
  ({ children, slim = false, className, ...props }, ref) => {
    return (
      <div className={cn("flex flex-col rounded border bg-control overflow-hidden", className)} {...props}>
        <ScrollArea slim={slim} className={slim ? "px-2": "px-4"}>
          <div className={"flex flex-col " + (slim ? "gap-2 my-2": "gap-2 my-4")}>
            {children}
          </div>
        </ScrollArea>
      </div>
    )
  }
)
ScrollSection.displayName = "ScrollSection";

export { ScrollSection };





import * as React from "react"
import { cn } from "./utils"
import { Button } from "./button"
import { Input } from "./input";
import { CheckIcon } from "@radix-ui/react-icons";

export interface InputSubmitProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
    initialValue: string,
    applyValue: (value: string) => void,
    validate?: (value: string) => true | string,
    preprocess?: (value: string) => string,
  }

const InputSubmit = React.forwardRef<HTMLInputElement, InputSubmitProps>(
  ({ className, initialValue, applyValue, validate, preprocess, ...props }, ref) => {
    const [currentValue, setCurrentValue] = React.useState(initialValue);
    React.useLayoutEffect(() => {
      setCurrentValue(initialValue);
    }, [initialValue]);
    const valid = validate !== undefined ? validate(currentValue) : true;
    return (
      <>
        <div className={cn("flex w-full items-center gap-0.5", className)}> 
          <Input value={currentValue} onChange={e => {
            let value = e.target.value as string;
            if(preprocess !== undefined) {
              value = preprocess(value);
            }
            setCurrentValue(value);
          }} className="rounded-e-none" ref={ref} {...props} onKeyDown={e => {
            if(e.key == "Enter" && currentValue.trim() != initialValue && valid === true) {
              applyValue(currentValue.trim());
            }
          }}/>
          <Button variant="primary" disabled={currentValue.trim() == initialValue || valid !== true} className="size-8 flex-none rounded-s-none p-0" onClick={() => applyValue(currentValue.trim())}>
            <CheckIcon className="size-5"/>
          </Button>
        </div>
        {valid !== true && (
          <div className="mb-2 mt-1 text-destructive">
            {valid}
          </div>
        )}
      </>
    )
  }
)
InputSubmit.displayName = "InputSubmit"

export { InputSubmit }

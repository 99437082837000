import React from "react";
import { cn } from "@ui/utils";
import { ScrollArea } from "./scroll-area";

export interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  scrolling?: boolean
}

const Page = React.forwardRef<HTMLDivElement, PageProps>(
  ({ children, className, scrolling = false, ...props }, ref) => {
    if(scrolling) {
      return (
        <ScrollArea className={cn("py-2", className)}>
          <div className="flex flex-col gap-2" ref={ref} {...props}>
            {children}
          </div>
        </ScrollArea>
      );
    } else {
      return (
        <div className={cn("flex flex-col gap-2 py-2", className)} ref={ref} {...props}>
          {children}
        </div>
      );
    }
  }
)

Page.displayName = "Page";

export { Page }


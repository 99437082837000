import { ChevronRightIcon } from "@radix-ui/react-icons";
import { Page } from "@ui/page";
import { PageHeader } from "@ui/pageHeader";
import { Section } from "@ui/section";
import { SectionDescription } from "@ui/sectionDescription";
import { SectionLabel } from "@ui/sectionLabel";
import { useNavigate } from "react-router-dom";

export function ExploreList() {
  const navigate = useNavigate();
  return (
    <Page>
      <Section>
        <div className="flex justify-evenly gap-4">
          <Section nested onClick={() => navigate("/printers")} className="cursor-pointer">
            <div className="underline">
              Printers
            </div>
            <SectionDescription>
              Organize printer metadata using printers.
            </SectionDescription>
            <div className="flex items-center gap-2 text-primary">
              Explore <ChevronRightIcon/>
            </div>
          </Section>
          <Section nested onClick={() => navigate("/cameras")} className="cursor-pointer">
            <div className="underline">
              Cameras
            </div>
            <SectionDescription>
              Calibrate cameras for accurate part scans.
            </SectionDescription>
            <div className="flex items-center gap-2 text-primary">
            Explore <ChevronRightIcon/>
            </div>
          </Section>
          <Section nested onClick={() => navigate("/boxes")} className="cursor-pointer">
            <div className="underline">
              Boxes
            </div>
            <SectionDescription>
              Measure board game boxes once, use them over and over again.
            </SectionDescription>
            <div className="flex items-center gap-2 text-primary">
            Explore <ChevronRightIcon/>
            </div>
          </Section>
        </div>
      </Section>
    </Page>
  );
}
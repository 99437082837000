import { DbPrinter, useLazySearchPrintersQuery } from "@/state/api/printers";
import { InputSearch } from "@ui/inputSearch";
import { Popover, PopoverContent, PopoverTrigger } from "@ui/popover";
import TextHighlight from "@ui/textHighlight";
import React, { useEffect, useState } from "react";

export interface InputPrinterSearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onSelectResult: (printer: DbPrinter) => void,
  clearOnSelect?: boolean,
}

const InputPrinterSearch = React.forwardRef<HTMLInputElement, InputPrinterSearchProps>(
  ({ className, onSelectResult, clearOnSelect, ...props }, ref) => {
  const [ searchPrinter, searchPrinterState ] = useLazySearchPrintersQuery();
  const [ query, setQuery ] = useState("");
  const [ isOpen, setIsOpen ] = useState(false);
  useEffect(() => {
    const used = query.trim();
    if(used.length > 2) {
      searchPrinter({
        search: used,
      });
    }
  }, [query]);
  useEffect(() => {
    if(!searchPrinterState.isFetching && searchPrinterState.isSuccess && searchPrinterState.data.length > 0) {
      setIsOpen(true);
    } else if(!searchPrinterState.isSuccess) {
      setIsOpen(false);
    }
  }, [ searchPrinterState ]);
  return (
    <Popover open={isOpen} onOpenChange={o => {
      if(!o) {
        setIsOpen(false);
      }
    }}>
      <PopoverTrigger className={className} asChild>
        <div className="h-8 w-full" >
          <InputSearch ref={ref} key="search" isBusy={searchPrinterState.isFetching} className="size-full"  value={query} onChange={e => setQuery(e.target.value)} onClear={() => {
            setQuery("");
            setIsOpen(false);
          }} {...props}/>
        </div>
      </PopoverTrigger>
      <PopoverContent side="bottom" align="start" className="bg-control p-1" onOpenAutoFocus={e => e.preventDefault()} onCloseAutoFocus={e => e.preventDefault()}>
        <div className="flex flex-col">
          {searchPrinterState.data?.map(d => (
            <TextHighlight key={d.id} className="cursor-pointer rounded-sm px-1 hover:bg-accent" text={d.name} highlight={query} highlightClassName="font-semibold" onClick={() => {
              if(clearOnSelect) {
                setQuery("");
              }
              onSelectResult(d);
              setIsOpen(false);
            }}/>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
});
InputPrinterSearch.displayName = "InputPrinterSearch";

export { InputPrinterSearch };
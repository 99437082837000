import { Badge } from "@ui/badge";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";
import { CubeIcon } from "react-line-awesome";
import { useAppDispatch, useAppSelector } from "@/state/store";
import { shallowEqual } from "react-redux";
import { BoxIcon, BoxModelIcon, GearIcon, MixIcon, MixerHorizontalIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { select } from "@/state/model";

export default function BlueprintSidebarHeader({ blueprintId }: { blueprintId: number }) {
  const name = useAppSelector(s => s.model.blueprints[blueprintId].name);
  const version = useAppSelector(s => s.model.blueprints[blueprintId].version);
  const type = useAppSelector(s => s.model.blueprints[blueprintId].type);
  const selection = useAppSelector(s => s.model.selection);
  const dispatch = useAppDispatch();
  return (
    <div className="flex flex-col gap-2 rounded-t-sm border-x border-t bg-control p-2">
      <div className="flex items-center">
        <span className="mr-2 text-xl leading-none">
          {name}
        </span>
        <Tooltip>
          <TooltipTrigger>
            <Badge>v{version}</Badge>
          </TooltipTrigger>
          <TooltipContent>
            The current tray version.
          </TooltipContent>
        </Tooltip>
        <div className="flex-auto"/>
        <Badge variant="accent">{type}</Badge>
      </div>
      <div className="flex gap-1 rounded-lg bg-background p-1">
          <Button disabled={selection.aspect === "basics"} variant="selector" onClick={() => dispatch(select({
            scope: "blueprint",
            aspect: "basics",
            blueprintId: blueprintId,
          }))} className="grow px-1">
            <MixIcon />
            Basics
          </Button>
          <Button disabled={selection.aspect === "configuration"} variant="selector" onClick={() => dispatch(select({
            scope: "blueprint",
            aspect: "configuration",
            blueprintId: blueprintId,
          }))} className="grow px-1">
            <GearIcon/>
              Configuration
          </Button>
          <Button disabled={selection.aspect === "globals"} variant="selector" onClick={() => dispatch(select({
            scope: "blueprint",
            aspect: "globals",
            blueprintId: blueprintId,
          }))} className="grow px-1">
            <MixerHorizontalIcon/>
              Globals
          </Button>
        </div>
    </div>
  );
}
import { GroupProps, useThree } from "@react-three/fiber";

export default function ScreenshotCapture({ children, registerScreenshotSource, ...props} : GroupProps & {
  registerScreenshotSource?: (handler: () => string) => void,
}) {
  const { gl, scene, camera } = useThree();
  if (registerScreenshotSource !== undefined) {
    registerScreenshotSource(() => {
      gl.render(scene, camera);
      return gl.domElement.toDataURL();
    });
  }
  return children;
}
import { getTrayName } from "@/state/store";
import { Bounds, Insert, Message, Model } from "@/types";
import { boundsIntersectAny, boundsOffsetConatinsAll, boundsOffsetIntersectAny } from "@/utils";
import { Draft } from "@reduxjs/toolkit";

export function validateInsert(model: Draft<Model>) {
  const trays = model.insert.trayIds.map(id => model.trays[id]);
  const insertBounds: Bounds = {
    xOffset: 0,
    yOffset: 0,
    zOffset: 0,
    x: model.insert.configuration.x,
    y: model.insert.configuration.y,
    z: model.insert.configuration.z,
  };
  const messages: Message[] = [];
  for(const a of trays) {
    const aName = getTrayName(model.blueprints[a.blueprintId].name, a.name);
    for(const b of trays) {
      if(a === b) {
        continue;
      }
      const bName = getTrayName(model.blueprints[b.blueprintId].name, b.name);
      const key = [a.id, b.id].sort((a, b) => a - b).join("-");
      // check overlap
      const overlapKey = key + ":o";
      if(!messages.some(m => m.key == overlapKey)) {
        if(boundsOffsetIntersectAny(a.bounds, a.offset, b.bounds, b.offset)) {
          messages.push({
            type: "error",
            key: overlapKey,
            content: `The tray t{${aName}} overlaps with the tray t{${bName}}.`,
          });
        }
      }
      // check names
      const nameKey = aName + ":n";
      if(!messages.some(m => m.key == nameKey)) {
        if(aName === bName) {
          messages.push({
            type: "error",
            key: nameKey,
            content: `The tray name t{${aName}} is not unique.`,
          });
        }
      }
    }
    // check if within insert bounds
    const boundsKey = a.id + ":b";
    if(!messages.some(m => m.key === boundsKey)) {
      if(!boundsOffsetConatinsAll(a.bounds, a.offset, insertBounds, {
        x: 0,
        y: 0,
        z: 0,
      })) {
        messages.push({
          type: "error",
          key: boundsKey,
          content: `The tray t{${aName}} is not fully contained within the insert bounds.`,
        });
      }
    }
    // check if within insert bounds
    const associationKey = "association";
    if(!messages.some(m => m.key === associationKey)) {
      if(model.insert.boardgame === undefined) {
        messages.push({
          type: "warning",
          key: associationKey,
          content: `The insert is not associated with any board game.`,
        });
      }
    }
  }
  model.insert.messages = messages;
}
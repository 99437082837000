/* eslint-disable react/no-unknown-property */
import { useRef, useLayoutEffect, useEffect, forwardRef } from "react";
import { LineSegmentsProps, MeshProps,  useThree } from "@react-three/fiber";
import { BufferGeometry } from "three";
import {
  syncFaces,
  syncLines,
  highlightInGeometry,
  ReplicadMeshedEdges,
  ReplicadMeshedFaces,
  ThreeGeometry,
} from "replicad-threejs-helper";
import { ReplicadShapeHandle } from "./ReplicadShape";

export function useFaceGeometry(faces: ReplicadMeshedFaces) {
  const { invalidate } = useThree();
  const body = useRef(new BufferGeometry());
  useLayoutEffect(() => {
    if (!faces) {
      return;
    }
    syncFaces(body.current, faces, []);
    invalidate();
  }, [faces, invalidate]);
  useEffect(
    () => () => {
      body.current.dispose();
      invalidate();
    },
    [invalidate]
  );
  return body.current;
}

export function useLinesGeometry(edges: ReplicadMeshedEdges) {
  const { invalidate } = useThree();
  const lines = useRef(new BufferGeometry());
  useLayoutEffect(() => {
    syncLines(lines.current, edges, []);
    invalidate();
  }, [edges, invalidate]);
  useEffect(
    () => () => {
      lines.current.dispose();
      invalidate();
    },
    [invalidate]
  );
  return lines.current;
}

export function useApplyHighlights(geometry: BufferGeometry, highlight?: number[]) {
  const { invalidate } = useThree();
  useLayoutEffect(() => {
    if(highlight !== undefined) {
      highlightInGeometry(highlight, (geometry as unknown) as ThreeGeometry);
      invalidate();
    }
  }, [geometry, highlight, invalidate]);
}


interface ReplicadFacesMeshProps extends MeshProps {
  faces: ReplicadMeshedFaces,
  highlight?: number[],
}

export const ReplicadFacesMesh = forwardRef<ReplicadShapeHandle, ReplicadFacesMeshProps>(({
  faces,
  highlight,
  children,
  ...props
}, ref) => {
  const faceGeometry = useFaceGeometry(faces);
  useApplyHighlights(faceGeometry, highlight);
  return (
    <mesh {...props} ref={ref}>
      <primitive object={faceGeometry} attach="geometry"/>
      {children}
    </mesh>
  );
});
ReplicadFacesMesh.displayName = "ReplicadFacesMesh";

export function ReplicadEdgesMesh({
  edges,
  highlight,
  children,
  ...props
}: {
  edges: ReplicadMeshedEdges,
  highlight?: number[],
} & LineSegmentsProps) {
  const linesGeometry = useLinesGeometry(edges);
  useApplyHighlights(linesGeometry, highlight);
  return (
    <lineSegments {...props}>
      <primitive object={linesGeometry} attach="geometry"/>
      {children}
    </lineSegments>
  );
}
import React from "react";
import { Badge } from "@ui/badge";
import { cn } from "@ui/utils";

export default function DesignerMarkup({ markup, className }: { markup: string, className?: string }) {
  const inlines: React.ReactNode[] = [];
  let index = 0;
  while(index < markup.length) {
    const startIndices = [markup.indexOf("v{", index), markup.indexOf("n{", index), markup.indexOf("c{", index), markup.indexOf("u{", index), markup.indexOf("t{", index)].filter(i => i != -1);
    const startIndex = startIndices.length == 0 ? -1 : Math.min(...startIndices);
    if(startIndex == -1) {
      // push remaining text
      inlines.push(<span className="whitespace-pre-wrap">{markup.substring(index)}</span>);
      break;
    } else {
      // prepend text
      if(startIndex > index) {
        inlines.push(<span className="whitespace-pre-wrap">{markup.substring(index, startIndex)}</span>);
      }
      // add styled segment
      const endIndex = markup.indexOf("}", startIndex);
      if(endIndex == -1) {
        console.error("invalid markup: " + markup);
      } else {
        if(markup[startIndex] === "v") {
          inlines.push(<span className="italic">{markup.substring(startIndex + 2, endIndex)}</span>);
        } else if(markup[startIndex] === "n") {
          inlines.push(<span className="font-semibold">{markup.substring(startIndex + 2, endIndex)}</span>);
        } else if(markup[startIndex] === "u") {
          inlines.push(<span className="underline">{markup.substring(startIndex + 2, endIndex)}</span>);
        } else if(markup[startIndex] === "t") {
          inlines.push(<Badge className="rounded-t-none">{markup.substring(startIndex + 2, endIndex)}</Badge>);
        } else {
          inlines.push(<span className="font-mono italic">{markup.substring(startIndex + 2, endIndex)}</span>);
        }
        index = endIndex + 1;
      }
    }
  }
  return (
    <div className={cn("flex flex-wrap items-center", className)}>
      {...inlines}
    </div>
  );
}
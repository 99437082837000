import WorkbenchTray from "@/pages/workbench/WorkbenchTray";
import { DbTray, useLazyGetTrayQuery, useListTraysQuery } from "@/state/api/trays";
import { useAppDispatch } from "@/state/store";
import { Cross1Icon, MixIcon } from "@radix-ui/react-icons";
import { InputSearch } from "@ui/inputSearch";
import { ScrollArea } from "@ui/scroll-area";
import InsertDialogTrayLibraryTray from "./InsertDialogTrayLibraryTray";
import { useState } from "react";
import { imageUrlFromId, importBlueprint } from "@/utils";
import { Button } from "@ui/button";
import { addBlueprint } from "@/state/model";
import { DialogClose } from "@ui/dialog";
import { Spinner } from "@ui/spinner";

export default function InsertDialogTrayLibrary() {
  const dispatch = useAppDispatch();
  const { isFetching, isSuccess, data = [] } = useListTraysQuery({});
  const [ loadSharedTray, loadSharedTrayStatus ] = useLazyGetTrayQuery();
  const [selectedTray, setSelectedTray] = useState<DbTray | undefined>();
  const [query, setQuery] = useState("");
  if (isFetching) {
    return (
      <div className="flex gap-1">
        <Spinner/>
        Loading shared trays...
      </div>
    );
  } else if (isSuccess) {
    return (
      <div className="flex gap-1">
        <div className="flex w-full flex-col gap-1">
          <InputSearch value={query} onChange={e => setQuery(e.target.value)} onClear={() => setQuery("")}/>
          <ScrollArea className="flex-auto" type="auto">
            <div className="flex flex-col gap-1">
              {data.filter(t => t.name.toLowerCase().includes(query.toLowerCase())).map(t => <InsertDialogTrayLibraryTray key={t.id} tray={t} isSelected={t === selectedTray} select={() => setSelectedTray(t)} />)}
            </div>
          </ScrollArea>
        </div>
        <div className="flex w-52 flex-none flex-col">
          <div className="text-center">
            Preview
          </div>
          {selectedTray !== undefined ? (
            <>
              <img className="size-52 rounded-sm object-contain p-1" src={imageUrlFromId("trays", selectedTray.id, selectedTray.images[0])}/>
              <DialogClose asChild>
                <Button onClick={async () => {
                  const trayQuery = await loadSharedTray({
                    id: selectedTray.id,
                  });
                  if(trayQuery.isSuccess) {
                    const blueprint = importBlueprint(trayQuery.data.content);
                    if(blueprint !== null) {
                      dispatch(addBlueprint({
                        blueprint: blueprint,
                        selectIn: "insert",
                      }));
                    }
                  }
                }}>
                  Add to insert
                </Button>
              </DialogClose>
            </>
          ) : (
            <div className="flex flex-col">
              <MixIcon className="size-52 text-slate-100"/>
              <div className="text-center text-sm">
                Select tray to show preview
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex gap-1">
        <Cross1Icon />
        Failed to load shared trays.
      </div>
    );
  }
}
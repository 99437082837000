import { useNavigate } from "react-router-dom";
import { DbSearchDocument } from "@/state/api/documents";
import { getTimestampDescription } from "@/utils";
import { CalendarIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { Section } from "@ui/section";
import TextHighlight from "@ui/textHighlight";

export function DocumentsCardSearch({ document, query }: { document: DbSearchDocument, query: string }) {
  const navigate = useNavigate();
  let excerpt = document.summary;
  const index = excerpt.toLowerCase().indexOf(query.toLowerCase());
  if(index === -1) {
    excerpt = document.description;
  } else {
    if(index - 50 > 0) {
      if(index + query.length + 50 < excerpt.length) {
        excerpt = "..." + excerpt.substring(index - 50, index + query.length + 50).trim() + "...";
      } else {
        excerpt = "..." + excerpt.substring(index - 50).trimStart();
      }
    } else if(index + query.length + 50 < excerpt.length) {
      excerpt = excerpt.substring(0, index + query.length + 50).trimEnd() + "...";
    }
  }
  return (
    <Section onClick={() => navigate("/documents/" + document.id)} className="cursor-pointer">
      <div className="flex items-center gap-2">
        <CalendarIcon />
        {getTimestampDescription(document.timestamp)}
        <div className="grow" />
        {document.views} views
      </div>
      <TextHighlight text={document.title} highlight={query} textClassName="font-semibold leading-none" highlightClassName="font-semibold leading-none bg-warning rounded-[2px]"/>
      <div className="leading-tight text-foreground-muted">
        <TextHighlight text={excerpt} highlight={query} highlightClassName="bg-warning rounded-[2px]"/>
      </div>
      <div className="flex items-center gap-2 text-primary">
        Read article <ChevronRightIcon />
      </div>
    </Section>
  );
}
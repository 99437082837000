import { selectCurrentElement, selectCurrentBlueprint, useAppSelector } from "@/state/store";
import { useState } from "react";
import { BlueprintPropertiesBasics } from "./blueprint/BlueprintPropertiesBasics";
import { BlueprintPropertiesConfiguration } from "./blueprint/BlueprintPropertiesConfiguration";
import BlueprintPropertiesGlobals from "./blueprint/BlueprintPropertiesGlobals";
import { PocketConfigurationProperties } from "./blueprint/element/PocketConfigurationProperties";
import { ContainerConfigurationProperties } from "./blueprint/element/ContainerConfigurationProperties";
import { InputSearch } from "@/components/ui/inputSearch";
import { ScrollArea } from "@/components/ui/scroll-area";
import { InsertBasicsProperties } from "./insert/InsertBasicsProperties";
import { ElementConfiguration } from "./blueprint/element/ElementConfiguration";
import InsertGlobalsProperties from "./insert/InsertGlobalsProperties";
import InsertConfigurationProperties from "./insert/InsertConfigurationProperties";
import InsertPropertiesTray from "./insert/InsertPropertiesTray";


export default function Properties({ trayId }: { trayId?: number }) {
  const selection = useAppSelector(s => s.model.selection);
  const [query, setQuery] = useState("");
  let content: JSX.Element;
  if (selection.scope === "insert") {
    if (selection.aspect === "tray") {
      content = <InsertPropertiesTray query={query} />;
    } else if (selection.aspect === "basics") {
      content = <InsertBasicsProperties query={query} />;
    } else if (selection.aspect === "configuration") {
      content = <InsertConfigurationProperties query={query} />;
    } else if (selection.aspect === "globals") {
      content = <InsertGlobalsProperties query={query} />;
    } else {
      content = (
        <div className="flex items-center justify-center">
          Select something to view its properties.
        </div>
      );
    }
  } else {
    if (selection.aspect === "basics") {
      content = <BlueprintPropertiesBasics query={query} blueprintId={selection.blueprintId} />;
    } else if (selection.aspect === "configuration") {
      content = <BlueprintPropertiesConfiguration query={query} blueprintId={selection.blueprintId} />;
    } else if (selection.aspect === "globals") {
      content = <BlueprintPropertiesGlobals query={query} blueprintId={selection.blueprintId} />;
    } else if (selection.aspect === "element") {
      content = <ElementConfiguration query={query} trayId={trayId!} blueprintId={selection.blueprintId} elementId={selection.elementId}/>
    } else {
      content = (
        <div className="flex items-center justify-center">
          Select something to view its properties.
        </div>
      );
    }
  }
  return (
    <div className="flex h-full flex-col">
      <InputSearch name="search" value={query} onChange={e => setQuery(e.target.value)} onClear={() => setQuery("")} className="-m-px mb-0" inputClassName="shadow" />
      <ScrollArea slim className="grow px-2">
        <div className="my-2">
          {content}
        </div>
      </ScrollArea>
    </div>
  );

}
import NavigationMenu from "@/pages/navigation/NavigationMenu";
import { Outlet } from "react-router-dom";

export default function FullscreenLayout({ showInsertToolbar } : { showInsertToolbar: boolean }) {
  return (
    <div className="flex h-screen flex-col">
      <div className="h-12 shrink-0 border-b bg-control px-2">
        <NavigationMenu showInsert={false} showInsertToolbar={showInsertToolbar} scope="designer"/>
      </div>
      <main className="flex w-full grow flex-col self-center overflow-hidden">
        <Outlet/>
      </main>
    </div>
  );
}
import { useEffect } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "@/state/store";
import { Label } from "@ui/label";
import { Input } from "@ui/input";
import { useRef, useState } from "react";
import { Textarea } from "@ui/textarea";
import { Button } from "@ui/button";
import { ChevronLeftIcon, Pencil2Icon } from "@radix-ui/react-icons";
import { imageUrlFromId, toMySqlDateString } from "@/utils";
import { DbDocumentDetails, useAddDocumentMutation, useEditDocumentMutation, useGetDocumentQuery } from "@/state/api/documents";
import { Page } from "@ui/page";
import { Editor, EditorHandle, Viewer } from "@ui/editor";
import { useReporter } from "@/state/messages";
import { decompressFromBase64 } from "lz-string";
import { DocumentType } from "@/types";

interface PostImage {
  name: string,
  source: string,
}

export function DocumentsPublishUpdate() {
  return Form({
    data: undefined,
    id: undefined,
  });
}

export function DocumentsEditUpdate() {
  const { id } = useParams();
  const query = useGetDocumentQuery({
    id: parseInt(id!),
  });
  return Form({
    data: query.data,
    id: parseInt(id!),
  });
}

function Form({ data, id }: {data: DbDocumentDetails | undefined, id: number | undefined }) {
  const reporter = useReporter();
  const navigate = useNavigate();
  const session = useAppSelector(s => s.session);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [title, setTitle] = useState(data?.title ?? "");
  const [timestamp, setTimestamp] = useState(new Date(data?.timestamp === undefined ? Date.now() : Date.parse(data.timestamp)));
  const [content, setContent] = useState(data?.content ?? "");
  const [description, setDescription] = useState(data?.description ?? "");
  const [slug, setSlug] = useState(data?.slug ?? "");
  const inputFile = useRef<HTMLInputElement>(null);
  const [ addDocument, addDocumentStatus ] = useAddDocumentMutation();
  const [ editDocument, editDocumentStatus ] = useEditDocumentMutation();
  const editorRef = useRef<EditorHandle>(null);
  useEffect(() => {
    if(textareaRef.current !== null && data?.content !== undefined) {
      textareaRef.current.textContent = data.content;
    }
  } , []);
  if (session.user === undefined) {
    return <Navigate to="/?login=true&redirect=workbench" />;
  } else if (!session.user.privileges.includes("post")) {
    return (
      <div>
        No post privileges.
      </div>
    );
  }
  return (
    <Page>
      <div className="flex gap-2">
        <Link to="/documents">
          <Button variant="outline">
            <ChevronLeftIcon/>
            Back to Updates
          </Button>
        </Link>
        <div className="flex items-center gap-2 text-lg">
          <Pencil2Icon/>
          New Post
        </div>
        <div className="flex-auto"/>
        <Button disabled={addDocumentStatus.isLoading || editDocumentStatus.isLoading} onClick={async () => {
          const content = editorRef.current!.editor.getEditorState().toJSON();
          if(data === undefined || id === undefined) {
            const response = await addDocument({
              slug: slug,
              type: DocumentType.Update,
              title: title,
              timestamp: toMySqlDateString(timestamp),
              description: description,
              content,
            });
            if(response.error !== undefined) {
              reporter.error("Failed to post document: " + String(response.error));
            } else {
              navigate("/documents/" + response.data.id)
            }
          } else {
            const response = await editDocument({
              id: id,
              title: title,
              description: description,
              content,
              timestamp: toMySqlDateString(timestamp),
            });
            if(response.error !== undefined) {
              reporter.error("Failed to edit document: " + String(response.error));
            } else {
              navigate("/documents/" + id)
            }
          }
        }}>
          {data === undefined || id === undefined ? "Publish" : "Update"}
        </Button>
      </div>
      <div className="flex flex-col gap-2 rounded border bg-control p-4">
        <div className="flex gap-2">
          <div className="flex grow-[3] flex-col gap-2">
            <Label htmlFor="title">
              Title
            </Label>
            <Input id="title" value={title} onChange={e => setTitle(e.target.value)} />
          </div>
          <div className="flex grow flex-col gap-2">
            <Label htmlFor="slug">
              Slug
            </Label>
            <Input disabled={data !== undefined && id !== undefined} id="slug" value={slug} onChange={e => setSlug(e.target.value.toLowerCase().replaceAll(/[^a-z\\-]/g,"-"))} />
          </div>
          <div className="flex w-36 flex-col gap-2">
            <Label htmlFor="timestamp">
              Timestamp
            </Label>
            <Input id="timestamp" defaultValue={toMySqlDateString(timestamp)} onChange={e => setTimestamp(new Date(Date.parse(e.target.value)))} />
          </div>
        </div>
        <Label htmlFor="description">
          Description
        </Label>
        <Textarea id="description" value={description} onChange={e => setDescription(e.target.value)} className="flex-auto"/>
        <Label htmlFor="content">
          Content
        </Label>
        {(data === undefined || id === undefined) ? (
          <Editor className="min-h-96" ref={editorRef} imageResolver={() => ""}/>
        ) : (
          <Editor className="min-h-96" initialState={decompressFromBase64(data.content)} ref={editorRef} imageResolver={name => imageUrlFromId("documents", id, name)}/>
        )}
      </div>
    </Page>
  );
}
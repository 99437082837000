import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@ui/resizable";
import { ScrollArea } from "@ui/scroll-area";
import Properties from "../Properties";
import InsertActions from "./InsertActions";
import InsertSidebarHeader from "./InsertSidebarHeader";
import InsertCanvas from "./InsertCanvas";
import CanvasContainer from "../canvas/CanvasContainer";
import InsertToolbar from "./InsertToolbar";
import InsertSidebarTrayList from "./InsertSidebarTrayList";
import { Section } from "@ui/section";
import { ScrollSection } from "@ui/ScrollSection";
import { selectInsertName, useAppSelector } from "@/state/store";
import { useEffect } from "react";

export default function InsertDesigner() {
  const settingCanvasToolbarInNavbar = useAppSelector(s => s.settings.settingCanvasToolbarInNavbar);
  const insertName = useAppSelector(s => selectInsertName(s));
  useEffect(() => {
    document.title = insertName;
  }, [ insertName ]);
  return (
    <ResizablePanelGroup direction="horizontal" autoSaveId="blueprint-panels-layout" storage={localStorage}>
      <ResizablePanel className="flex flex-col gap-1 p-1">
        {!settingCanvasToolbarInNavbar && (
          <InsertToolbar />
        )}
        <CanvasContainer>
          <InsertCanvas/>
        </CanvasContainer>
      </ResizablePanel>
      <ResizableHandle />
      <ResizablePanel defaultSize={20} className="min-w-[330px] max-w-[600px]">
        <ResizablePanelGroup direction="vertical">
          <ResizablePanel className="flex h-full flex-col p-1">
            <InsertActions />
            <ScrollSection slim className="grow">
              <InsertSidebarHeader />
              <InsertSidebarTrayList />
            </ScrollSection>
          </ResizablePanel>
          <ResizableHandle />
          <ResizablePanel defaultSize={50} className="min-h-[300px] p-1">
            <div className="size-full rounded border bg-control">
              <Properties />
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </ResizablePanel >
    </ResizablePanelGroup >
  );
}
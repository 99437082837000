import { ReactNode, useEffect, useMemo, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Box3, Group, LineSegments, Mesh, Object3DEventMap, Vector3, Plane, PlaneGeometry, MeshBasicMaterial, AlwaysStencilFunc, BackSide, IncrementWrapStencilOp, FrontSide, DecrementWrapStencilOp, NotEqualStencilFunc, ReplaceStencilOp, MeshMatcapMaterial } from "three";
import { ClippingPlaneConfiguration } from "@/types";
import { normalFromOrientedAxis } from "@/utils";

const forwardVector = new Vector3(0, 0, -1);

function initStencilMaterials(sideColor = "yellow") {
  // PASS 1
  // everywhere that the back faces are visible (clipped region) the stencil
  // buffer is incremented by 1.
  const backFaceStencilMat = new MeshMatcapMaterial();
  backFaceStencilMat.depthWrite = false;
  backFaceStencilMat.depthTest = false;
  backFaceStencilMat.colorWrite = false;
  backFaceStencilMat.stencilWrite = true;
  backFaceStencilMat.stencilFunc = AlwaysStencilFunc;
  backFaceStencilMat.side = BackSide;
  backFaceStencilMat.stencilFail = IncrementWrapStencilOp;
  backFaceStencilMat.stencilZFail = IncrementWrapStencilOp;
  backFaceStencilMat.stencilZPass = IncrementWrapStencilOp;
  // PASS 2
  // everywhere that the front faces are visible the stencil
  // buffer is decremented back to 0.
  const frontFaceStencilMat = new MeshMatcapMaterial();
  frontFaceStencilMat.depthWrite = false;
  frontFaceStencilMat.depthTest = false;
  frontFaceStencilMat.colorWrite = false;
  frontFaceStencilMat.stencilWrite = true;
  frontFaceStencilMat.stencilFunc = AlwaysStencilFunc;
  frontFaceStencilMat.side = FrontSide;
  frontFaceStencilMat.stencilFail = DecrementWrapStencilOp;
  frontFaceStencilMat.stencilZFail = DecrementWrapStencilOp;
  frontFaceStencilMat.stencilZPass = DecrementWrapStencilOp;
  // PASS 3
  // draw the plane everywhere that the stencil buffer != 0, which will
  // only be in the clipped region where back faces are visible.
  const planeStencilMat = new MeshMatcapMaterial({
    color: sideColor,
  });
  planeStencilMat.stencilWrite = true;
  planeStencilMat.stencilRef = 0;
  planeStencilMat.stencilFunc = NotEqualStencilFunc;
  planeStencilMat.stencilFail = ReplaceStencilOp;
  planeStencilMat.stencilZFail = ReplaceStencilOp;
  planeStencilMat.stencilZPass = ReplaceStencilOp;
  return [frontFaceStencilMat, backFaceStencilMat, planeStencilMat];
}

export default function ClipPlane({ configuration, sideColor, children }: { configuration?: ClippingPlaneConfiguration, sideColor: string, children: ReactNode }) {
  const clippingPlane = useMemo<Plane | null>(() => {
    if (!configuration) {
      return null;
    }
    const plane = new Plane();
    const normal = normalFromOrientedAxis(configuration.axis);
    plane.normal.set(normal.x, normal.y, normal.z);
    plane.constant = configuration.axis % 2 === 0 ? configuration.maxOffset - configuration.offset : -configuration.offset;
    return plane;
  }, [configuration]);
  const groupRef = useRef<Group>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const planeMeshRef = useRef<Mesh<PlaneGeometry, any, Object3DEventMap>>();
  useEffect(() => {
    const group = groupRef.current!;
    if (clippingPlane === null) {
      group.traverse((node) => {
        if (!(node instanceof Mesh || node instanceof LineSegments))
          return;
        if (Array.isArray(node.material)) {
          node.material.forEach((n) => (n.clippingPlanes = []));
        } else node.material.clippingPlanes = [];
      });
      return;
    }
    const [frontFaceStencilMat, backFaceStencilMat, planeStencilMat] = initStencilMaterials(sideColor);
    frontFaceStencilMat.clippingPlanes = [clippingPlane];
    backFaceStencilMat.clippingPlanes = [clippingPlane];
    group.traverse((node) => {
      if (!(node instanceof Mesh || node instanceof LineSegments)) {
        return;
      }
      if (Array.isArray(node.material)) {
        node.material.forEach((n) => (n.clippingPlanes = [clippingPlane]));
      } else {
        node.material.clippingPlanes = [clippingPlane];
      }
    });
    const front = group.clone();
    front.traverse((node) => {
      if (!(node instanceof Mesh)) {
        return;
      }
      node.material = frontFaceStencilMat;
    });
    const back = group.clone();
    back.traverse((node) => {
      if (!(node instanceof Mesh)) {
        return;
      }
      node.material = backFaceStencilMat;
    });
    const planeGeom = new PlaneGeometry();
    const planeMesh = new Mesh(planeGeom, planeStencilMat);
    planeMesh.quaternion.setFromUnitVectors(
      forwardVector,
      clippingPlane.normal
    );
    // We need double the radius to make sure to cover the whole object
    const bbox = new Box3().setFromObject(group);
    const radius = Math.max(bbox.max.distanceTo(new Vector3()), bbox.min.distanceTo(new Vector3()));
    planeMesh.scale.setScalar(radius * 2);
    planeMesh.renderOrder = 2;
    planeMeshRef.current = planeMesh;
    planeMesh.position.copy(
      clippingPlane.normal.clone().multiplyScalar(-clippingPlane.constant)
    );
    group.add(front, back, planeMesh);
    return () => {
      group.remove(front, back, planeMesh);
    };
  }, [clippingPlane, sideColor, children]);
  useFrame(() => {
    if (clippingPlane === null || planeMeshRef.current === undefined) {
      return;
    }
    planeMeshRef.current.position.copy(
      clippingPlane.normal.clone().multiplyScalar(-clippingPlane.constant)
    );
  });
  return <group ref={groupRef}>{children}</group>;
}
import { selectBlueprint, selectInsertTrays, selectTrayColor, selectTrayName, useAppSelector, selectInsertName, getTrayAssociationName, getTrayAssociation } from "@/state/store";
import { ArrowLeftIcon, DownloadIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { Button } from "@ui/button";
import { useCallback, useEffect, useState } from "react";
import { Label } from "@ui/label";
import { Input } from "@ui/input";
import { downloadBlob, getResponseError } from "@/utils";
import { SectionDescription } from "@ui/sectionDescription";
import { Section } from "@ui/section";
import { SectionLabel } from "@ui/sectionLabel";
import { Badge } from "@ui/badge";
import { shallowEqual } from "react-redux";
import { Spinner } from "@ui/spinner";
import { generateTrayStl } from "@/replicadWorkerPool";
import { DbPrinter, useLazyGetPrinterQuery, useRatePrinterMutation } from "@/state/api/printers";
import { InputPrinterSearch } from "@/components/InputPrinterSearch";
import { InputNumber } from "@ui/inputNumber";
import { produce } from "immer";
import { Checkbox } from "@ui/checkbox";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogTitle, DialogTrigger } from "@ui/dialog";
import { PrintersDialogCreate } from "@/pages/printers/PrintersDialogAdd";
import { useReporter } from "@/state/messages";
import { Icon } from "@ui/icon";
import { Rating } from "@ui/rating";
import { Boardgame, Tray } from "@/types";
import { export3mf, exportZip } from "@/insertExporter";
import Pack from "@mapbox/shelf-pack"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@ui/select";
interface DownloadTray {
  blob?: Blob,
  tray: Tray,
  download: boolean,
}

interface DownloadPlate {
  id: number,
  name: string,
  trays: {
    id: number,
    x: number,
    y: number,
    width: number,
    height: number,
    color?: string,
  }[],
}

function Plates({ plates, plateWidth, plateHeight, useColors, updateName }: { plates: DownloadPlate[], plateWidth: number, plateHeight: number, useColors: boolean, updateName: (plateId: number, name: string) => void }) {
  let scale: number;
  const size = 180;
  if (plateWidth >= plateHeight) {
    scale = size / plateWidth;
  } else {
    scale = size / plateHeight;
  }
  return plates.map(p => (
    <div key={p.id} className="flex flex-col" style={{
      width: (size + 16) + "px",
    }}>
      <div className="rounded-t border bg-control p-2">
        <div style={{
          width: (plateWidth * scale) + "px",
          height: (plateHeight * scale) + "px",
        }} className="relative border border-dashed">
          {p.trays.map(t => (
            <div key={t.id} className={"absolute" + (useColors ? "" : " bg-border-strong")} style={{
              top: (t.y * scale) + "px",
              left: (t.x * scale) + "px",
              height: (t.height * scale) + "px",
              width: (t.width * scale) + "px",
              backgroundColor: t.color,
            }} />
          ))}
        </div>
      </div>
      <Input value={p.name} onChange={v => updateName(p.id, v.target.value)} className="-mt-px rounded-t-none bg-accent" />
    </div>
  ));
}

export function InsertDialogDownload() {
  const reporter = useReporter();
  const primaryPrinterId = useAppSelector(s => s.session.user?.primaryPrinterId);
  const insertTrays = useAppSelector(s => selectInsertTrays(s), shallowEqual);
  const insert = useAppSelector(s => s.model.insert, shallowEqual);
  const [printer, setPrinter] = useState<DbPrinter>();
  const [mode, setMode] = useState<"zip" | "3mf" | "stl">("3mf");
  const [ratePrinter] = useRatePrinterMutation();
  const [isBusy, setIsBusy] = useState(true);
  const [filamentFromColor, setFilamentFromColor] = useState(true);
  const [distributeFilaments, setDistributeFilaments] = useState(true);
  const [arrangeOnPlates, setArrangeOnPlates] = useState(true);
  const [getPrinter] = useLazyGetPrinterQuery();
  const [traySpacing, setTraySpacing] = useState(10);
  const [trays, preSetTrays] = useState<DownloadTray[]>(insertTrays.map(t => ({
    tray: t,
    download: true,
  })));
  const setTrays = useCallback((handler: (draft: DownloadTray[]) => void) => preSetTrays(old => produce(old, draft => handler(draft))), [preSetTrays]);
  const [plates, preSetPlates] = useState<DownloadPlate[]>([]);
  const setPlates = useCallback((handler: (draft: DownloadPlate[]) => void) => preSetPlates(old => produce(old, draft => handler(draft))), [preSetTrays]);
  useEffect(() => {
    const work = async () => {
      setIsBusy(true);
      if (primaryPrinterId !== undefined && printer === undefined) {
        const response = await getPrinter({
          id: primaryPrinterId,
        });
        if (response.isSuccess && response.data !== undefined) {
          setPrinter(response.data);
        }
      }
      for (const tray of trays) {
        const blob = await generateTrayStl(tray.tray.id);
        if (blob !== null) {
          setTrays(s => {
            s.find(t => t.tray.id === tray.tray.id)!.blob = blob;
          });
        }
      }
      setIsBusy(false);
    };
    work();
  }, []);
  useEffect(() => {
    if (!arrangeOnPlates || insert.boardgame === undefined || printer === undefined) {
      return;
    }
    const traysMap = new Map<Boardgame, Tray[]>();
    if (filamentFromColor && distributeFilaments) {
      for (const t of trays.filter(t => t.download)) {
        const association = getTrayAssociation(t.tray, insert)!;
        const existing = traysMap.get(association);
        if (existing === undefined) {
          traysMap.set(association, [t.tray]);
        } else {
          existing.push(t.tray);
        }
      }
    } else {
      traysMap.set(insert.boardgame!, trays.filter(t => t.download).map(t => t.tray));
    }
    const plates: DownloadPlate[] = [];
    let nextId = 0;
    for (const association of traysMap.keys()) {
      let plate: DownloadPlate = {
        name: association.name,
        trays: [],
        id: nextId++,
      };
      let packer = new Pack(printer!.x, printer!.y);
      for (const tray of traysMap.get(association)!) {
        const bin = packer.packOne(tray.size.x + traySpacing, tray.size.y + traySpacing);
        if (bin) {
          plate.trays.push({
            id: tray.id,
            x: bin.x,
            y: bin.y,
            width: tray.size.x,
            height: tray.size.y,
            color: filamentFromColor ? selectTrayColor(tray.id) : undefined,
          });
        } else {
          const offsetX = (printer!.x - Math.max(...plate.trays.map(t => t.x + t.width))) / 2;
          const offsetY = (printer!.y - Math.max(...plate.trays.map(t => t.y + t.height))) / 2;
          for(const tray of plate.trays) {
            tray.x += offsetX;
            tray.y += offsetY;
          }
          plates.push(plate);
          plate = {
            name: association.name,
            trays: [],
            id: nextId++,
          };
          packer = new Pack(printer!.x, printer!.y);
          const bin = packer.packOne(tray.size.x + traySpacing, tray.size.y + traySpacing);
          if (bin) {
            plate.trays.push({
              id: tray.id,
              x: bin.x,
              y: bin.y,
              width: tray.size.x,
              height: tray.size.y,
              color: filamentFromColor ? selectTrayColor(tray.id) : undefined,
            });
          } else {
            reporter.error(`The tray ${selectTrayName(tray.id)} does not fit on any build plate.`)
          }
        }
      }
      if (plate.trays.length > 0) {
        const offsetX = (printer!.x - Math.max(...plate.trays.map(t => t.x + t.width))) / 2;
        const offsetY = (printer!.y - Math.max(...plate.trays.map(t => t.y + t.height))) / 2;
        for(const tray of plate.trays) {
          tray.x += offsetX;
          tray.y += offsetY;
        }
        plates.push(plate);
      }
    }
    setPlates(draft => {
      draft.splice(0, draft.length);
      draft.push(...plates);
    });
  }, [trays, arrangeOnPlates, filamentFromColor, distributeFilaments, traySpacing, printer, insert]);
  /*
  if (session.user === undefined) {
    return <Navigate to="/?login=true&redirect=workbench" />;
  }*/
  return (
    <Section nested>
      <DialogTitle>
        Download insert
      </DialogTitle>
      <SectionLabel>
        Select download type
      </SectionLabel>
      <Select value={mode} onValueChange={(v: "3mf" | "zip" | "stl") => setMode(v)}>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="3mf">
            <span className="mr-2 font-semibold">3MF container</span>
            <span className="text-foreground-muted">A single 3MF file that contains all trays including a plate distribution.</span>
          </SelectItem>
          <SelectItem value="zip">
            <span className="mr-2 font-semibold">ZIP archive</span>
            <span className="text-foreground-muted">A single ZIP file that contains an STL file for every tray of the insert.</span>
          </SelectItem>
          <SelectItem value="stl">
            <span className="mr-2 font-semibold">STL files</span>
            <span className="text-foreground-muted">One STL file for every tray of the insert. Multiple downloads.</span>
          </SelectItem>
        </SelectContent>
      </Select>
      {mode === "3mf" && (
        <>
          <div className="mt-2 flex gap-4">
            <Section nested className="flex-1">
              <SectionLabel htmlFor="game">
                Select your printer
              </SectionLabel>
              <SectionDescription>
                The printbed size is used to organize build plates. This allows the creation of a single <Badge>3MF</Badge> file that you can download.
              </SectionDescription>
              <InputPrinterSearch onSelectResult={p => {
                setPrinter(p);
              }} clearOnSelect autoFocus />
            </Section>
            <Section className="-mr-4 flex-1 rounded-r-none border-r-0 bg-background p-2 pr-4">
              {printer !== undefined ? (
                <>
                  <div className="flex flex-col gap-2 rounded border bg-control p-4">
                    <table className="z-10 flex-auto table-auto text-sm">
                      <tbody>
                        <tr>
                          <td className="w-0 pr-2">Name:</td>
                          <td className="font-semibold">{printer.name}</td>
                        </tr>
                        <tr>
                          <td className="w-0 pr-2">Printbed:</td>
                          <td className="font-mono">{printer.x}x{printer.y}mm</td>
                        </tr>
                        <tr>
                          <td className="w-0 pr-2">Rating:</td>
                          <td><Rating rating={printer.rating} deleteValue={-10} /></td>
                        </tr>
                      </tbody>
                    </table>
                    {printer.rating < 0 && (
                      <div className="flex items-center gap-2">
                        <Icon name="exclamation-triangle" className="text-yellow-600" />
                        <div className="text-sm leading-tight text-foreground-muted">
                          This printer has a negative rating which indicates that the provided printbed size might be incorrect. If you are familiar with the printer, please validate the data and submit a rating.
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex gap-2">
                    <Button variant="destructive" onClick={() => setPrinter(undefined)} size="sm">
                      Clear selection
                    </Button>
                    <div className="flex-auto" />
                    <Button size="sm" onClick={async () => {
                      const response = await ratePrinter({
                        id: printer.id,
                        rating: "down",
                      });
                      const error = getResponseError(response);
                      if (error !== null) {
                        reporter.error(error);
                      } else {
                        setPrinter({
                          ...printer,
                          rating: printer.rating - 1,
                        });
                      }
                    }}>
                      <Icon name="hand-thumbs-down" className="text-destructive" />
                    </Button>
                    <Button size="sm" onClick={async () => {
                      const response = await ratePrinter({
                        id: printer.id,
                        rating: "up",
                      });
                      const error = getResponseError(response);
                      if (error !== null) {
                        reporter.error(error);
                      } else {
                        setPrinter({
                          ...printer,
                          rating: printer.rating + 1,
                        });
                      }
                    }}>
                      <Icon name="hand-thumbs-up" />
                    </Button>
                  </div>
                </>
              ) : (
                <div className="flex h-full items-center justify-center">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2 text-foreground-muted">
                      <ArrowLeftIcon />
                      Search and select a printer
                    </div>
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button size="sm">
                          <PlusCircledIcon />
                          Add new printer
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="w-80">
                        <PrintersDialogCreate />
                      </DialogContent>
                    </Dialog>
                  </div>
                </div>
              )}
            </Section>
          </div>
          {printer !== undefined && (
            <>
              <SectionLabel>
                Configure 3MF container
              </SectionLabel>
              <div className="flex items-center gap-2">
                <Checkbox id="arrange-on-plates" checked={arrangeOnPlates} onCheckedChange={v => setArrangeOnPlates(Boolean(v))} />
                <Label htmlFor="arrange-on-plates" className="font-normal">
                  Arrange trays on plates
                </Label>
              </div>
              <div className="flex items-center gap-2">
                <Checkbox id="filament-from-color" checked={filamentFromColor} onCheckedChange={v => setFilamentFromColor(Boolean(v))} />
                <Label htmlFor="filament-from-color" className="font-normal">
                  Use a different filaments for each color
                </Label>
              </div>
              {filamentFromColor && arrangeOnPlates && (
                <div className="flex items-center gap-2">
                  <Checkbox id="distribute-filaments" checked={distributeFilaments} onCheckedChange={v => setDistributeFilaments(Boolean(v))} />
                  <Label htmlFor="distribute-filaments" className="font-normal">
                    Use separate plates for each filament
                  </Label>
                </div>
              )}
              {arrangeOnPlates && (
                <div className="flex items-center gap-2">
                  <Label className="font-normal">
                    Tray spacing
                  </Label>
                  <InputNumber unit="mm" value={traySpacing} min={0} setValue={v => setTraySpacing(v)} />
                </div>
              )}
              {arrangeOnPlates && plates.length > 0 && (
                <>
                  <SectionLabel>
                    Plates
                  </SectionLabel>
                  <div className="-mx-4 flex flex-wrap gap-2 border-y bg-background px-4 py-2">
                    <Plates plates={plates} plateWidth={printer.x} plateHeight={printer.y} useColors={filamentFromColor} updateName={(plateId, name) => {
                      setPlates(draft => {
                        draft.find(o => o.id == plateId)!.name = name;
                      });
                    }} />
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
      <SectionLabel>
        Trays
      </SectionLabel>
      {trays.map(t => (
        <div key={t.tray.id} className="rounded border-b-2" style={{
          borderColor: selectTrayColor(t.tray.id),
        }}>
          <div className="flex items-center gap-2 rounded-t border-x border-t p-2">
            <Checkbox id={"t-" + t.tray.id} checked={t.download} onCheckedChange={v => {
              setTrays(s => {
                s.find(o => o.tray.id === t.tray.id)!.download = Boolean(v);
              });
            }} />
            <Label htmlFor={"t-" + t.tray.id} className="font-normal">
              {selectTrayName(t.tray.id)}
            </Label>
            {t.blob === undefined && (
              <>
                <Spinner />
                Generating stl file...
              </>
            )}
            <div className="grow" />
            <div className="text-foreground-muted">
              {getTrayAssociationName(t.tray, insert, true)}
            </div>
          </div>
        </div>
      ))}
      <DialogFooter className="justify-between">
        <DialogClose asChild>
          <Button disabled={isBusy} variant="primary" onClick={async () => {
            if (mode === "zip") {
              const blob = await exportZip(trays.filter(t => t.download).map(t => ({
                name: selectTrayName(t.tray.id) + ".stl",
                model: t.blob!
              })));
              downloadBlob(blob, selectInsertName());
            } else if (mode === "stl") {
              for (const t of trays.filter(t => t.download)) {
                downloadBlob(t.blob!, selectTrayName(t.tray.id) + ".stl");
              }
            } else {
              if (printer === undefined) {
                return;
              }
              const blob = await export3mf(plates.map(p => ({
                width: printer.x,
                height: printer.y,
                name: p.name,
                models: p.trays.map(t => ({
                  x: t.x,
                  y: t.y,
                  model: trays.find(o => o.tray.id === t.id)!.blob!,
                  name: selectTrayName(t.id),
                })),
              })));
              downloadBlob(blob, selectInsertName() + ".3mf");
            }
          }}>
            <DownloadIcon />
            Download
          </Button>
        </DialogClose>
        <div className="text-foreground-muted">
          {trays.filter(t => t.download).length} trays selected for download
        </div>
      </DialogFooter>
    </Section>
  );
}
import { useEffect, useMemo } from "react";
import InsertDesigner from "./insert/InsertDesigner";
import BlueprintDesigner from "./blueprint/BlueprintDesigner";
import {  useAppDispatch, useAppSelector } from "@/state/store";
import { restoreBlueprint, restoreModel } from "../../state/model";
import { useSearchParams } from "react-router-dom";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@ui/dialog";
import { importBlueprint } from "@/utils";
import { useLazyGetTrayQuery } from "@/state/api/trays";
import { SectionLabel } from "@ui/sectionLabel";
import { SectionDescription } from "@ui/sectionDescription";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogFooter, AlertDialogTitle } from "@ui/alert-dialog";
import { Input } from "@ui/input";
import { loadModelFromLocalStorage } from "@/localStorage";

export default function Designer() {
  const dispatch = useAppDispatch();
  const scope = useAppSelector(s => s.model.selection.scope);
  const autosave = useMemo(() => {
    return loadModelFromLocalStorage();
  }, []);
  const recordCount = useAppSelector(s => s.model.history.records.length);
  const [ searchParams ] = useSearchParams();
  const [ loadSharedTray, loadSharedTrayStatus ] = useLazyGetTrayQuery();
  const loadTrayId = searchParams.get("tray");
  useEffect(() => {
    if(loadTrayId !== null && loadSharedTrayStatus.isUninitialized) {
      loadSharedTray({
        id: parseInt(loadTrayId),
      }).then(result => {
        if(result.isSuccess) {
          const blueprint = importBlueprint(result.data.content);
          if(blueprint !== null) {
            blueprint.library = {
              libraryId: loadTrayId,
              version: result.data.latestVersion,
              timestamp: result.data.timestamp,
              isIsolated: true,
            };
            console.log("restore blueprint");
            dispatch(restoreBlueprint({
              blueprint: blueprint
            }));
          }
        }
      });
    }
  }, [ ]);
  return (
    <>
      {scope === "blueprint" ? (
        <BlueprintDesigner/>
      ) : (
        <InsertDesigner/>
      )}
      {loadSharedTrayStatus.isFetching && (
        <Dialog>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                Loading tray from library...
              </DialogTitle>
            </DialogHeader>
            <p>
              This will only take a second!
            </p>
          </DialogContent>
        </Dialog>
      )}
      {loadTrayId === null && autosave !== null && recordCount === 1 && (
        <AlertDialog defaultOpen={true}>
          <AlertDialogContent>
            <AlertDialogTitle>
              Autosave found 
            </AlertDialogTitle>
            <SectionDescription>
              An autosaved insert was found in your local storage and the current insert is blank.
            </SectionDescription>
            <Input value={autosave.name} readOnly className="bg-background"/>
            <AlertDialogFooter>
              <AlertDialogCancel>
                Ignore
              </AlertDialogCancel>
              <AlertDialogAction onClick={() => dispatch(restoreModel({
                model: autosave.model,
              }))}>
                  Load autosave
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  );
}
import { InputSubmit } from "@/components/ui/inputSubmit";
import ConfigurationEditor from "../ConfigurationEditor";
import { useAppDispatch, useAppSelector } from "@/state/store";
import { modifyInsert, modifyInsertBoardgameExpansion } from "@/state/model";
import { Drawer } from "@/types";
import {  useMemo } from "react";
import { InsertPropertyDrawerBoardGame } from "./InsertPropertyDrawerBoardGame";
import { ColorPicker } from "@ui/colorPicker";
import { PartColors } from "@/catalog";
import { shallowEqual } from "react-redux";
import { Section } from "@ui/section";


export function InsertBasicsProperties({ query }: { query: string }) {
  const dispatch = useAppDispatch();
  const name = useAppSelector(s => s.model.insert.name);
  const path = useAppSelector(s => s.model.insert.path);
  const boardgame = useAppSelector(s => s.model.insert.boardgame, shallowEqual);
  const expansions = useAppSelector(s => s.model.insert.boardgameExpansions);
  const drawers = useMemo(() => {
    const result: Drawer[] = [{
      keys: ["insertGame"],
      control: <InsertPropertyDrawerBoardGame />,
      label: "box",
    }, {
      keys: ["insertName"],
      control: <InputSubmit id="name" initialValue={name} applyValue={n => dispatch(modifyInsert({
        name: n,
      }))} />,
      label: "row",
    }, {
      keys: ["insertPath"],
      control: <InputSubmit id="path" initialValue={path} applyValue={n => dispatch(modifyInsert({
        path: n,
      }))} preprocess={v => {
        if (!v.startsWith("/")) {
          v = "/" + v;
        }
        return v;
      }} />,
      label: "row",
    }];
    if(boardgame !== undefined) {
      result.push({
        keys: ["trayColors" ],
        control: <Section nested>
          <div className="leading-none">
            Base game
          </div>
          <ColorPicker color={boardgame.color} onColorChange={c => dispatch(modifyInsert({
            boardgame: {
              ...boardgame,
              color: c,
            }
          }))} swatches={PartColors} />
          {expansions.map(e => (
            <div key={e.id} className="flex flex-col gap-2">
              <div className="mt-2 leading-none">
                {e.name}
              </div>
              <ColorPicker color={e.color} onColorChange={c => dispatch(modifyInsertBoardgameExpansion({
                id: e.id,
                color: c,
              }))} swatches={PartColors} />
            </div>
          ))}
        </Section>,
        label: "box",
      });
    }
    return result;
  }, [ name, path, boardgame, expansions ]);
  return (
    <ConfigurationEditor query={query} configuration={{}} modifyConfiguration={c => { }} drawers={drawers} />
  );
}
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { MoonIcon, SunIcon } from "react-line-awesome";
import { darken, lighten } from "polished";
import { cn } from "./utils"
import { Button } from "./button"
import { Input } from "./input";
import { Icon } from "./icon";
import validate from "validate-color"
import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from "./popover";
import { HexColorPicker } from "react-colorful";
import { SectionLabel } from "./sectionLabel";
import { Section } from "./section";

export interface ColorPickerProps {
  color: string,
  onColorChange: (color: string) => void,
  swatches: string[],
  className?: string,
}

function ColorPickerPopover({ color, onColorChange }: { color: string, onColorChange: (color: string) => void }) {
  /*const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current!.value = color;
  }, [color]);*/
  return (
    <Section nested>
      <SectionLabel>
        Select color
      </SectionLabel>
      <HexColorPicker color={color} onChange={c => onColorChange(c)} />
      {/*<Input ref={inputRef} onChange={e => {
        const c = e.target.value as string;
        if (validate(c)) {
          onColorChange(c);
        }
      }} className="rounded-t-none" />*/}
    </Section>
  );
}

const ColorPicker = forwardRef<HTMLDivElement, ColorPickerProps>(
  ({ className, color, onColorChange, swatches }, ref) => {
    const items = useMemo(() => swatches.map(s => (
      <Button key={s} variant="ghost" className="h-full flex-1 rounded-none p-0 first:rounded-l last:rounded-r" style={{
        backgroundColor: s
      }} onClick={() => onColorChange(s)} />
    )), []);
   
    return (
      <div className={cn("rounded p-px pb-[6px]", className)} style={{
        backgroundColor: color,
      }}>
        <div ref={ref} className="flex gap-2 rounded bg-control p-2" >
          <div className="flex flex-auto">
            {items}
          </div>
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="ghost" size="sm" className="-m-1 h-7">
                <Icon name="palette" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-fit p-4">
              <ColorPickerPopover color={color} onColorChange={onColorChange}/>
              <PopoverArrow />
            </PopoverContent>
          </Popover>
        </div>
      </div>
    )
  }
);

ColorPicker.displayName = "ColorPicker";

export { ColorPicker };

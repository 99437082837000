import React from "react";
import { cn } from "./utils";
import { Button } from "./button";
import { Icon } from "./icon";

export interface FailureProps extends React.HTMLAttributes<SVGElement> {
  message?: React.ReactNode,
}

const Failure = React.forwardRef<HTMLDivElement, FailureProps>(
  ({ children, message, className, ...props }, ref) => {
    return (
      <div className="flex flex-col items-center justify-center gap-2 p-10">
          <div className="mb-2 text-center text-5xl underline decoration-primary underline-offset-4">
            {children}
          </div>
          {message !== undefined ? message : (
            <>
              This should never happen. Help the site by reporting the issue.
              <Button variant="primary">
                <Icon name="chat"/>
                Report issue
              </Button>
            </>
          )}
        </div>
    );
  }
);

Failure.displayName = "Failure";

export { Failure };


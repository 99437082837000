import { getPartColor } from "@/catalog";
import { InputGameSearch } from "@/components/InputGameSearch";
import { GameCard } from "@/pages/games/GameCard";
import { GameDetails, useLazyGetBoardgameQuery } from "@/state/api/boardgames";
import { modifyInsert } from "@/state/model";
import { useAppDispatch, useAppSelector } from "@/state/store";
import { BoardgameExpansion, ExpansionSupport } from "@/types";
import { ComponentBooleanIcon, ComponentInstanceIcon, ExternalLinkIcon, GearIcon } from "@radix-ui/react-icons";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { Button } from "@ui/button";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogTrigger } from "@ui/dialog";
import { Label } from "@ui/label";
import { Placeholder } from "@ui/placeholder";
import { RadioGroup, RadioGroupItem } from "@ui/radio-group";
import { SectionDescription } from "@ui/sectionDescription";
import { SectionLabel } from "@ui/sectionLabel";
import { Spinner } from "@ui/spinner";
import { useState } from "react";
import { shallowEqual } from "react-redux";

function parseExpansionSupport(value: string) {
  switch (value) {
    case "optional": return ExpansionSupport.Optional;
    case "required": return ExpansionSupport.Required;
    default: return ExpansionSupport.None;
  }
}

function GameSelection({
  onSelected
}: {
  onSelected: (selection: {
    data: GameDetails,
    expansions: BoardgameExpansion[],
  }) => void
}) {
  const [getBoardgame, getBoardgameStatus] = useLazyGetBoardgameQuery();
  const [expansions, setExpansions] = useState<BoardgameExpansion[]>([]);
  return (
    <>
      <SectionLabel>
        Select the board game for your insert
      </SectionLabel>
      <SectionDescription>
        Other users will find your insert when looking for the selected board game. The associated board game is also filter available boxes and parts.
      </SectionDescription>
      <InputGameSearch id="model" onSelectResult={id => getBoardgame({
        id: id,
      })} autoFocus />
      {getBoardgameStatus.isFetching ? (
        <div className="flex h-full items-center justify-center">
          <div className="flex items-center gap-2 text-foreground-muted">
            <Spinner />
            Loading metadata...
          </div>
        </div>
      ) : getBoardgameStatus.isSuccess && getBoardgameStatus.data !== undefined ? (
        <>
          <GameCard data={getBoardgameStatus.data} />
          {getBoardgameStatus.data.expansions.length > 0 && (
            <>
              <SectionLabel>
                Select supported board game expansions
              </SectionLabel>
              <SectionDescription>
                Expansions are available for this board game. Select the expanstions that your insert supports. Optional support means that the insert has optional trays or trays that can optionally hold the expansion components. Required support means that the insert does not work without the expansion components.
              </SectionDescription>
              <ScrollArea>
                <div className="flex flex-col gap-2">
                  {getBoardgameStatus.data.expansions.map(e =>
                    <div key={e.id} className="flex">
                      <div className="flex-auto rounded-l border border-r-0 p-2">
                        <span className="mr-2 text-lg leading-tight">
                          {e.name}
                        </span>
                        <Button onClick={() => window?.open(`https://boardgamegeek.com/boardgame/${e.id}`, '_blank')?.focus()} size="sm">
                          <ExternalLinkIcon />
                          {e.id}
                        </Button>
                      </div>
                      <RadioGroup defaultValue="none" onValueChange={(v: "none" | "optional" | "required") => {
                        const filtered = expansions.filter(o => o.id !== e.id);
                        if (v === "none") {
                          setExpansions(filtered);
                        } else {
                          setExpansions(filtered.concat({
                            id: e.id,
                            name: e.name,
                            support: parseExpansionSupport(v),
                            color: getPartColor(0),
                            year: 0,
                          }));
                        }
                      }} className="flex-none rounded-r border bg-background p-2">
                        <div className="flex items-center gap-2">
                          <RadioGroupItem value="none" id={e.id + "-none"} />
                          <Label htmlFor={e.id + "-none"}>not supported</Label>
                        </div>
                        <div className="flex items-center gap-2">
                          <RadioGroupItem value="optional" id={e.id + "-optional"} />
                          <Label htmlFor={e.id + "-optional"}>optional</Label>
                        </div>
                        <div className="flex items-center gap-2">
                          <RadioGroupItem value="required" id={e.id + "-required"} />
                          <Label htmlFor={e.id + "-required"}>required</Label>
                        </div>
                      </RadioGroup>
                    </div>
                  )}
                </div>
              </ScrollArea>
            </>
          )}
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="primary" onClick={() => onSelected({
                data: getBoardgameStatus.data,
                expansions: expansions
              })}>
                Apply
              </Button>
            </DialogClose>
          </DialogFooter>
        </>
      ) : (
        <Placeholder type="info">
          Search and select a game
        </Placeholder>
      )}
    </>
  );
}

export function InsertPropertyDrawerBoardGame() {
  const boardgame = useAppSelector(s => s.model.insert.boardgame);
  const expansions = useAppSelector(s => s.model.insert.boardgameExpansions, shallowEqual);
  const dispatch = useAppDispatch();
  return (
    <div className="flex flex-auto flex-col gap-2">
      {boardgame !== undefined ? (
        <div className="flex flex-col gap-1">
          <div className="text-lg leading-none">
            {boardgame.name} <span className="text-foreground-muted">({boardgame.year})</span>
          </div>
          {expansions !== undefined && expansions.map(e => (
            <div key={e.id} className="flex items-center gap-1">
              {e.support === ExpansionSupport.Optional ? (
                <ComponentBooleanIcon className="text-primary" />
              ) : (
                <ComponentInstanceIcon className="text-primary" />
              )}
              {e.name}
            </div>
          ))}
        </div>
      ) : (
        <div>
          no game selected
        </div>
      )}
      <Dialog>
        <DialogTrigger asChild>
          <Button className="w-full justify-start" size="sm">
            <GearIcon />
            Assign board game
          </Button>
        </DialogTrigger>
        <DialogContent className="w-[500px]">
          <GameSelection onSelected={s => {
            dispatch(modifyInsert({
              boardgame: {
                id: s.data.id,
                name: s.data.name,
                year: s.data.year,
                color: getPartColor(0),
              },
              boardgameExpansions: s.expansions,
            }));
          }} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@ui/button";
import { HamburgerMenuIcon, MixIcon } from "@radix-ui/react-icons";
import NavigationMenuProfile from "./NavigationMenuProfile";
import React from "react";
import { SiteScope } from "@/types";
import { InputGameSearch } from "@/components/InputGameSearch";
import { NavigationMenuMessages } from "./NavigationMenuMessages";
import { NavigationMenuInsert } from "./NavigationMenuInsert";
import { Icon } from "@ui/icon";
import { useMediaQuery } from 'react-responsive'
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "@ui/sheet";
import { Separator } from "@ui/separator";
import { SectionDescription } from "@ui/sectionDescription";
import InsertToolbar from "../designer/insert/InsertToolbar";
import { useAppSelector } from "@/state/store";
import NavigationMenuLogin from "./NavigationMenuLogin";

export interface NavigationmenuProps extends React.HTMLAttributes<HTMLDivElement> {
  showInsert: boolean,
  showInsertToolbar: boolean,
  scope: SiteScope,
}

export default function NavigationMenu({ showInsert, showInsertToolbar, scope, ...props }: NavigationmenuProps) {
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery({ minWidth: 1024 });
  const settingCanvasToolbarInNavbar = useAppSelector(s => s.settings.settingCanvasToolbarInNavbar);
  const isLoggedOut = useAppSelector(s => s.session.user === undefined)
  if (isLargeScreen) {
    return (
      <div className="flex size-full items-center justify-between gap-2">
        <Link to="/demo" className="flex items-center gap-2">
          <MixIcon className="size-6 text-primary" />
          <div className="text-nowrap font-semibold">
            Board Game Inserts
          </div>
        </Link>
        <div className="h-full w-px bg-border" />
        <div className="flex items-center">
          <Link to="/">
            <Button variant="link" size="sm" className={"px-1" + (scope !== "workbench" && scope !== "documents" ? " text-primary" : "")}>
              Explore
            </Button>
          </Link>
          {scope === "games" && (
            <>
              <Icon name="chevron-right" className="text-[12px] text-primary" />
              <div className="p-1 font-medium text-primary">
                Games
              </div>
            </>
          )}
          {scope === "cameras" && (
            <>
              <Icon name="chevron-right" className="text-[12px] text-primary" />
              <div className="p-1 font-medium text-primary">
                Cameras
              </div>
            </>
          )}
          {scope === "boxes" && (
            <>
              <Icon name="chevron-right" className="text-[12px] text-primary" />
              <div className="p-1 font-medium text-primary">
                Boxes
              </div>
            </>
          )}
          {scope === "printers" && (
            <>
              <Icon name="chevron-right" className="text-[12px] text-primary" />
              <div className="p-1 font-medium text-primary">
                Printers
              </div>
            </>
          )}
        </div>
        <Link to="/workbench">
          <Button variant="link" size="sm" className={"px-1" + (scope === "workbench" ? " text-primary" : "")}>Workbench</Button>
        </Link>
        <Link to="/documents">
          <Button variant="link" size="sm" className={"px-1" + (scope === "documents" ? " text-primary" : "")}>Documents</Button>
        </Link>
        <InputGameSearch className="flex w-fit grow justify-end" clearOnSelect={true} onSelectResult={id => navigate("/games/" + id)} />
        {showInsertToolbar && settingCanvasToolbarInNavbar && (
          <InsertToolbar className="h-full grow border-x bg-background px-2 py-1"/>
        )}
        {showInsert && (
          <NavigationMenuInsert showBox={true} />
        )}
        <NavigationMenuMessages autoHide={false} />
        <NavigationMenuProfile showLoginLogout={true}>
          <div className="flex items-center gap-1">
            <Icon name="bell" />
            5
          </div>
        </NavigationMenuProfile>
      </div>
    );
  } else {
    return (
      <div className="flex size-full items-center justify-between gap-2">
        <Link to="/" className="flex items-center gap-2">
          <MixIcon className="size-6 text-primary" />
        </Link>
        <div className="h-full w-px bg-border" />
        <Sheet>
          <SheetTrigger asChild>
            <Button size="sm">
              <HamburgerMenuIcon />
            </Button>
          </SheetTrigger>
          <SheetContent side="left" className="w-fit max-w-[400px] p-4">
            <SheetClose asChild>
              <Link to="/" className="-mx-4 -mt-4 flex items-center gap-2 border-b bg-background p-4">
                <MixIcon className="size-6 text-primary" />
                <div className="text-nowrap font-semibold">
                  Board Game Inserts
                </div>
              </Link>
            </SheetClose>
            <div className="flex items-center">
              <SheetClose asChild>
                <Link to="/">
                  <Button variant="link" size="sm" className={"px-1" + (scope !== "workbench" && scope !== "documents" ? " text-primary" : "")}>
                    Explore
                  </Button>
                </Link>
              </SheetClose>
              {scope === "games" && (
                <>
                  <Icon name="chevron-right" className="text-[12px] text-primary" />
                  <div className="p-1 text-sm font-medium text-primary">
                    Games
                  </div>
                </>
              )}
              {scope === "cameras" && (
                <>
                  <Icon name="chevron-right" className="text-[12px] text-primary" />
                  <div className="p-1 text-sm font-medium text-primary">
                    Cameras
                  </div>
                </>
              )}
              {scope === "boxes" && (
                <>
                  <Icon name="chevron-right" className="text-[12px] text-primary" />
                  <div className="p-1 text-sm font-medium text-primary">
                    Boxes
                  </div>
                </>
              )}
            </div>
            <SheetClose asChild>
              <Link to="/workbench">
                <Button variant="link" size="sm" className={"px-1" + (scope === "workbench" ? " text-primary" : "")}>Workbench</Button>
              </Link>
            </SheetClose>
            <SheetClose asChild>
              <Link to="/documents">
                <Button variant="link" size="sm" className={"px-1" + (scope === "documents" ? " text-primary" : "")}>Documents</Button>
              </Link>
            </SheetClose>
            <Separator />
            {showInsert && (
              <>
                <NavigationMenuInsert showBox={false} />
                <SectionDescription>
                  Note that the insert designer requires a lot of screen space and performance. It is not recommended to use it on a mobile device.
                </SectionDescription>
              </>
            )}
            <div className="grow"/>
            {isLoggedOut && (
              <div className="grid grid-cols-2 gap-2">
                <NavigationMenuLogin dialogInitiallyOpen={false}/>
              </div>
            )}
          </SheetContent>
        </Sheet>
        <InputGameSearch className="flex flex-auto justify-end" clearOnSelect={true} onSelectResult={id => navigate("/games/" + id)} />
        <NavigationMenuMessages autoHide={true} />
        <NavigationMenuProfile showLoginLogout={false}>
          <div className="flex items-center gap-1">
            <Icon name="bell" />
            5
          </div>
        </NavigationMenuProfile>
      </div>
    );
  }
}
import { store } from "@/state/store";
import Designer from "../pages/designer/Designer";
import { Provider } from "react-redux";
import { TooltipProvider } from "./ui/tooltip";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Error404 from "../pages/community/Error404";
import Home from "../pages/community/Home";
import WorkbenchLayout from "@/pages/workbench/WorkbenchLayout";
import WorkbenchRecent from "@/pages/workbench/WorkbenchRecent";
import FullscreenLayout from "@/pages/FullscreenLayout";
import WorkbenchAll from "@/pages/workbench/WorkbenchAll";
import WorkbenchTrays from "@/pages/workbench/WorkbenchTrays";
import { StrictMode } from "react";
import PartDesigner from "@/pages/parts/PartDesigner";
import CameraNew from "@/pages/cameras/CameraNew";
import CameraDetails from "@/pages/cameras/CameraDetails";
import GameDetails from "@/pages/games/GameDetails";
import BoxesCreate from "@/pages/boxes/BoxesCreate";
import BoxesDetails from "@/pages/boxes/BoxesDetails";
import InsertDialogPublish from "@/pages/designer/insert/InsertDialogPublish";
import { MessagesProvider } from "@/state/messages";
import PrintersList from "@/pages/printers/PrintersList";
import BoxesList from "@/pages/boxes/BoxesList";
import { InsertDialogDownload } from "@/pages/designer/insert/InsertDialogDownload";
import CenteredLayout from "@/pages/CenteredLayout";
import { ExploreList } from "@/pages/explore/ExploreList";
import CamerasList from "@/pages/cameras/CamerasList";
import DocumentsList from "@/pages/documents/DocumentsList";
import { DocumentsEditUpdate, DocumentsPublishUpdate } from "@/pages/documents/DocumentsPublishUpdate";
import DocumentsDetails from "@/pages/documents/DocumentsDetails";

export default function ReplicadApp() {
  return (
    <StrictMode>
      <MessagesProvider>
        <GoogleOAuthProvider clientId="653501001383-gui3o1ksajqg2m366l850dg886to2mcr.apps.googleusercontent.com">
          <TooltipProvider>
            <Provider store={store}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<CenteredLayout scope="explore" maxWidth={1400} />}>
                    <Route index element={<ExploreList />} />
                    <Route path="insert" element={<Designer />} />
                    <Route path="profile" element={<Designer />} />
                    <Route path="profile" element={<Designer />} />
                    <Route path="demo" element={<Home />} />
                    <Route path="*" element={<Error404 />} />
                  </Route>
                  <Route path="/workbench" element={<WorkbenchLayout />}>
                    <Route index element={<WorkbenchRecent />} />
                    <Route path="recent" element={<WorkbenchRecent />} />
                    <Route path="all" element={<WorkbenchAll />} />
                    <Route path="trays" element={<WorkbenchTrays />} />
                    <Route path="*" element={<Error404 />} />
                  </Route>
                  <Route path="/designer" element={<FullscreenLayout showInsertToolbar={true} />}>
                    <Route index element={<Designer />} />
                    <Route path="publish" element={<InsertDialogPublish />} />
                    <Route path="download" element={<InsertDialogDownload />} />
                    <Route path="*" element={<Error404 />} />
                  </Route>
                  <Route path="/documents" element={<CenteredLayout scope="documents" maxWidth={1000} />}>
                    <Route index element={<DocumentsList />} />
                    <Route path="publish" element={<DocumentsPublishUpdate />} />
                    <Route path=":id/edit" element={<DocumentsEditUpdate />} />
                    <Route path=":id" element={<DocumentsDetails />} />
                    <Route path="*" element={<Error404 />} />
                  </Route>
                  <Route path="/parts" element={<FullscreenLayout showInsertToolbar={true} />}>
                    <Route index element={<Error404 />} />
                    <Route path="new" element={<PartDesigner />} />
                    <Route path="*" element={<Error404 />} />
                  </Route>
                  <Route path="/games" element={<CenteredLayout scope="games" maxWidth={1000} />}>
                    <Route index element={<Error404 />} />
                    <Route path=":id" element={<GameDetails />} />
                    <Route path="*" element={<Error404 />} />
                  </Route>
                  <Route path="/cameras" element={<CenteredLayout scope="cameras" maxWidth={1000} />}>
                    <Route index element={<CamerasList />} />
                    <Route path="new" element={<CameraNew />} />
                    <Route path=":id" element={<CameraDetails />} />
                    <Route path="*" element={<Error404 />} />
                  </Route>
                  <Route path="/boxes" element={<CenteredLayout scope="boxes" maxWidth={1000} />}>
                    <Route index element={<BoxesList />} />
                    <Route path="new" element={<BoxesCreate />} />
                    <Route path=":id" element={<BoxesDetails />} />
                    <Route path="*" element={<Error404 />} />
                  </Route>
                  <Route path="/printers" element={<CenteredLayout scope="printers" maxWidth={1000} />}>
                    <Route index element={<PrintersList />} />
                    <Route path="*" element={<Error404 />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </Provider>
          </TooltipProvider>
        </GoogleOAuthProvider>
      </MessagesProvider>
    </StrictMode>
  );
}
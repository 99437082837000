import { Badge } from "@ui/badge";
import { GeometryEdge } from "@/types";
import { printable } from "@/utils";
import { AngleIcon } from "@radix-ui/react-icons";

export default function CanvasEdgeInfo({ edge, designator }: { edge: GeometryEdge, designator: string | undefined }) {
  return (
    <div className="flex flex-col rounded-sm border bg-control" >
      <div className="flex items-center gap-2 rounded-t-sm bg-accent p-1">
        {designator !== undefined && (
          <Badge variant="primary" className="px-1.5 font-mono">{designator}</Badge>
        )}
        <AngleIcon/>
        <div className="select-none">Edge</div>
        <div className="flex-auto"/>
        <Badge variant="primary" className="font-mono">lenght: {printable(edge.length)}</Badge>
      </div>
      <div className="flex gap-1 p-1">
        <Badge className="font-mono">{printable(edge.start.x)}, {printable(edge.start.y)}, {printable(edge.start.z)}</Badge>
        <div className="select-none">-</div>
        <Badge className="font-mono">{printable(edge.end.x)}, {printable(edge.end.y)}, {printable(edge.end.z)}</Badge>
      </div>
    </div>
  );
}
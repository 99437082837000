import { ScrollArea } from "@ui/scroll-area";
import * as exifr from "exifr";
import { ReactNode, Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Label } from "@ui/label";
import { Button } from "@ui/button";
import { ArrowLeftIcon, ArrowRightIcon, EnterFullScreenIcon, ExitFullScreenIcon, MagnifyingGlassIcon, MinusIcon, Pencil1Icon, PlusIcon, UploadIcon } from "@radix-ui/react-icons";
import { MiniMap, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useLazyGetCameraQuery, useLazyListCamerasQuery } from "@/state/api/cameras";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@ui/select";
import { Link, useNavigate } from "react-router-dom";
import { displayImage, getImageData } from "@/utils";
import { Board, opencvRequest, Rect } from "@/opencv";
import { InputNumber } from "@ui/inputNumber";
import { CardHeader } from "@ui/cardHeader";
import { Textarea } from "@ui/textarea";
import { Slider } from "@ui/slider";
import { CropperRef, Cropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "@ui/chart";
import { Area, AreaChart, CartesianGrid, XAxis } from "recharts";
import PixelEditor, { PixelEditorRef } from "../PixelEditor";
import { Switch } from "@ui/switch";
import { Canvas as ThreeCanvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import PhotoStage from "../designer/canvas/PhotoStage";
import ScreenshotCapture from "../designer/canvas/ScreenshotCapture";
import { generateTrayGeometriesAlt } from "@/replicadWorkerPool";
import { Contour, Tray, TrayModel } from "@/types";
import { getDefaultBlueprint, getDefaultElementInstance, getDefaultTray, getPartColor } from "@/catalog";
import { layoutTray } from "@/designer/measurement";
import { ReplicadShapes } from "../designer/canvas/ReplicadShapes";
import { Spinner } from "@ui/spinner";

interface ViewerEditor {
  type: "viewer",
  stepIndex: number,
  image: ImageData,
  center: boolean,
}

interface DrawerEditor {
  type: "drawer",
  stepIndex: number,
  editor: ReactNode,
}

interface RendererEditor {
  type: "renderer",
  stepIndex: number,
  editor: ReactNode,
}

type Editor = ViewerEditor | DrawerEditor | RendererEditor;

interface StepProps extends React.InputHTMLAttributes<HTMLDivElement> {
  index: number,
  stepIndex: number,
  setStepIndex: (stepIndex: number) => void,
  openStep: (initial: boolean) => void,
  header: string,
  busy: boolean,
  editor: Editor | undefined,
  error: string | undefined,
  output: ImageData | undefined,
}

function Step({ index, stepIndex, setStepIndex, header, busy, error, output, editor, openStep, children }: StepProps) {
  const isEnabled = stepIndex >= index;
  const isDone = stepIndex > index;
  const isActive = index === stepIndex;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (output !== undefined) {
      displayImage(canvasRef.current!, output);
      if(stepIndex === index) {
        openStep(editor?.stepIndex !== index);
      }
    }
  }, [output]);
  return (
    <div className="flex flex-col">
      <CardHeader index={index} color={error !== undefined ? "red" : isDone ? "green" : isActive ? "lead-green" : "gray"} className={isEnabled === false ? " rounded-sm border" : ""}>
        <Label>
          {header}
        </Label>
      </CardHeader>
      <div className={"relative flex flex-col gap-2 rounded-b-sm border-x border-b bg-control p-4" + (isEnabled === false ? " hidden" : "") + (isDone ? " border-primary" : "")}>
        {isActive && (
          children
        )}
        <Label className={"flex items-center gap-2" + (isActive ? " mt-2" : "")}>
          Result
          {output !== undefined && (
            <div className="font-mono font-normal text-foreground-muted">
              {output.width}x{output.height}
            </div>
          )}
        </Label>
        <canvas ref={canvasRef} className="max-h-48 w-full rounded border bg-background object-contain p-1" />
        {error && (
          <>
            <Label className="mt-2">
              Error
            </Label>
            <div className="text-destructive">
              {error}
            </div>
          </>
        )}
        <div className="flex justify-between gap-2">
          <Button disabled={editor?.stepIndex === index || output === undefined} onClick={() => openStep(true)} variant="outline">
            <MagnifyingGlassIcon />
            Open
          </Button>
          {isActive ? (
            <Button disabled={error !== undefined || output === undefined} onClick={() => setStepIndex(index + 1)}>
              Next
              <ArrowRightIcon />
            </Button>
          ) : (
            <Button onClick={() => setStepIndex(index)}>
              <Pencil1Icon />
              Edit
            </Button>
          )}
        </div>
        {busy && (
          <div className="absolute inset-0 flex items-center justify-center bg-control/50">
            <Spinner/>
          </div>
        )}
      </div>
    </div>
  );
}

interface CommonStepProps {
  stepIndex: number,
  editor: Editor | undefined,
  setStepIndex: (stepIndex: number) => void,
  openStep: (editor: Editor) => void,
}

function UploadPhoto({ rawImage, setRawImage, cameraModel, setCameraModel, stepIndex, setStepIndex, editor, openStep  }: { rawImage: ImageData | undefined, setRawImage: (image: ImageData) => void, cameraModel: string | undefined, setCameraModel: (cameraModel: string) => void } & CommonStepProps) {
  const inputFile = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | undefined>(undefined);
  const [busy, setBusy] = useState(false);
  return (
    <Step index={1} editor={editor} header="Part scan" busy={busy} output={rawImage} stepIndex={stepIndex} setStepIndex={setStepIndex} openStep={center => openStep({
      type: "viewer",
      stepIndex: 1,
      image: rawImage!,
      center: center,
    })} error={error}>
      <Label>
        Take a photo
      </Label>
      <p className="text-sm leading-tight text-foreground-muted">
        Print one of the many scan sheets provided <Link to="sdfsf" className="text-primary">here</Link> and place the board game part on its center.
        <br />
        Use your best camera and try to minimize shadows by using multiple light sources.
        <br />
        Take a photo that contains the entire sheet. Try to point the camera perfectly straight down and keep the part in the center.
      </p>
      <Label htmlFor="model" className="mt-2">
        Upload photo
      </Label>
      <div className="text-sm leading-tight text-foreground-muted">
        Use only uncompressed and unfiltered images.
      </div>
      <Button variant="outline" onClick={async () => {
        inputFile.current!.click();
      }} className="w-full">
        <UploadIcon />
        Upload
      </Button>
      <input type="file" id="file" accept=".jpg,.jpeg,.png,.gif" ref={inputFile} style={{ display: "none" }} onChange={e => {
        setBusy(true);
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files![0]);
        reader.onloadend = async () => {
          try {
            const data = reader.result as string;
            const metadata = await exifr.parse(data);
            if (metadata !== undefined) {
              setCameraModel(`${metadata.Make} ${metadata.Model}‖${metadata.LensModel}`);
            }
            const newRawImage = await getImageData(data);
            setRawImage(newRawImage);
            setError(undefined);
          } catch (e) {
            if (e instanceof Error) {
              setError(e.message);
            } else {
              setError(String(e));
            }
          }
          setBusy(false);
        };
      }} />
      <Label htmlFor="model" className="mt-2">
        Camera model
      </Label>
      <div className="text-sm leading-tight text-foreground-muted">
        Extracted automatically from uploaded image metadata.
      </div>
      <Textarea id="model" readOnly value={cameraModel?.split("‖").join("\n")} className="bg-background" />
    </Step>
  );
}

function UndistortPhoto({ rawImage, undistortedImage, setUndistortedImage, cameraModel, stepIndex, setStepIndex, editor, openStep }: { rawImage: ImageData | undefined, undistortedImage: ImageData | undefined, setUndistortedImage: (image: ImageData) => void, cameraModel: string | undefined } & CommonStepProps) {
  const navigate = useNavigate();
  const [listCameras, listCamerasState] = useLazyListCamerasQuery();
  const [getCamera] = useLazyGetCameraQuery();
  const [selectedCameraId, setSelectedCameraId] = useState<number>();
  const [error, setError] = useState<string | undefined>(undefined);
  const [busy, setBusy] = useState(false);
  useEffect(() => {
    if (cameraModel !== undefined) {
      listCameras({
        count: 20,
        page: 0,
        model: cameraModel,
      }).then(status => {
        if (status.data !== undefined && status.data.cameras.length > 0 && selectedCameraId === undefined) {
          setSelectedCameraId(status.data.cameras[0].id);
        }
      })
    }
  }, [cameraModel]);
  useEffect(() => {
    const handler = async () => {
      if (stepIndex === 2 && selectedCameraId !== undefined) {
        const camera = await getCamera({
          id: selectedCameraId,
        });
        if (camera.isSuccess && camera.data !== undefined) {
          setBusy(true);
          const result = await opencvRequest({
            type: "undistort",
            image: rawImage!,
            calibration: camera.data!.data,
          });
          if (result instanceof Error) {
            setError(result.message);
          } else {
            setUndistortedImage(result.image);
          }
          setBusy(false);
        }
      }
    };
    handler();
  }, [stepIndex, selectedCameraId, rawImage]);
  return (
    <Step index={2} editor={editor} busy={busy} header="Remove camera distortion" output={undistortedImage} stepIndex={stepIndex} setStepIndex={setStepIndex} openStep={center => openStep({
      type: "viewer",
      stepIndex: 2,
      image: undistortedImage!,
      center: center,
    })} error={error}>
      <Label>
        Select calibration camera
      </Label>
      <p className="text-sm leading-tight text-foreground-muted">
        Modern cameras introduce significant radial and tangential distortion.
        <br />
        These errors are constant and can be eliminated with camera calibration.
      </p>
      {listCamerasState.isLoading ? (
        <div className="flex w-80 items-center gap-2">
          <Spinner/>
          Loading matching cameras...
        </div>
      ) : listCamerasState.isSuccess && listCamerasState.data !== undefined ? (
        <>
          <Select value={selectedCameraId?.toString() ?? ""} onValueChange={async e => {
            setSelectedCameraId(parseInt(e));
          }}>
            <SelectTrigger>
              <SelectValue placeholder="Select camera...">
                {listCamerasState.data.cameras.find(c => c.id == selectedCameraId)?.model.replace("‖", " - ")}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {listCamerasState.data.cameras.map(c => (
                <SelectItem value={c.id.toString()} key={c.id}>
                  <div className="font-semibold">
                    {c.model.substring(0, c.model.indexOf("‖"))}
                  </div>
                  {c.model.substring(c.model.indexOf("‖") + 1)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <div className="">
            {listCamerasState.data.cameras.find(c => c.id == selectedCameraId)?.description}
          </div>
        </>
      ) : (
        <div>
          Error
        </div>
      )}
      <Button onClick={() => navigate("/cameras/new")} variant="outline">
        <PlusIcon />
        Calibrate and publish new camera
      </Button>
    </Step>
  );
}

function UnwarpPhoto({ undistortedImage, unwarpedImage, setUnwarpedImage, board, setBoard, stepIndex, setStepIndex, editor, openStep }: { undistortedImage: ImageData | undefined, unwarpedImage: ImageData | undefined, setUnwarpedImage: (image: ImageData) => void, board: Board | undefined, setBoard: (board: Board) => void } & CommonStepProps) {
  const [error, setError] = useState<string | undefined>(undefined);
  const [unscaledBoard, setUnscaledBoard] = useState<Board>();
  const [busy, setBusy] = useState(false);
  useEffect(() => {
    if (stepIndex === 3 && undistortedImage !== undefined) {
      setBusy(true);
      opencvRequest({
        type: "unwarp",
        image: undistortedImage,
      }).then(result => {
        if (result instanceof Error) {
          setError(result.message);
        } else {
          setUnscaledBoard(result.board);
          setBoard(result.scaledBoard);
          setUnwarpedImage(result.image);
          openStep({
            type: "viewer",
            stepIndex: 3,
            center: true,
            image: result.image,
          });
          setError(undefined);
        }
        setBusy(false);
      });
    }
  }, [stepIndex, undistortedImage]);
  return (
    <Step index={3} editor={editor} busy={busy} header="Remove perspective warp" output={unwarpedImage} stepIndex={stepIndex} setStepIndex={setStepIndex} openStep={() => openStep({
      type: "viewer",
      stepIndex: 3,
      image: unwarpedImage!,
      center: true,
    })} error={error}>
      <p className="text-sm leading-tight text-foreground-muted">
        Identify the scan sheet using the markers and calculate the camera pose.
        <br />
        Remove the warp introduced by the camera perspective using the camera pose.
      </p>
      <Label className="mt-2">
        Detected board
      </Label>
      {board !== undefined && unscaledBoard !== undefined && (
        <div className="font-mono text-sm">
          {board.name}
          <br />
          Image size: {board.areaWidth.toFixed(0)}x{board.areaHeight.toFixed(0)}px
          <br />
          Object size: {unscaledBoard.areaWidth.toFixed(1)}x{unscaledBoard.areaHeight.toFixed(1)}mm
        </div>
      )}
    </Step>
  );
}

function PreprocessPhoto({ unwarpedImage, preprocessedImage, setPreprocessedImage, board, setHistogram, stepIndex, setStepIndex, editor, openStep }: { unwarpedImage: ImageData | undefined, preprocessedImage: ImageData | undefined, setPreprocessedImage: (image: ImageData) => void, setHistogram: (image: number[]) => void, board: Board | undefined } & CommonStepProps) {
  const [error, setError] = useState<string | undefined>(undefined);
  const [busy, setBusy] = useState(false);
  const [angle, setAngle] = useState(0);
  const [crop, setCrop] = useState<Rect | undefined>(undefined);
  const [cropImage, setCropImage] = useState<ImageData | undefined>(undefined);
  const cropperRef = useRef<CropperRef>(null);
  const cropperSource = useMemo(() => {
    if (cropImage !== undefined) {
      const canvas = document.createElement("canvas");
      displayImage(canvas, cropImage);
      return canvas.toDataURL();
    }
    return "";
  }, [cropImage]);
  useEffect(() => {
    if (stepIndex === 4 && unwarpedImage !== undefined && board !== undefined) {
      setBusy(true);
      opencvRequest({
        type: "preprocess",
        image: unwarpedImage,
        board: board,
        angle: angle,
        crop: crop ?? {
          left: 0,
          top: 0,
          width: unwarpedImage.width,
          height: unwarpedImage.height,
        },
      }).then(result => {
        if (result instanceof Error) {
          setError(result.message);
        } else {
          setCropImage(result.cropImage);
          setPreprocessedImage(result.image);
          setHistogram(result.histogram);
          setError(undefined);
        }
        setBusy(false);
      });
    }
  }, [stepIndex, unwarpedImage, board, crop, angle]);
  return (
    <Step index={4} busy={busy} editor={editor} header="Preprocess image" output={preprocessedImage} stepIndex={stepIndex} setStepIndex={setStepIndex} openStep={() => openStep({
      type: "viewer",
      stepIndex: 4,
      image: preprocessedImage!,
      center: true,
    })} error={error}>
      <p className="text-sm leading-tight text-foreground-muted">
        Remove the sheet markers and reduce the image to grayscale.
      </p>
      <Label className="mt-2">
        Angle correction
      </Label>
      <p className="text-sm leading-tight text-foreground-muted">
        When the board game part is not perfectly aligned on the scan sheet, the rotation should be fixed to implement a good default orientation in the trays.
      </p>
      <InputNumber min={-360} max={360} value={angle} unit="°" setValue={v => setAngle(v)} />
      <Label className="mt-2">
        Set region of interest
      </Label>
      <p className="text-sm leading-tight text-foreground-muted">
        Select the region of the sheet that contains the board game part. Leave a litte border (~1cm) arount the part to not obstruct the edge detection.
      </p>
      <Button disabled={cropperSource === ""} onClick={() => {
        openStep({
          type: "drawer",
          stepIndex: 4,
          editor: <>
            <Cropper className="absolute inset-0 rounded bg-control p-2" src={cropperSource} ref={cropperRef} defaultCoordinates={crop} />
              <div className="absolute bottom-2 right-2 flex gap-2">
                <Button onClick={() => openStep({
                  type: "viewer",
                  stepIndex: 4,
                  image: preprocessedImage!,
                  center: true,
                })} variant="destructive">
                  Cancel
                </Button>
                <Button onClick={() => {
                  const coordiantes = cropperRef.current?.getCoordinates();
                  if (coordiantes !== null && coordiantes !== undefined) {
                    setCrop(coordiantes);
                  }
                  openStep({
                    type: "viewer",
                    stepIndex: 4,
                    image: preprocessedImage!,
                    center: true,
                  });
                }}>
                  Accept
                </Button>
              </div>
            </>,
        });
      }}>
        Crop image
      </Button>
    </Step>
  );
}

function ThresholdPhoto({ preprocessedImage, thresholdedImage, setThresholdedImage, histogram, stepIndex, setStepIndex, editor, openStep }: { preprocessedImage: ImageData | undefined, thresholdedImage: ImageData | undefined, setThresholdedImage: (image: ImageData) => void, histogram: number[] | undefined } & CommonStepProps) {
  const [error, setError] = useState<string | undefined>(undefined);
  const [threshold, setThreshold] = useState(170);
  const [smoothing, setSmoothing] = useState(0);
  const [closing, setClosing] = useState(0);
  const [busy, setBusy] = useState(false);
  const chartConfig = {
    distribution: {
      label: "Distribution",
      color: "hsl(var(--primary))",
    },
  } satisfies ChartConfig;
  const data = useMemo(() => {
    const result: {
      color: string,
      distribution: number,
    }[] = [];
    if (histogram !== undefined) {
      for (let index = 0; index < histogram.length; index++) {
        result.push({
          color: index.toString(),
          distribution: histogram[index],
        });
      }
    }
    return result;
  }, [histogram]);
  useEffect(() => {
    if (stepIndex === 5 && preprocessedImage !== undefined) {
      setBusy(true);
      opencvRequest({
        type: "threshold",
        image: preprocessedImage,
        threshold: threshold,
        smoothing: smoothing,
        closing: closing,
      }).then(result => {
        if (result instanceof Error) {
          setError(result.message);
        } else {
          setThresholdedImage(result.image);
          setError(undefined);
        }
        setBusy(false);
      });
    }
  }, [stepIndex, preprocessedImage, threshold, closing, smoothing]);
  return (
    <Step index={5} editor={editor} busy={busy} header="Apply threshold" output={thresholdedImage} stepIndex={stepIndex} setStepIndex={setStepIndex} openStep={() => openStep({
      type: "viewer",
      stepIndex: 5,
      image: thresholdedImage!,
      center: true,
    })} error={error}>
      <p className="text-sm leading-tight text-foreground-muted">
        Separate the board game part from the background by applying a threshold. Use a threshold that barely provides a closed contour. Lowering the threshold usually increases the noise.
      </p>
      <Label className="mt-2">
        Histogram
      </Label>
      <ChartContainer config={chartConfig}>
        <AreaChart accessibilityLayer data={data}>
          <CartesianGrid vertical={true} />
          <XAxis dataKey="color" tickLine={false} axisLine={false} />
          <ChartTooltip cursor={true} content={<ChartTooltipContent indicator="line" />} />
          <Area dataKey="distribution" type="natural" fill="var(--color-primary)" fillOpacity={0.4} stroke="var(--color-primary)" />
        </AreaChart>
      </ChartContainer>
      <Label className="mt-2">
        Threshold
      </Label>
      <Slider value={[threshold]} onValueChange={v => setThreshold(v[0])} min={0} max={255} step={1} />
      <InputNumber title="Left" min={0} max={255} value={threshold} setValue={v => setThreshold(v)} />
      <Label className="mt-2">
        Closing
      </Label>
      <p className="text-sm leading-tight text-foreground-muted">
        Remove gaps along the edge of the part. Use the lowest value that closes all gaps to minimize shape deformation.
      </p>
      <Slider value={[closing]} onValueChange={v => setClosing(v[0])} min={0} max={20} step={1} />
      <InputNumber min={0} max={20} value={closing} setValue={v => setClosing(v)} change={1} />
      <Label className="mt-2">
        Smoothing
      </Label>
      <p className="text-sm leading-tight text-foreground-muted">
        Remove jitter along the edge of the part. Use a value that removes most of the noise without deforming the shape.
      </p>
      <Slider value={[smoothing]} onValueChange={v => setSmoothing(v[0])} min={0} max={5} step={1} />
      <InputNumber min={0} max={5} value={smoothing} setValue={v => setSmoothing(v)} change={1} />
    </Step>
  );
}

function RetouchPhoto({ thresholdedImage, retouchedImage, setRetouchedImage, stepIndex, setStepIndex, editor, openStep }: { thresholdedImage: ImageData | undefined, retouchedImage: ImageData | undefined, setRetouchedImage: (image: ImageData) => void } & CommonStepProps) {
  const [error, setError] = useState<string | undefined>(undefined);
  const [busy, setBusy] = useState(false);
  const pixelEditorRef = useRef<PixelEditorRef>(null);
  return (
    <Step index={6} busy={busy} editor={editor} header="Retouch image" output={retouchedImage} stepIndex={stepIndex} setStepIndex={setStepIndex} openStep={() => openStep({
      type: "viewer",
      stepIndex: 6,
      image: retouchedImage!,
      center: true,
    })} error={error}>
      <p className="text-sm leading-tight text-foreground-muted">
        Thresholding usually produces unwanted artifacts when the contours of the board game part are blurry or obscured by shadows.
      </p>
      <Label className="mt-2">
        Fix contour
      </Label>
      <p className="text-sm leading-tight text-foreground-muted">
        Currect the contour of the board game part by removing any unwanted artifacts.
      </p>
      <Button onClick={() => openStep({
        type: "drawer",
        stepIndex: 6,
        editor: <div className="relative">
          <PixelEditor sharpness={1} ref={pixelEditorRef} className="absolute inset-0" onInit={() => {
            pixelEditorRef.current!.load(thresholdedImage!);
          }} />
          <div className="absolute bottom-2 right-2 flex gap-2">
            <Button onClick={() => openStep({
              type: "viewer",
              stepIndex: 6,
              image: retouchedImage!,
              center: true,
            })} variant="destructive">
              Cancel
            </Button>
            <Button onClick={() => {
              const result = pixelEditorRef.current?.save();
              if (result) {
                setRetouchedImage(result);
              }
            }}>
              Accept
            </Button>
          </div>
        </div>,
      })}>
        Retouch image
      </Button>
    </Step>
  );
}

function ContourPhoto({ retouchedImage, contouredImage, setContouredImage, board, contour, setContour, stepIndex, setStepIndex, editor, openStep }: { retouchedImage: ImageData | undefined, contouredImage: ImageData | undefined, setContouredImage: (image: ImageData) => void, board: Board | undefined, contour: Contour | undefined, setContour: (contour: Contour) => void } & CommonStepProps) {
  const [error, setError] = useState<string | undefined>(undefined);
  const [busy, setBusy] = useState(false);
  const [approximation, setApproximation] = useState(0.3);
  const [detectedAngle, setDetectedAngle] = useState(0);
  const [showBoundingBox, setShowBoundingBox] = useState(false);
  useEffect(() => {
    if (stepIndex === 7 && retouchedImage !== undefined && board !== undefined) {
      setBusy(true);
      opencvRequest({
        type: "contour",
        image: retouchedImage,
        approximation: approximation,
        showBoundingBox: showBoundingBox,
        board: board,
      }).then(result => {
        if (result instanceof Error) {
          setError(result.message);
        } else {
          setContouredImage(result.image);
          setContour(result.contour);
          setDetectedAngle(result.detectedAngle);
          setError(undefined);
        }
        setBusy(false);
      });
    }
  }, [stepIndex, retouchedImage, approximation, showBoundingBox]);
  return (
    <Step index={7} busy={busy} editor={editor} header="Detect contour" output={contouredImage} stepIndex={stepIndex} setStepIndex={setStepIndex} openStep={() => openStep({
      type: "viewer",
      stepIndex: 7,
      image: contouredImage!,
      center: true,
    })} error={error}>
      <p className="text-sm leading-tight text-foreground-muted">
        Detect the contour of the board game part.
      </p>
      <Label className="mt-2">
        Approximation
      </Label>
      <p className="text-sm leading-tight text-foreground-muted">
        Reduce the detail of the contour by approximating it. This can remove noise and will drastically improve the performance when generation 3D models.
      </p>
      {contour !== undefined && (
        <div>
          {contour.points.length / 2} corners
        </div>
      )}
      <Slider value={[approximation]} onValueChange={v => setApproximation(v[0])} min={0} max={3} step={0.1} />
      <InputNumber min={0} max={3} value={approximation} setValue={v => setApproximation(v)} change={0.1} />
      <Label className="mt-2">
        Angle validation
      </Label>
      <p className="text-sm leading-tight text-foreground-muted">
        When the board game part is not perfectly aligned on the scan sheet, the rotation should be fixed to implement a good default orientation in the trays.
      </p>
      <div className="text-sm leading-tight">
        Detected angle: {detectedAngle}°
      </div>
      <div className="flex items-center space-x-2">
        <Switch id="showBox" checked={showBoundingBox} onCheckedChange={e => setShowBoundingBox(e)} />
        <Label htmlFor="showBox">Show bounding box</Label>
      </div>
    </Step>
  );
}

function PreviewModel({ contour, stepIndex, openStep }: { contour: Contour | undefined, stepIndex: number, openStep: (editor: Editor) => void, }) {
  const [error, setError] = useState<string | undefined>(undefined);
  const [busy, setBusy] = useState(false);
  const [height, setHeight] = useState(5);
  const [models, setModels] = useState<TrayModel[]>([]);
  const dpr = Math.min(window.devicePixelRatio, 2);
  let takeScreenshot: () => string;
  useEffect(() => {
    if (contour === undefined || stepIndex !== 8) {
      return;
    }
    const handler = async () => {
      setBusy(true);
      try {
        const tray: Tray = getDefaultTray({
          id: 999,
          blueprintId: 999
        });
        tray.elements[0] = getDefaultElementInstance();
        tray.elements[1] = getDefaultElementInstance();
        const blueprint = getDefaultBlueprint({
          id: 999,
        });
        blueprint.elements[1] = {
          id: 1,
          name: "Part",
          type: "pocket",
          pocketType: "part-vertical",
          //@ts-expect-error incomplete
          configuration: {
            part: {
              ...contour,
              height: height,
            },
            hexCutPrevent: true,
            stackCount: 1,
          },
          layout: {
            type: "stack",
            xAlign: 0.5,
            yAlign: 0.5,
            az: 1,
            xGrow: 1,
            yGrow: 1,
            zGrow: 0,
          },
        };
        layoutTray(blueprint, tray);
        const geometry = await generateTrayGeometriesAlt(tray, blueprint, false);
        setModels([{
          ...geometry[0],
          color: getPartColor(0),
          trayId: tray.id,
        }]);
      } finally {
        setBusy(false);
      }
    };
    handler();
  }, [contour, height]);
  return (
    <div className="flex flex-col">
      <CardHeader index={8} color={contour !== undefined ? "green" : "gray"}>
        <Label>
          3D model preview
        </Label>
      </CardHeader>
      <div className="relative flex flex-col gap-2 rounded-b-sm border-x border-b border-primary bg-control p-4">
        <p className="text-sm leading-tight text-foreground-muted">
          Detect the contour of the board game part.
        </p>
        <Label className="mt-2">
          Height
        </Label>
        <p className="text-sm leading-tight text-foreground-muted">
          The height of the board game part.
        </p>
        <InputNumber min={0} max={100} value={height} unit="mm" setValue={v => setHeight(v)} change={0.1} />
        <Button disabled={busy} variant="outline" onClick={() => openStep({
          type: "renderer",
          stepIndex: 8,
          editor: 
            <Suspense fallback={null}>
              <ThreeCanvas className="rounded-sm bg-background"
                dpr={dpr}
                frameloop="demand"
                camera={{ position: [0, -40, 80] }}>
                {/*<OrbitControls makeDefault enableDamping={false} enablePan={false} minAzimuthAngle={mode === "cover" ? -Math.PI / 2.5 : -Math.PI / 16} maxAzimuthAngle={mode === "cover" ? -Math.PI / 5 : Math.PI / 16} minPolarAngle={mode === "cover" ? Math.PI / 5 : 0} maxPolarAngle={mode === "cover" ? Math.PI / 3 : Math.PI / 10} enableZoom={false} />*/}
                <OrbitControls makeDefault enableDamping={false} />
                <PhotoStage>
                  <ScreenshotCapture registerScreenshotSource={h => takeScreenshot = h}>
                    <ReplicadShapes edgeOpacity={20} models={models} selections={[]} color="#0ea5e9" />
                  </ScreenshotCapture>
                </PhotoStage>
              </ThreeCanvas>
            </Suspense>,
        })}>
          <ArrowLeftIcon />
          Preview
        </Button>
        {busy && (
          <div className="absolute inset-0 flex items-center justify-center bg-control/50">
            <Spinner/>
          </div>
        )}
      </div>
    </div>
  );
}

export default function PartDesigner() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasMinimapRef = useRef<HTMLCanvasElement>(null);
  const [cameraModel, setCameraModel] = useState<string>();
  const [rawImage, setRawImage] = useState<ImageData>();
  const [undistortedImage, setUndistortedImage] = useState<ImageData>();
  const [preprocessedImage, setPreprocessedImage] = useState<ImageData>();
  const [previewImage, setPreviewImage] = useState<ImageData>();
  const [thresholdedImage, setThresholdedImage] = useState<ImageData>();
  const [retouchedImage, setRetouchedImage] = useState<ImageData>();
  const [contouredImage, setContouredImage] = useState<ImageData>();
  const [histogram, setHistogram] = useState<number[]>();
  const [editor, setEditor] = useState<Editor | undefined>(undefined);
  const [unwarpedImage, setUnwarpedImage] = useState<ImageData>();
  const [board, setBoard] = useState<Board | undefined>(undefined);
  const [contour, setContour] = useState<Contour | undefined>(undefined);
  const [stepIndex, setStepIndex] = useState(1);
  let resetView: (() => void) | undefined;
  useEffect(() => {
    if(editor?.type === "viewer") {
      if(canvasRef.current !== null) {
        displayImage(canvasRef.current, editor.image);
        if (editor.center) {
          resetView?.call(undefined);
        }
      }
      if (canvasMinimapRef.current !== null) {
        displayImage(canvasMinimapRef.current, editor.image);
      }
    }
  }, [ editor ])
  useEffect(() => {
    // preload opencv
    opencvRequest({
      type: "load",
    });
  }, []);
  return (
    <div className="relative h-full w-screen">
      <div className="absolute inset-y-0 left-0 right-[400px] text-clip p-1">
        <div className="relative size-full">
          {editor?.type === "viewer" ? (
            <TransformWrapper minScale={0.1} panning={{
              velocityDisabled: true,
            }} disablePadding={true} zoomAnimation={{
              disabled: true,
            }}>
              {({ zoomIn, zoomOut, centerView, resetTransform, zoomToElement, ...rest }) => {
                resetView = () => zoomToElement(canvasRef.current!);
                return (
                  <>
                    <TransformComponent wrapperClass="!size-full max-w-full max-h-full rounded">
                      <canvas ref={canvasRef} style={{
                        imageRendering: "pixelated",
                      }} />
                    </TransformComponent>
                    <div className="absolute left-2 top-2 flex flex-col items-center gap-2">
                      <Button variant="outline" onClick={() => zoomIn()}>
                        <PlusIcon />
                      </Button>
                      <Button variant="outline" onClick={() => zoomOut()}>
                        <MinusIcon />
                      </Button>
                      <Button variant="outline" onClick={() => zoomToElement(canvasRef.current!)}>
                        <EnterFullScreenIcon />
                      </Button>
                      <Button variant="outline" onClick={() => centerView()}>
                        <ExitFullScreenIcon />
                      </Button>
                    </div>
                    <div className="absolute right-2 top-2">
                      <MiniMap className="rounded ring-2 ring-primary" borderColor="#22c55e">
                        <canvas ref={canvasMinimapRef}></canvas>
                      </MiniMap>
                    </div>
                  </>
                );
              }}
            </TransformWrapper>
          ) : editor !== undefined ? editor.editor : <></>}
        </div>
      </div>
      <div className="absolute inset-y-0 right-0 w-[400px] overflow-y-auto p-1">
        <ScrollArea className="" >
          <div className="flex flex-col gap-2">
            <UploadPhoto editor={editor} stepIndex={stepIndex} setStepIndex={setStepIndex} rawImage={rawImage} setRawImage={setRawImage} cameraModel={cameraModel} setCameraModel={setCameraModel} openStep={setEditor} />
            <UndistortPhoto editor={editor} stepIndex={stepIndex} setStepIndex={setStepIndex} rawImage={rawImage} undistortedImage={undistortedImage} setUndistortedImage={setUndistortedImage} cameraModel={cameraModel} openStep={setEditor} />
            <UnwarpPhoto editor={editor} stepIndex={stepIndex} setStepIndex={setStepIndex} undistortedImage={undistortedImage} unwarpedImage={unwarpedImage} setUnwarpedImage={setUnwarpedImage} board={board} setBoard={setBoard} openStep={setEditor} />
            <PreprocessPhoto editor={editor} stepIndex={stepIndex} setStepIndex={setStepIndex} unwarpedImage={unwarpedImage} preprocessedImage={preprocessedImage} setPreprocessedImage={setPreprocessedImage} board={board} setHistogram={setHistogram} openStep={setEditor} />
            <ThresholdPhoto editor={editor} stepIndex={stepIndex} setStepIndex={setStepIndex} preprocessedImage={preprocessedImage} histogram={histogram} thresholdedImage={thresholdedImage} setThresholdedImage={setThresholdedImage} openStep={setEditor} />
            <RetouchPhoto editor={editor} stepIndex={stepIndex} setStepIndex={setStepIndex} thresholdedImage={thresholdedImage} retouchedImage={retouchedImage} setRetouchedImage={setRetouchedImage} openStep={setEditor} />
            <ContourPhoto editor={editor} stepIndex={stepIndex} setStepIndex={setStepIndex} retouchedImage={retouchedImage} contouredImage={contouredImage} setContouredImage={setContouredImage} board={board} contour={contour} setContour={setContour} openStep={setEditor} />
            <PreviewModel contour={contour} stepIndex={stepIndex} openStep={setEditor} />
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}
import { getPalletteColor } from "@/utils";
import { useDndContext } from "@dnd-kit/core";

export default function InsertSidebarTrayListConnectors({ items }: {
  items: {
    trayId: number,
    blueprintId: number,
    type: "standalone" | "primary" | "secondary",
    group?: number,
  }[]
}) {
  const { active: dragActive } = useDndContext();
  const arrows: {
    start: number,
    end: number,
    offset: number,
    group: number,
  }[] = [];
  const groups = [...new Set(items.filter(i => i.group !== undefined).map(i => i.group!))];
  let groupOffset = 0;
  const itemHeight = 41;
  for(const group of groups) {
    const primary = items.find(i => i.group === group && i.type === "primary");
    if(primary) {
      const secondaries = items.filter(i => i.group === group && i.type === "secondary");
      if(secondaries.length > 0) {
        arrows.push(...secondaries.map(s => ({
          start: items.indexOf(primary),
          end: items.indexOf(s),
          offset: groupOffset,
          group: group,
        })));
      }
    }
    groupOffset++;
  }
  if(arrows.length == 0) {
    return <></>;
  }
  return (
    <div className="relative" style={{
      width: 7 + groups.length * 5,
      opacity: dragActive === null ? 1 : 0.1,
    }}>
      {arrows.map(a => <div key={arrows.indexOf(a)} className="absolute border-y border-r" style={{
        top: itemHeight / 2 + itemHeight * Math.min(a.start, a.end),
        height: itemHeight * Math.abs(a.end - a.start) + 1,
        width: 7 + a.offset * 5,
        borderColor: getPalletteColor(a.group),
      }}/>)}
      {arrows.map(a => <div key={arrows.indexOf(a)} className="absolute border-l" style={{
        top: itemHeight * a.start + 10,
        height: itemHeight - 20,
        width: 1,
        marginLeft: -1,
        borderColor: getPalletteColor(a.group),
      }}/>)}
    </div>
  );
}
import { selectModel } from "./state/store";
import { Model } from "./types";
import { compressObject, decompressObject, getTimestampDescription } from "./utils";


export function saveModelToLocalStorage() {
  const save = {
    model: selectModel(),
    timestamp: Date.now(),
  };
  const compressed = compressObject(save);
  localStorage.setItem("autosave", compressed);
}

export function loadModelFromLocalStorage() {
  const compressed = localStorage.getItem("autosave");
  if(compressed === null) {
    return null;
  }
  const save: {
    model: Model,
    timestamp: number,
  } | null = decompressObject(compressed);
  if(save === null) {
    return null;
  }
  const timestampDescription = getTimestampDescription(save.timestamp);
  return {
    ...save,
    name: `${save.model.insert.name} (${timestampDescription})`,
  }
}
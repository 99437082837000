import React from "react";
import { cn } from "./utils";
import { Spinner } from "./spinner";
import { Stripes, StripesProps } from "./stripes";
import { Icon, IconName } from "./icon";

export interface PlaceholderProps extends StripesProps, React.HTMLAttributes<HTMLDivElement> {
  type?: "busy" | "info",
  iconName?: IconName,
}

const Placeholder = React.forwardRef<HTMLDivElement, PlaceholderProps>(
  ({ children, iconName = "info-circle", type = "busy", accent, background, className, ...props }, ref) => {
    return (
      <Stripes className={cn("flex items-center justify-center p-4 rounded-lg", className)} accent={accent} background={background}>
        <div className="flex items-center gap-2 rounded bg-control/60 px-4 py-2">
          {type === "busy" ? <Spinner/> : <Icon name={iconName} className="text-primary"/>}
          {children}
        </div>
      </Stripes>
    )
  }
)

Placeholder.displayName = "Placeholder";

export { Placeholder }


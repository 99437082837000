import { SerializedEditorState } from 'lexical';
import { api } from '../store';
import { DocumentType, DocumentVote } from '@/types';

export interface AddDocumentResponse {
  id: string,
}

export interface AddVoteResponse {
  id: number,
}

export interface AddCommentResponse {
  id: number,
}

export interface DbDocument {
  id: string,
  slug: string,
  type: number,
  configuration: string,
  title: string,
  description: string,
  views: number,
  timestamp: string,
}

export interface DbDocuments {
  documents: DbDocument[],
  page: number,
  remaining: number,
}

export interface DbSearchDocument {
  id: string,
  slug: string,
  type: number,
  configuration: string,
  title: string,
  description: string,
  summary: string,
  views: number,
  timestamp: string,
}

export interface DbAddDocument {
  title: string,
  configuration: string,
  content: string,
  images: { [name: string] : string },
}

export interface DbDocumentComment {
  id: number,
  ownerId: number,
  ownerName: string,
  comment: string,
  timestamp: string
}

export interface DbDocumentDetails {
  slug: string,
  type: number,
  configuration: number,
  title: string,
  description: string,
  content: string,
  timestamp: string,
  editTimestamp: string | undefined,
  views: number,
  comments: DbDocumentComment[],
  votes: { [vote: number] : number },
  userVote?: number,
}

const documentsApi = api.injectEndpoints({
  endpoints: builder => ({
    listDocuments: builder.query<DbDocuments, {
      page: number,
      count: number,
    }>({
      query: (arg) => ({
        url: `/documents`,
        params: arg,
      }),
      providesTags: [ "documents" ],
    }),
    searchDocuments: builder.query<DbSearchDocument[], {
      query: string,
    }>({
      query: (arg) => ({
        url: `/documents/search`,
        params: arg,
      }),
    }),
    getDocument: builder.query<DbDocumentDetails, {
      id: number,
    }>({
      query: (arg) => ({
        url: `/documents/${arg.id}`,
      }),
      providesTags: [ "documents" ],
    }),
    addDocument: builder.mutation<AddDocumentResponse, {
      slug: string,
      type: DocumentType,
      configuration?: string,
      title: string,
      description: string,
      content: SerializedEditorState,
      timestamp: string,
    }>({
      query: (arg) => ({
        url: "/documents/",
        method: "POST",
        body: arg,
      }),
      invalidatesTags: [ "documents" ],
    }),
    editDocument: builder.mutation<AddDocumentResponse, {
      id: number,
      title: string,
      description: string,
      content: SerializedEditorState,
      timestamp: string,
    }>({
      query: (arg) => ({
        url: `/documents/${arg.id}`,
        method: "PUT",
        body: {
          title: arg.title,
          description: arg.description,
          content: arg.content,
          timestamp: arg.timestamp,
        },
      }),
      invalidatesTags: [ "documents" ],
    }),
    deleteDocument: builder.mutation<Record<string, never>, {
      id: number,
    }>({
      query: (arg) => ({
        url: `/documents/${arg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [ "documents" ],
    }),
    addVote: builder.mutation<AddVoteResponse, {
      updateId: number,
      vote: DocumentVote,
    }>({
      query: (arg) => ({
        url: `/documents/${arg.updateId}/vote/${arg.vote}`,
        method: "POST",
        body: arg,
      }),
    }),
    addComment: builder.mutation<AddCommentResponse, {
      updateId: number,
      content: string,
    }>({
      query: (arg) => ({
        url: `/documents/${arg.updateId}/comment`,
        method: "POST",
        body: {
          content: arg.content,
        },
      }),
      invalidatesTags: [ "documents" ],
    }),
  }),
  overrideExisting: false,
})

export const { useAddDocumentMutation, useListDocumentsQuery, useLazySearchDocumentsQuery, useGetDocumentQuery, useEditDocumentMutation, useDeleteDocumentMutation, useAddCommentMutation } = documentsApi;
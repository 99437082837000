import { useListPrintersQuery, useRatePrinterMutation } from "@/state/api/printers";
import { PageHeader } from "@ui/pageHeader";
import { Placeholder } from "@ui/placeholder";
import { Section } from "@ui/section";
import { SectionDescription } from "@ui/sectionDescription";
import { SectionHeader } from "@ui/sectionHeader";
import { PrinterCard } from "./PrinterCard";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@ui/table";
import { useState } from "react";
import { InputSearch } from "@ui/inputSearch";
import { Button } from "@ui/button";
import { Icon } from "@ui/icon";
import { useReporter } from "@/state/messages";
import { ScrollArea } from "@ui/scroll-area";
import { Skeleton } from "@ui/skeleton";
import { ExclamationTriangleIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { Dialog, DialogContent, DialogTrigger } from "@ui/dialog";
import { PrintersDialogCreate } from "./PrintersDialogAdd";
import { getResponseError } from "@/utils";
import { Page } from "@ui/page";

export default function PrintersList() {
  const listPrinters = useListPrintersQuery({});
  const reporter = useReporter();
  const [ ratePrinter ] = useRatePrinterMutation();
  const [query, setQuery] = useState("");
  const filteredPrinters = (listPrinters.isSuccess && listPrinters.data !== undefined) ? listPrinters.data.filter(b => b.name.toLowerCase().includes(query.toLowerCase())) : undefined;
  return (
    <Page>
      <PageHeader>
        <SectionHeader>
          Printers
        </SectionHeader>
        <SectionDescription>
          The listed printers provide information about the printbed sizes which is used in the download process to arrange build plates.
          Rate printers based on the accuracy of their data. Printers with a bad rating are deleted automatically.
        </SectionDescription>
      </PageHeader>
      <Section className="flex-auto overflow-hidden">
        <div className="flex gap-2">
          <InputSearch value={query} onChange={e => setQuery(e.target.value)} onClear={() => setQuery("")} className="flex-auto" autoFocus/>
          <Dialog>
            <DialogTrigger>
              <Button size="sm">
                <PlusCircledIcon/>
                Add printer
              </Button>
            </DialogTrigger>
            <DialogContent className="w-80">
              <PrintersDialogCreate/>
            </DialogContent>
          </Dialog>
        </div>
        <Table>
          <TableHeader>
            <TableRow className="rounded-t">
              <TableHead className="w-full">Name</TableHead>
              <TableHead>Printbed</TableHead>
              <TableHead>Rating</TableHead>
            </TableRow>
          </TableHeader>
          {listPrinters.isLoading ? (
            <TableBody>
              {[...Array(12).keys()].map(k => (
              <TableRow key={k}>
                <TableCell>
                  <Skeleton className="h-[25px]"/>
                </TableCell>
                <TableCell>
                  <Skeleton className="h-[25px]"/>
                </TableCell>
                <TableCell>
                  <Skeleton className="h-[25px]"/>
                </TableCell>
              </TableRow>
              ))}
            </TableBody>
          ) : filteredPrinters !== undefined ? (
            <TableBody>
              {filteredPrinters.map(p => (
                <TableRow key={p.id}>
                  <TableCell>
                    <div className="flex flex-col">
                      <div className="text-wrap font-medium">
                        {p.name}
                      </div>
                      {p.rating < 0 && (
                        <div>
                          <Icon name="exclamation-triangle" className="float-left mr-2 mt-1 text-yellow-600"/>
                          <div className="text-sm leading-tight text-foreground-muted">
                            This printer has a negative rating which indicates that the provided printbed size might be incorrect. If you are familiar with the printer, please validate the data and submit a rating.
                          </div>
                        </div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="font-mono">{p.x}x{p.y}</TableCell>
                  <TableCell>
                    <div className="flex items-center justify-end gap-1">
                      <div className={p.rating < 0 ? "mr-1 text-destructive" : "mr-1"}>
                        {p.rating}
                      </div>
                      <Button size="sm" onClick={async () => {
                        const response = await ratePrinter({
                          id: p.id,
                          rating: "down",
                        });
                        const error = getResponseError(response);
                        if(error !== null) {
                          reporter.error(error);
                        }
                      }}>
                        <Icon name="hand-thumbs-down" className="text-destructive"/>
                      </Button>
                      <Button size="sm" onClick={async () => {
                        const response = await ratePrinter({
                          id: p.id,
                          rating: "up",
                        });
                        const error = getResponseError(response);
                        if(error !== null) {
                          reporter.error(error);
                        }
                      }}>
                        <Icon name="hand-thumbs-up"/>
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {filteredPrinters.length == 0 && (
                <TableRow>
                  <TableCell colSpan={3}>
                    The query &quot;{query}&quot; returned no matching printers.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          ) : (
            <Placeholder>
              Error
            </Placeholder>
          )}
        </Table>
      </Section>
    </Page>
  );
}
import { Outlet } from "react-router";
import NavigationMenu from "@/pages/navigation/NavigationMenu";
import { useAppSelector } from "@/state/store";
import { SiteScope } from "@/types";

export default function CenteredLayout({ maxWidth, scope } : { maxWidth: number, scope: SiteScope }) {
  const session = useAppSelector(s => s.session);
  return (
    <>
      <div className="sticky top-0 z-10 flex h-12 justify-center border-b bg-control px-2 shadow">
        <div className="size-full" style={{
          maxWidth: maxWidth,
        }}>
          <NavigationMenu showInsert={true} showInsertToolbar={false} scope={scope}/>
        </div>
      </div>
      <main className="mx-auto w-full px-2" style={{
        maxWidth: maxWidth + 16,
      }}>
        <Outlet/>
      </main>
    </>
  );
}
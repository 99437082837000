import { Button } from "@ui/button"
import { DownloadIcon, Share2Icon } from "@radix-ui/react-icons";
import { saveModelToLocalStorage } from "@/localStorage";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogClose, DialogContent, DialogTrigger } from "@ui/dialog";
import { InsertDialogDownload } from "./InsertDialogDownload";
import { ScrollArea } from "@ui/scroll-area";
import { useAppSelector } from "@/state/store";
import InsertDialogPublish from "./InsertDialogPublish";

export default function InsertActions() {
  const navigate = useNavigate();
  const valid = useAppSelector(s => s.model.insert.messages.length === 0);
  return (
    <div className="mb-1 flex shrink-0 flex-row items-center gap-1">
      <Dialog>
        <DialogTrigger asChild>
          <Button disabled={!valid} variant="outline" size="sm">
          <Share2Icon />
          Publish
          </Button>
        </DialogTrigger>
        <DialogContent className="overflow-y-auto">
          <InsertDialogPublish/>
        </DialogContent>
      </Dialog>
      <Dialog>
        <DialogTrigger asChild>
          <Button disabled={!valid} variant="outline" size="sm">
            <DownloadIcon />
            Download
          </Button>
        </DialogTrigger>
        <DialogContent className="w-[700px] overflow-y-auto">
          <InsertDialogDownload />
        </DialogContent>
      </Dialog>
      <div className="flex-auto" />
      <Button size="sm" onClick={saveModelToLocalStorage}>
        Save
      </Button>
    </div>
  );
}
import * as React from "react"
import { cn } from "./utils"
import { Button, ButtonProps } from "./button"
import { InfoCircledIcon } from "@radix-ui/react-icons";

export interface HintButtonProps extends ButtonProps {
  hint?: string,
  containerClassName?: string,
  hintClassName?: string,
}

const HintButton = React.forwardRef<HTMLButtonElement, HintButtonProps>(
  ({ value, disabled, children, className, hintClassName, containerClassName, hint, ...props }, ref) => {
    return (
      <div className={cn("flex", containerClassName)}>
        {hint !== undefined && (
          <div className={cn("flex items-center gap-2 rounded-l border border-r-0 bg-control px-4 text-nowrap", hintClassName)}>
            <InfoCircledIcon className="text-primary"/>
            {hint}
          </div>
        )}
        <Button disabled={hint !== undefined || disabled} className={cn("rounded-l-none first:rounded-l", className)} ref={ref} {...props}>
          {children}
        </Button>
      </div>
    )
  }
)
HintButton.displayName = "HintButton"

export { HintButton }

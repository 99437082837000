import { Link } from "react-router-dom";
import { useAppSelector } from "@/state/store";
import { Button } from "@ui/button";
import { useLazySearchDocumentsQuery, useListDocumentsQuery } from "@/state/api/documents";
import { useEffect, useState } from "react";
import { DocumentsCard } from "./DocumentsCard";
import { Failure } from "@ui/failure";
import { Section } from "@ui/section";
import { Page } from "@ui/page";
import { Skeleton } from "@ui/skeleton";
import { Calendar } from "@ui/calendar";
import { InputSearch } from "@ui/inputSearch";
import { Switch } from "@ui/switch";
import { Label } from "@ui/label";
import { DocumentsCardSearch } from "./DocumentsCardSearch";

export default function DocumentsList() {
  const session = useAppSelector(s => s.session);
  const [ page, setPage ] = useState(0);
  const listDocumentsState = useListDocumentsQuery({
    page: page,
    count: 20,
  });
  const [ query, setQuery ] = useState("");
  const [ searchDocuments, searchDocumentsState ] = useLazySearchDocumentsQuery();
  useEffect(() => {
    if(query.length >= 4) {
      searchDocuments({query});
    }
  }, [query]);
  useEffect(() => {
    document.title = "Documents";
  }, [ ]);
  return (
    <Page>
      {session.user?.privileges.includes("post") && (
        <div className="flex gap-2">
          <Link to="/documents/publish?type=update">
            <Button variant="outline">
              New update
            </Button>
          </Link>
          <Link to="/documents/publish?type=documentation">
            <Button variant="outline">
              New documentation
            </Button>
          </Link>
        </div>
      )}
      <div className="flex gap-2 items-center">
        <InputSearch className="grow" value={query} onChange={e => setQuery(e.target.value)} onClear={() => setQuery("")}/>
        <Switch id="updates"/>
        <Label htmlFor="updates">
          Updates
        </Label>
        <Switch id="documentation"/>
        <Label htmlFor="documentation">
          Documentation
        </Label>
        <Switch id="guides"/>
        <Label htmlFor="guides">
          Guides
        </Label>
      </div>
      <div className="flex gap-2">
        <div className="flex grow flex-col gap-2">
          {searchDocumentsState.isFetching || listDocumentsState.isFetching ? (
            [...Array(12).keys()].map(k => (
              <Section key={k}>
                <div className="flex justify-between">
                  <Skeleton className="h-6 w-56"/>
                  <Skeleton className="h-6 w-32"/>
                </div>
                <Skeleton className="h-6 w-72"/>
                <Skeleton className="h-16 w-full"/>
              </Section>
            ))
          ) : query.length < 4 && listDocumentsState.isSuccess && listDocumentsState.data ? (
            listDocumentsState.data.documents.map(t => <DocumentsCard key={t.timestamp} document={t}/>)
          ) : searchDocumentsState.isSuccess && searchDocumentsState.data ? (
            searchDocumentsState.data.map(t => <DocumentsCardSearch key={t.timestamp} document={t} query={query}/>)
          ) : (
            <Failure>
              Failed to load documents.
            </Failure>
          )}
        </div>
        <Section className="sticky top-14 hidden self-start md:block">
          <Calendar/>
        </Section>
      </div>
    </Page>
  );
}
import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, VariantProps } from "class-variance-authority"
import { cn } from "./utils"
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip"

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md font-medium transition-colors focus:border-primary disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        primary:
          "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "border border-destructive bg-background text-destructive shadow-sm hover:bg-destructive hover:text-destructive-foreground",
        outline:
          "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:font-semibold",
        link: "hover:text-primary hover:underline",
        selector:
          "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground disabled:bg-control disabled:text-primary disabled:opacity-100",
        selector_primary:
          "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground disabled:bg-primary disabled:opacity-100",
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-md px-2",
        lg: "h-10 rounded-md px-8",
        icon: "size-8 p-1",
      },
    },
    defaultVariants: {
      variant: "outline",
      size: "default",
    },
  }
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean,
  tooltip?: React.ReactNode,
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, tooltip, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    if(tooltip === undefined) {
      return (
        <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}/>
      );
    } else {
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}/>
          </TooltipTrigger>
          <TooltipContent>
            {tooltip}
          </TooltipContent>
        </Tooltip>
      );
    }
  }
)
Button.displayName = "Button";

export { Button, buttonVariants };

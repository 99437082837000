import React from "react"
import { cn } from "./utils"
import { Button } from "./button";
import { ChevronLeftIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { Stripes } from "./stripes";
import { Section } from "./section";
import { useMediaQuery } from "react-responsive";

export interface PageHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
}

const PageHeader = React.forwardRef<HTMLDivElement, PageHeaderProps>(
  ({ children, className, ...props }, ref) => {
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery({ minWidth: 640 });
    if (!isSmallScreen) {
      return <></>;
    } else {
      return (
        <div className={cn("flex", className)} {...props}>
          <Stripes className="flex flex-none items-center rounded-l border p-4">
            <Button variant="outline" onClick={() => navigate(-1)}>
              <ChevronLeftIcon />
              Back
            </Button>
          </Stripes>
          <Section className="flex-auto rounded-l-none border-l-0">
            {children}
          </Section>
        </div>
      );
    }
  }
)

PageHeader.displayName = "PageHeader";

export { PageHeader }





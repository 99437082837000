import { GroupProps, useThree } from "@react-three/fiber";
import { CameraControls, GizmoHelper, GizmoViewport } from "@react-three/drei";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { Group, Box3, Mesh, Vector3, Sphere, BufferGeometry, Matrix4, PerspectiveCamera } from "three";
import { OrthographicCamera } from "three";
import { Camera } from "three";
import { roundNumber, roundVector } from "@/utils";
import { ViewcubeGizmo } from "./Gizmo";

export interface StageHandle {
  controls: CameraControls,
  fit: (mode: "box" | "sphere" | "model", transition: boolean) => void,
  getBounds: () => Box3,
}

interface StageProps extends GroupProps {
  panGround: boolean,
  enableControls?: boolean,
  showGizmo?: boolean,
  fit?: boolean,
}

function aggregateBoundingBox(group: Group) {
  let box = new Box3()
  for (const child of group.children) {
    if (child instanceof Mesh && child.isMesh) {
      if (child.geometry instanceof BufferGeometry && child.geometry.isBufferGeometry && child.geometry.type === "BufferGeometry") {
        child.geometry.computeBoundingBox();
        const position = new Vector3();
        child.getWorldPosition(position);
        box = box.union(new Box3(child.geometry.boundingBox!.min.add(position), child.geometry.boundingBox!.max.add(position)));
      }
    } else if (child instanceof Group && child.isGroup) {
      box = box.union(aggregateBoundingBox(child));
    }
  }
  return box;
}

function isOrthographic(def: Camera) {
  if (def instanceof OrthographicCamera) {
    return def.isOrthographicCamera;
  }
  return false;
}

const Stage = forwardRef<StageHandle, StageProps>(
  ({ enableControls = true, showGizmo = false, fit = false, panGround, children, ...props }, ref) => {
    const camera = useThree(s => s.camera);
    const contentRef = useRef<Group>(null);
    const cameraControlsRef = useRef<CameraControls>(null);
    const getBounds = () => {
      if (contentRef.current !== null) {
        contentRef.current.updateWorldMatrix(true, true);
        const box = aggregateBoundingBox(contentRef.current);
        return new Box3(new Vector3(roundNumber(box.min.x), roundNumber(box.min.y), roundNumber(box.min.z)), new Vector3(roundNumber(box.max.x), roundNumber(box.max.y), roundNumber(box.max.z)));
      }
      return new Box3();
    };
    useImperativeHandle(ref, () => ({
      controls: cameraControlsRef.current!,
      getBounds,
      fit: (mode, transition) => {
        if (cameraControlsRef.current != null && contentRef.current !== null) {
          contentRef.current.updateWorldMatrix(true, true);
          const box = aggregateBoundingBox(contentRef.current);
          if (mode === "box") {
            cameraControlsRef.current.fitToBox(box, transition);
          } else if (mode === "sphere") {
            const sphere = new Sphere();
            box.getBoundingSphere(sphere);
            cameraControlsRef.current.fitToSphere(sphere, transition);
          } else {
            const rotation = new Matrix4().extractRotation(camera.matrixWorld);
            box.applyMatrix4(rotation);
            cameraControlsRef.current.fitToBox(box, transition);
            /*
            const { center, distance } = getSize(box, 1, camera);
            const direction = camera.position.clone().sub(center).normalize();
            const position = center.clone().addScaledVector(direction, distance);
            if(camera instanceof PerspectiveCamera && camera.isPerspectiveCamera) {
              cameraControlsRef.current.setLookAt(position.x, position.y, position.z, center.x, center.y, center.z, false);
            } else if(camera instanceof OrthographicCamera && camera.isOrthographicCamera) {
              let maxHeight = 0;
              let maxWidth = 0;
              const vertices = [
                new Vector3(box.min.x, box.min.y, box.min.z),
                new Vector3(box.min.x, box.max.y, box.min.z),
                new Vector3(box.min.x, box.min.y, box.max.z),
                new Vector3(box.min.x, box.max.y, box.max.z),
                new Vector3(box.max.x, box.max.y, box.max.z),
                new Vector3(box.max.x, box.max.y, box.min.z),
                new Vector3(box.max.x, box.min.y, box.max.z),
                new Vector3(box.max.x, box.min.y, box.min.z),
              ];
              // Transform the center and each corner to camera space
              const mCamWInv = center ? new Matrix4().lookAt(position, center, camera.up).setPosition(position).invert() : camera.matrixWorldInverse;
              for (const v of vertices) {
                v.applyMatrix4(mCamWInv);
                maxHeight = Math.max(maxHeight, Math.abs(v.y));
                maxWidth = Math.max(maxWidth, Math.abs(v.x));
              }
              maxHeight *= 2;
              maxWidth *= 2;
              const zoomForHeight = (camera.top - camera.bottom) / maxHeight
              const zoomForWidth = (camera.right - camera.left) / maxWidth
              cameraControlsRef.current.zoomTo(Math.min(zoomForHeight, zoomForWidth) / 1.2);
            }*/
          }
        }
      }
    }), []);
    useEffect(() => {
      if (fit && cameraControlsRef.current != null && contentRef.current !== null) {
        setTimeout(() => {
          contentRef.current!.updateWorldMatrix(true, true);
          const box = aggregateBoundingBox(contentRef.current!);
          const sphere = new Sphere();
          box.getBoundingSphere(sphere);
          cameraControlsRef.current!.fitToSphere(sphere, false);
        }, 0);
      }
    }, []);
    return (
      <group {...props}>
        <CameraControls makeDefault ref={cameraControlsRef} verticalDragToForward={panGround} enabled={enableControls} minDistance={3} maxDistance={800} smoothTime={0.1} draggingSmoothTime={0.1} />
        {showGizmo && (
          <ViewcubeGizmo margin={30} size={60} getBounds={getBounds}/>
        )}
        <group ref={contentRef}>
          {children}
        </group>
      </group>
    );
  });
Stage.displayName = "Stage";

export { Stage };
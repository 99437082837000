import { PageHeader } from "@ui/pageHeader";
import { Placeholder } from "@ui/placeholder";
import { Section } from "@ui/section";
import { SectionDescription } from "@ui/sectionDescription";
import { SectionHeader } from "@ui/sectionHeader";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@ui/table";
import { useState } from "react";
import { InputSearch } from "@ui/inputSearch";
import { Button } from "@ui/button";
import { Icon } from "@ui/icon";
import { useReporter } from "@/state/messages";
import { ScrollArea } from "@ui/scroll-area";
import { Skeleton } from "@ui/skeleton";
import { PlusCircledIcon } from "@radix-ui/react-icons";
import { getResponseError, getTimestampDescription } from "@/utils";
import { Page } from "@ui/page";
import { useNavigate } from "react-router-dom";
import { useListCamerasQuery, useRateCameraMutation } from "@/state/api/cameras";

export default function CamerasList() {
  const listCameras = useListCamerasQuery({
    page: 0,
    count: 100,
  });
  const reporter = useReporter();
  const navigate = useNavigate();
  const [ rateCamera ] = useRateCameraMutation();
  const [query, setQuery] = useState("");
  const filteredBoxes = (listCameras.isSuccess && listCameras.data !== undefined) ? listCameras.data.cameras.filter(b => b.model.toLowerCase().includes(query.toLowerCase())) : undefined;
  return (
    <Page>
      <PageHeader>
        <SectionHeader>
          Cameras
        </SectionHeader>
        <SectionDescription>
          Cameras are used to manage lense calibration data for eliminating radial and tagential distortion introduced by the physical camera setup. This will improve part scan accuracy.
        </SectionDescription>
      </PageHeader>
      <Section className="flex-auto overflow-hidden">
        <div className="flex gap-2">
          <InputSearch value={query} onChange={e => setQuery(e.target.value)} onClear={() => setQuery("")} className="flex-auto" autoFocus/>
          <Button size="sm" onClick={() => navigate("/cameras/new")}>
            <PlusCircledIcon/>
            Add camera
          </Button>
        </div>
        <ScrollArea section>
          <Table>
            <TableHeader>
              <TableRow className="rounded-t">
                <TableHead className="w-full">Model</TableHead>
                <TableHead className="">Rating</TableHead>
              </TableRow>
            </TableHeader>
            {listCameras.isLoading ? (
              <TableBody>
                {[...Array(12).keys()].map(k => (
                <TableRow key={k}>
                  <TableCell>
                    <Skeleton className="h-[25px]"/>
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-[25px]"/>
                  </TableCell>
                </TableRow>
                ))}
              </TableBody>
            ) : filteredBoxes !== undefined ? (
              <TableBody>
                {filteredBoxes.map(b => (
                  <TableRow key={b.id}>
                    <TableCell className="cursor-pointer" onClick={() => navigate("/boxes/" + b.id)}>
                      <div className="flex flex-col">
                        <div className="font-medium">
                          {b.model}
                        </div>
                        {b.rating < 0 && (
                          <div>
                            <Icon name="exclamation-triangle" className="float-left mr-2 mt-1 text-yellow-600"/>
                            <div className="text-sm leading-tight text-foreground-muted">
                              This camera has a negative rating which indicates that the provided calibration data might be incorrect. If you are familiar with the camera, please validate the calibration data and submit a rating.
                            </div>
                          </div>
                        )}
                        <div className="whitespace-nowrap text-sm">
                          {getTimestampDescription(b.description)}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center gap-1">
                        <div className={b.rating < 0 ? "mr-1 text-destructive" : "mr-1"}>
                          {b.rating}
                        </div>
                        <Button size="sm" onClick={async () => {
                          const response = await rateCamera({
                            id: b.id,
                            rating: "down",
                          });
                          const error = getResponseError(response);
                          if(error !== null) {
                            reporter.error(error);
                          }
                        }}>
                          <Icon name="hand-thumbs-down" className="text-destructive"/>
                        </Button>
                        <Button size="sm" onClick={async () => {
                          const response = await rateCamera({
                            id: b.id,
                            rating: "up",
                          });
                          const error = getResponseError(response);
                          if(error !== null) {
                            reporter.error(error);
                          }
                        }}>
                          <Icon name="hand-thumbs-up"/>
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
                {filteredBoxes.length == 0 && (
                  <TableRow>
                    <TableCell colSpan={4}>
                      The query &quot;{query}&quot; returned no matching boxes.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <Placeholder>
                Error
              </Placeholder>
            )}
          </Table>
        </ScrollArea>
      </Section>
    </Page>
  );
}
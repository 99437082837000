import { forwardRef, Fragment } from "react";
import {ReplicadShapeHandle, ReplicadShapeInteractive } from "./ReplicadShape";
import { ClippingPlaneConfiguration, TrayModel, Vector } from "@/types";
import { EdgeSelection, FaceSelection, Selection } from "@/pages/designer/blueprint/BlueprintCanvas";
import { ThreeEvent } from "@react-three/fiber";

interface ReplicadShapesProps {
  models: TrayModel[],
  selections: Selection[],
  onSelect?: (s: Selection) => void,
  clippingPlaneConfiguration?: ClippingPlaneConfiguration,
  edgeOpacity: number,
  edgeDepthTest?: boolean,
  color?: string,
  onClick?: (e: ThreeEvent<MouseEvent>) => void
}

export function ReplicadShapes({
  models,
  selections,
  onSelect,
  clippingPlaneConfiguration,
  edgeOpacity,
  edgeDepthTest = true,
  color,
  onClick,
} : ReplicadShapesProps) {
  return models.map(model => (
    <ReplicadShapeInteractive
      key={model.name}
      clippingPlaneConfiguration={clippingPlaneConfiguration}
      edgeOpacity={edgeOpacity}
      model={model}
      edgeDepthTest={edgeDepthTest}
      color={color}
      edgesHighlight={selections.filter(s => s.type == "edge" && s.shapeName == model.name).map(s => (s as EdgeSelection).index)}
      facesHighlight={selections.filter(s => s.type == "face" && s.shapeName == model.name).map(s => (s as FaceSelection).index)}
      onFaceClick={(e, index, face) => onSelect?.({
        type: "face",
        shapeName: model.name,
        index: index,
        face: face,
      })} onEdgeClick={(e, index, edge) => onSelect?.({
        type: "edge",
        shapeName: model.name,
        index: index,
        edge: edge,
      })} onClick={onClick}/>
  ));
}
import { SerializedEditorState } from 'lexical';
import { api } from '../store'

export interface AddTrayResponse {
  id: number,
}

export interface AddTrayVersionResponse {
  id: number,
  version: number,
}

export interface DbTrayDetails {
  ownerId: number,
  name: string,
  views: string,
  latestVersion: number,
  description: string,
  images: string,
  content: string,
  timestamp: string,
}

export interface DbTray {
  id: number,
  name: string,
  views: number,
  latestVersion: number,
  images: string,
  timestamp: string,
}

export interface DbTrayVersion {
  images: string,
  version: number,
  timestamp: string,
}

export interface DbTrayVersionDetails {
  ownerId: number,
  name: string,
  views: string,
  description: string,
  timestamp: string,
  content: string,
}

const traysApi = api.injectEndpoints({
  endpoints: builder => ({
    addTray: builder.mutation<AddTrayResponse, {
      name: string,
      description: SerializedEditorState,
      content: string,
      images: string[],
    }>({
      query: (arg) => ({
        url: "/trays/",
        method: "POST",
        body: arg,
      }),
      invalidatesTags: [ "trays" ],
    }),
    addTrayVersion: builder.mutation<AddTrayVersionResponse, {
      id: number,
      description: string,
      content: string,
      images: string[],
    }>({
      query: (arg) => ({
        url: `/trays/${arg.id}/versions`,
        method: "POST",
        body: {
          description: arg.description,
          content: arg.content,
          images: arg.images,
        },
      }),
      invalidatesTags: [ "trays" ],
    }),
    getTray: builder.query<DbTrayDetails, {
      id: number,
    }>({
      query: (arg) => ({
        url: `/trays/${arg.id}`,
      }),
      providesTags: [ "trays" ],
    }),
    listTrayVersions: builder.query<DbTrayVersion[], {
      id: number,
    }>({
      query: (arg) => ({
        url: `/trays/${arg.id}/versions`,
      }),
      providesTags: [ "trays" ],
    }),
    getTrayVersion: builder.query<DbTrayVersionDetails, {
      id: number,
      version: number,
    }>({
      query: (arg) => ({
        url: `/trays/${arg.id}/versions/${arg.version}`,
      }),
      providesTags: [ "trays" ],
    }),
    listTrays: builder.query<DbTray[], Record<string, never>>({
      query: () => ({
        url: "/trays",
      }),
      providesTags: [ "trays" ],
    }),
  }),
  overrideExisting: false,
})

export const { useAddTrayMutation, useAddTrayVersionMutation, useLazyGetTrayVersionQuery, useLazyListTrayVersionsQuery, useLazyGetTrayQuery, useListTraysQuery } = traysApi;
import { Line } from "@react-three/drei";
import { TrayConnector } from "@/types";
import InsertCanvasHorizontalConnectorPanel from "./InsertCanvasHorizontalConnectorPanel";

export default function InsertCanvasHorizontalConnector({ connector, negative, insertHeight, action } : {
  connector: TrayConnector,
  negative: boolean,
  insertHeight: number,
  action: (type: "fill" | "snap", axis: "X" | "Y" | "Z", distance: number, negative: boolean) => void,
 }) {
  const wiggle = negative ? -0.05 : 0.05;
  const sign = negative ? -1 : 1;
  const distance = connector.distance * sign;
  let yOffset: number;
  if(connector.endOrthogonalMax !== undefined && connector.endOrthogonalMin !== undefined) {
    yOffset = (Math.min(connector.startOrthogonalMax, connector.endOrthogonalMax) + Math.max(connector.startOrthogonalMin, connector.endOrthogonalMin)) / 2;
  } else {
    yOffset = (connector.startOrthogonalMin + connector.startOrthogonalMax) / 2;
  }
  return (
    <>
      <Line lineWidth={2} points={[[connector.offset + wiggle, yOffset, connector.z + 0.05], [connector.offset + distance - wiggle, yOffset, connector.z + 0.05]]} color="#F87171"/>
      <Line lineWidth={2} points={[[connector.offset + wiggle, connector.startOrthogonalMin, connector.z + 0.05], [connector.offset + wiggle, connector.startOrthogonalMax, connector.z + 0.05]]} color="#F87171" />
      {connector.endOrthogonalMax !== undefined && connector.endOrthogonalMin !== undefined ? (
        <Line lineWidth={2} points={[[connector.offset + distance - wiggle, connector.endOrthogonalMin, connector.z + 0.05], [connector.offset + distance - wiggle, connector.endOrthogonalMax, connector.z + 0.05]]} color="#F87171"/>
      ) : (
        <Line points={[[connector.offset + distance, yOffset, 0], [connector.offset + distance, yOffset, insertHeight]]} color="#94a3b8" />
      )}
      <InsertCanvasHorizontalConnectorPanel distance={connector.distance} action={action} negative={negative} flip={yOffset <= (connector.startOrthogonalMin + connector.startOrthogonalMax) / 2} position={{
        x: connector.offset + wiggle,
        y: yOffset,
        z: connector.z + 0.05,
      }}/>
    </>
  );
}
import { useEffect, useState } from "react";
import workerUrl from "./replicadWorker?worker&url";
import { TrayGeometry, Blueprint, Tray } from "./types";
import { selectBlueprint, selectTray } from "./state/store";
import { reporter } from "./state/messages";

export interface WorkerRequestGeometry {
  jobId: number,
  type: "geometry",
  blueprint: Blueprint,
  tray: Tray,
  debug: boolean,
}

export interface WorkerRequestStl {
  jobId: number,
  type: "stl",
  blueprint: Blueprint,
  tray: Tray,
}

export type WorkerRequest = WorkerRequestGeometry | WorkerRequestStl;

export interface WorkerResultGeometry {
  jobId: number,
  type: "geometry",
  geometries: TrayGeometry[],
}

export interface WorkerResultStl {
  jobId: number,
  type: "stl",
  stl: Blob,
  error?: string,
}

export interface WorkerResultError {
  jobId: number,
  type: "error",
  error: string,
}

export type WorkerResult = WorkerResultStl | WorkerResultGeometry | WorkerResultError;

interface WorkerJob {
  id: number,
  request: WorkerRequest,
  isActive: boolean,
  isCompleted: boolean,
}

interface WorkerJobInternal extends WorkerJob {
  workerIndex: number,
  sessionKey: string,
  sessionToken: number,
  complete: (result: WorkerResult) => void,
  completion: Promise<WorkerResult>,
}

export interface WorkerInstance {
  worker: Worker,
  isActive: boolean,
}

const geometryCache = new Map<string, TrayGeometry[]>();
const sessions = new Map<string, number>();
const workers: WorkerInstance[] = [];
const listeners: ((jobs: WorkerJob[]) => void)[] = [];
let nextJobId = 0;
let jobs: WorkerJobInternal[] = [];

export function addWorkers(count = 1) {
  while(count-- > 0) {
    const worker = new Worker(workerUrl, {
      type: "module",
    });
    worker.addEventListener("message", (e: MessageEvent<WorkerResult>) => handleWorkerResponse(e.data));
    workers.push({
      isActive: false,
      worker,
    });
  }
  updateState();
}

// add initial 4 workers
addWorkers(4);

export function getCacheSize() {
  return geometryCache.size;
}

export function clearCache() {
  geometryCache.clear();
  sessions.clear();
}

export function useWorkerPool() {
  const [currentJobs, updateJobs] = useState<WorkerJob[]>(jobs);
  useEffect(() => {
    listeners.push(updateJobs);
    return () => {
      const index = listeners.indexOf(updateJobs);
      if (index != -1) {
        listeners.splice(index, 1);
      }
    };
  }, []);
  return currentJobs;
}

function updateState() {
  const copy: WorkerJob[] = jobs.map(j => ({
    id: j.id,
    isActive: j.isActive,
    isCompleted: j.isCompleted,
    request: j.request,
  }));
  for (const listener of listeners) {
    listener(copy);
  }
}

export function inGeometryCache(trayId: number, debug: boolean) {
  const tray = selectTray(trayId);
  return inGeometryCacheAlt(tray, selectBlueprint(tray.blueprintId), debug);
}

export function inGeometryCacheAlt(tray: Tray, blueprint: Blueprint, debug: boolean) {
  const cacheKey = blueprint.modelHash + "-" + tray.modelHash + (debug ? "-d" : "");
  return geometryCache.has(cacheKey);
}

function queueJob(sessionKey: string, sessionToken: number, request: WorkerRequest) {
  // purge depecated jobs
  jobs = jobs.filter(j => j.sessionKey !== sessionKey || j.isActive || j.isCompleted);
  // add new job
  request.jobId = nextJobId;
  let jobResolve: (result: WorkerResult) => void;
  const completion = new Promise<WorkerResult>((resolve) => {
    jobResolve = resolve;
  });
  jobs.push({
    request,
    completion: completion,
    complete: jobResolve!,
    isActive: false,
    isCompleted: false,
    id: nextJobId++,
    sessionKey,
    sessionToken,
    workerIndex: 0, // not known yet
  });
  handleQueuedJobs();
  updateState();
  return completion;
}

function handleQueuedJobs() {
  const job = jobs.find(j => !j.isActive && !j.isCompleted)
  if(job === undefined) {
    return false;
  }
  for (let index = 0; index < workers.length; index++) {
    if (!workers[index].isActive) {
      job.workerIndex = index;
      job.isActive = true;
      workers[index].isActive = true;
      workers[index].worker.postMessage(job.request);
      return true;
    }
  }
  return false;
}

function handleWorkerResponse(result: WorkerResult) {
  const job = jobs.find(j => j.id === result.jobId);
  if(job === undefined) {
    console.error("WOHA!")
    return;
  }
  job.isActive = false;
  job.isCompleted = true;
  const worker = workers[job.workerIndex];
  worker.isActive = false;
  handleQueuedJobs();
  updateState();
  setTimeout(() => {
    jobs = jobs.filter(j => j.id !== job.id);
    updateState();
  }, 2000);
  const currentSessionToken = sessions.get(job.sessionKey);
  if(currentSessionToken !== undefined && currentSessionToken > job.sessionToken) {
    job.complete({
      jobId: result.jobId,
      type: "error",
      error: "invalid session",
    });
  }
  job.complete(result);
}

export async function generateTrayGeometriesAlt(tray: Tray, blueprint: Blueprint, debug: boolean) {
  if (blueprint === null || tray === null || tray.size.x == 0 || tray.size.y == 0 || tray.size.z == 0) {
    return [];
  }
  // increase session to invalidate previous requests
  const sessionKey = blueprint.id + "-" + tray.id;
  let sessionToken = sessions.get(sessionKey);
  if (sessionToken === undefined) {
    sessionToken = 1;
  } else {
    sessionToken++;
  }
  sessions.set(sessionKey, sessionToken);
  const cacheKey = blueprint.modelHash + "-" + tray.modelHash + (debug ? "-d" : "");
  const cacheHit = geometryCache.get(cacheKey);
  if (cacheHit !== undefined) {
    return cacheHit;
  }
  const result = await queueJob(sessionKey, sessionToken, {
    jobId: 0,
    type: "geometry",
    blueprint: blueprint,
    tray: tray,
    debug: debug
  });
  if(result.type === "geometry") {
    geometryCache.set(cacheKey, result.geometries);
    return result.geometries;
  } else {
    return [];
  }
}

export function generateTrayGeometries(trayId: number, debug: boolean): Promise<TrayGeometry[]> {
  const tray = selectTray(trayId);
  const blueprint = selectBlueprint(tray.blueprintId);
  return generateTrayGeometriesAlt(tray, blueprint, debug);
}


export async function generateTrayStlAlt(tray: Tray, blueprint: Blueprint) {
  if (blueprint === null || tray === null || tray.size.x == 0 || tray.size.y == 0 || tray.size.z == 0) {
    return null;
  }
  // increase session to invalidate previous requests
  const sessionKey = blueprint.id + "-" + tray.id;
  let sessionToken = sessions.get(sessionKey);
  if (sessionToken === undefined) {
    sessionToken = 1;
  } else {
    sessionToken++;
  }
  sessions.set(sessionKey, sessionToken);
  const result = await queueJob(sessionKey, sessionToken, {
    jobId: 0,
    type: "stl",
    blueprint: blueprint,
    tray: tray,
  });
  if(result.type === "stl") {
    return result.stl;
  } else {
    return null;
  }
}

export function generateTrayStl(trayId: number) {
  const tray = selectTray(trayId);
  const blueprint = selectBlueprint(tray.blueprintId);
  return generateTrayStlAlt(tray, blueprint);
}
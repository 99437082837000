/* eslint-disable react/no-unknown-property */
import { Scene, Matrix4, Vector3, Euler, Camera, Sphere, Box3, Group } from 'three'
import React, { useRef, useMemo, useState, useCallback, MutableRefObject } from 'react'
import { Canvas, useFrame, useThree, createPortal } from '@react-three/fiber'
import { Box, CameraControls, OrbitControls, OrthographicCamera, Plane, Text, useCamera } from '@react-three/drei'
import { StageHandle } from './Stage';
import { Object3DEventMap } from 'three';

function ViewcubeFace({size, position, rotation, label, camera, color, hoverColor, action} : { size: number, position: Vector3, rotation: Euler, label: string, camera: MutableRefObject<Camera>, color: string, hoverColor: string, action: () => void}) {
  const [ hover, setHover] = useState(false);
  return (
    <>
      <Plane args={[size, size]} position={position} rotation={rotation} onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)} raycast={useCamera(camera)} onClick={e => {
        e.stopPropagation();
        if(e.delta < 0.01) {
          action();
        }
      }}>
        <meshBasicMaterial color={hover ? hoverColor: color} polygonOffset polygonOffsetFactor={3} polygonOffsetUnits={3}/>
      </Plane>
      <Text position={position} rotation={rotation} scale={size / 4} anchorX="center" anchorY="middle" renderOrder={1}>
        <meshBasicMaterial color="black" />
        {label}
      </Text>
    </>
  );
}

function ViewcubeCorner({size, position, camera, action} : { size: number, position: Vector3, camera: MutableRefObject<Camera>, action: () => void}) {
  const [ hover, setHover] = useState(false);
  return (
    <>
      <Box args={[size, size, size]} position={position} onPointerEnter={e => {
        e.stopPropagation();
        setHover(true);
      }} onPointerLeave={e => {
        e.stopPropagation();
        setHover(false);
      }} raycast={useCamera(camera)} onClick={e => {
        e.stopPropagation();
        if(e.delta < 0.01) {
          action();
        }
      }} renderOrder={3}>
        <meshBasicMaterial color={hover ? "white": "#e4e4e7"} transparent={true} opacity={0.6} polygonOffset polygonOffsetFactor={3} polygonOffsetUnits={3}/>
      </Box>
    </>
  );
}

function ViewcubeEdge({size, position, camera, action} : { size: Vector3, position: Vector3, camera: MutableRefObject<Camera>, action: () => void}) {
  const [ hover, setHover] = useState(false);
  return (
    <>
      <Box args={[size.x, size.y, size.z]} position={position} onPointerEnter={e => {
        e.stopPropagation();
        setHover(true);
      }} onPointerLeave={e => {
        e.stopPropagation();
        setHover(false);
      }} raycast={useCamera(camera)} onClick={e => {
        e.stopPropagation();
        if(e.delta < 0.01) {
          action();
        }
      }} renderOrder={2}>
        <meshBasicMaterial color={hover ? "white": "#e4e4e7"} transparent={true} opacity={0.6} polygonOffset polygonOffsetFactor={3} polygonOffsetUnits={3}/>
      </Box>
    </>
  );
}

export function ViewcubeGizmo({ margin = 25, size = 60, getBounds } : { margin?: number, size?: number, getBounds: () => Box3}) {
  const { gl, scene, camera, size: canvasSize, controls } = useThree()
  const virtualScene = useMemo(() => new Scene(), []);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const virtualCam = useRef<any>(null!);
  const groupRef = useRef<Group<Object3DEventMap>>(null!);
  useFrame(() => {
    groupRef.current.quaternion.setFromRotationMatrix(camera.matrix.clone().invert());
    gl.autoClear = true;
    gl.render(scene, camera);
    gl.autoClear = false;
    gl.clearDepth();
    gl.render(virtualScene, virtualCam.current);
  }, 1);
  const fitFace = useCallback((offset: Vector3) => {
    const cameraControls = controls as unknown as CameraControls; 
    const box = getBounds();
    const sphere = box.getBoundingSphere(new Sphere());
    const distance = cameraControls.getDistanceToFitSphere(sphere.radius);
    const position = sphere.center.clone().add(offset.clone().normalize().multiplyScalar(distance));
    cameraControls.setLookAt(position.x, position.y , position.z, sphere.center.x, sphere.center.y, sphere.center.z, true);
  }, [ getBounds, controls ]);
  return createPortal(
    <>
      <OrthographicCamera ref={virtualCam} makeDefault={false} position={[0, 0, 100]} />
      <group ref={groupRef} position={[margin + (size -canvasSize.width) / 2, margin + (size - canvasSize.height) / 2, 0]}>
        <ViewcubeFace position={new Vector3(0, -size / 2, 0)} rotation={new Euler(Math.PI / 2, 0, 0)} label="Front" size={size} camera={virtualCam} color="#F87171" hoverColor="#FB8282" action={() => fitFace(new Vector3(0, -1, 0))}/>
        <ViewcubeFace position={new Vector3(0, size / 2, 0)} rotation={new Euler(Math.PI / 2, Math.PI, 0)} label="Back" size={size} camera={virtualCam}  color="#F87171" hoverColor="#FB8282" action={() => fitFace(new Vector3(0, 1, 0))}/>
        <ViewcubeFace position={new Vector3(-size / 2, 0, 0)} rotation={new Euler(Math.PI / 2, -Math.PI / 2, 0)} label="Left" size={size} color="#22C55E" hoverColor="#27db6a" camera={virtualCam} action={() => fitFace(new Vector3(-1, 0, 0))}/>
        <ViewcubeFace position={new Vector3(size / 2, 0, 0)} rotation={new Euler(Math.PI / 2, Math.PI / 2, 0)} label="Right" size={size} camera={virtualCam} color="#22C55E" hoverColor="#27db6a" action={() => fitFace(new Vector3(1, 0, 0))}/>
        <ViewcubeFace position={new Vector3(0, 0, size / 2)} rotation={new Euler(0, 0, 0)} label="Top" size={size} color="#60a5fa" hoverColor="#71b0fc" camera={virtualCam} action={() => fitFace(new Vector3(0, 0, 1))}/>
        <Plane args={[size, size]} position={new Vector3(0, 0, -size / 2)} rotation={new Euler(Math.PI, 0, 0)}>
          <meshBasicMaterial color="#60a5fa" polygonOffset polygonOffsetFactor={3} polygonOffsetUnits={3}/>
        </Plane>

        <ViewcubeCorner position={new Vector3(-size / 2.5, -size / 2.5, -size / 2.5)} size={size / 4} camera={virtualCam} action={() => fitFace(new Vector3(-1, -1, -1))}/>
        <ViewcubeCorner position={new Vector3(size / 2.5, -size / 2.5, -size / 2.5)} size={size / 4} camera={virtualCam} action={() => fitFace(new Vector3(1, -1, -1))}/>
        <ViewcubeCorner position={new Vector3(-size / 2.5, size / 2.5, -size / 2.5)} size={size / 4} camera={virtualCam} action={() => fitFace(new Vector3(-1, 1, -1))}/>
        <ViewcubeCorner position={new Vector3(size / 2.5, size / 2.5, -size / 2.5)} size={size / 4} camera={virtualCam} action={() => fitFace(new Vector3(1, 1, -1))}/>
        <ViewcubeCorner position={new Vector3(-size / 2.5, -size / 2.5, size / 2.5)} size={size / 4} camera={virtualCam} action={() => fitFace(new Vector3(-1, -1, 1))}/>
        <ViewcubeCorner position={new Vector3(size / 2.5, -size / 2.5, size / 2.5)} size={size / 4} camera={virtualCam} action={() => fitFace(new Vector3(1, -1, 1))}/>
        <ViewcubeCorner position={new Vector3(-size / 2.5, size / 2.5, size / 2.5)} size={size / 4} camera={virtualCam} action={() => fitFace(new Vector3(-1, 1, 1))}/>
        <ViewcubeCorner position={new Vector3(size / 2.5, size / 2.5, size / 2.5)} size={size / 4} camera={virtualCam} action={() => fitFace(new Vector3(1, 1, 1))}/>

        <ViewcubeEdge position={new Vector3(-size / 2.2, -size / 2.2, 0)} size={new Vector3(size / 8, size / 8, size / 20 * 11)} camera={virtualCam} action={() => fitFace(new Vector3(-1, -1, 0))}/>
        <ViewcubeEdge position={new Vector3(size / 2.2, -size / 2.2, 0)} size={new Vector3(size / 8, size / 8, size / 20 * 11)} camera={virtualCam} action={() => fitFace(new Vector3(1, -1, 0))}/>
        <ViewcubeEdge position={new Vector3(-size / 2.2, size / 2.2, 0)} size={new Vector3(size / 8, size / 8, size / 20 * 11)} camera={virtualCam} action={() => fitFace(new Vector3(-1, 1, 0))}/>
        <ViewcubeEdge position={new Vector3(size / 2.2, size / 2.2, 0)} size={new Vector3(size / 8, size / 8, size / 20 * 11)} camera={virtualCam} action={() => fitFace(new Vector3(1, 1, 0))}/>
        
        <ViewcubeEdge position={new Vector3(-size / 2.2, 0, size / 2.2)} size={new Vector3(size / 8, size / 20 * 11, size / 8)} camera={virtualCam} action={() => fitFace(new Vector3(-1, 0, 1))}/>
        <ViewcubeEdge position={new Vector3(size / 2.2, 0, size / 2.2)} size={new Vector3(size / 8, size / 20 * 11, size / 8)} camera={virtualCam} action={() => fitFace(new Vector3(1, 0, 1))}/>
        <ViewcubeEdge position={new Vector3(-size / 2.2, 0, -size / 2.2)} size={new Vector3(size / 8, size / 20 * 11, size / 8)} camera={virtualCam} action={() => fitFace(new Vector3(-1, 0, -1))}/>
        <ViewcubeEdge position={new Vector3(size / 2.2, 0, -size / 2.2)} size={new Vector3(size / 8, size / 20 * 11, size / 8)} camera={virtualCam} action={() => fitFace(new Vector3(1, 0, -1))}/>
        
        <ViewcubeEdge position={new Vector3(0, -size / 2.2, size / 2.2)} size={new Vector3(size / 20 * 11, size / 8, size / 8)} camera={virtualCam} action={() => fitFace(new Vector3(0, -1, 1))}/>
        <ViewcubeEdge position={new Vector3(0, size / 2.2, size / 2.2)} size={new Vector3(size / 20 * 11, size / 8, size / 8)} camera={virtualCam} action={() => fitFace(new Vector3(0, 1, 1))}/>
        <ViewcubeEdge position={new Vector3(0, -size / 2.2, -size / 2.2)} size={new Vector3(size / 20 * 11, size / 8, size / 8)} camera={virtualCam} action={() => fitFace(new Vector3(0, -1, -1))}/>
        <ViewcubeEdge position={new Vector3(0, size / 2.2, -size / 2.2)} size={new Vector3(size / 20 * 11, size / 8, size / 8)} camera={virtualCam} action={() => fitFace(new Vector3(0, 1, -1))}/>
      </group>
    </>,
    virtualScene
  );
}
import { useGetCameraQuery } from "@/state/api/cameras";
import { parseNumber } from "@/utils";
import { Input } from "@ui/input";
import { PageHeader } from "@ui/pageHeader";
import { Section } from "@ui/section";
import { SectionDescription } from "@ui/sectionDescription";
import { SectionHeader } from "@ui/sectionHeader";
import { SectionLabel } from "@ui/sectionLabel";
import { Spinner } from "@ui/spinner";
import { Textarea } from "@ui/textarea";
import { useParams } from "react-router-dom";

export default function CameraDetails() {
  const { id } = useParams();
  const cameraId = parseNumber(id);
  const status = useGetCameraQuery({
    id: cameraId!,
  });
  if (status.isLoading) {
    return (
      <div className="flex w-80 items-center gap-2">
        <Spinner/>
        Posting comment...
      </div>
    );
  } else if (status.isSuccess && status.data !== undefined) {
    return (
      <div className="flex flex-col gap-2">
        <PageHeader>
          <SectionHeader>
          Camera details
          </SectionHeader>
          <SectionDescription>
            Cameras are created using a calibration wizard and store information about lense distortion. This information is used to improve the accuracy of part scans.
          </SectionDescription>
        </PageHeader>
        <Section>
          <SectionLabel htmlFor="model">
            Model
          </SectionLabel>
          <Input id="model" value={status.data.model} readOnly className="bg-background" />
          <SectionLabel htmlFor="setup">
            Setup
          </SectionLabel>
          <Textarea rows={5} id="setup" value={status.data.description} readOnly className="bg-background" />
        </Section>
        <Section>
          <SectionLabel htmlFor="model">
            Used for scanning these parts
          </SectionLabel>
          not implemented yet
        </Section>
      </div>
    );
  } else {
    <div className="flex w-80 items-center gap-2">
      Error
    </div>
  }
}
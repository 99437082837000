import { GearIcon, MixIcon, MixerHorizontalIcon } from "@radix-ui/react-icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";
import { useAppDispatch, useAppSelector } from "@/state/store";
import { Badge } from "@ui/badge";
import { Button } from "@ui/button";
import { select } from "@/state/model";

export default function InsertSidebarHeader() {
  const name = useAppSelector(s => s.model.insert.name);
  const version = useAppSelector(s => s.model.insert.version);
  const path = useAppSelector(s => s.model.insert.path);
  const selection = useAppSelector(s => s.model.selection);
  const dispatch = useAppDispatch();
  return (
    <>
      <div className="flex items-center gap-2">
        <span className="text-xl leading-none">
          {name}
        </span>
        <Tooltip>
          <TooltipTrigger>
            <Badge>v{version}</Badge>
          </TooltipTrigger>
          <TooltipContent>
            The current insert version.
          </TooltipContent>
        </Tooltip>
        <div className="flex-auto" />
        <Badge variant="accent">{path}</Badge>
      </div>
      <div className="flex gap-1 rounded-lg bg-accent p-1">
        <Button disabled={selection.aspect === "basics"} variant="selector" onClick={() => dispatch(select({
          scope: "insert",
          aspect: "basics",
        }))} className="grow px-1">
          <MixIcon />
          Basics
        </Button>
        <Button disabled={selection.aspect === "configuration"} variant="selector" onClick={() => dispatch(select({
          scope: "insert",
          aspect: "configuration",
        }))} className="grow px-1">
          <GearIcon />
          Configuration
        </Button>
        <Button disabled={selection.aspect === "globals"} variant="selector" onClick={() => dispatch(select({
          scope: "insert",
          aspect: "globals",
        }))} className="grow px-1">
          <MixerHorizontalIcon />
          Globals
        </Button>
      </div>
    </>
  );
}
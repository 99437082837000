import { Badge } from "@ui/badge";
import { Vector } from "@/types";
import { printable } from "@/utils";
import { Crosshair1Icon } from "@radix-ui/react-icons";

export default function CanvasPointInfo({ point, designator }: { point: Vector, designator: string | undefined }) {
  return (
    <div className="flex flex-col rounded-sm border bg-control" >
      <div className="flex items-center gap-2 rounded-t-sm bg-accent p-1">
        {designator !== undefined && (
          <Badge className="px-1.5 font-mono">{designator}</Badge>
        )}
        <Crosshair1Icon/>
        Point
        <div className="flex-auto"/>
      </div>
      <div className="flex gap-1 p-1">
        <Badge className="font-mono">{printable(point.x)}, {printable(point.y)}, {printable(point.z)}</Badge>
      </div>
    </div>
  );
}
import { Plane, ShapeProps } from "@react-three/drei";
import { useState } from "react";
import { PlaneGeometry } from "three";

export default function CanvasPanel({color, hoverColor, ...props} : ShapeProps<typeof PlaneGeometry> & {
  color: string,
  hoverColor: string,
}) {
  const [hover, setHover] = useState(false);
  return (
    <Plane {...props} onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)} material-color={hover ? hoverColor : color} renderOrder={1} material-depthTest={false} material-transparent={true}/>
  );
}
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  EditorThemeClasses,
  LexicalEditor,
  NodeKey,
  RangeSelection,
  EditorState,
} from "lexical";
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode
} from "@lexical/rich-text";
import { $isAtNodeEnd, $wrapNodes } from "@lexical/selection";
import React, { ForwardedRef, forwardRef, HTMLAttributes, MutableRefObject, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  ListItemNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode
} from "@lexical/list";
import { AutoLinkNode, LinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { useReporter } from "@/state/messages";
import { Button } from "./button";
import { Icon } from "./icon";
import { Separator } from "./separator";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./select";
import { Popover, PopoverContent } from "./popover";
import { Input } from "./input";
import { ChevronRightIcon, ClipboardIcon, CodeIcon, FontBoldIcon, FontItalicIcon, ImageIcon, Link1Icon, ResetIcon, StrikethroughIcon, TextAlignCenterIcon, TextAlignJustifyIcon, TextAlignLeftIcon, TextAlignRightIcon, UnderlineIcon, UploadIcon } from "@radix-ui/react-icons";
import ImagePlugin, { InsertImagePayload, INSERT_IMAGE_COMMAND } from "./editor/ImagePlugin";
import { ImageNode } from "./editor/ImageNode";
import { cn } from "./utils";
import { MD5 } from "object-hash";

const theme: EditorThemeClasses = {
  ltr: "text-left",
  rtl: "text-right",
  paragraph: "",
  quote: "my-1 pl-2 border-l-4 border",
  heading: {
    h1: "text-xl font-semibold underline underline-offset-2 decoration-primary mt-2 first:mt-0",
    h2: "text-xl",
  },
  list: {
    nested: {
      listitem: "",
    },
    ol: "list-decimal list-inside marker:text-border-strong pl-2",
    ul: "list-disc list-inside marker:text-border-strong pl-2",
    listitem: "",
  },
  image: "flex items-center justify-center my-2",
  link: "text-primary underline underline-offset-2 decorator-primary",
  text: {
    bold: "font-semibold",
    code: "font-mono rounded px-1 bg-accent text-sm",
    italic: "italic",
    strikethrough: "line-through",
    underline: "underline underline-offset-2",
  }
};

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don"t throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: Error, editor: LexicalEditor) {
  console.error(error);
}

const urlMatcher = /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
const emailMatcher = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

const linkMatchers = [
  (text: string) => {
    const match = urlMatcher.exec(text);
    return (
      match && {
        index: match.index,
        length: match[0].length,
        text: match[0],
        url: match[0]
      }
    );
  },
  (text: string) => {
    const match = emailMatcher.exec(text);
    return (
      match && {
        index: match.index,
        length: match[0].length,
        text: match[0],
        url: `mailto:${match[0]}`
      }
    );
  }
];

interface ViewerProps extends HTMLAttributes<HTMLDivElement> {
  initialState?: EditorState | string,
  imageResolver: (name: string) => string,
}

export function Viewer({ className, initialState, imageResolver } : ViewerProps) {
  const reporter = useReporter();
  const expandedTheme = {
    ...theme,
    imageResolver,
  };
  const initialConfig: InitialConfigType = {
    namespace: "MyViewer",
    theme: expandedTheme,
    editable: false,
    editorState: initialState,
    onError: error => reporter.error(error.message),
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      ImageNode,
    ]
  };
  return (
    <LexicalComposer initialConfig={initialConfig}>
      <RichTextPlugin
        contentEditable={<ContentEditable spellCheck={false} className={cn("grow outline-none focus:border-primary", className)}/>}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <LinkPlugin/>
      <ListPlugin />
      <ImagePlugin/>
    </LexicalComposer>
  );
}

interface EditorProps extends HTMLAttributes<HTMLDivElement> {
  initialState?: EditorState | string,
  imageResolver: (name: string) => string,
}

export interface EditorHandle {
  editor: LexicalEditor,
}

const Editor = forwardRef<EditorHandle, EditorProps>(
  ({ className, initialState, imageResolver, ...props }, ref) => {
  const reporter = useReporter();
  const expandedTheme = {
    ...theme,
    imageResolver,
  };
  const initialConfig: InitialConfigType = {
    namespace: "MyEditor",
    theme: expandedTheme,
    editorState: initialState,
    onError: error => reporter.error(error.message),
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      ImageNode,
    ]
  };
  const [ editor, setEditor] = useState<LexicalEditor>();
  useImperativeHandle(ref, () => ({
    editor: editor!,
  }), [ editor ]);
  return (
    <div className={cn(className, "flex flex-col")} {...props}>
      <LexicalComposer initialConfig={initialConfig}>
        <ToolbarPlugin />
        <RichTextPlugin
          contentEditable={<ContentEditable className="grow rounded-b border p-2 outline-none focus:border-primary" />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <AutoFocusPlugin />
        <LinkPlugin/>
        <ListPlugin />
        <ImagePlugin/>
        <OnChangePlugin onChange={(state, editor) => {
          setEditor(editor);
        }} />
        <AutoLinkPlugin matchers={linkMatchers} />
      </LexicalComposer>
    </div>
  );
});
Editor.displayName = "Editor";
export { Editor };

const LowPriority = 1;

function getSelectedNode(selection: RangeSelection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

function LinkEditor({editor}: {editor:LexicalEditor}) {
  const [linkUrl, setLinkUrl] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [linkNode, setLinkNode] = useState<LinkNode | null>(null);
  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
        setLinkNode(parent);
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
        setLinkNode(node);
      } else {
        setLinkUrl("");
        setLinkNode(null);
      }
    }
    return true;
  }, [editor]);
  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority,
      )
    );
  }, [editor, updateLinkEditor]);
  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);
  if(linkNode !== null) {
    return (
      <Popover defaultOpen={true}>
        <PopoverContent>
          <Input/>
        </PopoverContent>
      </Popover>
    );
  }
  return (
    <></>
  );
}

function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [selectedElementKey, setSelectedElementKey] = useState<NodeKey | null>(null);
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const inputFile = useRef<HTMLInputElement>(null);
  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // update block type and code language
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
      // update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsCode(selection.hasFormat("code"));
      // update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, []);
  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);
  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority,
      ),
    );
  }, [editor, updateToolbar]);
  const formatBlock = (format: string) => {
    if (format === "paragraph" && format !== blockType) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    } else if ((format === "h1" || format === "h2") && format !== blockType) {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode(format));
        }
      });
    } else if (format === "ul") {
      if (blockType !== "ul") {
        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined as void);
      } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined as void);
      }
    } else if (format === "ol") {
      if (blockType !== "ol") {
        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined as void);
      } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined as void);
      }
    } else if (format === "quote" && format !== blockType) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
  };
  return (
    <div className="flex flex-wrap items-center gap-1 rounded-t border-x border-t bg-background p-2 pr-0" ref={toolbarRef}>
      <div className="flex items-center gap-1 mr-2">
        <Button disabled={!canUndo} onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND, undefined);
        }} aria-label="Undo" size="sm">
          <ResetIcon />
        </Button>
        <Button disabled={!canRedo} onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)} aria-label="Redo" size="sm">
          <ResetIcon className="-scale-x-100" />
        </Button>
      </div>
      <Select value={blockType} onValueChange={formatBlock}>
        <SelectTrigger className="w-fit mr-2">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="paragraph">
            <div className="flex items-center gap-2">
              <Icon name="paragraph" />
              Paragraph
            </div>
          </SelectItem>
          <SelectItem value="h1" className="flex gap-2">
            <div className="flex items-center gap-2">
              <Icon name="type-h1" />
              Heading
            </div>
          </SelectItem>
          <SelectItem value="h2" className="flex gap-2">
            <div className="flex items-center gap-2">
              <Icon name="type-h2" />
              Subheading
            </div>
          </SelectItem>
          <SelectItem value="ul" className="flex gap-2">
            <div className="flex items-center gap-2">
              <Icon name="list-ul" />
              Unordered list
            </div>
          </SelectItem>
          <SelectItem value="ol" className="flex gap-2">
            <div className="flex items-center gap-2">
              <Icon name="list-ol" />
              Ordered list
            </div>
          </SelectItem>
          <SelectItem value="quote" className="flex gap-2">
            <div className="flex items-center gap-2">
              <Icon name="quote" />
              Quote
            </div>
          </SelectItem>
        </SelectContent>
      </Select>
      <div className="flex items-center gap-1 mr-2">
        <Button onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")}
          className={isBold ? "border-primary bg-control" : ""}
          aria-label="Format Bold" size="sm">
          <FontBoldIcon/>
        </Button>
        <Button onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")}
          className={isItalic ? "border-primary bg-control" : ""}
          aria-label="Format Italics" size="sm">
          <FontItalicIcon/>
        </Button>
        <Button onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")}
          className={isUnderline ? "border-primary bg-control" : ""}
          aria-label="Format Underline" size="sm">
          <UnderlineIcon/>
        </Button>
        <Button onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")}
          className={isStrikethrough ? "border-primary bg-control" : ""}
          aria-label="Format Strikethrough" size="sm">
          <StrikethroughIcon/>
        </Button>
        <Button onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code")}
          className={isCode ? "border-primary bg-control" : ""}
          aria-label="Insert Code" size="sm">
          <CodeIcon/>
        </Button>
        <Button onClick={insertLink}
          className={isLink ? "border-primary bg-control" : ""}
          aria-label="Insert Link" size="sm">
          <Link1Icon />
        </Button>
        {isLink && <LinkEditor editor={editor}/>}
      </div>
      <div className="flex items-center gap-1 mr-2">
        <Button onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left")}
          aria-label="Left Align" size="sm">
          <TextAlignLeftIcon/>
        </Button>
        <Button onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center")}
          aria-label="Center Align" size="sm">
          <TextAlignCenterIcon/>
        </Button>
        <Button
          onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right")}
          aria-label="Right Align" size="sm">
          <TextAlignRightIcon />
        </Button>
        <Button onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify")}
          aria-label="Justify Align" size="sm">
          <TextAlignJustifyIcon />
        </Button>
      </div>
      <div className="flex items-center gap-1 mr-2">
        <Button onClick={async () => {
          const clipboardContents = await navigator.clipboard.read();
          for (const item of clipboardContents) {
            if (item.types.includes("image/png")) {
              const blob = await item.getType("image/png");
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                const payload: InsertImagePayload = {
                  src: reader.result as string,
                  name: MD5(reader.result),
                };
                editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
              }
              break;
            }
          }
        }} className="gap-0" size="sm">
          <ClipboardIcon/>
          <ChevronRightIcon/>
          <ImageIcon />
        </Button>
        <input type="file" id="file" accept=".jpg,.jpeg,.png,.gif" ref={inputFile} style={{ display: "none" }} onChange={e => {
          const reader = new FileReader();
          reader.readAsDataURL(e.target.files![0]);
          reader.onloadend = () => {
            const payload: InsertImagePayload = {
              src: reader.result as string,
              name: MD5(reader.result),
            };
            editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
          }
        }}/>
        <Button onClick={async () => {
          inputFile.current!.click();
        }} className="gap-0" size="sm">
          <UploadIcon/>
          <ChevronRightIcon/>
          <ImageIcon />
        </Button>
      </div>
    </div>
  );
}

import { MD5 } from "object-hash";

const imageCache = new Map<string, string>();

export function getCachedImage(name: string) {
  if(name.startsWith("http")) {
    return name;
  }
  const result = imageCache.get(name);
  if(result === undefined) {
    return name;
  }
  return result;
}

export function cacheImage(source: string) {
  if(source.startsWith("http")) {
    return source;
  }
  const hash = MD5(source);
  const result = imageCache.get(hash);
  if(result !== undefined) {
    return hash;
  }
  imageCache.set(hash, source);
  return hash;
}

export function clearImageCache() {
  imageCache.clear();
}
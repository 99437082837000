/* eslint-disable react/no-unknown-property */
import { Grid, PerspectiveCamera, Text, useBounds } from "@react-three/drei";
import BoxInfo from "../canvas/BoxInfo";
import BoxFootprint from "../canvas/BoxFootprint";
import { selectCurrentTrayId, useAppDispatch, useAppSelector } from "@/state/store";
import Canvas from "../canvas/Canvas";
import InsertCanvasTray from "./InsertCanvasTray";
import { Box3, Euler, Group, Object3DEventMap, Vector3 } from "three";
import InsertCanvasManipulator from "./InsertCanvasManipulator";
import { select } from "@/state/model";
import { useRef, useState } from "react";
import { Label } from "@ui/label";
import DesignerMessagesBar from "../DesignerMessagesBar";
import { AngleIcon, CheckCircledIcon, CornersIcon, CrossCircledIcon, ExclamationTriangleIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import DesignerMarkup from "../DesignerMarkup";
import InsertJobs from "./InsertJobs";
import { ScrollArea } from "@ui/scroll-area";
import { Section } from "@ui/section";
import { Button } from "@ui/button";
import Ground from "../canvas/Ground";
import PhotoStage, { PhotoStageHandle } from "../canvas/PhotoStage";
import { Controls } from "../canvas/Controls";
import { Stage, StageHandle } from "../canvas/Stage";

function InsertCanvasBox() {
  const insertConfiguration = useAppSelector(s => s.model.insert.configuration);
  const boundaryIgnoresDepth = useAppSelector(s => s.settings.settingCanvasBoundaryIngoresDepth);
  const settingDarkMode = useAppSelector(s => s.settings.settingDarkMode);
  const color = settingDarkMode ? "#475569" : "#94a3b8";
  return (
    <>
      <BoxFootprint position={{ x: 0, y: 0, z: 0 }} size={insertConfiguration} />
      <BoxInfo position={{ x: 0, y: 0, z: 0 }} size={insertConfiguration} showCoordinates={false} color={color} depthTest={!boundaryIgnoresDepth} />
      <Text position={[1, -0.01, 1]} rotation={[Math.PI / 2, 0, 0]} scale={10} anchorX="left" anchorY="bottom">
        <meshBasicMaterial color={color} transparent={true} opacity={0.5} />
        Front
      </Text>
      <Text position={[insertConfiguration.x + 0.01, 1, 1]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={10} anchorX="left" anchorY="bottom">
        <meshBasicMaterial color={color} transparent={true} opacity={0.5} />
        Right
      </Text>
      <Text position={[insertConfiguration.x - 1, insertConfiguration.y + 0.01, 1]} rotation={[Math.PI / 2, Math.PI, 0]} scale={10} anchorX="left" anchorY="bottom">
        <meshBasicMaterial color={color} transparent={true} opacity={0.5} />
        Back
      </Text>
      <Text position={[-0.01, insertConfiguration.y - 1, 1]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={10} anchorX="left" anchorY="bottom">
        <meshBasicMaterial color={color} transparent={true} opacity={0.5} />
        Left
      </Text>
    </>
  );
}

function InsertCanvasTrayInstances() {
  const insertConfiguration = useAppSelector(s => s.model.insert.configuration);
  const trayIds = useAppSelector(s => s.model.insert.trayIds);
  const dispatch = useAppDispatch();
  const selectedTrayId = useAppSelector(selectCurrentTrayId);
  const otherTrayIds = trayIds.filter(t => t !== selectedTrayId);
  const [manipulatorPosition, setManupulatorPosition] = useState(new Vector3());
  const [manupulatorRotation, setManipulatorRotation] = useState(new Euler());
  const bounds = useBounds();
  return (
    <>
      {selectedTrayId !== null && (
        <InsertCanvasManipulator position={manipulatorPosition} setPosition={setManupulatorPosition} rotation={manupulatorRotation} setRotation={setManipulatorRotation} selectedTrayId={selectedTrayId} otherTrayIds={otherTrayIds} insertBounds={insertConfiguration}/>
      )}
      {trayIds.map(i => (
        <InsertCanvasTray key={i} trayId={i} positionOverride={i == selectedTrayId ? manipulatorPosition : undefined} selectTray={() => dispatch(select({
          scope: "insert",
          aspect: "tray",
          trayId: i,
        }))} />
      ))}
    </>
  );
}

function InsertCanvasMessages() {
  const messages = useAppSelector(s => s.model.insert.messages);
  let infoCount = 0;
  let warningCount = 0;
  let errorCount = 0;
  for (const message of messages) {
    if (message.type === "info") {
      infoCount++;
    } else if (message.type === "warning") {
      warningCount++;
    } else {
      errorCount++;
    }
  }
  return (
    <Section slim className="select-none">
      {messages.length == 0 ? (
        <div className="flex items-center gap-2">
          <CheckCircledIcon className="text-primary" />
          No issues detected.
        </div>
      ) : (
        <>
          <div className="-my-2 flex items-center justify-between gap-2">
            <Label className="py-2">Insert validation</Label>
            <DesignerMessagesBar errorCount={errorCount} warningCount={warningCount} infoCount={infoCount} />
          </div>
          <div className="-mx-2 -mb-2 flex max-h-[500px] flex-col gap-1 overflow-y-auto px-2 pb-2">
            {messages.map(m =>
              <div key={messages.indexOf(m)} className="flex items-center gap-2">
                {m.type === "warning" ? <ExclamationTriangleIcon className="text-yellow-500" /> : (m.type === "error" ? <CrossCircledIcon className="text-red-500" /> : <InfoCircledIcon />)}
                <DesignerMarkup markup={m.content} />
              </div>
            )}
          </div>
        </>
      )}
    </Section>
  );
}

export default function InsertCanvas() {
  const dispatch = useAppDispatch();
  const settingShowJobs = useAppSelector(s => s.settings.settingShowJobs);
  const settingCanvasOrthographic = useAppSelector(s => s.settings.settingCanvasOrthographic);
  const settingCanvasShowPorts = useAppSelector(s => s.settings.settingCanvasShowPorts);
  const settingCanvasShowGizmo = useAppSelector(s => s.settings.settingCanvasShowGizmo);
  const stageRef = useRef<StageHandle>(null);
  return (
    <>
      <Canvas isOrthographic={settingCanvasOrthographic} onPointerMissed={() => dispatch(select({
        aspect: "configuration",
        scope: "insert",
      }))}>
        <Ground/>
        <InsertCanvasBox />
        <Stage enableControls={true} panGround={true} fit={true} ref={stageRef} showGizmo={settingCanvasShowGizmo}>
          <InsertCanvasTrayInstances />
        </Stage>
      </Canvas>
      <div className="absolute right-1 top-1 z-20">
        <InsertCanvasMessages />
      </div>
      <div className="absolute bottom-1 right-1 z-50 flex select-none flex-col gap-1">
        <Button className="z-30 justify-start" onClick={() => {
          if (stageRef.current !== null) {
            stageRef.current.fit("sphere", true);
          }
        }} tooltip="Zoom and pan the camera to make the tray fill the entire canvas.">
          <CornersIcon />
          Zoom to fit
        </Button>
        <Button className="z-30 justify-start" onClick={() => {
          if (stageRef.current !== null) {
            stageRef.current.fit("box", true);
          }
        }}>
          <AngleIcon />
          Zoom align
        </Button>
      </div>
      {settingShowJobs && (
        <div className="absolute bottom-1 left-1 z-20 flex flex-col items-end gap-1">
          <InsertJobs />
        </div>
      )}
    </>
  );
}
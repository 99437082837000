import { Button } from "@ui/button";
import { addBlueprint, moveTray, select } from "../../../state/model";
import { selectTray, useAppDispatch, useAppSelector } from "@/state/store";
import InsertSidebarTrayListTray from "./InsertSidebarTrayListTray";
import { defaultTrayName, getDefaultBlueprint } from "../../../catalog";
import { DndContext } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import InsertDialogTrayLibrary from "./InsertDialogTrayLibrary";
import { Dialog, DialogContent, DialogTrigger } from "@ui/dialog";
import { useMemo } from "react";
import { TrayType } from "@/types";
import InsertSidebarTrayListConnectors from "./InsertSidebarTrayListConnectors";
import { ScrollArea } from "@ui/scroll-area";

interface TrayItem {
  name: string,
  index: number,
}

interface TrayItemActionMove {
  type: "move",
  oldIndex: number,
  newIndex: number,
}

interface TrayItemActionReset {
  type: "reset",
  items: TrayItem[],
}

type TrayItemAction = TrayItemActionReset | TrayItemActionMove;

export default function InsertSidebarTrayList() {
  const dispatch = useAppDispatch();
  const trayIds = useAppSelector(s => s.model.insert.trayIds);
  const items = useMemo(() => {
    const blueprints: {[blueprintId: number]: {
      id: number,
      group?: number,
      primary?: number,
      secondary: number[]
    }} = {};
    for(const trayId of trayIds) {
      const tray = selectTray(trayId);
      if(!(tray.blueprintId in blueprints)) {
        blueprints[tray.blueprintId] = {
          id: tray.blueprintId,
          secondary: [],
        };
      }
      if(tray.type === TrayType.Primary) {
        blueprints[tray.blueprintId].primary = tray.id;
      } else if(tray.type === TrayType.Secondary) {
        blueprints[tray.blueprintId].secondary.push(tray.id);
      }
    }
    let group = 0;
    for(const blueprint of Object.values(blueprints)) {
      if(blueprint.primary !== undefined && blueprint.secondary.length > 0) {
        blueprint.group = group++;
      }
    }
    const result: {
      trayId: number,
      blueprintId: number,
      type: "standalone" | "primary" | "secondary",
      group?: number,
    }[] = [];
    for(const trayId of trayIds) {
      for(const blueprint of Object.values(blueprints)) {
        if(blueprint.primary === trayId) {
          result.push({
            trayId: trayId,
            blueprintId: blueprint.id,
            type: "primary",
            group: blueprint.group,
          });
          break;
        } else if(blueprint.secondary.includes(trayId)) {
          result.push({
            trayId: trayId,
            blueprintId: blueprint.id,
            type: "secondary",
            group: blueprint.group,
          });
          break;
        }
      }
    }
    return result;
  }, [ trayIds ]);
  return (
    <>
      <div className="flex flex-row items-center gap-2">
        <div className="flex-auto font-medium text-slate-700">
          {items.length} tray{items.length == 1 ? "" : "s"}
        </div>
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="outline" size="sm">
              Add from library
            </Button>
          </DialogTrigger>
          <DialogContent>
            <InsertDialogTrayLibrary/>
          </DialogContent>
        </Dialog>
        <Button variant="outline" onClick={() => dispatch(addBlueprint({
          blueprint: getDefaultBlueprint({
            name: defaultTrayName + " " + trayIds.length,
          }),
          selectIn: "blueprint",
        }))} size="sm">Add new</Button>
      </div>
      <DndContext autoScroll={false} onDragEnd={e => {
        if(e.over === null) {
          return;
        }
        dispatch(moveTray({
          tray: Number(e.active.id),
          index: trayIds.indexOf(Number(e.over.id)),
        }));
      }} modifiers={[]}>
        <SortableContext items={trayIds} id="trays" strategy={verticalListSortingStrategy}>
          <div className="flex">
            <div className="flex flex-auto flex-col">
              {items.map(item => <InsertSidebarTrayListTray key={item.trayId} item={item}/>)}
            </div>
            <InsertSidebarTrayListConnectors items={items}/>
          </div>
        </SortableContext>
      </DndContext>
    </>
  );
}
import { getDefaultSettings } from '@/catalog';
import { Settings } from '@/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const SettingsVersion = 1;

function loadSettings() {
  let data: Settings | null = null;
  const storage = localStorage.getItem("settings");
  if(storage !== null) {
    try {
      data = JSON.parse(storage) as Settings;
    } catch {
      // do nothing
    }
  } 
  if(data === null) {
    data = getDefaultSettings();
  }
  if(data.version !== SettingsVersion) {
    // perform settings migration
  }
  if(data.settingDarkMode) {
    document.body.parentElement!.classList.add("dark");
  }
  return data;
}

export const settingsSlice = createSlice({
  name: "settings",
  initialState: loadSettings(),
  reducers: {
    updateSettings: (state, action: PayloadAction<Partial<Settings>>) => {
      for(const key in action.payload) {
        const value = action.payload[key];
        if(value !== undefined) {
          state[key] = value;
          if(key === "settingDarkMode") {
            if(value) {
              document.body.parentElement!.classList.add("dark");
            } else {
              document.body.parentElement!.classList.remove("dark");
            }
          }
        }
      }
      // save settings
      state.version = SettingsVersion;
      localStorage.setItem("settings", JSON.stringify(state));
    },
  }
});

export const { updateSettings } = settingsSlice.actions;

export default settingsSlice.reducer;

import { useLazySearchBoardgameQuery } from "@/state/api/boardgames";
import { InputSearch } from "@ui/inputSearch";
import { Popover, PopoverContent, PopoverTrigger } from "@ui/popover";
import TextHighlight from "@ui/textHighlight";
import { cn } from "@ui/utils";
import React, { useEffect, useState } from "react";

export interface InputGameSearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onSelectResult: (id: number, name: string, year: number) => void,
  clearOnSelect?: boolean,
}

const InputGameSearch = React.forwardRef<HTMLInputElement, InputGameSearchProps>(
  ({ className, onSelectResult, clearOnSelect, ...props }, ref) => {
  const [ searchBoardgame, searchBoardgameState ] = useLazySearchBoardgameQuery();
  const [ query, setQuery ] = useState("");
  const [ isOpen, setIsOpen ] = useState(false);
  useEffect(() => {
    const used = query.trim();
    if(used.length > 2) {
      searchBoardgame({
        query: used,
      });
    }
  }, [query]);
  useEffect(() => {
    if(!searchBoardgameState.isFetching && searchBoardgameState.isSuccess && searchBoardgameState.data.length > 0) {
      setIsOpen(true);
    } else if(!searchBoardgameState.isSuccess) {
      setIsOpen(false);
    }
  }, [ searchBoardgameState ]);
  return (
    <Popover open={isOpen} onOpenChange={o => {
      if(!o) {
        setIsOpen(false);
      }
    }}>
      <PopoverTrigger asChild>
        <div className={cn("h-8 w-full", className)}>
          <InputSearch ref={ref} key="search" isBusy={searchBoardgameState.isFetching} className="size-full"  value={query} onChange={e => setQuery(e.target.value)} onClear={() => {
            setQuery("");
            setIsOpen(false);
          }} {...props}/>
        </div>
      </PopoverTrigger>
      <PopoverContent side="bottom" align="start" className="bg-control p-1" onOpenAutoFocus={e => e.preventDefault()} onCloseAutoFocus={e => e.preventDefault()}>
        <div className="flex flex-col">
          {searchBoardgameState.data?.map(d => (
            <TextHighlight key={d.id} className="cursor-pointer rounded-sm px-1 hover:bg-accent" text={`${d.name} (${d.year})`} highlight={query} highlightClassName="font-semibold" onClick={() => {
              if(clearOnSelect) {
                setQuery("");
              }
              onSelectResult(d.id, d.name, d.year);
              setIsOpen(false);
            }}/>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
});

InputGameSearch.displayName = "InputGameSearch";

export { InputGameSearch };
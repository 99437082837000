import React from "react";
import { cn } from "@ui/utils";
import { Button, ButtonProps } from "./button";
import { Cross2Icon } from "@radix-ui/react-icons";

const ButtonClose = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, ...props }, ref) => {
    return (
    <Button className={cn("absolute -top-px rounded-t-none border-t-border right-1 h-6 w-8 p-0", className)} size="sm" ref={ref} {...props}>
      <Cross2Icon className="size-4"/>
      <span className="sr-only">Close</span>
    </Button>
    );
  }
);
ButtonClose.displayName = "ButtonClose";

export { ButtonClose };


import ConfigurationEditor from "../../ConfigurationEditor";
import { useAppDispatch, useAppSelector } from "@/state/store";
import { modifyElementConfiguration } from "@/state/model";
import { ContainerElementBlueprint } from "@/types";
import ElementPlacementDrawer from "./ElementPlacementDrawer";

export function ContainerConfigurationProperties({ query, trayId, blueprintId, elementBlueprint }: { query: string, trayId: number, blueprintId: number, elementBlueprint: ContainerElementBlueprint}) {
  const dispatch = useAppDispatch();
  const element = useAppSelector(s => s.model.trays[trayId].elements[elementBlueprint.id]);
  return (
    <ConfigurationEditor query={query} configuration={elementBlueprint.configuration} modifyConfiguration={c => dispatch(modifyElementConfiguration({
      element: elementBlueprint.id,
      configuration: c
    }))} drawers={[{
      keys: [ "placement" ],
      control: <ElementPlacementDrawer elementId={elementBlueprint.id} layout={elementBlueprint.layout} measure={element.measure} placement={element.placement}/>,
      label: "row",
    }, ]} />
  );
}
import { Button } from "@ui/button";
import { useAppDispatch, useAppSelector } from "@/state/store";
import { Avatar, AvatarFallback } from "@ui/avatar";
import { ChevronDownIcon, EnterIcon, PersonIcon } from "@radix-ui/react-icons";
import { Dialog, DialogContent, DialogTrigger } from "@ui/dialog";
import {NavigationDialogLogin } from "./NavigationDialogLogin";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@ui/dropdown-menu";
import { HTMLAttributes, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLazyLoginQuery } from "@/state/api/site";
import { endSession, startSession } from "@/state/session";
import { Spinner } from "@ui/spinner";
import { Switch } from "@ui/switch";
import { updateSettings } from "@/state/settings";
import { Label } from "@ui/label";
import { configurationKeyName } from "@/catalog";

interface NavigationMenuProfileProps extends HTMLAttributes<HTMLDivElement> {
  dialogInitiallyOpen: boolean,
  redirectUrl?: string,
}

export default function NavigationMenuLogin({ dialogInitiallyOpen, redirectUrl, children, ...props} : NavigationMenuProfileProps) {
  const navigate = useNavigate();
  const [triggerLogin, loginStatus] = useLazyLoginQuery();
  const dispatch = useAppDispatch();
  const session = useAppSelector(s => s.session);
  const [visible, setVisible] = useState(dialogInitiallyOpen);
  useEffect(() => {
    if(session.token !== undefined && session.type === "new") {
      triggerLogin({}).then(r => {
        if(r.isSuccess) {
          dispatch(startSession({
            type: "cookie",
            user: r.data,
            token: r.data.token,
          }));
        }
      });
    }
  }, [ session.type ]);
  if(loginStatus.isFetching) {
    return (
      <div className="flex h-8 items-center gap-2 rounded-sm border px-2">
        <Spinner/>
        Logging you in...
      </div>
    );
  } 
  return (
    <>
      <Dialog open={visible} onOpenChange={setVisible}>
        <DialogTrigger asChild>
          <Button variant="outline" className="px-2 underline">
            <EnterIcon />
            Log in
          </Button>
        </DialogTrigger>
        <DialogContent className="w-96">
          <NavigationDialogLogin tokenReceived={t => {
            setVisible(false);
            triggerLogin({
              token: t,
            }).then(r => {
              if(r.isSuccess) {
                dispatch(startSession({
                  type: "login",
                  user: r.data,
                  token: r.data.token,
                }));
                if(redirectUrl !== undefined) {
                  navigate(decodeURI(redirectUrl));
                }
              }
            });
          }}/>
        </DialogContent>
      </Dialog>
      <Button variant="outline" className="px-2">
        <PersonIcon />
        Sign up
      </Button>
    </>
  );
}
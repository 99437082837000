import React from "react"
import { cn } from "./utils"

export interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
  header?: React.ReactNode,
  headerClassName?: string,
  footer?: React.ReactNode,
  footerClassName?: string,
  nested?: boolean,
  slim?: boolean,
}

const Section = React.forwardRef<HTMLDivElement, SectionProps>(
  ({ children, nested = false, header, headerClassName, footer, footerClassName, slim = false, className, ...props }, ref) => {
    let finalClassName = "flex flex-col gap-2";
    if(!nested) {
      if(footer === undefined && header === undefined) {
        finalClassName += " rounded border bg-control " + (slim ? "p-2": "p-4");
      } else if(footer === undefined && header !== undefined) {
        finalClassName += " rounded-b border border-t-0 bg-control " + (slim ? "p-2": "px-4 pt-2 pb-4");
      } else if(footer !== undefined && header === undefined) {
        finalClassName += " rounded-t border border-b-0 bg-control " + (slim ? "p-2" : "px-4 pt-4 pb-2");
      } else {
        finalClassName += " border-x bg-control " + (slim ? "p-2": "px-4 py-2");
      }
    }
    return (
      <>
        {header !== undefined && (
          <div className={cn("mb-[-8px] flex gap-2 rounded-t border bg-background", slim ? "p-2" :"px-4 py-2", headerClassName)}>
            {header}
          </div>
        )}
        <div className={cn(finalClassName, className)} {...props}>
          {children}
        </div>
        {footer !== undefined && (
          <div className={cn("mt-[-8px] flex gap-2 rounded-b border bg-background", slim ? "p-2" :"px-4 py-2", footerClassName)}>
            {footer}
          </div>
        )}
      </>
    )
  }
)
Section.displayName = "Section";

export { Section };





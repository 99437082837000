import { api } from '../store'

export interface AddBoxResponse {
  id: number,
}

export interface DbBoxRaw {
  id: number,
  gameId: number,
  name: string,
  data: string,
  images: string,
  timestamp: string,
  rating: number,
}

export interface DbBox extends Omit<DbBoxRaw, "images"> {
  images: string[],
}

export interface DbBoxDetailsRaw {
  gameId: number,
  ownerId: number,
  name: string,
  data: string,
  images: string,
  timestamp: string,
  rating: number,
  inserts: string[],
}

export interface DbBoxDetails extends Omit<DbBoxDetailsRaw, "images"> {
  images: string[],
}

const boxesApi = api.injectEndpoints({
  endpoints: builder => ({
    addBox: builder.mutation<AddBoxResponse, {
      gameId: number,
      name: string,
      data: string,
      image?: string,
    }>({
      query: (arg) => ({
        url: "/boxes/",
        method: "POST",
        body: arg,
      }),
      invalidatesTags: [ "boxes" ],
    }),
    listBoxes: builder.query<DbBox[], {
      query?: string,
      ownerId?: number,
      gameId?: number,
    }>({
      query: (arg) => ({
        url: `/boxes`,
        params: arg,
        
      }),
      transformResponse: (response: DbBoxRaw[]) => response.map(r => ({
        ...r,
        images: r.images.split(",")
      })),
      providesTags: [ "boxes" ],
    }),
    getBox: builder.query<DbBoxDetails, {
      id: string,
    }>({
      query: (arg) => ({
        url: `/boxes/${arg.id}`,
      }),
      transformResponse: (response: DbBoxDetailsRaw) => ({
        ...response,
        images: response.images.split(",")
      }),
      providesTags: [ "boxes" ],
    }),
    rateBox: builder.mutation<AddBoxResponse, {
      id: number,
      rating: "up" | "down",
    }>({
      query: (arg) => ({
        url: `/boxes/${arg.id}/rate`,
        method: "POST",
        params: {
          rating: arg.rating,
        },
      }),
      invalidatesTags: [ "boxes" ],
    }),
  }),
  overrideExisting: false,
})

export const { useAddBoxMutation, useLazyListBoxesQuery, useListBoxesQuery, useGetBoxQuery, useRateBoxMutation } = boxesApi;
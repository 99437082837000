import { Label } from "@ui/label";
import { Button } from "@ui/button"
import { selectTray, selectTraysForBlueprint, selectTrayName, useAppDispatch, useAppSelector, } from "@/state/store";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@ui/dialog";
import { Input } from "@ui/input";
import { Badge } from "@ui/badge";
import { useEffect, useRef, useState } from "react";
import { select } from "@/state/model";
import { ArrowLeftIcon, TokensIcon } from "@radix-ui/react-icons";
import BlueprintDialogShare from "./BlueprintDialogShare";
import { TrayModel, TrayType } from "@/types";
import { SectionLabel } from "@ui/sectionLabel";
import { generateTrayStl } from "@/replicadWorkerPool";
import { Placeholder } from "@ui/placeholder";
import { export3mf } from "@/insertExporter";

function DownloadTray({ trayId } : { trayId: number }) {
  const trayName = useAppSelector(s => selectTrayName(trayId, s));
  const [isBusy, setIsBusy] = useState(false);
  const [stl, setStl] = useState<Blob | null>(null);
  useEffect(() => {
    const work = async () => {
    setIsBusy(true);
      const result = await generateTrayStl(trayId);
      if(result != null) {
        setStl(result);
      }
      setIsBusy(false);
    };
    work();
  }, [ trayId ]);
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="sm">Download</Button>
      </DialogTrigger>
      <DialogContent>
        <SectionLabel>
          Download tray model
        </SectionLabel>
        {isBusy ? (
          <Placeholder>
            Generating model...
          </Placeholder>
        ) : (
          <>
            <Button onClick={() => {
              if(stl !== null) {
                const element = document.createElement("a");
                element.href = URL.createObjectURL(stl);
                element.download = trayName + ".stl";
                document.body.appendChild(element);
                element.click();
              }
            }}>
              Download stl
            </Button>
            <Button onClick={async () => {
              if(stl !== null) {
                const element = document.createElement("a");
                element.href = URL.createObjectURL(stl);
                element.download = trayName + ".stl";
                document.body.appendChild(element);
                element.click();
              }
            }}>
              Download 3mf
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default function BlueprintSidebarActions({ blueprintId, models, trayId }: { blueprintId: number, trayId: number, models: TrayModel[] }) {
  const dispatch = useAppDispatch();
  const version = useAppSelector(s => s.model.blueprints[blueprintId].version);
  const library = useAppSelector(s => s.model.blueprints[blueprintId].library);
  const input = useRef<HTMLInputElement>(null);
  const isPreview = selectTray(trayId).type === TrayType.Preview;
  return (
    <div className="mb-1 flex flex-row items-center gap-2">
      <Dialog>
        <DialogTrigger asChild>
          <Button size="sm">Save</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Save current tray</DialogTitle>
            <DialogDescription>
              Save the current tray as <Badge className="px-1">version {version}</Badge> and clear all tracked changes. Further changes are compared with the newly created version.
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-col gap-1">
            <Label>Description</Label>
            <Input placeholder="optional description of changes" ref={input} />
            <Label className="mb-0 mt-2">Change summary</Label>
            {/**<TrayDiff showActions={false} />**/}
          </div>
          <DialogFooter>
            <Button>
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <DownloadTray trayId={trayId}/>
      <Dialog>
        <DialogTrigger asChild>
          <Button disabled={!isPreview} size="sm">
            <TokensIcon />
            {!isPreview ? "Switch to Preview" : (library === undefined ? "Add to library" : "Update in library")}
          </Button>
        </DialogTrigger>
        <DialogContent className="w-min max-w-[1000px] overflow-y-auto">
          <BlueprintDialogShare blueprintId={blueprintId} models={models}/>
        </DialogContent>
      </Dialog>
      <div className="flex-auto" />
      {(library === undefined || library === null || !library.isIsolated) && (
        <Button onClick={() => {
          if(isPreview) {
            // do not select preview trays in insert designer as they are not listed there
            const primaryTray = selectTraysForBlueprint(blueprintId).find(t => t.type === TrayType.Primary);
            if(primaryTray !== undefined) {
              dispatch(select({
                scope: "insert",
                aspect: "tray",
                trayId: primaryTray.id,
              }));
            } else {
              dispatch(select({
                scope: "insert",
                aspect: "basics",
              }));
            }
          } else {
            dispatch(select({
              scope: "insert",
              aspect: "tray",
              trayId: trayId,
            }));
          }
        }} size="sm">
          <ArrowLeftIcon/>
          back to insert
        </Button>
      )}
    </div>
  );
}
import { Text } from "@react-three/drei";
import CanvasPanel from "../canvas/CanvasPanel";
import { Vector } from "@/types";
import roboto from '../../../../public/fonts/RobotoMono-Regular.ttf?url'
import { printable } from "@/utils";
import { isHotkeyPressed } from "react-hotkeys-hook";

export default function InsertCanvasHorizontalConnectorPanel({ position, distance, negative, flip, action } : {
  position: Vector,
  distance: number,
  negative: boolean,
  flip: boolean,
  action: (type: "fill" | "snap", axis: "X" | "Y" | "Z", distance: number, negative: boolean) => void,
 }) {
  const label = printable(distance);
  const width = 2 + Math.max(5, label.length) * 1.799;
  const sign = negative ? -1 : 1;
  return (
    <>
      <CanvasPanel args={[width, 4]} position={[position.x - sign * width / 2 + (distance > width + 2 ? width * sign : 0), position.y + (flip ? 2 : -2), position.z + 0.05]} color="#F87171" hoverColor="#94a3b8" onClick={e => {
        e.stopPropagation();
        if(e.delta < 0.01) {
          action(isHotkeyPressed("Shift") ? "fill" : "snap", "X", distance, negative);
        }
      }}/>
      <Text position={[position.x - sign + (distance > width + 2 ? width * sign : 0), position.y + (flip ? 2 : -2), position.z + 0.1]} color="black" anchorX={negative ? "left" : "right"} anchorY="middle" fontSize={3} font={roboto} renderOrder={2}>
        {label}
      </Text>
    </>
  );
}
import { AlignBottomIcon, AlignCenterHorizontallyIcon, AlignCenterVerticallyIcon, AlignLeftIcon, AlignRightIcon, AlignTopIcon, InputIcon, LockClosedIcon, SizeIcon, StretchHorizontallyIcon, StretchVerticallyIcon } from '@radix-ui/react-icons';
import { printable } from '../../../../utils';
import { Button } from '../../../../components/ui/button';
import { useAppDispatch } from '../../../../state/store';
import { elementPadding } from '@/designer/measurement';
import { ElementLayout, ElementMeasure, ElementPlacement } from '@/types';
import { modifyElementLayout } from '@/state/model';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { cn } from "@ui/utils"

export function Panel({ children, className, ...props }: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div className={cn("z-30 flex h-8 select-none items-center rounded-sm border border-border-strong bg-control px-2 py-0", className)} {...props}>
      {children}
    </div>
  );
}

export default function ElementPlacementControl({ elementId, layout, measure, placement }: { elementId: number, layout: ElementLayout, measure: ElementMeasure, placement: ElementPlacement }) {
  const dispatch = useAppDispatch();
  const padding = elementPadding(placement, layout);
  const gx = placement.x - measure.xMin;
  const gy = placement.y - measure.yMin;
  const gz = placement.z - measure.zMin;
  const modifyLayout = (layout: Partial<ElementLayout>) => {
    dispatch(modifyElementLayout({
      element: elementId,
      layout: layout,
    }));
  };
  return (
    <div className="flex flex-col gap-1">
      <div className="relative flex justify-center">
        <div className="absolute inset-x-[40px] top-[16px] z-20 border-t border-slate-200" />
        <Panel className="absolute left-[33px]">
          padding
        </Panel>
        <Panel>
          {printable(padding.yEnd)}
        </Panel>
        <div className="absolute end-[33px] z-30 flex justify-end gap-1">
          {padding.x !== 0 && (
            <>
              <Button variant="outline" disabled={layout.xAlign === 0} className="size-8 rounded-sm border-[#F87171] p-0 disabled:bg-[#F87171] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
                xAlign: 0,
              })}>
                <AlignLeftIcon />
              </Button>
              <Button variant="outline" disabled={layout.xAlign === 0.5} className="size-8 rounded-sm border-[#F87171] p-0 disabled:bg-[#F87171] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
                xAlign: 0.5,
              })}>
                <AlignCenterHorizontallyIcon />
              </Button>
              <Button variant="outline" disabled={layout.xAlign === 1} className="size-8 rounded-sm border-[#F87171] p-0 disabled:bg-[#F87171] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
                xAlign: 1,
              })}>
                <AlignRightIcon />
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="relative z-30 flex justify-center">
        <div className="absolute inset-x-[120px] bottom-[-30px] top-[16px] z-20 border-t border-slate-400 bg-background" />
        <Panel className="absolute left-[72px]">
          size
        </Panel>
        <div className="relative">
          <div className="absolute bottom-[-70px] left-1/2 top-full z-20 border-l border-dashed border-[#22c55e]" />
          <Panel className="relative border-[#22c55e] p-0">
            <div className="flex items-center gap-1 px-2">
              {layout.yGrow == 0 && (
                <LockClosedIcon className="text-[#22c55e]" />
              )}
              {printable(placement.y) + (measure.yLimit !== undefined && layout.yGrow !== 0 ? "/" + printable(measure.yLimit) : "")}
            </div>
            {gy > 0 && (
              <div className="flex h-full items-center bg-[#22c55e] px-2 text-secondary">
                <SizeIcon />
                {printable(gy)}
              </div>
            )}
          </Panel>
        </div>
      </div>
      <div className="relative flex gap-1">
        <div className="absolute inset-x-[55px] top-1/2 z-30 border-t border-dashed border-[#F87171]" />
        <div className="relative">
          <div className="absolute bottom-full left-1/2 top-[-56px] z-20 w-[40px] border-l border-t border-slate-200" />
          <Panel>
            {printable(padding.xStart)}
          </Panel>
        </div>
        <div className="relative">
          <div className="absolute inset-y-[-20px] left-1/2 z-20 w-[100px] border-y border-l border-slate-400 bg-background" />
          <Panel className="relative border-[#F87171] p-0">
            <div className="flex items-center gap-1 px-2">
              {layout.xGrow == 0 && (
                <LockClosedIcon className="text-[#F87171]" />
              )}
              {printable(placement.x) + (measure.xLimit !== undefined && layout.xGrow !== 0 ? "/" + printable(measure.xLimit) : "")}
            </div>
            {gx > 0 && (
              <div className="flex h-full items-center bg-[#F87171] px-2 text-secondary">
                <SizeIcon />
                {printable(gx)}
              </div>
            )}
          </Panel>
        </div>
        <div className="flex-auto" />
        <div className="relative">
          <div className="absolute inset-y-[-20px] right-[32px] z-20 w-[60px] border-y border-r border-slate-400 bg-background" />
          <div className="relative z-30 flex">
            <div className="flex size-8 items-center justify-center rounded-l-sm border border-r-0 border-[#F87171] bg-control p-0">
              <StretchHorizontallyIcon />
            </div>
            <Button variant="outline" disabled={layout.xGrow === 0} className="h-8 w-6 rounded-none border-x-0 border-[#F87171] p-0 disabled:bg-[#F87171] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
              xGrow: 0,
            })}>
              0
            </Button>
            <Button variant="outline" disabled={layout.xGrow === 1} className="h-8 w-6 rounded-none border-x-0 border-[#F87171] p-0 disabled:bg-[#F87171] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
              xGrow: 1,
            })}>
              1
            </Button>
            <Button variant="outline" disabled={layout.xGrow === 2} className="h-8 w-6 rounded-none border-x-0 border-[#F87171] p-0 disabled:bg-[#F87171] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
              xGrow: 2,
            })}>
              2
            </Button>
            <Button variant="outline" disabled={layout.xGrow === 3} className="h-8 w-6 rounded-none border-x-0 border-[#F87171] p-0 disabled:bg-[#F87171] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
              xGrow: 3,
            })}>
              3
            </Button>
            <Button variant="outline" size="icon" className="size-8 rounded-l-none rounded-r-sm border-l-0 border-[#F87171] px-1 py-0">
              <InputIcon />
            </Button>
          </div>
        </div>
        <div className="relative">
          <div className="absolute inset-y-[-56px] right-1/2 z-20 w-[40px] border-y border-r border-slate-200" />
          <Panel className="relative">
            {printable(padding.xEnd)}
          </Panel>
        </div>
      </div>
      <div className="relative flex justify-center">
        <div className="absolute inset-x-[120px] bottom-[16px] top-[-30px] z-20 border-b border-slate-400 bg-background" />
        <div className="z-30 flex size-8 items-center justify-center rounded-l-sm border border-r-0 border-[#22c55e] bg-control p-0">
          <StretchVerticallyIcon />
        </div>
        <Button variant="outline" disabled={layout.yGrow === 0} className="z-30 h-8 w-6 rounded-none border-x-0 border-[#22c55e] p-0 disabled:bg-[#22c55e] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
          yGrow: 0,
        })}>
          0
        </Button>
        <Button variant="outline" disabled={layout.yGrow === 1} className="z-30 h-8 w-6 rounded-none border-x-0 border-[#22c55e] p-0 disabled:bg-[#22c55e] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
          yGrow: 1,
        })}>
          1
        </Button>
        <Button variant="outline" disabled={layout.yGrow === 2} className="z-30 h-8 w-6 rounded-none border-x-0 border-[#22c55e] p-0 disabled:bg-[#22c55e] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
          yGrow: 2,
        })}>
          2
        </Button>
        <Button variant="outline" disabled={layout.yGrow === 3} className="z-30 h-8 w-6 rounded-none border-x-0 border-[#22c55e] p-0 disabled:bg-[#22c55e] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
          yGrow: 3,
        })}>
          3
        </Button>
        <Button variant="outline" size="icon" className="z-30 size-8 rounded-l-none rounded-r-sm border-l-0 border-[#22c55e] px-1 py-0">
          <InputIcon />
        </Button>
      </div>
      <div className="relative z-30 flex justify-center">
        <div className="absolute bottom-[16px] left-1/2 right-[40px] z-20 border-t border-slate-200" />
        <div className="absolute left-0 z-30 flex">
          <Panel className="left-0 border-[#60A5FA] p-0">
            <div className="p-2">
              {printable(placement.z)}
            </div>
            {gz > 0 && (
              <div className="flex h-full items-center bg-[#60A5FA] px-2 text-secondary">
                <SizeIcon />
                {printable(gz)}
              </div>
            )}
          </Panel>
          <div className="mt-[16px] w-3 border-t border-dashed border-[#60A5FA]"/>
          <Button variant="outline" disabled={layout.zGrow === 0} className="z-30 h-8 w-6 rounded-r-none border-r-0 border-[#60A5FA] p-0 disabled:bg-[#60A5FA] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
            zGrow: 0,
          })}>
            0
          </Button>
          <Button variant="outline" disabled={layout.zGrow === 1} className="z-30 h-8 w-6 rounded-l-none border-l-0 border-[#60A5FA] p-0 disabled:bg-[#60A5FA] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
            zGrow: 1,
          })}>
            1
          </Button>
        </div>
        <Panel>
          {printable(padding.yStart)}
        </Panel>
        <div className="absolute end-[33px] z-30 flex justify-end gap-1">
          {padding.y !== 0 && (
            <>
              <Button variant="outline" disabled={layout.yAlign === 1} className="size-8 rounded-sm border-[#22c55e] p-0 disabled:bg-[#22c55e] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
                yAlign: 1,
              })}>
                <AlignTopIcon />
              </Button>
              <Button variant="outline" disabled={layout.yAlign === 0.5} className="size-8 rounded-sm border-[#22c55e] p-0 disabled:bg-[#22c55e] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
                yAlign: 0.5,
              })}>
                <AlignCenterVerticallyIcon />
              </Button>
              <Button variant="outline" disabled={layout.yAlign === 0} className="size-8 rounded-sm border-[#22c55e] p-0 disabled:bg-[#22c55e] disabled:text-secondary disabled:opacity-100" onClick={() => modifyLayout({
                yAlign: 0,
              })}>
                <AlignBottomIcon />
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
/*

<div className="absolute ml-2 mt-1 select-none">
padding
</div>
<div className="text-center">
{printable(paddingTop)}
</div>
<div className="flex flex-row">
<div className="px-2 min-w-9 flex justify-center items-center">
  {printable(paddingLeft)}
</div>
<div className="relative flex-auto text-center border border-slate-500 bg-control h-10 mt-[12px]">
  <div className="absolute inset-x-0 flex justify-center">
    <div className="select-none border h-[24px] bg-control rounded-sm p-0 border-slate-500" style={{
      marginTop: -12
    }}>
      <div className="px-1">
        {printable(placement.x)}
      </div>
    </div>
  </div>
  <div className="absolute inset-y-0 flex items-center">
    <div className="select-none border h-[24px] bg-control rounded-sm p-0 border-slate-500 flex" style={{
      marginLeft: -12
    }}>
      <div className="px-1">
        {printable(placement.y)}
      </div>
      {gy > 0 && (
        <div className="text-secondary bg-secondary-foreground px-1 flex items-center gap-0.5">
          <SizeIcon/>
          {printable(gy)}
        </div>
      )}
    </div>
  </div>
  <div className="font-mono select-none">
    size
  </div>
</div>
<div className="px-2 min-w-9 flex justify-center items-center">
  {printable(paddingRight)}
</div>
</div>
<div className="text-center">
{printable(paddingBottom)}
</div>
</div>

*/
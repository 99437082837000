import { EyeOpenIcon, SymbolIcon } from "@radix-ui/react-icons";
import { DbTray, useLazyListTrayVersionsQuery } from "@/state/api/trays";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { getTimestampDescription, imageUrlFromId } from "@/utils";
import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from "@ui/popover";
import { useState } from "react";
import { ScrollArea } from "@ui/scroll-area";
import { SadCryIcon } from "react-line-awesome";

export default function WorkbenchTray( { tray } : { tray: DbTray }) {
  const navigate = useNavigate();
  const images = tray.images.split(",");
  const coverName = images.length > 0 ? imageUrlFromId("trays", tray.id, images[0]) : undefined;
  const [trigger, status] = useLazyListTrayVersionsQuery();
  const [selectedVersion, setSelectedVersion] = useState(tray.latestVersion);
  const [selectedImage, setSelectedImage] = useState(0);
  return (
    <Popover onOpenChange={e => {
      if(e && status.isUninitialized) {
        trigger({
          id: tray.id,
        });
      }
    }}>
      <PopoverTrigger>
        <div className="relative size-52 rounded-sm border bg-control">
          <div className="pointer-events-none absolute inset-x-0 top-0 bg-border-strong/30 px-2 py-1 text-left">
            {tray.name} v{tray.latestVersion}
          </div>
          <div className="pointer-events-none absolute inset-x-0 bottom-0 flex items-center p-1">
            <Badge className="gap-1 border-slate-300 text-black">
              <EyeOpenIcon/>
              {tray.views}
            </Badge>
            <div className="flex-auto"/>
          </div>
          <img className="size-52 rounded-sm object-contain p-1" src={coverName}/>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto pb-0">
        <PopoverArrow/>
        {status.isError ? (
          <div className="flex flex-col items-center gap-2 px-4 pb-4 pt-2">
            Loading tray...
            <SymbolIcon className="animate-spin"/>
          </div>
        ) : (status.isSuccess ? (
          <div className="flex flex-col gap-2">
            <div className="flex">
              <div className="flex flex-col gap-1">
                <div className="flex justify-between font-medium">
                  {tray.name} <Badge>{tray.views} views</Badge>
                </div>
                <div className="text-sm italic">
                  Published {new Date(status.data[selectedVersion].timestamp).toLocaleDateString()} at {new Date(status.data[selectedVersion].timestamp).toLocaleTimeString()}
                </div>
                {false && (
                  <div className="mx-[-8px] mb-1 bg-accent px-2 py-1 text-sm">
                    description
                  </div>
                )}
                <div style={{
                  backgroundImage: `url(${imageUrlFromId("trays", tray.id, coverName!)})`,
                 }} className="size-[300px] bg-contain bg-center bg-no-repeat" />
                <div className="flex gap-1">
                  {images.map(i => 
                    <img key={images.indexOf(i)} src={imageUrlFromId("trays", tray.id, i)} className="h-20 cursor-pointer rounded border bg-control p-1" onClick={() => {
                      setSelectedImage(images.indexOf(i));
                    }} />
                  )}
                </div>
              </div>
              <div className="mx-2 my-[-8px] w-px bg-border"/>
              <div className="flex flex-col gap-1">
                <div className="text-sm font-medium">
                  History
                </div>
                <ScrollArea type="always">
                  <div className="flex flex-col gap-1">
                    {status.data.reverse().map(v => 
                      <Button disabled={selectedVersion == v.version} variant="outline" key={v.version} size="sm" className="h-auto flex-col items-start gap-0 px-2 py-1 disabled:border-primary disabled:opacity-100">
                        Version {v.version}
                        <div className="text-sm font-normal">
                          {getTimestampDescription(v.timestamp)}
                        </div>
                      </Button>
                    )}
                    </div>
                </ScrollArea>
              </div>
            </div>
            <div className="mx-[-8px] flex justify-between gap-2 rounded-b border-t bg-accent p-2">
              <Button variant="destructive">
                Delete tray
              </Button>
              <Button onClick={() => {
                navigate(`/designer?tray=${tray.id}&version=${selectedVersion}`);
              }}>
                Load tray
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-2 p-2">
            Failed to load tray.
            <SadCryIcon size="4x" className="text-primary"/>
          </div>
        ))}
      </PopoverContent>
    </Popover>
  );
}
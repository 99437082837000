import Canvas, { CanvasHandle } from "./Canvas";
import { PhotoStageHandle } from "./PhotoStage";
import { ReplicadShape } from "./ReplicadShape";
import { Switch } from "@ui/switch";
import { Label } from "@ui/label";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { TrayModel } from "@/types";
import { cn } from "@ui/utils";
import { Button } from "@ui/button";
import { CameraIcon, CornersIcon } from "@radix-ui/react-icons";
import { Stage, StageHandle } from "./Stage";
import { Vector3 } from "three";
import { Bounds, Center } from "@react-three/drei";

interface CanvasCaptureProps extends React.AllHTMLAttributes<HTMLDivElement> {
  model: TrayModel, 
  width: number, 
  height: number,
  addImage?: (image: string) => void,
}

export const CanvasCapture = forwardRef<PhotoStageHandle, CanvasCaptureProps>(
  ({ model, width, height, className, addImage, ...props }, ref) => {
  const [autoFit, setAutoFit] = useState(true);
  const stageRef = useRef<StageHandle>(null);
  const canvasRef = useRef<CanvasHandle>(null);
  useEffect(() => {
    setTimeout(() => {
      if (stageRef.current !== null) {
        stageRef.current.fit("sphere", true);
      }
    }, 0);
  }, [ model ])
  return (
    <div className={cn("relative", className)} style={{
      width: (width + 2) + "px",
      height: (height + 2) + "px",
    }} {...props}>
      <Canvas isOrthographic={true} ref={canvasRef} initialCameraPosition={new Vector3(-50, -100, 80)} onCreated={() => {
        canvasRef.current!.setSize(width, height);
        setTimeout(() => {
          if (stageRef.current !== null) {
            stageRef.current.fit("sphere", true);
          }
        }, 0);
      }} onClick={() => {
        if(autoFit) {
          if (stageRef.current !== null) {
            stageRef.current.fit("sphere", true);
          }
        }
      }}>
        <Stage ref={stageRef} panGround={false}>
          <ReplicadShape model={model} color="#0ea5e9" edgeOpacity={40} />
        </Stage>
      </Canvas>
      <div className="absolute bottom-2 left-2 z-50 flex select-none items-start gap-2">
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2 rounded-md bg-control/80 p-2">
            <Switch id="autoFit" checked={autoFit} onCheckedChange={c => setAutoFit(c)}/>
            <Label htmlFor="autoFit" className="font-normal">
              Auto-fit
            </Label>
          </div>
          {!autoFit && (
            <Button variant="outline" className="justify-start" size="sm" onClick={() => {
              // https://drei.docs.pmnd.rs/staging/bounds
              if (stageRef.current !== null) {
                stageRef.current.fit("sphere", true);
              }
            }}>
              <CornersIcon />
              Fit
            </Button>
          )}
          {addImage !== undefined && (
            <Button variant="primary" className="justify-start" size="sm" onClick={() => {
              if (canvasRef.current !== null) {
                addImage(canvasRef.current.captureCanvas());
              }
            }}>
              <CameraIcon />
              Take picture
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});
CanvasCapture.displayName = "CanvasCapture";
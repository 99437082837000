import { Blueprint, ElementBlueprint, InsertGlobals, PocketElementBlueprint, ContainerElementBlueprint, ElementType, ElementLayout, Model, Configuration, ModelSelection, Vector, MinifiedBlueprint, ModelRecoryType, Tray, TrayType, BoardgameExpansion, BlueprintType, PocketType, Boardgame, ExpansionSupport, getExpansionSupportName } from '@/types';
import { getDefaultPocketElementConfiguration, getDefaultContainerElementConfiguration, getDefaultElementLayout, configurationKeyName, formatConfigurationValue, getDefaultInsert, getDefaultBlueprint, getDefaultTray, getDefaultElementInstance } from '../catalog';
import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { alignHexagonCutout, layoutTray as layoutTray } from '@/designer/measurement';
import { calculateBlueprintHash, inflateBlueprint, inflateTray, printable, vectorEquals } from '@/utils';
import { validateInsert } from '@/designer/insertLayout';
import { shallowEqual } from 'react-redux';
import { reporter } from './messages';
import { getTrayName } from './store';

function getNextBlueprintId(model: Draft<Model>) {
  const blueprints = Object.keys(model.blueprints);
  if (blueprints.length == 0) {
    return 1;
  }
  return Math.max(...blueprints.map(k => parseInt(k))) + 1;
}

function getNextTrayId(model: Draft<Model>) {
  const trays = Object.keys(model.trays);
  if (trays.length == 0) {
    return 1;
  }
  return Math.max(...trays.map(k => parseInt(k))) + 1;
}

function getNextElementId(tray: Draft<Blueprint>) {
  const elements = Object.keys(tray.elements);
  if (elements.length == 0) {
    return 1;
  }
  return Math.max(...elements.map(k => parseInt(k))) + 1;
}

function getTraysFromBlueprint(model: Readonly<Model>, blueprintId: number) {
  return Object.values(model.trays).filter(i => i.blueprintId === blueprintId);
}

function doModifyElementConfiguration(state: Draft<Model>, blueprint: Draft<Blueprint>, element: Draft<ElementBlueprint>, configuration: Partial<Configuration>) {
  let hasChanges = false;
  for (const key in configuration) {
    const value = configuration[key];
    const lastValue = element.configuration[key];
    if (value !== undefined && value !== lastValue) {
      element.configuration[key] = value;
      hasChanges = true;
    }
  }
  return hasChanges;
}

function addTrayForBlueprint(model: Draft<Model>, type: TrayType, blueprint: Readonly<Blueprint>) {
  // create new tray
  const tray: Tray = inflateTray({
    id: getNextTrayId(model),
    type: type,
    blueprintId: blueprint.id,
    size: {
      x: 0,
      y: 0,
      z: 0,
    },
    offset: {
      x: 0,
      y: 0,
      z: 0,
    },
    images: [],
  });
  // add elements in tray
  for (const element of Object.values(blueprint.elements)) {
    tray.elements[element.id] = getDefaultElementInstance();
  }
  updateTray(blueprint, tray);
  model.trays[tray.id] = tray;
  return model.trays[tray.id];
}

function getInitialModel() {
  const blueprint = getDefaultBlueprint();
  const model: Model = {
    insert: getDefaultInsert(),
    blueprints: {
      1: blueprint,
    },
    trays: {},
    selection: {
      scope: "insert",
      aspect: "basics",
    },
    history: {
      records: [{
        type: "childs",
        change: "initial model",
        insert: getDefaultInsert(),
        blueprints: {
          1: getDefaultBlueprint(),
        },
        trays: {
          1: getDefaultTray(),
        },
      }],
      index: 0,
    },
  };
  addTrayForBlueprint(model, TrayType.Primary, blueprint);
  addTrayForBlueprint(model, TrayType.Preview, blueprint);
  return model;
}

function updateBlueprint(blueprint: Draft<Blueprint>) {
  blueprint.modelHash = calculateBlueprintHash(blueprint);
}

function updateTray(blueprint: Readonly<Blueprint>, tray: Draft<Tray>) {
  layoutTray(blueprint, tray);
}

function updateHistory(model: Draft<Model>, type: ModelRecoryType, change: string) {
  if (model.history.index != model.history.records.length - 1) {
    // delete alternative timeline
    model.history.records.splice(model.history.index + 1)
  }
  model.history.records.push({
    insert: model.insert,
    blueprints: model.blueprints,
    trays: model.trays,
    change: change,
    type: type,
  });
  model.history.index = model.history.records.length - 1;
}

function updateModel(model: Draft<Model>, options: {
  blueprint?: Draft<Blueprint>,
  updateAllTrays?: boolean,
  tray?: Draft<Tray>,
  recordType: ModelRecoryType,
  recordChange: string,
}) {
  if(options.blueprint !== undefined) {
    updateBlueprint(options.blueprint);
    if(options.updateAllTrays) {
      for (const tray of getTraysFromBlueprint(model, options.blueprint.id)) {
        updateTray(options.blueprint, tray);
      }
    } else if(options.tray !== undefined) {
      updateTray(options.blueprint, options.tray);
    }
  } else if(options.tray !== undefined) {
    updateTray(model.blueprints[options.tray.blueprintId], options.tray);
  }
  validateInsert(model);
  updateHistory(model, options.recordType, options.recordChange);
}

export const modelSlice = createSlice({
  name: "model",
  initialState: getInitialModel(),
  reducers: {
    modifyInsert: (state, action: PayloadAction<{
      name?: string,
      path?: string,
      boardgame?: Boardgame,
      boardgameExpansions?: BoardgameExpansion[],
    }>) => {
      if (action.payload.name !== undefined && action.payload.name != state.insert.name) {
        state.insert.name = action.payload.name;
        updateHistory(state, "basics", `n(insert name) set to v(${action.payload.name})`);
      }
      if (action.payload.path !== undefined && action.payload.path != state.insert.path) {
        state.insert.path = action.payload.path;
        updateHistory(state, "basics", `n(insert path) set to v(${action.payload.path})`);
      }
      if (action.payload.boardgame !== undefined && !shallowEqual(state.insert.boardgame, action.payload.boardgame)) {
        state.insert.boardgame = action.payload.boardgame;
        updateHistory(state, "basics", `n(board game) set to v(${action.payload.boardgame.name})`);
      }
      if (action.payload.boardgameExpansions !== undefined && !shallowEqual(action.payload.boardgameExpansions, state.insert.boardgameExpansions)) {
        state.insert.boardgameExpansions = action.payload.boardgameExpansions;
        updateHistory(state, "basics", `n(board game expansions) set to v(${action.payload.boardgameExpansions.map(e => e.name.toString()).join(", ")})`);
      }
    },
    modifyInsertBoardgameExpansion: (state, action: PayloadAction<{
      id: number,
      support?: ExpansionSupport,
      color?: string,
    }>) => {
      const expansion = state.insert.boardgameExpansions.find(e => e.id === action.payload.id);
      if(expansion !== undefined) {
        if (action.payload.support !== undefined && action.payload.support != expansion.support) {
          expansion.support = action.payload.support;
          updateHistory(state, "basics", `n(support for ${expansion.name}) set to v(${getExpansionSupportName(expansion.support)})`);
        }
        if (action.payload.color !== undefined && action.payload.color != expansion.color) {
          expansion.color = action.payload.color;
          updateHistory(state, "basics", `n(color for ${expansion.name}) set to v(${expansion.color})`);
        }
      }
    },
    modifyInsertConfiguration: (state, action: PayloadAction<{
      configuration: Configuration,
    }>) => {
      const changes: string[] = [];
      for (const key in action.payload.configuration) {
        const value = action.payload.configuration[key];
        const lastValue = state.insert.configuration[key];
        if (value !== undefined && value != lastValue) {
          changes.push(`n(${configurationKeyName(key)}) set to v(${formatConfigurationValue(key, value)})`);
          state.insert.configuration[key] = value;
        }
      }
      if (changes.length > 0) {
        updateModel(state, {
          recordType: "configuration",
          recordChange: "insert configuration " + changes.join(", "),
        });
      }
    },
    modifyInsertGlobals: (state, action: PayloadAction<{
      globals: Partial<InsertGlobals>,
    }>) => {
      const changes: string[] = [];
      for (const key in action.payload.globals) {
        const value = action.payload.globals[key];
        const lastValue = state.insert.globals[key];
        if (value !== undefined && value !== lastValue) {
          changes.push(`n(${configurationKeyName(key)}) set to v(${formatConfigurationValue(key, value)})`);
          state.insert.globals[key] = value;
          // update in all blueprints
          for (const blueprintId in state.blueprints) {
            const blueprint = state.blueprints[blueprintId];
            if (blueprint.globals[key] === lastValue) {
              blueprint.globals[key] = value;
              updateBlueprint(blueprint);
            }
          }
        }
      }
      if (changes.length > 0) {
        updateModel(state, {
          recordType: "configuration",
          recordChange: "insert globals " + changes.join(", "),
        });
      }
    },
    modifyInsertImages: (state, action: PayloadAction<{
      images: string[],
    }>) => {
      state.insert.images = action.payload.images;
      updateModel(state, {
        recordType: "basics",
        recordChange: `updated insert images`,
      });
    },
    addBlueprint: (state, action: PayloadAction<{
      blueprint: Omit<MinifiedBlueprint, "id" | "globals">,
      name?: string,
      index?: number,
      selectIn?: "blueprint" | "insert",
    }>) => {
      // add blueprint
      const blueprint = inflateBlueprint({
        ...action.payload.blueprint,
        id: getNextBlueprintId(state),
        globals: {
          ...state.insert.globals
        },
      });
      if (action.payload.name !== undefined) {
        blueprint.name = action.payload.name;
      }
      state.blueprints[blueprint.id] = blueprint;
      // add primary tray
      const primaryTray = addTrayForBlueprint(state, TrayType.Primary, blueprint);
      let insertionIndex = state.insert.trayIds.length;
      if (action.payload.index !== undefined) {
        insertionIndex = Math.min(insertionIndex, action.payload.index);
      }
      state.insert.trayIds.splice(insertionIndex, 0, primaryTray.id);
      // add preview tray
      addTrayForBlueprint(state, TrayType.Preview, blueprint);
      // update selection
      if (action.payload.selectIn === "insert") {
        state.selection = {
          scope: "insert",
          aspect: "tray",
          trayId: primaryTray.id,
        };
      } else if (action.payload.selectIn === "blueprint") {
        state.selection = {
          scope: "blueprint",
          aspect: "configuration",
          blueprintId: blueprint.id,
        };
      }
      updateModel(state, {
        recordType: "childs",
        recordChange: `added t(${blueprint.name})`,
      });
    },
    addTray: (state, action: PayloadAction<{
      blueprintId: number,
      name?: string,
      index?: number,
      selectIn?: "blueprint" | "insert",
    }>) => {
      const blueprint = state.blueprints[action.payload.blueprintId];
      const tray = addTrayForBlueprint(state, TrayType.Secondary, blueprint);
      tray.name = action.payload.name;
      let insertionIndex = state.insert.trayIds.length;
      if (action.payload.index !== undefined) {
        insertionIndex = Math.min(insertionIndex, action.payload.index);
      }
      state.insert.trayIds.splice(insertionIndex, 0, tray.id);
      // update selection
      if (action.payload.selectIn === "insert") {
        state.selection = {
          scope: "insert",
          aspect: "tray",
          trayId: tray.id,
        };
      } else if (action.payload.selectIn === "blueprint") {
        state.selection = {
          scope: "blueprint",
          aspect: "configuration",
          blueprintId: blueprint.id,
        };
      }
      updateModel(state, {
        recordType: "childs",
        recordChange: `added t(${tray.name})`,
      });
    },
    deleteTray: (state, action: PayloadAction<{
      tray?: number,
    }>) => {
      if (action.payload.tray === undefined) {
        if (state.selection.scope === "insert" && state.selection.aspect === "tray") {
          action.payload.tray = state.selection.trayId;
        } else {
          console.log("failed to get current tray id");
          return;
        }
      }
      // remove tray and potentially the blueprint if last instance was removed
      const tray = state.trays[action.payload.tray];
      const blueprint = state.blueprints[tray.blueprintId];
      const trays = getTraysFromBlueprint(state, blueprint.id);
      if (tray.type === TrayType.Primary && trays.some(t => t.type === TrayType.Secondary)) {
        reporter.error("Failed to delete tray, delete all instanced trays before deleting the template.");
        return;
      }
      // remove tray from insert
      let index = state.insert.trayIds.indexOf(action.payload.tray);
      if (index === -1) {
        console.error(`failed to delete tray ${action.payload.tray} as it is no child of the insert`);
        return;
      }
      state.insert.trayIds.splice(index, 1);
      // remove tray
      delete state.trays[action.payload.tray];
      if (tray.type === TrayType.Primary) {
        // deleting primary, only preview can be left
        for (const other of trays) {
          delete state.trays[other.id];
        }
      }
      // update selection
      if (state.selection.scope === "insert" && state.selection.aspect === "tray" && state.selection.trayId === action.payload.tray) {
        index = Math.min(index, state.insert.trayIds.length - 1);
        if (index === -1) {
          state.selection = {
            scope: "insert",
            aspect: "configuration",
          };
        } else {
          state.selection = {
            scope: "insert",
            aspect: "tray",
            trayId: state.insert.trayIds[index],
          };
        }
      }
      updateModel(state, {
        recordType: "childs",
        recordChange: `deleted t(${tray.name})`,
      });
    },
    moveTray: (state, action: PayloadAction<{
      tray?: number,
      index: number,
    }>) => {
      if (action.payload.tray === undefined) {
        if (state.selection.aspect === "tray") {
          action.payload.tray = state.selection.trayId;
        } else {
          console.log("failed to get current tray id");
          return;
        }
      }
      const currentIndex = state.insert.trayIds.indexOf(action.payload.tray);
      if (currentIndex == -1) {
        console.error(`can not move tray ${action.payload.tray} as it is no child of the insert`);
        return;
      }
      if (action.payload.index >= state.insert.trayIds.length) {
        console.error(`can not move tray ${action.payload.tray} as target index ${action.payload.index} is invalid`);
        return;
      }
      // move element
      state.insert.trayIds.splice(currentIndex, 1);
      state.insert.trayIds.splice(action.payload.index, 0, action.payload.tray);
      updateModel(state, {
        recordType: "childs",
        recordChange: `t(${state.trays[action.payload.tray].name}) moved to slot ${action.payload.index + 1}`,
      });
    },
    clearTrayMessages: (state, action: PayloadAction<{
      tray?: number,
      blueprint?: number,
    }>) => {
      if(action.payload.blueprint !== undefined) {
        const trays = getTraysFromBlueprint(state, action.payload.blueprint);
        for(const tray of trays) {
          tray.messages = [];
        }
      } else {
        if (action.payload.tray === undefined) {
          if (state.selection.aspect === "tray") {
            action.payload.tray = state.selection.trayId;
          } else {
            console.log("failed to get current tray id");
            return;
          }
        }
        const tray = state.trays[action.payload.tray];
        tray.messages = [];
      }
    },
    modifyBlueprint: (state, action: PayloadAction<{
      blueprint?: number,
      name?: string,
      type?: BlueprintType,
    }>) => {
      if (action.payload.blueprint === undefined) {
        if (state.selection.scope === "blueprint") {
          action.payload.blueprint = state.selection.blueprintId;
        } else {
          console.log("failed to get current blueprint id");
          return;
        }
      }
      const blueprint = state.blueprints[action.payload.blueprint];
      if (action.payload.type !== undefined && action.payload.type != blueprint.type) {
        blueprint.type = action.payload.type;
        // TODO: apply changes from type change
        updateModel(state, {
          blueprint: blueprint,
          updateAllTrays: true,
          recordType: "basics",
          recordChange: `t(${state.blueprints[action.payload.blueprint].name}) n(type) set to v(${action.payload.type})`,
        });
      }
      if (action.payload.name !== undefined && action.payload.name != blueprint.name) {
        blueprint.name = action.payload.name;
        updateModel(state, {
          blueprint: blueprint,
          recordType: "basics",
          recordChange: `t(${state.blueprints[action.payload.blueprint].name}) n(name) set to v(${action.payload.name})`,
        });
      }
    },
    modifyTray: (state, action: PayloadAction<{
      tray?: number,
      offset?: Vector,
      size?: Vector,
      color?: string,
      name?: string,
      associatedGameId?: number,
    }>) => {
      if (action.payload.tray === undefined) {
        if (state.selection.aspect === "tray") {
          action.payload.tray = state.selection.trayId;
        } else {
          console.log("failed to get current tray id");
          return;
        }
      }
      const tray = state.trays[action.payload.tray];
      const blueprint = state.blueprints[tray.blueprintId];
      if (action.payload.name !== undefined && tray.name !== action.payload.name) {
        tray.name = action.payload.name;
        updateModel(state, {
          tray: tray,
          recordType: "basics",
          recordChange: `t(${getTrayName(blueprint.name, tray.name)}) was renamed`,
        });
      }
      if (action.payload.size !== undefined && !vectorEquals(action.payload.size, tray.size)) {
        tray.size = action.payload.size;
        updateModel(state, {
          tray: tray,
          recordType: "layout",
          recordChange: `t(${getTrayName(blueprint.name, tray.name)}) n(size) set to c(${printable(tray.size.x)}/${printable(tray.size.y)}/${printable(tray.size.z)})`,
        });
      }
      if (action.payload.offset !== undefined && !vectorEquals(action.payload.offset, tray.offset)) {
        tray.offset = action.payload.offset;
        updateModel(state, {
          tray: tray,
          recordType: "arrangement",
          recordChange: `t(${getTrayName(blueprint.name, tray.name)}) n(offset) set to c(${printable(tray.offset.x)}/${printable(tray.offset.y)}/${printable(tray.offset.z)})`,
        });
      }
      if (action.payload.color !== undefined && action.payload.color != tray.color) {
        tray.color = action.payload.color;
        updateModel(state, {
          tray: tray,
          recordType: "basics",
          recordChange: `t(${getTrayName(blueprint.name, tray.name)}}) n(color) set to c(${action.payload.color})`,
        });
      }
      if (action.payload.associatedGameId !== undefined && action.payload.associatedGameId != tray.associatedGameId) {
        let name = "";
        if(state.insert.boardgame !== undefined && state.insert.boardgame.id === action.payload.associatedGameId) {
          name = "Base game";
        } else {
          const expansion = state.insert.boardgameExpansions.find(e => e.id === action.payload.associatedGameId);
          if(expansion !== undefined) {
            name = expansion.name;
          } else {
            return;
          }
        }
        tray.associatedGameId = action.payload.associatedGameId;
        updateModel(state, {
          tray: tray,
          recordType: "basics",
          recordChange: `t(${getTrayName(blueprint.name, tray.name)}}) n(association) set to c(${name})`,
        });
      }
    },
    modifyTrayImages: (state, action: PayloadAction<{
      tray?: number,
      images: string[],
    }>) => {
      if (action.payload.tray === undefined) {
        if (state.selection.aspect === "tray") {
          action.payload.tray = state.selection.trayId;
        } else {
          console.log("failed to get current tray id");
          return;
        }
      }
      const tray = state.trays[action.payload.tray];
      const blueprint = state.blueprints[tray.blueprintId];
      tray.images = action.payload.images;
      updateModel(state, {
        tray: tray,
        recordType: "basics",
        recordChange: `updated images for t(${getTrayName(blueprint.name, tray.name)})`,
      });
    },
    modifyBlueprintGlobals: (state, action: PayloadAction<{
      blueprint?: number,
      globals: Partial<InsertGlobals>,
    }>) => {
      if (action.payload.blueprint === undefined) {
        if (state.selection.scope === "blueprint") {
          action.payload.blueprint = state.selection.blueprintId;
        } else {
          console.log("failed to get current blueprint id");
          return;
        }
      }
      const blueprint = state.blueprints[action.payload.blueprint];
      const changes: string[] = [];
      for (const key in action.payload.globals) {
        const value = action.payload.globals[key];
        const lastValue = blueprint.globals[key];
        if (value !== undefined && value !== lastValue) {
          blueprint.globals[key] = value;
          changes.push(`n(${configurationKeyName(key)}) set to v(${formatConfigurationValue(key, value)})`);
        }
      }
      if (changes.length > 0) {
        updateModel(state, {
          blueprint: blueprint,
          updateAllTrays: true,
          recordType: "globals",
          recordChange: `t(${state.blueprints[action.payload.blueprint].name}) globals ${changes.join(", ")}`,
        });
      }
    },
    modifyBlueprintConfiguration: (state, action: PayloadAction<{
      blueprint?: number,
      configuration: Configuration,
    }>) => {
      if (action.payload.blueprint === undefined) {
        if (state.selection.scope === "blueprint") {
          action.payload.blueprint = state.selection.blueprintId;
        } else {
          console.log("failed to get current blueprint id");
          return;
        }
      }
      const blueprint = state.blueprints[action.payload.blueprint];
      const changes: string[] = [];
      for (const key in action.payload.configuration) {
        const value = action.payload.configuration[key];
        const lastValue = blueprint.configuration[key];
        if (value !== undefined && value != lastValue) {
          blueprint.configuration[key] = value;
          changes.push(`n(${configurationKeyName(key)}) set to v(${formatConfigurationValue(key, value)})`);
        }
      }
      if (changes.length > 0) {
        updateModel(state, {
          blueprint: blueprint,
          updateAllTrays: true,
          recordType: "configuration",
          recordChange: `t(${state.blueprints[action.payload.blueprint].name}) configuration ${changes.join(", ")}`,
        });
      }
    },
    addPocketElement: (state, action: PayloadAction<{
      blueprint?: number,
      parent: number,
      element: Partial<Omit<PocketElementBlueprint, "id" | "placement" | "measure" | "changes">>,
      name?: string,
      index?: number,
    }>) => {
      if (action.payload.blueprint === undefined) {
        if (state.selection.scope === "blueprint") {
          action.payload.blueprint = state.selection.blueprintId;
        } else {
          console.log("failed to get current blueprint id");
          return;
        }
      }
      const blueprint = state.blueprints[action.payload.blueprint];
      const parent = blueprint.elements[action.payload.parent];
      if (parent.type == "pocket") {
        console.error(`pocket <${action.payload.parent}> was used as parent for <${action.payload.element}>`);
        return;
      }
      let insertionIndex = Object.keys(blueprint.elements).length;
      if (action.payload.index !== undefined) {
        insertionIndex = Math.min(insertionIndex, action.payload.index);
      }
      const id = getNextElementId(blueprint);
      const name = action.payload.name ?? (action.payload.element.name ?? "new element");
      blueprint.elements[id] = {
        id: id,
        name: name,
        type: "pocket",
        pocketType: action.payload.element.pocketType ?? "tokens",
        configuration: action.payload.element.configuration ?? getDefaultPocketElementConfiguration(action.payload.element.pocketType ?? "tokens"),
        layout: action.payload.element.layout === undefined || action.payload.element.layout.type !== parent.type ? getDefaultElementLayout(parent.type, false) : action.payload.element.layout,
      };
      // add to parent and trays
      parent.childs.splice(insertionIndex, 0, id);
      for (const tray of getTraysFromBlueprint(state, blueprint.id)) {
        tray.elements[id] = getDefaultElementInstance();
      }
      // update selection
      state.selection = {
        scope: "blueprint",
        aspect: "element",
        blueprintId: action.payload.blueprint,
        elementId: id,
      };
      updateModel(state, {
        blueprint: blueprint,
        updateAllTrays: true,
        recordType: "childs",
        recordChange: `t(${state.blueprints[action.payload.blueprint].name}) added n(pocket) v(${name})`,
      });
    },
    addContainerElement: (state, action: PayloadAction<{
      blueprint?: number,
      parent: number,
      element: Partial<Omit<ContainerElementBlueprint, "id" | "placement" | "measure" | "changes" | "childs">>,
      index?: number,
    }>) => {
      if (action.payload.blueprint === undefined) {
        if (state.selection.scope === "blueprint") {
          action.payload.blueprint = state.selection.blueprintId;
        } else {
          console.log("failed to get current blueprint id");
          return;
        }
      }
      const blueprint = state.blueprints[action.payload.blueprint];
      const parent = blueprint.elements[action.payload.parent];
      if (parent.type == "pocket") {
        console.error(`pocket <${action.payload.parent}> was used as parent for <${action.payload.element}>`);
        return;
      }
      let insertionIndex = Object.keys(blueprint.elements).length;
      if (action.payload.index !== undefined) {
        insertionIndex = Math.min(insertionIndex, action.payload.index);
      }
      const id = getNextElementId(blueprint);
      // @ts-expect-error the object is completed without using the correct types as this would lead to much more code
      blueprint.elements[id] = {
        id: id,
        type: action.payload.element.type ?? "stack",
        configuration: action.payload.element.configuration ?? getDefaultContainerElementConfiguration(action.payload.element.type ?? "stack"),
        layout: action.payload.element.layout === undefined || action.payload.element.layout.type !== parent.type ? getDefaultElementLayout(parent.type, true) : action.payload.element.layout,
        childs: [],
      }
      // add to parent and trays
      parent.childs.splice(insertionIndex, 0, id);
      for (const tray of getTraysFromBlueprint(state, blueprint.id)) {
        tray.elements[id] = getDefaultElementInstance();
      }
      // update selection
      state.selection = {
        scope: "blueprint",
        aspect: "element",
        blueprintId: action.payload.blueprint,
        elementId: id,
      };
      updateModel(state, {
        blueprint: blueprint,
        updateAllTrays: true,
        recordType: "childs",
        recordChange: `t(${state.blueprints[action.payload.blueprint].name}) added n(container) v(${blueprint.elements[id].type})`,
      });
    },
    deleteElement: (state, action: PayloadAction<{
      blueprint?: number,
      element: number,
    }>) => {
      if (action.payload.blueprint === undefined) {
        if (state.selection.scope === "blueprint") {
          action.payload.blueprint = state.selection.blueprintId;
        } else {
          console.log("failed to get current blueprint id");
          return;
        }
      }
      const blueprint = state.blueprints[action.payload.blueprint];
      const element = blueprint.elements[action.payload.element];
      let lastParentId = -1;
      // remove element from parents
      for (const id in blueprint.elements) {
        const element = blueprint.elements[id];
        if (element.type !== "pocket") {
          const index = element.childs.indexOf(action.payload.element);
          if (index != -1) {
            lastParentId = element.id;
            element.childs.splice(index, 1);
          }
        }
      }
      // remove element
      delete blueprint.elements[action.payload.element];
      // update selection
      if (state.selection.aspect === "element" && state.selection.elementId === action.payload.element) {
        if (lastParentId != -1) {
          state.selection.elementId = lastParentId;
        } else {
          state.selection.elementId = blueprint.root;
        }
      }
      updateModel(state, {
        blueprint: blueprint,
        updateAllTrays: true,
        recordType: "childs",
        recordChange: `t(${blueprint.name}) deleted n(element) v(${element.type + (element.type === "pocket" ? " " + element.name : "")})`,
      });
    },
    moveElement: (state, action: PayloadAction<{
      blueprint?: number,
      parent: number,
      element: number,
      index: number,
    }>) => {
      if (action.payload.blueprint === undefined) {
        if (state.selection.scope === "blueprint") {
          action.payload.blueprint = state.selection.blueprintId;
        } else {
          console.log("failed to get current blueprint id");
          return;
        }
      }
      const blueprint = state.blueprints[action.payload.blueprint];
      const parent = blueprint.elements[action.payload.parent];
      if (parent.type == "pocket") {
        console.error(`can not move <${action.payload.element}> in pocket <${action.payload.parent}>`);
        return;
      }
      const currentIndex = parent.childs.indexOf(action.payload.element);
      if (currentIndex == -1) {
        console.error(`can not move <${action.payload.element}> in <${action.payload.parent}> as it is not a child`);
        return;
      }
      if (action.payload.index >= parent.childs.length) {
        console.error(`can not move <${action.payload.element}> in <${action.payload.parent}> as target index ${action.payload.index} is invalid`);
        return;
      }
      // move element
      parent.childs.splice(currentIndex, 1);
      parent.childs.splice(action.payload.index, 0, action.payload.element);
      updateModel(state, {
        blueprint: blueprint,
        updateAllTrays: true,
        recordType: "childs",
        recordChange: `moved element`,
      });
    },
    modifyElement: (state, action: PayloadAction<{
      blueprint?: number,
      element: number,
      name?: string,
      type?: ElementType,
      pocketType?: PocketType,
    }>) => {
      if (action.payload.blueprint === undefined) {
        if (state.selection.scope === "blueprint") {
          action.payload.blueprint = state.selection.blueprintId;
        } else {
          console.log("failed to get current blueprint id");
          return;
        }
      }
      let hasLayoutChanges = false;
      const blueprint = state.blueprints[action.payload.blueprint];
      const element = blueprint.elements[action.payload.element];
      if (action.payload.type !== undefined && action.payload.type != element.type) {
        const lastType = element.type;
        // remove all childs when changing type to pocket
        if (element.type !== "pocket" && action.payload.type === "pocket") {
          // remove all childs
          element.childs.splice(0, element.childs.length);
        }
        element.type = action.payload.type;
        hasLayoutChanges = true;
        // update child element layouts while applying whatever possible
        if (element.type !== "pocket" && element.childs.length > 0) {
          const defaultConfiguration = getDefaultElementLayout(element.type, true);
          for (const child of element.childs) {
            const childElement = blueprint.elements[child];
            // explicitly update layout type
            childElement.layout.type = defaultConfiguration.type;
            for (const key in defaultConfiguration) {
              if (key in childElement.layout) {
                // keep old key value
                // TODO validation of existing values for new layout might be requried later on
              } else {
                // add new keys from new default layout
                childElement.layout[key] = defaultConfiguration[key];
              }
            }
            for (const key in childElement.layout) {
              if (key in defaultConfiguration) {
                // key is part of new layout configuration
              } else {
                // delete key that is not compatible with new layout configuration
                delete childElement.layout[key];
              }
            }
          }
        }
      }
      if (element.type === "pocket") {
        if (action.payload.name !== undefined && action.payload.name != element.name) {
          element.name = action.payload.name;
        }
        if (action.payload.pocketType !== undefined && action.payload.pocketType != element.pocketType) {
          element.pocketType = action.payload.pocketType;
          // update configuration
          const configuration = getDefaultPocketElementConfiguration(element.pocketType);
          for (const key in configuration) {
            const existingValue = element.configuration[key];
            if (existingValue !== undefined) {
              configuration[key] = existingValue;
            }
          }
          element.configuration = configuration;
          if (element.pocketType === "tokens-spacer") {
            // spaces usually fill z
            element.layout.zGrow = 1;
            element.configuration.hexCutPrevent = false;
          }
          if (element.pocketType === "connector") {
            // connectory must fill z
            element.layout.zGrow = 1;
            element.configuration.hexCutPrevent = true;
          }
          doModifyElementConfiguration(state, blueprint, element, configuration);
          hasLayoutChanges = true;
        }
      }
      // update tray layouts
      if (hasLayoutChanges) {
        updateModel(state, {
          blueprint: blueprint,
          updateAllTrays: true,
          recordType: "basics",
          recordChange: `changed element basics`,
        });
      }
    },
    modifyElementConfiguration: (state, action: PayloadAction<{
      blueprint?: number,
      element: number,
      configuration: Partial<Configuration>,
    }>) => {
      if (action.payload.blueprint === undefined) {
        if (state.selection.scope === "blueprint") {
          action.payload.blueprint = state.selection.blueprintId;
        } else {
          console.log("failed to get current blueprint id");
          return;
        }
      }
      const blueprint = state.blueprints[action.payload.blueprint];
      const element = blueprint.elements[action.payload.element];
      const hasChanges = doModifyElementConfiguration(state, blueprint, element, action.payload.configuration);
      if(hasChanges) {
        updateModel(state, {
          blueprint: blueprint,
          updateAllTrays: true,
          recordType: "configuration",
          recordChange: `changed element configuration`,
        });
      }
    },
    modifyElementLayout: (state, action: PayloadAction<{
      blueprint?: number,
      element: number,
      layout: Partial<Omit<ElementLayout, "type">>,
    }>) => {
      if (action.payload.blueprint === undefined) {
        if (state.selection.scope === "blueprint") {
          action.payload.blueprint = state.selection.blueprintId;
        } else {
          console.log("failed to get current blueprint id");
          return;
        }
      }
      const blueprint = state.blueprints[action.payload.blueprint];
      const element = blueprint.elements[action.payload.element];
      let hasChanges = false;
      for (const key in action.payload.layout) {
        const value = action.payload.layout[key];
        const lastValue = element.layout[key];
        if (value !== undefined && value !== lastValue) {
          element.layout[key] = value;
          hasChanges = true;
        }
      }
      // update tray layouts
      if (hasChanges) {
        updateModel(state, {
          blueprint: blueprint,
          updateAllTrays: true,
          recordType: "layout",
          recordChange: `changed element layout`,
        });
      }
    },
    alignHexagonCutoutToPocket: (state, action: PayloadAction<{
      blueprint?: number,
      element: number,
    }>) => {
      if (action.payload.blueprint === undefined) {
        if (state.selection.scope === "blueprint") {
          action.payload.blueprint = state.selection.blueprintId;
        } else {
          console.log("failed to get current blueprint id");
          return;
        }
      }
      const blueprint = state.blueprints[action.payload.blueprint];
      const element = blueprint.elements[action.payload.element];
      if (element.type !== "pocket") {
        console.error("hexagon cutout can only be aligned to pockets")
        return;
      }
      if (blueprint.type !== "layout") {
        console.error("hexagon cutout can only be aligned to pockets in layout tray")
        return;
      }
      alignHexagonCutout(blueprint, element);
    },
    restoreRecord: (state, action: PayloadAction<{
      index: number,
    }>) => {
      let index = action.payload.index;
      if (index >= state.history.records.length) {
        index = state.history.records.length - 1;
      } else if (index < 0) {
        index = 0;
      }
      state.insert = state.history.records[index].insert;
      state.blueprints = state.history.records[index].blueprints;
      state.trays = state.history.records[index].trays;
      state.history.index = index;
      // fix selection
      if (state.selection.scope === "blueprint" && !(state.selection.blueprintId in state.blueprints)) {
        state.selection = {
          scope: "insert",
          aspect: "configuration",
        };
      } else if (state.selection.scope === "insert" && state.selection.aspect === "tray" && !(state.selection.trayId in state.trays)) {
        state.selection = {
          scope: "insert",
          aspect: "configuration",
        };
      } else {
        if (state.selection.aspect === "element" && !(state.selection.elementId in state.blueprints[state.selection.blueprintId].elements)) {
          state.selection = {
            scope: "insert",
            aspect: "configuration",
          };
        }
      }
      validateInsert(state);
    },
    restoreModel: (state, action: PayloadAction<{
      model: Model,
    }>) => {
      state.insert = action.payload.model.insert;
      state.blueprints = action.payload.model.blueprints;
      state.trays = action.payload.model.trays;
      state.history = action.payload.model.history;
      state.selection = action.payload.model.selection;
      validateInsert(state);
    },
    invalidateLayout: (state, action: PayloadAction<{
      blueprint?: number,
    }>) => {
      if (action.payload.blueprint === undefined) {
        if (state.selection.scope === "blueprint") {
          action.payload.blueprint = state.selection.blueprintId;
        } else {
          console.log("failed to get current blueprint id");
          return;
        }
      }
      const blueprint = state.blueprints[action.payload.blueprint];
      for (const tray of getTraysFromBlueprint(state, blueprint.id)) {
        updateTray(blueprint, tray);
      }
      validateInsert(state);
    },
    restoreBlueprint: (state, action: PayloadAction<{
      blueprint: Blueprint,
    }>) => {
      updateBlueprint(action.payload.blueprint);
      state.insert = getDefaultInsert({
        name: action.payload.blueprint.name,
        globals: action.payload.blueprint.globals,
        trays: [],
      });
      state.blueprints = {};
      state.trays = {};
      state.blueprints[action.payload.blueprint.id] = action.payload.blueprint;
      addTrayForBlueprint(state, TrayType.Preview, action.payload.blueprint);
      addTrayForBlueprint(state, TrayType.Primary, action.payload.blueprint);
      state.selection = {
        scope: "blueprint",
        aspect: "configuration",
        blueprintId: action.payload.blueprint.id,
      },
      state.history = {
        index: 0,
        records: [],
      };
      validateInsert(state);
    },
    select: (state, action: PayloadAction<ModelSelection>) => {
      state.selection = action.payload;
    },
  }
});

export const { select, modifyInsertBoardgameExpansion, restoreBlueprint, restoreRecord, restoreModel, modifyInsert, modifyInsertImages, alignHexagonCutoutToPocket, clearTrayMessages, invalidateLayout, addPocketElement, addContainerElement, modifyTray, modifyTrayImages, deleteElement, modifyElement, moveElement, modifyElementConfiguration, modifyElementLayout, modifyInsertConfiguration, modifyBlueprint, modifyBlueprintConfiguration, modifyBlueprintGlobals, addBlueprint, addTray, deleteTray, modifyInsertGlobals, moveTray } = modelSlice.actions;

export default modelSlice.reducer; 

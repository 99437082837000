import * as lz from "lz-string";
import objectHash from 'object-hash';
import { Bounds, ElementBlueprint, Insert, MinifiedElementBlueprint, MinifiedInsert, MinifiedModel, MinifiedBlueprint, MinifiedTray, Model, Blueprint, Tray, Vector, ContainerElementBlueprint, TrayElement, PocketElementBlueprint, StorageType } from "./types";
import { Draft } from "@reduxjs/toolkit";
import { useRef } from "react";

//#region import, export and compression

const base64abc = [
	"A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
	"N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
	"a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m",
	"n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
	"0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "+", "/"
];
const base64codes = [
	255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
	255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
	255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 62, 255, 255, 255, 63,
	52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 255, 255, 255, 0, 255, 255,
	255, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
	15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 255, 255, 255, 255, 255,
	255, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
	41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51
];

function getBase64Code(charCode: number) {
	if (charCode >= base64codes.length) {
		throw new Error("Unable to parse base64 string.");
	}
	const code = base64codes[charCode];
	if (code === 255) {
		throw new Error("Unable to parse base64 string.");
	}
	return code;
}

export function bytesToBase64(bytes: Uint8Array) {
	let result = '';
  let i: number;
  const l = bytes.length;
	for (i = 2; i < l; i += 3) {
		result += base64abc[bytes[i - 2] >> 2];
		result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
		result += base64abc[((bytes[i - 1] & 0x0F) << 2) | (bytes[i] >> 6)];
		result += base64abc[bytes[i] & 0x3F];
	}
	if (i === l + 1) { // 1 octet yet to write
		result += base64abc[bytes[i - 2] >> 2];
		result += base64abc[(bytes[i - 2] & 0x03) << 4];
		result += "==";
	}
	if (i === l) { // 2 octets yet to write
		result += base64abc[bytes[i - 2] >> 2];
		result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
		result += base64abc[(bytes[i - 1] & 0x0F) << 2];
		result += "=";
	}
	return result;
}

export function base64ToBytes(string: string) {
	if (string.length % 4 !== 0) {
		throw new Error("Unable to parse base64 string.");
	}
	const index = string.indexOf("=");
	if (index !== -1 && index < string.length - 2) {
		throw new Error("Unable to parse base64 string.");
	}
	const missingOctets = string.endsWith("==") ? 2 : string.endsWith("=") ? 1 : 0;
  const n = string.length;
	const result = new Uint8Array(3 * (n / 4));
  let buffer: number;
	for (let i = 0, j = 0; i < n; i += 4, j += 3) {
		buffer =
			getBase64Code(string.charCodeAt(i)) << 18 |
			getBase64Code(string.charCodeAt(i + 1)) << 12 |
			getBase64Code(string.charCodeAt(i + 2)) << 6 |
			getBase64Code(string.charCodeAt(i + 3));
		result[j] = buffer >> 16;
		result[j + 1] = (buffer >> 8) & 0xFF;
		result[j + 2] = buffer & 0xFF;
	}
	return result.subarray(0, result.length - missingOctets);
}

export function compressObject(obj: object) {
  const stringified = JSON.stringify(obj);
  return lz.compressToBase64(stringified);
}

export function decompressObject<T>(compressed: string) {
  const stringified = lz.decompressFromBase64(compressed);
  try {
    return JSON.parse(stringified) as T;
  } catch (e) { 
    console.error("failed to decompress object: " + e);
    return null;
  }
}

export function exportModel(model: Model) {
  const minified = minifyModel(model);
  return compressObject(minified);
}

export function importModel(data: string) {
  const decompressed = decompressObject<MinifiedModel>(data);
  if(decompressed === null) {
    return null;
  }
  return inflateModel(decompressed);
}

export function exportBlueprint(tray: Blueprint) {
  const minified = minifyBlueprint(tray);
  return compressObject(minified);
}

export function importBlueprint(data: string) {
  const decompressed = decompressObject<MinifiedBlueprint>(data);
  if(decompressed === null) {
    return null;
  }
  return inflateBlueprint(decompressed);
}

//#endregion import, export and compression

//#region inflate and minify

export function minifyModel(model: Model): MinifiedModel {
  return {
    insert: minifyInsert(model.insert),
    blueprints: minifyBlueprints(model.blueprints),
    trays: minifyTrays(model.trays),
  };
}

export function inflateModel(model: MinifiedModel): Model {
  return {
    insert: inflateInsert(model.insert),
    blueprints: inflateBlueprints(model.blueprints),
    trays: inflateTrays(model.trays),
    selection: {
      scope: "insert",
      aspect: "configuration",
    },
    history: {
      index: 0,
      records: [],
    }
  };
}

export function minifyInsert(insert: Insert): MinifiedInsert {
  return {
    id: insert.id,
    name: insert.name,
    path: insert.path,
    boardgame: insert.boardgame,
    boardgameExpansions: [ ...insert.boardgameExpansions ],
    version: insert.version,
    images: [...insert.images],
    configuration: {
      ...insert.configuration,
    },
    globals: {
      ...insert.globals,
    },
    trayIds: [
      ...insert.trayIds,
    ],
  };
}

export function inflateInsert(insert: MinifiedInsert): Insert {
  return {
    ...insert,
    history: [],
    messages: [],
  };
}

export function minifyBlueprints(blueprints: { [id: number]: Blueprint }) {
  const result: { [id: number]: MinifiedBlueprint } = {};
  for(const id in blueprints) {
    result[id] = minifyBlueprint(blueprints[id]);
  }
  return result;
}

export function inflateBlueprints(blueprints: { [id: number]: MinifiedBlueprint }) {
  const result: { [id: number]: Blueprint } = {};
  for(const id in blueprints) {
    result[id] = inflateBlueprint(blueprints[id]);
  }
  return result;
}

export function minifyBlueprint(blueprint: Blueprint): MinifiedBlueprint {
  return {
    id: blueprint.id,
    type: blueprint.type,
    name: blueprint.name,
    root: blueprint.root,
    version: blueprint.version,
    configuration: {
      ...blueprint.configuration,
    },
    globals: {
      ...blueprint.globals,
    },
    elements: minifyElements(blueprint.elements),
  };
}

export function inflateBlueprint(blueprint: MinifiedBlueprint) {
  const result: Blueprint = {
    ...blueprint,
    elements: inflateElements(blueprint.elements),
    modelHash: "",
  };
  result.modelHash = calculateBlueprintHash(result);
  return result;
}

export function minifyTrays(trays: { [id: number]: Tray }) {
  const result: { [id: number]: MinifiedTray } = {};
  for(const id in trays) {
    result[id] = minifyTray(trays[id]);
  }
  return result;
}

export function inflateTrays(trays: { [id: number]: MinifiedTray }) {
  const result: { [id: number]: Tray } = {};
  for(const id in trays) {
    result[id] = inflateTray(trays[id]);
  }
  return result;
}

export function minifyTray(trayInstance: Tray): MinifiedTray {
  return {
    id: trayInstance.id,
    blueprintId: trayInstance.blueprintId,
    size: trayInstance.size,
    offset: trayInstance.offset,
    type: trayInstance.type,
    color: trayInstance.color,
    associatedGameId: trayInstance.associatedGameId,
    images: [...trayInstance.images],
  };
}

export function inflateTray(trayInstance: MinifiedTray) {
  const result: Tray = {
    ...trayInstance,
    elements: {},
    bounds: [],
    measure: {
      xMin: 0,
      yMin: 0,
      zMin: 0,
    },
    ports: [],
    messages: [],
    modelHash: "",
  };
  result.modelHash = calculateTrayHash(result);
  return result;
}

export function minifyElements(elements: { [id: number]: ElementBlueprint }) {
  const result: { [id: number]: MinifiedElementBlueprint } = {};
  for(const id in elements) {
    result[id] = minifyElement(elements[id]);
  }
  return result;
}

export function inflateElements(elements: { [id: number]: MinifiedElementBlueprint }) {
  const result: { [id: number]: ElementBlueprint } = {};
  for(const id in elements) {
    result[id] = inflateElement(elements[id]);
  }
  return result;
}

export function minifyElement(element: ElementBlueprint): MinifiedElementBlueprint {
  return element;
}

export function inflateElement(element: MinifiedElementBlueprint): ElementBlueprint {
  return element;
}

//#endregion inflate and minify

//#region number handling

export function parseNumber(value: unknown) {
  if (value == null) {
    return null;
  }
  switch(typeof(value)) {
    case "number":
    case "bigint": return Number(value);
    case "boolean": return value ? 1 : 0;
    case "function":
    case "symbol":
    case "undefined":
    case "object": return 0;
    case "string": 
      try {
        if (value.includes(".")) {
          return parseFloat(value);
        }
        return parseInt(value);
      } catch {
        return null;
      }
  }
  return null;
}

export function roundNumber(value: number) {
  value = Math.round((value + Number.EPSILON) * 1000) / 1000;
  if(Object.is(value, -0)) {
    value = 0;
  }
  return value;
}

export function roundVector(vector: Vector) {
  return {
    x: roundNumber(vector.x),
    y: roundNumber(vector.y),
    z: roundNumber(vector.z),
  };
}

export function vectorEquals(left: Vector, right: Vector) {
  return left.x === right.x && left.y === right.y && left.z === right.z;
}

//#endregion number handling

//#region time and date handling

export function getTimestamp() {
  const iso = new Date().toISOString(); // iso: YYYY-MM-DDTHH:mm:ss.sssZ, mysql: 2024-10-22 22:23:15
  return iso.substring(0, 10) + " " + iso.substring(11, 19);
}

export function getTimestampDescription(timestamp: string | number, daysToDate = 4) {
  if(typeof timestamp === "string") {
    timestamp = Date.parse(timestamp);
  }
  const currentTimestamp = Date.now();
  let diffTimestamp = currentTimestamp - timestamp;
  const elapsedHours = Math.floor(diffTimestamp / 1000 / 60 / 60);
  if(elapsedHours < 1) {
    const minutes = Math.floor(diffTimestamp / 1000 / 60);
    if(minutes === 0) {
      const seconds = Math.floor(diffTimestamp / 1000);
      return `${seconds} second${seconds == 1 ? "" : "s"} ago`;
    } else {
      return `${minutes} minute${minutes == 1 ? "" : "s"} ago`;
    }
  } else if(elapsedHours < 24) {
    diffTimestamp -= (elapsedHours *  1000 * 60 * 60);
    const minutes = Math.floor(diffTimestamp / 1000 / 60);
    if(minutes > 0) {
      return `${elapsedHours} hour${elapsedHours == 1 ? "" : "s"} ${minutes} minute${minutes == 1 ? "" : "s"} ago`
    }
    return `${elapsedHours} hour${elapsedHours == 1 ? "" : "s"} ago`;
  } else {
    const elapsedDays = Math.floor(diffTimestamp / 1000 / 60 / 60 / 24);
    if(elapsedDays < daysToDate) {
      return `${elapsedDays} day${elapsedDays == 1 ? "" : "s"} ago`;
    } else {
      const date = new Date(timestamp);
      return new Intl.DateTimeFormat('en-GB', {
        dateStyle: 'long',
        timeStyle: 'short',
      }).format(date);
    }
  }
}

export function toMySqlDateString(date: Date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
}

//#endregion time and date handling

//#region bounds, intersection and other 3D stuff

export function boundsIntersectX(first: Bounds, second: Bounds) {
  return !(roundNumber(first.xOffset + first.x) <= second.xOffset ||
    first.xOffset >= roundNumber(second.xOffset + second.x));
}

export function boundsIntersectY(first: Bounds, second: Bounds) {
  return !(roundNumber(first.yOffset + first.y) <= second.yOffset ||
    first.yOffset >= roundNumber(second.yOffset + second.y));
}

export function boundsIntersectZ(first: Bounds, second: Bounds) {
  return !(roundNumber(first.zOffset + first.z) <= second.zOffset ||
    first.zOffset >= roundNumber(second.zOffset + second.z));
}

export function boundsIntersect(first: Bounds, second: Bounds) {
  return !(roundNumber(first.xOffset + first.x) <= second.xOffset ||
    first.xOffset >= roundNumber(second.xOffset + second.x) || 
    roundNumber(first.yOffset + first.y) <= second.yOffset ||
    first.yOffset >= roundNumber(second.yOffset + second.y) ||
    roundNumber(first.zOffset + first.z) <= second.zOffset ||
    first.zOffset >= roundNumber(second.zOffset + second.z));
}

export function boundsIntersectAny(first: Bounds[], second: Bounds[]) {
  for(const current of first) {
    for(const other of second) {
      if(boundsIntersect(current, other)) {
        return true;
      }
    }
  }
  return false;
}

export function boundsOffsetIntersect(first: Bounds, firstOffset: Vector, second: Bounds, secondOffset: Vector) {
  return !(roundNumber(first.xOffset + first.x + firstOffset.x) <= roundNumber(second.xOffset + secondOffset.x) ||
    roundNumber(first.xOffset + firstOffset.x) >= roundNumber(second.xOffset + second.x + secondOffset.x ) || 
    roundNumber(first.yOffset + first.y + firstOffset.y) <= roundNumber(second.yOffset + secondOffset.y) ||
    roundNumber(first.yOffset + firstOffset.y) >= roundNumber(second.yOffset + second.y + secondOffset.y) ||
    roundNumber(first.zOffset + first.z + firstOffset.z) <= roundNumber(second.zOffset + secondOffset.z) ||
    roundNumber(first.zOffset + firstOffset.z) >= roundNumber(second.zOffset + second.z) + secondOffset.z);
}

export function boundsOffsetIntersectAny(first: Bounds[], firstOffset: Vector, second: Bounds[], secondOffset: Vector) {
  for(const f of first) {
    for(const s of second) {
      if(boundsOffsetIntersect(f, firstOffset, s, secondOffset)) {
        return true;
      }
    }
  }
  return false;
}

export function boundsOffsetConatins(first: Bounds, firstOffset: Vector, second: Bounds, secondOffset: Vector) {
  return roundNumber(first.xOffset + firstOffset.x) >= roundNumber(second.xOffset + secondOffset.x) && 
    roundNumber(first.xOffset + first.x + firstOffset.x) <= roundNumber(second.xOffset + second.x + secondOffset.x) &&
    roundNumber(first.yOffset + firstOffset.y) >= roundNumber(second.yOffset + secondOffset.y) && 
    roundNumber(first.yOffset + first.y + firstOffset.y) <= roundNumber(second.yOffset + second.y + secondOffset.y) &&
    roundNumber(first.zOffset + firstOffset.z) >= roundNumber(second.zOffset + secondOffset.z) && 
    roundNumber(first.zOffset + first.z + firstOffset.z) <= roundNumber(second.zOffset + second.z + secondOffset.z);
}

export function boundsOffsetConatinsAll(first: Bounds[], firstOffset: Vector, second: Bounds, secondOffset: Vector) {
  for(const f of first) {
    if(!boundsOffsetConatins(f, firstOffset, second, secondOffset)) {
      return false;
    }
  }
  return true;
}

export function normalFromOrientedAxis(axis: OrientedAxis): Vector {
  switch(axis) {
    case OrientedAxis.xLeft: return { x: -1, y: 0, z: 0};
    case OrientedAxis.xRight: return { x: 1, y: 0, z: 0};
    case OrientedAxis.yFront: return { x: 0, y: -1, z: 0};
    case OrientedAxis.yBack: return { x: 0, y: 1, z: 0};
    case OrientedAxis.zBottom: return { x: 0, y: 0, z: -1};
    case OrientedAxis.zTop: return { x: 0, y: 0, z: 1};
  }
}

export enum OrientedAxis {
  xLeft,
  xRight,
  yFront,
  yBack,
  zBottom,
  zTop,
}

//#endregion bounds and intersection

//#region files, image and URLs

export function getStorageDirctory(type: StorageType, id: number) {
  const shard = Math.ceil(id / 512);
  return `${import.meta.env.MODE == "development" ? "http://localhost:3000/files" : "https://files.boardgameinserts.xyz"}/${type}/${shard}/${id}`;
}

export function imageUrlFromId(type: StorageType, id: number, name: string) {
  return getStorageDirctory(type, id) + "/" + name;
}

export function downloadBlob(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = "none";
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
}

export function sanatizeFileName(fileName: string, keepFileExtension = false) {
  fileName = fileName.toLowerCase();
  const extensionIndex = fileName.lastIndexOf(".");
  if(!keepFileExtension) {
    if(extensionIndex != -1) {
      fileName = fileName.substring(0, extensionIndex);
    }
    fileName = fileName.replace(/[^a-zA-Z0-9-]/g, "");
  } else {
    if(extensionIndex != -1) {
      fileName = fileName.substring(0, extensionIndex).replace(/[^a-zA-Z0-9-]/g, "") + fileName.substring(extensionIndex).toLowerCase();
    } else {
      fileName =  fileName.replace(/[^a-zA-Z0-9-]/g, "");
    }
  }
  return fileName;
}

export async function getImageData(imageData: string) {
  const image = new Image();
  await new Promise(r => { image.onload = r; image.src = imageData; });
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const context = canvas.getContext('2d', {
    alpha: false,
  })!;
  context.drawImage(image, 0, 0);
  return context.getImageData(0, 0, image.width, image.height);
}

export function displayImage(canvas: HTMLCanvasElement, data: ImageData) {
  const context = canvas.getContext('2d');
  if(context !== null) {
    context.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = data.width;
    canvas.height = data.height;
    context.putImageData(data, 0, 0);
  }
}

//#endregion files, image and URLs

export function addTrayMessage(tray: Draft<Tray>, type: "error" | "warning" | "info", key: string, content: string) {
  const existing = tray.messages.find(m => m.key === key);
  if(existing) {
    existing.content = content;
    existing.type = type;
  } else {
    tray.messages.push({
      type: type,
      key: key,
      content: content,
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getResponseError(response: any) {
  if(response.error !== undefined) {
    return String(response.error.data.error);
  }
  return null;
}

export function printable(value: unknown, options?: {
  maximumFractionDigits: number,
}) {
  if(typeof value === "number") {
    return value.toLocaleString("en-US", {
      maximumFractionDigits: options?.maximumFractionDigits ?? 2,
    });
  } else if(typeof value === "string") {
    return value;
  }
  return String(value);
}

export function calculateBlueprintHash(blueprint: Blueprint) {
  return objectHash(blueprint, {
    excludeKeys: key => key == "id" || 
                        key == "name" || 
                        key == "version" || 
                        key == "library" || 
                        key == "modelHash",
  });
}

export function calculateTrayHash(tray: Tray) {
  return objectHash(tray, {
    excludeKeys: key => key == "id" || 
                        key == "name" || 
                        key == "blueprintId" || 
                        key == "color" || 
                        key == "associatedGameId" || 
                        key == "offset" || 
                        key == "type" || 
                        key == "ports" || 
                        key == "bounds" || 
                        key == "modelHash" || 
                        key == "messages" ||
                        key == "images",
  });
}

export function getPalletteColor(index: number) {
  switch(index % 15) {
    case 0: return "#be123c";
    case 1: return "#0891b2";
    case 2: return "#6d28d9";
    case 3: return "#ea580c";
    case 4: return "#65a30d";
    case 5: return "#ca8a04";
    case 6: return "#c026d3";
    case 7: return "#0d9488";
    case 8: return "#d97706";
    case 9: return "#15803d";
    case 10: return "#2563eb";
    case 11: return "#e11d48";
    case 12: return "#0e7490";
    case 13: return "#a21caf";
    default: return "#047857";
  }
}

export function getBlueprintContainerElements(tray: Tray, elements: { [id: number] : ElementBlueprint }, root: number) {
  const containers: {
    id: number,
    blueprint: ContainerElementBlueprint,
    element: TrayElement,
  }[] = [];
  const gather = (element: ElementBlueprint) => {
    if (element.type !== "pocket") {
      containers.push({
        id: element.id,
        blueprint: element,
        element: tray.elements[element.id],
      });
      for (const childId of element.childs) {
        gather(elements[childId]);
      }
    }
  };
  gather(elements[root]);
  return containers;
}

export function getBlueprintPocketElements(tray: Tray, elements: { [id: number] : ElementBlueprint }, root: number) {
  const pockets: {
    id: number,
    blueprint: PocketElementBlueprint,
    element: TrayElement,
    parent: ContainerElementBlueprint | null
  }[] = [];
  const gather = (element: ElementBlueprint, parent: ContainerElementBlueprint | null) => {
    if(element.type === "pocket") {
      pockets.push({
        id: element.id,
        blueprint: element,
        element: tray.elements[element.id],
        parent: parent
      });
    } else {
      for(const childId of element.childs) {
        gather(elements[childId], element);
      }
    }
  };
  gather(elements[root], null);
  return pockets;
}

export function usePrintRedraws() {
  const count = useRef(0);
  count.current++;
  console.info(count.current);
}
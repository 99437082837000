import { api } from '../store'

export interface AddCameraResponse {
  id: number,
}

export interface DbCamera {
  id: number,
  model: string,
  description: string,
  rating: number,
}

export interface DbCameraDetails {
  model: string,
  description: string,
  data: string,
  rating: number,
}

export interface DbListCameras {
  cameras: DbCamera[],
  page: number,
  remaining: number,
}

const camerasApi = api.injectEndpoints({
  endpoints: builder => ({
    listCameras: builder.query<DbListCameras, {
      page: number,
      count: number,
      ownerId?: number,
      model?: string,
    }>({
      query: (arg) => ({
        url: `/cameras?page=${arg.page}&count=${arg.count}${arg.ownerId !== undefined ? `&ownerId=${arg.ownerId}` : ""}${arg.model !== undefined ? `&model=${encodeURI(arg.model)}` : ""}`,
      }),
      providesTags: [ "cameras" ],
    }),
    getCamera: builder.query<DbCameraDetails, {
      id: number,
    }>({
      query: (arg) => ({
        url: `/cameras/${arg.id}`,
      }),
      providesTags: [ "cameras" ],
    }),
    rateCamera: builder.mutation<AddCameraResponse, {
      id: number,
      rating: "up" | "down",
    }>({
      query: (arg) => ({
        url: `/cameras/${arg.id}/rate`,
        method: "POST",
        params: {
          rating: arg.rating,
        },
      }),
      invalidatesTags: [ "printers" ],
    }),
    addCamera: builder.mutation<AddCameraResponse, {
      model: string,
      description: string,
      data: string,
    }>({
      query: (arg) => ({
        url: "/cameras/",
        method: "POST",
        body: arg,
      }),
      invalidatesTags: [ "cameras" ],
    }),
  }),
  overrideExisting: false,
})

export const { useAddCameraMutation, useListCamerasQuery, useGetCameraQuery, useLazyListCamerasQuery, useLazyGetCameraQuery, useRateCameraMutation } = camerasApi;
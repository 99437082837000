import { useAddPrinterMutation } from "@/state/api/printers";
import { useReporter } from "@/state/messages";
import { getResponseError } from "@/utils";
import { PlusCircledIcon } from "@radix-ui/react-icons";
import { Button } from "@ui/button";
import { DialogClose, DialogFooter, DialogTitle } from "@ui/dialog";
import { Input } from "@ui/input";
import { InputNumber } from "@ui/inputNumber";
import { Section } from "@ui/section";
import { SectionLabel } from "@ui/sectionLabel";
import { useState } from "react";

export function PrintersDialogCreate() {
  const [ name, setName] = useState("");
  const [ width, setWidth] = useState(250);
  const [ depth, setDepth] = useState(250);
  const [ addPrinter ] = useAddPrinterMutation();
  const reporter = useReporter();
  return (
    <>
    <DialogTitle>
      Add printer
    </DialogTitle>
    <SectionLabel>
      Printer name
    </SectionLabel>
    <Input value={name} onChange={e => setName(e.target.value)}/>
    <SectionLabel>
      Printbed width (X)
    </SectionLabel>
    <InputNumber min={100} value={width} setValue={v => setWidth(v)} unit="mm"/>
    <SectionLabel>
      Printbed depth (Y)
    </SectionLabel>
    <InputNumber min={100} value={depth} setValue={v => setDepth(v)} unit="mm"/>
    <DialogFooter>
      <DialogClose asChild>
        <Button variant="primary" onClick={async () => {
          const response = await addPrinter({
            name,
            x: width,
            y: depth,
          });
          const error = getResponseError(response);
          if(error !== null) {
            reporter.error(error);
          } else {
            reporter.info(`Printer "${name}" was added`);
          }
        }}>
          <PlusCircledIcon/>
          Add printer
        </Button>
      </DialogClose>
    </DialogFooter>
    </>
  )
}
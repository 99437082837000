import { Button } from "@ui/button";
import { useAppDispatch, useAppSelector } from "@/state/store";
import { Avatar, AvatarFallback } from "@ui/avatar";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@ui/dropdown-menu";
import { HTMLAttributes } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { endSession } from "@/state/session";
import { Switch } from "@ui/switch";
import { updateSettings } from "@/state/settings";
import { configurationKeyName } from "@/catalog";
import NavigationMenuLogin from "./NavigationMenuLogin";

interface NavigationMenuProfileProps extends HTMLAttributes<HTMLDivElement> {
  showLoginLogout: boolean,
}

export default function NavigationMenuProfile({ showLoginLogout, children, ...props} : NavigationMenuProfileProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const session = useAppSelector(s => s.session);
  const settingDarkMode = useAppSelector(s => s.settings.settingDarkMode);
  const showLoginDialog = searchParams.get("login") === "true" && session.type === "new";
  if (session.user !== undefined) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="group h-8 px-2">
            <div className="flex gap-2">
              {children}
            </div>
            <Avatar>
              <AvatarFallback className="border-2 border-primary bg-control group-hover:bg-background group-focus:bg-background">
                {session.user.name[0].toUpperCase()}
              </AvatarFallback>
            </Avatar>
            <ChevronDownIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end">
          <div className="flex items-center gap-2 p-2">
            <Avatar>
              <AvatarFallback>
                {session.user.name[0]}
              </AvatarFallback>
            </Avatar>
            {session.user.name}
          </div>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={() => {
              dispatch(updateSettings({
                settingDarkMode: !settingDarkMode,
              }));
            }}>
              <Switch checked={settingDarkMode}/>
              {configurationKeyName("settingDarkMode")}
            </DropdownMenuItem>
            <DropdownMenuItem>
              Profile
            </DropdownMenuItem>
            <DropdownMenuItem>
              My inserts
            </DropdownMenuItem>
            <DropdownMenuItem>
              Shared inserts
            </DropdownMenuItem>
            <DropdownMenuItem>
              Liked inserts
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem>
              Settings
            </DropdownMenuItem>
          <DropdownMenuItem onClick={() => {
            dispatch(endSession());
            navigate("/");
          }}>
            Log out
          </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  } else if(showLoginLogout) {
    return <NavigationMenuLogin dialogInitiallyOpen={showLoginDialog} redirectUrl={searchParams.get("redirect") ?? undefined}/>
  } else {
    <></>
  }
}
/* eslint-disable react/no-unknown-property */
import { useModifiers } from "@/components/useModifiers";
import { Bounds, TrayConnector, Vector } from "@/types";
import { Box, DragControls, } from "@react-three/drei";
import { useCallback, useLayoutEffect, useRef } from "react";
import { Euler, Matrix4, Quaternion, Vector3 } from "three";
import { selectTray, selectFromTray, useAppDispatch, useAppSelector } from "@/state/store";
import { modifyTray } from "@/state/model";
import { boundsIntersectAny, boundsIntersectX, boundsIntersectY, boundsIntersectZ, roundNumber, roundVector } from "@/utils";
import InsertCanvasHorizontalConnector from "./InsertCanvasHorizontalConnector";
import InsertCanvasVerticalConnector from "./InsertCanvasVerticalConnector";

function snapToCardinalAxis(value: number, angle: number) {
  if (Math.abs(value) < angle) {
    return 0;
  } else if (Math.abs(value - Math.PI / 2) < angle) {
    return Math.PI / 2;
  } else if (Math.abs(value - Math.PI) < angle || Math.abs(value + Math.PI) < angle) {
    return Math.PI;
  } else if (Math.abs(value + Math.PI / 2) < angle) {
    return -Math.PI / 2;
  }
  return value;
}

function roundConnector(connector: TrayConnector): TrayConnector {
  return {
    distance: roundNumber(connector.distance),
    offset: roundNumber(connector.offset),
    localOffset: roundNumber(connector.localOffset),
    startOrthogonalMin: roundNumber(connector.startOrthogonalMin),
    startOrthogonalMax: roundNumber(connector.startOrthogonalMax),
    endOrthogonalMin: connector.endOrthogonalMin !== undefined ? roundNumber(connector.endOrthogonalMin) : undefined,
    endOrthogonalMax: connector.endOrthogonalMax !== undefined ? roundNumber(connector.endOrthogonalMax) : undefined,
    z: roundNumber(connector.z),
  };
}

function getConnectors(bounds: Bounds[], trayOffset: Vector3, otherTrayIds: number[], width: number, height: number) {
  const right: TrayConnector = {
    distance: width,
    startOrthogonalMax: 0,
    startOrthogonalMin: height,
    offset: 0,
    localOffset: 0,
    z: 0,
  };
  const left: TrayConnector = {
    distance: width,
    startOrthogonalMax: 0,
    startOrthogonalMin: height,
    offset: 0,
    localOffset: 0,
    z: 0,
  };
  const top: TrayConnector = {
    distance: height,
    startOrthogonalMax: 0,
    startOrthogonalMin: width,
    offset: 0,
    localOffset: 0,
    z: 0,
  };
  const bottom: TrayConnector = {
    distance: height,
    startOrthogonalMax: 0,
    startOrthogonalMin: width,
    offset: 0,
    localOffset: 0,
    z: 0,
  };
  const startBounds = bounds.map<Bounds>(b => ({
    x: b.x,
    y: b.y,
    z: b.z,
    xOffset: roundNumber(b.xOffset + trayOffset.x),
    yOffset: roundNumber(b.yOffset + trayOffset.y),
    zOffset: roundNumber(b.zOffset + trayOffset.z),
  }));
  for (const start of startBounds) {
    for (const otherTrayId of otherTrayIds) {
      const otherTray = selectTray(otherTrayId);
      const endBounds = otherTray.bounds.map<Bounds>(b => ({
        x: b.x,
        y: b.y,
        z: b.z,
        xOffset: roundNumber(b.xOffset + otherTray.offset.x),
        yOffset: roundNumber(b.yOffset + otherTray.offset.y),
        zOffset: roundNumber(b.zOffset + otherTray.offset.z),
      }));
      if(boundsIntersectAny(startBounds, endBounds)) {
        continue;
      }
      for (const end of endBounds) {
        if(!boundsIntersectZ(start, end)) {
          continue;
        }
        if (boundsIntersectY(start, end)) {
          const rightDistance = roundNumber(end.xOffset - (start.xOffset + start.x));
          if (rightDistance >= 0) {
            if (rightDistance < right.distance) {
              right.offset = start.xOffset + start.x;
              right.startOrthogonalMin = start.yOffset;
              right.startOrthogonalMax = start.yOffset + start.y;
              right.endOrthogonalMin = end.yOffset;
              right.endOrthogonalMax = end.yOffset + end.y;
              right.distance = rightDistance;
              right.z = Math.max(start.zOffset + start.z, end.zOffset + end.z);
            } else if (rightDistance == right.distance && right.offset == start.xOffset + start.x) {
              right.startOrthogonalMin = Math.min(right.startOrthogonalMin, start.yOffset);
              right.startOrthogonalMax = Math.max(right.startOrthogonalMax, start.yOffset + start.y);
              right.endOrthogonalMin = Math.min(right.endOrthogonalMin!, end.yOffset);
              right.endOrthogonalMax = Math.max(right.endOrthogonalMax!, end.yOffset + end.y);
              right.z = Math.max(right.z, start.zOffset + start.z, end.zOffset + end.z);
            }
          } else {
            const leftDistance = roundNumber(start.xOffset - (end.xOffset + end.x));
            if (leftDistance >= 0) {
              if (leftDistance < left.distance) {
                left.offset = start.xOffset;
                left.startOrthogonalMin = start.yOffset;
                left.startOrthogonalMax = start.yOffset + start.y;
                left.endOrthogonalMin = end.yOffset;
                left.endOrthogonalMax = end.yOffset + end.y;
                left.distance = leftDistance;
                left.z = Math.max(start.zOffset + start.z, end.zOffset + end.z);
              } else if (leftDistance == left.distance && left.offset == start.xOffset) {
                left.startOrthogonalMin = Math.min(left.startOrthogonalMin, start.yOffset);
                left.startOrthogonalMax = Math.max(left.startOrthogonalMax, start.yOffset + start.y);
                left.endOrthogonalMin = Math.min(left.endOrthogonalMin!, end.yOffset);
                left.endOrthogonalMax = Math.max(left.endOrthogonalMax!, end.yOffset + end.y);
                left.z = Math.max(left.z, start.zOffset + start.z, end.zOffset + end.z);
              }
            }
          }
        }
        if (boundsIntersectX(start, end)) {
          const topDistance = roundNumber(end.yOffset - (start.yOffset + start.y));
          if (topDistance >= 0) {
            if (topDistance < top.distance) {
              top.offset = start.yOffset + start.y;
              top.startOrthogonalMin = start.xOffset;
              top.startOrthogonalMax = start.xOffset + start.x;
              top.endOrthogonalMin = end.xOffset;
              top.endOrthogonalMax = end.xOffset + end.x;
              top.distance = topDistance;
              top.z = Math.max(start.zOffset + start.z, end.zOffset + end.z);
            } else if (topDistance == top.distance && top.offset == start.yOffset + start.y) {
              top.startOrthogonalMin = Math.min(top.startOrthogonalMin, start.xOffset);
              top.startOrthogonalMax = Math.max(top.startOrthogonalMax, start.xOffset + start.x);
              top.endOrthogonalMin = Math.min(top.endOrthogonalMin!, end.xOffset);
              top.endOrthogonalMax = Math.max(top.endOrthogonalMax!, end.xOffset + end.x);
              top.z = Math.max(top.z, start.zOffset + start.z, end.zOffset + end.z);
            }
          } else {
            const bottomDistance = roundNumber(start.yOffset - (end.yOffset + end.y));
            if (bottomDistance >= 0) {
              if (bottomDistance < bottom.distance) {
                bottom.offset = start.yOffset;
                bottom.startOrthogonalMin = start.xOffset;
                bottom.startOrthogonalMax = start.xOffset + start.x;
                bottom.endOrthogonalMin = end.xOffset;
                bottom.endOrthogonalMax = end.xOffset + end.x;
                bottom.distance = bottomDistance;
                bottom.z = Math.max(start.zOffset + start.z, end.zOffset + end.z);
              } else if (bottomDistance == bottom.distance && bottom.offset == start.yOffset) {
                bottom.startOrthogonalMin = Math.min(bottom.startOrthogonalMin, start.xOffset);
                bottom.startOrthogonalMax = Math.max(bottom.startOrthogonalMax, start.xOffset + start.x);
                bottom.endOrthogonalMin = Math.min(bottom.endOrthogonalMin!, end.xOffset);
                bottom.endOrthogonalMax = Math.max(bottom.endOrthogonalMax!, end.xOffset + end.x);
                bottom.z = Math.max(bottom.z, start.zOffset + start.z, end.zOffset + end.z);
              }
            }
          }
        }
      }
    }
    const rightDistance = width - (start.xOffset + start.x);
    if (rightDistance < right.distance) {
      right.offset = start.xOffset + start.x;
      right.localOffset = right.offset - trayOffset.x;
      right.startOrthogonalMin = start.yOffset;
      right.startOrthogonalMax = start.yOffset + start.y;
      right.distance = rightDistance;
      right.z = start.zOffset + start.z;
    } else if (rightDistance == right.distance && right.offset == start.xOffset + start.x) {
      right.startOrthogonalMin = Math.min(right.startOrthogonalMin, start.yOffset);
      right.startOrthogonalMax = Math.max(right.startOrthogonalMax, start.yOffset + start.y);
      right.z = Math.max(right.z, start.zOffset + start.z);
    }
    const leftDistance = start.xOffset;
    if (leftDistance < left.distance) {
      left.offset = start.xOffset;
      left.startOrthogonalMin = start.yOffset;
      left.startOrthogonalMax = start.yOffset + start.y;
      left.distance = leftDistance;
      left.z = start.zOffset + start.z;
    } else if (leftDistance == left.distance && left.offset == start.xOffset) {
      left.startOrthogonalMin = Math.min(left.startOrthogonalMin, start.yOffset);
      left.startOrthogonalMax = Math.max(left.startOrthogonalMax, start.yOffset + start.y);
      left.z = Math.max(left.z, start.zOffset + start.z);
    }
    const topDistance = height - (start.yOffset + start.y);
    if (topDistance < top.distance) {
      top.offset = start.yOffset + start.y;
      top.startOrthogonalMin = start.xOffset;
      top.startOrthogonalMax = start.xOffset + start.x;
      top.distance = topDistance;
      top.z = start.zOffset + start.z;
    } else if (topDistance == top.distance && top.offset == start.yOffset + start.y) {
      top.startOrthogonalMin = Math.min(top.startOrthogonalMin, start.xOffset);
      top.startOrthogonalMax = Math.max(top.startOrthogonalMax, start.xOffset + start.x);
      top.z = Math.max(top.z, start.zOffset + start.z);
    }
    const bottomDistance = start.yOffset;
    if (bottomDistance < bottom.distance) {
      bottom.offset = start.yOffset;
      bottom.startOrthogonalMin = start.xOffset;
      bottom.startOrthogonalMax = start.xOffset + start.x;
      bottom.distance = bottomDistance;
      bottom.z = start.zOffset + start.z;
    } else if (bottomDistance == bottom.distance && bottom.offset == start.yOffset) {
      bottom.startOrthogonalMin = Math.min(bottom.startOrthogonalMin, start.xOffset);
      bottom.startOrthogonalMax = Math.max(bottom.startOrthogonalMax, start.xOffset + start.x);
      bottom.z = Math.max(bottom.z, start.zOffset + start.z);
    }
  }
  right.localOffset = right.offset - trayOffset.x;
  left.localOffset = left.offset - trayOffset.x;
  top.localOffset = top.offset - trayOffset.y;
  bottom.localOffset = bottom.offset - trayOffset.y;
  return {
    right: roundConnector(right),
    left: roundConnector(left),
    top: roundConnector(top),
    bottom: roundConnector(bottom),
  };
}

export default function InsertCanvasManipulator({ selectedTrayId, otherTrayIds, insertBounds, setPosition, setRotation, position, rotation }: {
  selectedTrayId: number,
  otherTrayIds: number[],
  insertBounds: Vector,
  position: Vector3,
  rotation: Euler,
  setPosition: (position: Vector3) => void,
  setRotation: (rotation: Euler) => void,
}) {
  const dispatch = useAppDispatch();
  const modifiers = useModifiers();
  const matrix = useRef(new Matrix4());
  const offset = useAppSelector(s => selectFromTray(s, selectedTrayId, t => t.offset));
  const size = useAppSelector(s => selectFromTray(s, selectedTrayId, t => t.size));
  const bounds = useAppSelector(s => selectFromTray(s, selectedTrayId, t => t.bounds));
  const snapDistance = useAppSelector(s => s.settings.settingCanvasSnapDistance);
  useLayoutEffect(() => {
    setPosition(new Vector3(offset.x, offset.y, offset.z));
    // TODO set rotation
  }, [ offset ])
  useLayoutEffect(() => {
    matrix.current.compose(position, new Quaternion().setFromEuler(rotation), new Vector3(1, 1, 1));
  }, [position, rotation]);
  const connectors = getConnectors(bounds, position, otherTrayIds, insertBounds.x, insertBounds.y);
  const connectorAction = useCallback((type: "fill" | "snap", axis: "X" | "Y" | "Z", distance: number, negative: boolean) => {
    if (type === "fill") {
      dispatch(modifyTray({
        tray: selectedTrayId,
        size: {
          x: roundNumber(size.x + (axis === "X" ? distance : 0)),
          y: roundNumber(size.y + (axis === "Y" ? distance : 0)),
          z: roundNumber(size.z + (axis === "Z" ? distance : 0)),
        },
        offset: {
          x: roundNumber(offset.x - (negative && axis === "X" ? distance : 0)),
          y: roundNumber(offset.y - (negative && axis === "Y" ? distance : 0)),
          z: roundNumber(offset.z - (negative && axis === "Z" ? distance : 0)),
        }
      }));
    } else {
      dispatch(modifyTray({
        tray: selectedTrayId,
        offset: {
          x: roundNumber(offset.x + (axis === "X" ? (negative ? -distance : distance) : 0)),
          y: roundNumber(offset.y + (axis === "Y" ? (negative ? -distance : distance) : 0)),
          z: roundNumber(offset.z + (axis === "Z" ? (negative ? -distance : distance) : 0)),
        },
      }));
    }
  }, [selectedTrayId, offset, size]);
  return (
    <>
      <DragControls matrix={matrix.current} autoTransform={false} axisLock="z" onDrag={(localMatrix) => {
          const dragPosition = new Vector3();
          dragPosition.setFromMatrixPosition(localMatrix);
          if (modifiers.shift) {
            dragPosition.x -= dragPosition.x % 5;
          }
          if(Math.abs(connectors.left.distance + (dragPosition.x - position.x)) < snapDistance) {
            dragPosition.x = connectors.left.offset - connectors.left.distance - connectors.left.localOffset;
          }
          if(Math.abs(connectors.right.distance + (position.x - dragPosition.x)) < snapDistance) {
            dragPosition.x = connectors.right.offset + connectors.right.distance - connectors.right.localOffset;
          }
          if(Math.abs(connectors.bottom.distance + (dragPosition.y - position.y)) < snapDistance) {
            dragPosition.y = connectors.bottom.offset - connectors.bottom.distance - connectors.bottom.localOffset;
          }
          if(Math.abs(connectors.top.distance + (position.y - dragPosition.y)) < snapDistance) {
            dragPosition.y = connectors.top.offset + connectors.top.distance - connectors.top.localOffset;
          }
          if (dragPosition.x < 0) {
            dragPosition.x = 0;
          } else if (dragPosition.x + size.x > insertBounds.x) {
            dragPosition.x = insertBounds.x - size.x;
          }
          if (modifiers.shift) {
            dragPosition.y -= dragPosition.y % 5;
          }
          if (dragPosition.y < 0) {
            dragPosition.y = 0;
          } else if (dragPosition.y + size.y > insertBounds.y) {
            dragPosition.y = insertBounds.y - size.y;
          }
          setPosition(dragPosition);
          const rotation = new Euler();
          rotation.setFromRotationMatrix(localMatrix);
          rotation.z = snapToCardinalAxis(rotation.z, Math.PI / 14);
          matrix.current.compose(dragPosition, new Quaternion().setFromEuler(rotation), new Vector3(1, 1, 1));
        }} onDragEnd={() => {
          const position = new Vector3();
          position.setFromMatrixPosition(matrix.current);
          const rotation = new Euler();
          rotation.setFromRotationMatrix(matrix.current);
          rotation.z = snapToCardinalAxis(rotation.z, Math.PI / 4);
          matrix.current.compose(position, new Quaternion().setFromEuler(rotation), new Vector3(1, 1, 1));
          dispatch(modifyTray({
            tray: selectedTrayId,
            offset: roundVector({
              x: position.x,
              y: position.y,
              z: position.z,
            }),
          }));
        }}>
          {bounds.map(b => (
            <Box key={bounds.indexOf(b)} position={[b.xOffset + b.x / 2, b.yOffset + b.y / 2, b.zOffset + b.z / 2]} scale={[b.x, b.y, b.z]}>
              <meshBasicMaterial transparent={true} opacity={0} depthTest={false}/>
            </Box>
          ))}
      </DragControls>
      {connectors.right.distance > 0 && (
        <InsertCanvasHorizontalConnector connector={connectors.right} negative={false} insertHeight={insertBounds.z} action={connectorAction} />
      )}
      {connectors.left.distance > 0 && (
        <InsertCanvasHorizontalConnector connector={connectors.left} negative={true} insertHeight={insertBounds.z} action={connectorAction} />
      )}
      {connectors.top.distance > 0 && (
        <InsertCanvasVerticalConnector connector={connectors.top} negative={false} insertHeight={insertBounds.z} action={connectorAction} />
      )}
      {connectors.bottom.distance > 0 && (
        <InsertCanvasVerticalConnector connector={connectors.bottom} negative={true} insertHeight={insertBounds.z} action={connectorAction} />
      )}
    </>
  );
}
import { addPocketElement, deleteElement, select } from "@/state/model";
import { selectCurrentElementId, useAppDispatch, useAppSelector } from "@/state/store";
import { Button } from "@ui/button";
import { ColumnSpacingIcon, CopyIcon, DashboardIcon, MarginIcon, TrashIcon } from "@radix-ui/react-icons";
import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from "@ui/popover";
import PocketLibrary from "./PocketLibrary";
import { useState } from "react";
import { PocketElementBlueprint } from "@/types";
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from "@dnd-kit/sortable";

export default function PocketElementTreeNode({ element, parentElementId, blueprintId, isSelected }: { element: PocketElementBlueprint, blueprintId: number, parentElementId: number, isSelected: boolean }) {
  const dispatch = useAppDispatch();
  const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({
    id: element.id,
  });
  return (
    <div ref={setNodeRef} style={{
      transform: CSS.Translate.toString(transform),
      transition,
    }} className={"bg-background mb-[-1px] rounded-l-lg border peer" + (isSelected ? " bg-control hover:bg-control" : " hover:bg-accent") + (isDragging ? " z-50" : "")}>
      <div className="flex cursor-pointer flex-row items-center p-0.5">
        <button {...listeners} {...attributes}>
          {element.pocketType === "tokens-spacer" ? (
            <ColumnSpacingIcon className={isSelected ? "ml-1 text-primary" : "mx-1"}/>
            ) : (
            <MarginIcon className={isSelected ? "ml-1 text-primary" : "mx-1"}/>
          )}
        </button>
        <Button className="flex-auto select-none justify-start px-0" variant="ghost" onClick={() => dispatch(select({
          scope: "blueprint",
          aspect: "element",
          blueprintId: blueprintId,
          elementId: element.id,
        }))}>
          <div className={isSelected ? "text-primary" : ""}>
            {element.name}
          </div>
        </Button>
        <Button onClick={() => dispatch(addPocketElement({
          parent: parentElementId,
          element: element,
          name: element.name + " - Copy",
        }))} variant="ghost" className="px-2">
          <CopyIcon />
        </Button>
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="ghost" className="px-2">
              <DashboardIcon />
            </Button>
          </PopoverTrigger>
          <PopoverContent side="left" align="center">
            <PocketLibrary element={element} />
            <PopoverArrow/>
          </PopoverContent>
        </Popover>
        <Button onClick={() => dispatch(deleteElement({
          element: element.id,
        }))} variant="ghost" className="px-2">
          <TrashIcon />
        </Button>
      </div>
    </div>
  );
}
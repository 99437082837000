import { Button } from "@ui/button";
import { Section } from "@ui/section";
import { SectionLabel } from "@ui/sectionLabel";
import { SectionDescription } from "@ui/sectionDescription";
import { SectionHeader } from "@ui/sectionHeader";
import { PageHeader } from "@ui/pageHeader";
import { Placeholder } from "@ui/placeholder";
import { Failure } from "@ui/failure";
import { useReporter } from "@/state/messages";
import { PartColors, ToolColors } from "@/catalog";
import { ColorPicker } from "@ui/colorPicker";
import { useState } from "react";
import { Separator } from "@ui/separator";
import { ScrollArea } from "@ui/scroll-area";
import { Switch } from "@ui/switch";
import { Spinner } from "@ui/spinner";
import { CheckCircledIcon } from "@radix-ui/react-icons";

export default function Home() {
  const reporter = useReporter();
  const [color, setColor] = useState("#0ea5e9");
  return (
    <div className="flex h-full flex-col gap-2 pt-2">
      <PageHeader>
        <SectionHeader>
          Page header, start upperscore, no notations
        </SectionHeader>
        <SectionDescription>
          Page description for long explanations and hints. Fill sentences with notations.
        </SectionDescription>
      </PageHeader>
      <ScrollArea className="flex-auto">
        <div className="flex flex-col gap-2">
          <Section>
            <SectionLabel>
              Tool colors
            </SectionLabel>
            <div className="flex flex-wrap gap-2">
              { ToolColors.map(c => <div key={c} className="size-20" style={{
                backgroundColor: c,
              }}/>)}
            </div>
          </Section>
          <Section>
            <SectionLabel>
              Part colors
            </SectionLabel>
            <div className="flex flex-wrap gap-2">
              { PartColors.map(c => <div key={c} className="size-20" style={{
                backgroundColor: c,
              }}/>)}
              <Separator/>
              <div className="size-20 bg-[#0ea5e9]"/>
              <div className="size-20 bg-[#1cac52]"/>
              <div className="size-20 bg-[#e11d48]"/>
              <div className="size-20 bg-[#976d97]"/>
              <div className="size-20 bg-[#D96941]"/>
              <div className="size-20 bg-[#F2668B]"/>
              <div className="size-20 bg-[#35a093]"/>
              <div className="size-20 bg-[#c026d3]"/>
            </div>
          </Section>
          <div className="flex gap-2">
            <div className="flex flex-1 flex-col gap-2">
              <SectionHeader>
                Header above section
              </SectionHeader>
              <Section>
                <SectionLabel>
                  Section label, start upperscore, no notations
                </SectionLabel>
                <SectionDescription>
                  Section details for long explanations and hints. Fill sentences with notations.
                </SectionDescription>
              </Section>

              <Section header={
                <Button>
                  Button in section header
                </Button>
              }>
                <SectionLabel>
                  Label
                </SectionLabel>
                Content
              </Section>

              <Section footer={
                <Button>
                  Button in section footer
                </Button>
              }>
                <SectionLabel>
                  Label
                </SectionLabel>
                Content
              </Section>

              <Section header={
                <Button>
                  Button in section header
                </Button>
              } footer={
                <Button>
                  Button in section footer
                </Button>
              }>
                <SectionLabel>
                  Label
                </SectionLabel>
                Content
              </Section>
              <Section>
                <SectionLabel>
                  Messages
                </SectionLabel>
                <Button onClick={() => reporter.info("Demo info")}>
                  Add info message
                </Button>
                <Button onClick={() => reporter.warning("Demo warning")}>
                  Add warning message
                </Button>
                <Button onClick={() => reporter.error("Demo error")}>
                  Add error message
                </Button>
                <Button onClick={() => {
                  const close = reporter.widget(<div className="flex items-center gap-2">
                  <Spinner/>
                  Duration
                  </div>, false, "neutral");
                  setTimeout(close, 2000);
                }}>
                  Add background task
                </Button>
                <Button onClick={() => {
                  reporter.widget(<div className="flex items-center gap-2">
                  <CheckCircledIcon/>
                  Great job, that worked!
                  </div>, true, "success");
                }}>
                  Add custom widget
                </Button>
              </Section>
              <ColorPicker color={color} onColorChange={c => setColor(c)} swatches={PartColors}/>
              <Section>
                <div className="flex items-center gap-2">
                  <Switch/>
                  Some switch
                </div>
              </Section>
            </div>
            <div className="flex flex-1 flex-col gap-2">
              <Placeholder>
                Loading...
              </Placeholder>
              <Placeholder type="info">
                Information
              </Placeholder>
              <Section>
                <div className="flex gap-2">
                  <Section nested className="flex-1">
                    <SectionLabel>
                      Buttons enabled
                    </SectionLabel>
                    <Button variant="destructive">destructive</Button>
                    <Button variant="ghost">ghost</Button>
                    <Button variant="link">link</Button>
                    <Button variant="outline">outline</Button>
                    <Button variant="primary">primary</Button>
                    <Button variant="secondary">secondary</Button>
                    <Button variant="selector">selector</Button>
                    <Button variant="selector_primary">selector_primary</Button>
                  </Section>
                  <Section nested className="flex-1">
                    <SectionLabel>
                      Buttons disabled
                    </SectionLabel>
                    <Button disabled variant="destructive">destructive</Button>
                    <Button disabled variant="ghost">ghost</Button>
                    <Button disabled variant="link">link</Button>
                    <Button disabled variant="outline">outline</Button>
                    <Button disabled variant="primary">primary</Button>
                    <Button disabled variant="secondary">secondary</Button>
                    <Button disabled variant="selector">selector</Button>
                    <Button disabled variant="selector_primary">selector_primary</Button>
                  </Section>
                </div>
              </Section>
              <Failure>
                Failure
              </Failure>
            </div>
          </div>
        </div>
      </ScrollArea>
    </div>
  );
}
import { CanvasProps as ThreeCanvasProps, GroupProps, useThree } from "@react-three/fiber";
import { forwardRef, ReactNode, Suspense, useEffect, useImperativeHandle } from "react";
import { Canvas as ThreeCanvas } from "@react-three/fiber";
import * as THREE from "three";
import { Skeleton } from "@ui/skeleton";
import { OrthographicCamera, PerspectiveCamera } from "@react-three/drei";

export interface CanvasHandle {
  setSize: (width: number, height: number) => void,
  captureCanvas: () => string,
}

export interface CanvasProps extends ThreeCanvasProps {
  isOrthographic: boolean,
  initialCameraPosition?: THREE.Vector3,
}

interface CanvasWrapperProps {
  children: ReactNode,
  isOrthographic: boolean,
  initialCameraPosition: THREE.Vector3,
}

const CanvasWrapper = forwardRef<CanvasHandle, CanvasWrapperProps>(
  ({ isOrthographic, initialCameraPosition, children }, ref) => {
  const { gl, scene, camera } = useThree();
  useImperativeHandle(ref, () => ({
    setSize: (width, height) => {
      gl.setSize(width, height);
    },
    captureCanvas: () => {
      gl.render(scene, camera);
      return gl.domElement.toDataURL();
    },
  }), [ gl, scene, camera]);
  return (
    <>
      {isOrthographic ? (
        <OrthographicCamera makeDefault position={initialCameraPosition} />
      ) : (
        <PerspectiveCamera makeDefault position={initialCameraPosition} />
      )}
      {children}
    </>
  );
});
CanvasWrapper.displayName = "CanvasWrapper";

const Canvas = forwardRef<CanvasHandle, CanvasProps>(
  ({ children, isOrthographic, initialCameraPosition = new THREE.Vector3(0, -50, 100), onCreated, ...props }, ref) => {
  useEffect(() => {
    // use z as up
    THREE.Object3D.DEFAULT_UP.set(0, 0, 1);
  }, []);
  const dpr = Math.min(window.devicePixelRatio, 2);
  return (
    <ThreeCanvas className="rounded border bg-control" dpr={dpr} onCreated={state => {
      state.gl.localClippingEnabled = true;
      if(onCreated !== undefined) {
        onCreated(state);
      }
    }} {...props}>
      <CanvasWrapper isOrthographic={isOrthographic} ref={ref} initialCameraPosition={initialCameraPosition}>
        {children}
      </CanvasWrapper>
    </ThreeCanvas>
  );
});
Canvas.displayName = "Canvas";

export default Canvas;
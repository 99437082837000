import { api } from '../store'

export interface AddPrinterResponse {
  id: number,
}

export interface DbPrinter {
  id: number,
  name: string,
  x: number,
  y: number,
  rating: number,
}

const printersApi = api.injectEndpoints({
  endpoints: builder => ({
    addPrinter: builder.mutation<AddPrinterResponse, {
      name: string,
      x: number,
      y: number,
    }>({
      query: (arg) => ({
        url: "/printers/",
        method: "POST",
        body: arg,
      }),
      invalidatesTags: [ "printers" ],
    }),
    listPrinters: builder.query<DbPrinter[], {
      search?: string,
    }>({
      query: (arg) => ({
        url: `/printers`,
        params: arg,
        
      }),
      providesTags: [ "printers" ],
    }),
    searchPrinters: builder.query<DbPrinter[], {
      search: string,
    }>({
      query: (arg) => ({
        url: `/printers`,
        params: arg,
        
      }),
      // no tags, search must not be refetched
    }),
    getPrinter: builder.query<DbPrinter, {
      id: number,
    }>({
      query: (arg) => ({
        url: `/printers/${arg.id}`,
      }),
      providesTags: [ "printers" ],
    }),
    ratePrinter: builder.mutation<AddPrinterResponse, {
      id: number,
      rating: "up" | "down",
    }>({
      query: (arg) => ({
        url: `/printers/${arg.id}/rate`,
        method: "POST",
        params: {
          rating: arg.rating,
        },
      }),
      invalidatesTags: [ "printers" ],
    }),
  }),
  overrideExisting: false,
})

export const { useListPrintersQuery, useLazySearchPrintersQuery, useLazyGetPrinterQuery, useAddPrinterMutation, useRatePrinterMutation } = printersApi;
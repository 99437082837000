/* eslint-disable react/no-unknown-property */
import { GeometryEdge, GeometryFace, TrayModel, Vector, ClippingPlaneConfiguration, OrientedAxis } from "@/types"
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { AngleIcon, CopyIcon, CornersIcon, Crosshair2Icon, CursorArrowIcon, GearIcon, QuestionMarkCircledIcon, RulerHorizontalIcon, RulerSquareIcon, StackIcon, TrashIcon } from "@radix-ui/react-icons";
import { Switch } from "@ui/switch";
import { Label } from "@ui/label";
import { Button } from "@ui/button";
import CanvasEdgeInfo from "../canvas/CanvasEdgeInfo";
import CanvasFaceInfo from "../canvas/CanvasFaceInfo";
import CanvasPointInfo from "../canvas/CanvasPointInfo";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@ui/popover"
import { Box, Line, Sphere, Text } from "@react-three/drei";
import BoxInfo from "../canvas/BoxInfo";
import BoxFootprint from "../canvas/BoxFootprint";
import Skeleton from "../canvas/Skeleton";
import { selectCurrentElement, selectElementParent, selectTraysForBlueprint, useAppDispatch, useAppSelector } from "@/state/store";
import { configurationKeyName } from "@/catalog";
import { addPocketElement, clearTrayMessages, deleteElement, select } from "@/state/model";
import Canvas from "../canvas/Canvas";
import roboto from '../../../../public/fonts/RobotoMono-Regular.ttf?url'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@ui/select";
import BlueprintCanvasTray from "./BlueprintCanvasTray";
import DesignerMessagesBar from "../DesignerMessagesBar";
import { shallowEqual } from "react-redux";
import { getBlueprintContainerElements, getBlueprintPocketElements, printable } from "@/utils";
import InsertJobs from "../insert/InsertJobs";
import Ground from "../canvas/Ground";
import { Stage, StageHandle } from "../canvas/Stage";
import { updateSettings } from "@/state/settings";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";
import { Hotkey } from "@ui/hotkey";
import { useHotkeys } from "react-hotkeys-hook";
import { Slider } from "@ui/slider";
import { InputNumber } from "@ui/inputNumber";
import { Box3, Intersection, Object3D, Object3DEventMap, Plane, Ray, Raycaster, Vector2, Vector3 } from "three";
import { ButtonClose } from "@ui/buttonClose";
import { ThreeEvent } from "@react-three/fiber";
import { ReplicadShape } from "../canvas/ReplicadShape";
import ClipPlane from "../canvas/ClipPlane";
import { ViewcubeGizmo } from "../canvas/Gizmo";

export enum CanvasMode {
  Select,
  Measure,
}

export interface FaceSelection {
  type: "face",
  shapeName: string,
  index: number,
  face: GeometryFace,
}

export interface EdgeSelection {
  type: "edge",
  shapeName: string,
  index: number,
  edge: GeometryEdge,
}

export interface PointSelection {
  type: "point",
  point: Vector,
}

export type Selection = FaceSelection | EdgeSelection | PointSelection;

function selectionDiffers(a: Selection, b: Selection) {
  if (a.type == "edge" && b.type == "edge") {
    if (a.shapeName === b.shapeName && a.index === b.index) {
      return false;
    }
  }
  if (a.type == "face" && b.type == "face") {
    if (a.shapeName === b.shapeName && a.index == b.index) {
      return false;
    }
  }
  if (a.type == "point" && b.type == "point") {
    if (a.point.x === b.point.x && a.point.y === b.point.y && a.point.z === b.point.z) {
      return false;
    }
  }
  return true;
}

function ClippingTools({ clippingPlaneConfiguration, setClippingPlaneConfiguration, bounds, close }: { clippingPlaneConfiguration: ClippingPlaneConfiguration | undefined, setClippingPlaneConfiguration: (configuration: ClippingPlaneConfiguration | undefined) => void, bounds?: Box3, close: () => void }) {
  const calculateMaxOffset = useCallback((axis?: OrientedAxis) => {
    if (axis === undefined || bounds === undefined) {
      return 0;
    }
    if (axis === OrientedAxis.xLeft || axis === OrientedAxis.xRight) {
      return bounds.max.x - bounds.min.x;
    } else if (axis === OrientedAxis.yBack || axis === OrientedAxis.yFront) {
      return bounds.max.y - bounds.min.y;
    } else {
      return bounds.max.z - bounds.min.z;
    }
  }, [clippingPlaneConfiguration, bounds]);
  const maxOffset = useMemo(() => calculateMaxOffset(clippingPlaneConfiguration?.axis), [clippingPlaneConfiguration]);
  return (
    <div className="relative flex flex-col gap-2 rounded border bg-control p-2">
      <Label>
        Clip from
      </Label>
      <Select value={clippingPlaneConfiguration?.axis.toString()} onValueChange={v => {
        if (v === "-1") {
          setClippingPlaneConfiguration(undefined);
        } else {
          const axis: OrientedAxis = parseInt(v);
          const maxOffset = calculateMaxOffset(axis);
          setClippingPlaneConfiguration({
            axis,
            offset: Math.min(maxOffset, clippingPlaneConfiguration?.offset ?? 5),
            maxOffset,
          });
        }
      }}>
        <SelectTrigger>
          <SelectValue placeholder="Select axis" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="1">
            Left
          </SelectItem>
          <SelectItem value="0">
            Right
          </SelectItem>
          <SelectItem value="3">
            Front
          </SelectItem>
          <SelectItem value="2">
            Back
          </SelectItem>
          <SelectItem value="5">
            Bottom
          </SelectItem>
          <SelectItem value="4">
            Top
          </SelectItem>
        </SelectContent>
      </Select>
      {clippingPlaneConfiguration !== undefined && (
        <>
          <InputNumber min={0} max={maxOffset} value={clippingPlaneConfiguration.offset} setValue={v => setClippingPlaneConfiguration({
            axis: clippingPlaneConfiguration.axis,
            offset: v,
            maxOffset,
          })} unit="mm" />

          <Slider min={0} max={maxOffset} value={[clippingPlaneConfiguration.offset]} onValueChange={v => setClippingPlaneConfiguration({
            axis: clippingPlaneConfiguration.axis,
            offset: v[0],
            maxOffset,
          })} />
        </>
      )}
      <ButtonClose onClick={() => {
        setClippingPlaneConfiguration(undefined);
        close();
      }} />
    </div>
  );
}

function getParallelPlaneProjection(lastPoint: SelectionPoint, currentPoint: SelectionPoint) {
  if (lastPoint.normal !== undefined && currentPoint.normal !== undefined) {
    if (currentPoint.normal.clone().cross(lastPoint.normal).length() < 0.001) {
      const plane = new Plane().setFromNormalAndCoplanarPoint(lastPoint.normal, lastPoint.position);
      const projection = plane.projectPoint(currentPoint.position, new Vector3());
      if (projection.clone().sub(currentPoint.position).length() < 0.001) {
        return null;
      }
      return projection;
    }
  }
  return null;
}

function SelectionVisualizerLink({ lastPoint, currentPoint }: { lastPoint: SelectionPoint, currentPoint: SelectionPoint }) {
  const dx = currentPoint.position.x - lastPoint.position.x;
  const dy = currentPoint.position.y - lastPoint.position.y;
  const dz = currentPoint.position.z - lastPoint.position.z;
  const vx = new Vector3(lastPoint.position.x + dx, lastPoint.position.y, lastPoint.position.z);
  const vz = new Vector3(lastPoint.position.x + dx, lastPoint.position.y, lastPoint.position.z + dz);
  const pp = getParallelPlaneProjection(lastPoint, currentPoint);
  return (
    <>
      <Line points={[lastPoint.position, currentPoint.position]} color="white" depthTest={false} lineWidth={1}>
        <meshBasicMaterial />
      </Line>
      {dx !== 0 && (
        <>
          <Line points={[lastPoint.position, vx]} color="#F87171" depthTest={false} lineWidth={2}>
            <meshBasicMaterial />
          </Line>
          <Sphere position={vx} args={[0.1]} renderOrder={2}>
            <meshMatcapMaterial color="white" depthTest={false} />
          </Sphere>
        </>
      )}
      {dz !== 0 && (
        <Line points={[vx, vz]} color="#60A5FA" depthTest={false} lineWidth={2}>
          <meshBasicMaterial />
        </Line>
      )}
      {dy !== 0 && (
        <>
          <Line points={[vz, currentPoint.position]} color="#22c55e" depthTest={false} lineWidth={2}>
            <meshBasicMaterial />
          </Line>
          <Sphere position={vz} args={[0.1]} renderOrder={2}>
            <meshMatcapMaterial color="white" depthTest={false} />
          </Sphere>
        </>
      )}
      {pp !== null && (
        <>
          <Line points={[currentPoint.position, pp]} color="#d946ef" depthTest={false} lineWidth={2}>
            <meshBasicMaterial />
          </Line>
          <Sphere position={pp} args={[0.1]} renderOrder={2}>
            <meshMatcapMaterial color="white" depthTest={false} />
          </Sphere>
          <Line points={[pp, lastPoint.position]} color="#d946ef" depthTest={false} lineWidth={2} dashed dashScale={5}>
            <meshBasicMaterial />
          </Line>
        </>
      )}
    </>
  );
}

function SelectionVisualizer({ lastPoint, currentPoint }: { lastPoint: SelectionPoint | null, currentPoint: SelectionPoint | null }) {
  return (
    <>
      {currentPoint !== null && (
        <>
          {lastPoint !== null && (
            <SelectionVisualizerLink lastPoint={lastPoint} currentPoint={currentPoint} />
          )}
          <Sphere position={currentPoint.position} args={[0.1]} renderOrder={2}>
            <meshMatcapMaterial color="white" depthTest={false} />
          </Sphere>
          <Sphere position={currentPoint.position} args={[0.25]} renderOrder={2}>
            <meshMatcapMaterial color="white" />
          </Sphere>
        </>
      )}
      {lastPoint !== null && (
        <>
          <Sphere position={lastPoint.position} args={[0.1]} renderOrder={2}>
            <meshMatcapMaterial color="white" depthTest={false} />
          </Sphere>
          <Sphere position={lastPoint.position} args={[0.25]} renderOrder={2}>
            <meshMatcapMaterial color="white" />
          </Sphere>
        </>
      )}
    </>
  );
}

function SelectionDetails({ lastPoint, currentPoint }: { lastPoint: SelectionPoint | null, currentPoint: SelectionPoint | null }) {
  const pp = lastPoint !== null && currentPoint !== null ? getParallelPlaneProjection(lastPoint, currentPoint) : null;
  return (
    <div className="flex items-start gap-1">
      {currentPoint !== null && (
        <div className="pointer-events-auto flex flex-col rounded-sm border bg-control" >
          <div className="flex items-center gap-2 rounded-t-sm bg-accent px-2 py-1">
            <Crosshair2Icon />
            <div className="font-semibold">Current point</div>
          </div>
          <div className="px-2 py-1 font-mono">
            x: {printable(currentPoint.position.x)}
            <br />
            y: {printable(currentPoint.position.y)}
            <br />
            z: {printable(currentPoint.position.z)}
          </div>
        </div>
      )}
      {lastPoint !== null && (
        <div className="pointer-events-auto  flex flex-col rounded-sm border bg-control" >
          <div className="flex items-center gap-2 rounded-t-sm bg-accent px-2 py-1">
            <Crosshair2Icon />
            <div className="font-semibold">Last point</div>
          </div>
          <div className="px-2 py-1 font-mono">
            x: {printable(lastPoint.position.x)}
            <br />
            y: {printable(lastPoint.position.y)}
            <br />
            z: {printable(lastPoint.position.z)}
          </div>
        </div>
      )}
      {currentPoint !== null && lastPoint !== null && (
        <div className="pointer-events-auto flex flex-col">
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2 rounded-t border bg-accent px-2 py-1 font-semibold">
                <RulerHorizontalIcon />
                Offset
                <div className="grow" />
                <QuestionMarkCircledIcon />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              The vector between the current point and the last point. The components along the primary axis (x, y, z) and the total length (l) can be used to validate the tray dimensions.
            </TooltipContent>
          </Tooltip>
          <div className="flex flex-col rounded-b border-x border-b bg-control px-2 py-1 font-mono">
            <div>
              x: {printable(Math.abs(currentPoint.position.x - lastPoint.position.x))}
            </div>
            <div>
              y: {printable(Math.abs(currentPoint.position.y - lastPoint.position.y))}
            </div>
            <div>
              z: {printable(Math.abs(currentPoint.position.z - lastPoint.position.z))}
            </div>
            <div>
              l: {printable(currentPoint.position.clone().sub(lastPoint.position).length())}
            </div>
          </div>
        </div>
      )}
      {pp !== null && (
        <div className="pointer-events-auto flex flex-col">
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2 rounded-t border bg-accent px-2 py-1 font-semibold">
                <RulerSquareIcon />
                Parallel planes <QuestionMarkCircledIcon />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              The current point and the last point are on planes that are parallel. The distance between the planes (d) can be used to validate the tray dimensions.
            </TooltipContent>
          </Tooltip>
          <div className="flex flex-col rounded-b border-x border-b bg-control px-2 py-1 font-mono">
            <div className=" text-[#d946ef]">
              d: {printable(currentPoint!.position.clone().sub(pp).length())}
            </div>
          </div>
        </div>
      )
      }
    </div >
  );
}

function selectionPointFromIntersection(intersection: Intersection<Object3D<Object3DEventMap>> | undefined) {
  if (intersection === undefined) {
    return undefined;
  }
  return {
    position: intersection.point,
    normal: intersection.normal,
  };
}

function intersectAfterClippingPlane(e: ThreeEvent<MouseEvent>, clippingPlaneConfiguration: ClippingPlaneConfiguration | undefined) {
  if (clippingPlaneConfiguration !== undefined) {
    if (clippingPlaneConfiguration.axis === OrientedAxis.xRight && e.point.x <= clippingPlaneConfiguration.offset) {
      const intersections = new Raycaster(e.ray.origin, e.ray.direction).intersectObject(e.object);
      return selectionPointFromIntersection(intersections.find(i => i.point.x > clippingPlaneConfiguration.offset));
    } else if (clippingPlaneConfiguration.axis === OrientedAxis.xLeft && e.point.x >= clippingPlaneConfiguration.maxOffset - clippingPlaneConfiguration.offset) {
      const intersections = new Raycaster(e.ray.origin, e.ray.direction).intersectObject(e.object);
      return selectionPointFromIntersection(intersections.find(i => i.point.x < clippingPlaneConfiguration.maxOffset - clippingPlaneConfiguration.offset));
    } else if (clippingPlaneConfiguration.axis === OrientedAxis.yBack && e.point.y <= clippingPlaneConfiguration.offset) {
      const intersections = new Raycaster(e.ray.origin, e.ray.direction).intersectObject(e.object);
      return selectionPointFromIntersection(intersections.find(i => i.point.y > clippingPlaneConfiguration.offset));
    } else if (clippingPlaneConfiguration.axis === OrientedAxis.yFront && e.point.y >= clippingPlaneConfiguration.maxOffset - clippingPlaneConfiguration.offset) {
      const intersections = new Raycaster(e.ray.origin, e.ray.direction).intersectObject(e.object);
      return selectionPointFromIntersection(intersections.find(i => i.point.y < clippingPlaneConfiguration.maxOffset - clippingPlaneConfiguration.offset));
    } else if (clippingPlaneConfiguration.axis === OrientedAxis.zTop && e.point.z <= clippingPlaneConfiguration.offset) {
      const intersections = new Raycaster(e.ray.origin, e.ray.direction).intersectObject(e.object);
      return selectionPointFromIntersection(intersections.find(i => i.point.z > clippingPlaneConfiguration.offset));
    } else if (clippingPlaneConfiguration.axis === OrientedAxis.zBottom && e.point.z >= clippingPlaneConfiguration.maxOffset - clippingPlaneConfiguration.offset) {
      const intersections = new Raycaster(e.ray.origin, e.ray.direction).intersectObject(e.object);
      return selectionPointFromIntersection(intersections.find(i => i.point.z < clippingPlaneConfiguration.maxOffset - clippingPlaneConfiguration.offset));
    }
  }
  return {
    position: e.point,
    normal: e.normal,
  };
}

interface SelectionPoint {
  position: Vector3,
  normal?: Vector3,
}


export default function BlueprintCanvas({ blueprintId, trayId, setTrayId, models, isGenerating, isDebugging, setIsDebugging }: {
  blueprintId: number,
  trayId: number,
  setTrayId: (id: number) => void,
  models: TrayModel[],
  isGenerating: boolean,
  isDebugging: boolean,
  setIsDebugging: (debug: boolean) => void,
}) {
  const [selections, setSelections] = useState<Selection[]>([]);
  const [mode, setMode] = useState<CanvasMode>(CanvasMode.Select);
  const [lastPoint, setLastPoint] = useState<SelectionPoint | null>(null);
  const [currentPoint, setCurrentPoint] = useState<SelectionPoint | null>(null);
  const [selectEdges, setSelectEdges] = useState(false);
  const [selectFaces, setSelectFaces] = useState(false);
  const [selectPoints, setSelectPoints] = useState(true);
  const [selectPockets, setSelectPockets] = useState(true);
  const [selectContainers, setSelectContainers] = useState(false);
  const [clippingPlaneConfiguration, setClippingPlaneConfiguration] = useState<ClippingPlaneConfiguration | undefined>(undefined);
  const selectedElement = useAppSelector(selectCurrentElement);
  const selectedElementInstance = useAppSelector(s => selectedElement === null ? null : s.model.trays[trayId].elements[selectedElement.id]);
  const tray = useAppSelector(s => s.model.trays[trayId]);
  const blueprint = useAppSelector(s => s.model.blueprints[blueprintId]);
  const blueprintTrays = useAppSelector(s => selectTraysForBlueprint(blueprint.id, s), shallowEqual);
  const [visibleModels, setVisibleModels] = useState<string[]>([]);
  const [wireframeMode, setWireframeMode] = useState(false);
  const blueprintRoot = useAppSelector(s => s.model.blueprints[blueprintId].root);
  const pockets = useMemo(() => getBlueprintPocketElements(tray, blueprint.elements, blueprintRoot), [tray, blueprint.elements, blueprintRoot]);
  const containers = useMemo(() => getBlueprintContainerElements(tray, blueprint.elements, blueprintRoot), [tray, blueprint.elements, blueprintRoot]);
  const dispatch = useAppDispatch();
  const stageRef = useRef<StageHandle>(null);
  const settingCanvasAutoZoom = useAppSelector(s => s.settings.settingCanvasAutoZoom);
  const settingCanvasOrthographic = useAppSelector(s => s.settings.settingCanvasOrthographic);
  const settingCanvasShowGizmo = useAppSelector(s => s.settings.settingCanvasShowGizmo);
  const settingCanvasShowPorts = useAppSelector(s => s.settings.settingCanvasShowPorts);
  const settingCanvasShowClippingTools = useAppSelector(s => s.settings.settingCanvasShowClippingTools);
  const settingDarkMode = useAppSelector(s => s.settings.settingDarkMode);
  const duplicatePocketElement = () => {
    if (selectedElement !== null && selectedElement.type === "pocket") {
      dispatch(addPocketElement({
        blueprint: blueprintId,
        parent: selectElementParent(selectedElement.id, blueprintId)!,
        element: selectedElement,
        name: `Copy of ${selectedElement.name}`,
      }));
    }
  };
  const deletePocketElement = () => {
    if (selectedElement !== null && selectedElement.type === "pocket") {
      dispatch(deleteElement({
        blueprint: blueprintId,
        element: selectedElement.id,
      }));
    }
  };
  useHotkeys("F", () => stageRef.current!.fit("sphere", true));
  useHotkeys("A", () => stageRef.current!.fit("box", true));
  useHotkeys("D", duplicatePocketElement);
  useHotkeys("Delete", deletePocketElement);
  let infoCount = 0;
  let warningCount = 0;
  let errorCount = 0;
  for (const tray of blueprintTrays) {
    for (const message of tray.messages) {
      if (message.type === "info") {
        infoCount++;
      } else if (message.type === "warning") {
        warningCount++;
      } else {
        errorCount++;
      }
    }
  }
  useLayoutEffect(() => {
    setSelections([]);
    const remainingModels = visibleModels.filter(v => models.some(r => r.name == v));
    if (remainingModels.length == 0) {
      if (models.some(r => r.name == "tray")) {
        remainingModels.push("tray");
      } else if (models.length > 0) {
        remainingModels.push(models[0].name);
      }
    }
    setVisibleModels(remainingModels);
    setLastPoint(null);
    setCurrentPoint(null);
  }, [models]);
  useEffect(() => {
    if (settingCanvasAutoZoom) {
      setTimeout(() => {
        if (stageRef.current !== null) {
          stageRef.current.fit("sphere", true);
        }
      }, 0);
    }
  }, [models, settingCanvasAutoZoom]);
  useEffect(() => {
    setTimeout(() => {
      if (stageRef.current !== null) {
        stageRef.current.fit("sphere", false);
      }
    }, 0);
  }, [settingCanvasOrthographic]);
  const pointerMissed = () => {
    if (mode === CanvasMode.Measure && selectPoints) {
      if (lastPoint !== null) {
        setLastPoint(null);
      } else if (currentPoint !== null) {
        setCurrentPoint(null);
      }
    }
  };
  return (
    <>
      <Canvas isOrthographic={settingCanvasOrthographic} onPointerMissed={() => {
        dispatch(select({
          aspect: "configuration",
          scope: "blueprint",
          blueprintId,
        }));
        pointerMissed();
      }}>
        <Ground />
        <Stage panGround={false} fit={true} ref={stageRef} showGizmo={settingCanvasShowGizmo}>
          <ClipPlane sideColor={"white"} configuration={clippingPlaneConfiguration}>
            {models?.map(m => <ReplicadShape key={m.trayId} edgeOpacity={20} model={m} edgeDepthTest={!wireframeMode} onClick={e => {
              if (mode === CanvasMode.Measure && selectPoints && e.delta === 0) {
                const point = intersectAfterClippingPlane(e, clippingPlaneConfiguration);
                if (point === undefined) {
                  pointerMissed();
                  return;
                }
                e.stopPropagation();
                if (currentPoint !== null) {
                  setLastPoint(currentPoint);
                }
                setCurrentPoint(point);
              }
            }} />)}
          </ClipPlane>
        </Stage>
        {isGenerating && (
          <Skeleton size={tray.size} pockets={pockets} />
        )}
        {isDebugging && (
          <BoxInfo color="#cbd5e1" position={{ x: 0, y: 0, z: 0 }} size={tray.size} showCoordinates={true} />
        )}
        {mode === CanvasMode.Select && selectedElement !== null && selectedElementInstance !== null && (
          <>
            <Text color="#22c55e" position={[selectedElementInstance.placement.xOffset + 1, selectedElementInstance.placement.yOffset + selectedElementInstance.placement.y - 1, selectedElementInstance.placement.zOffset + selectedElementInstance.placement.z]} anchorX="left" anchorY="top" fontSize={2.5} font={roboto}>
              {selectedElement.type === "pocket" ? selectedElement.name : configurationKeyName(selectedElement.type)}
            </Text>
            <BoxInfo color="#22c55e" position={{ x: selectedElementInstance.placement.xOffset, y: selectedElementInstance.placement.yOffset, z: selectedElementInstance.placement.zOffset }} size={{ x: selectedElementInstance.placement.x, y: selectedElementInstance.placement.y, z: selectedElementInstance.placement.z }} showCoordinates={false} />
          </>
        )}
        <BoxFootprint position={{ x: 0, y: 0, z: 0 }} size={tray.size} />
        <Sphere position={[0, 0, -0.05]} material-color={settingDarkMode ? "#334155" : "#F0F0F0"} scale={0.2} />
        {mode === CanvasMode.Select && selectPockets && pockets.map(p => (
          <Box key={p.blueprint.id} position={[p.element.placement.xOffset + p.element.placement.x / 2, p.element.placement.yOffset + p.element.placement.y / 2, p.element.placement.zOffset + p.element.placement.z / 2]} scale={[p.element.placement.x, p.element.placement.y, p.element.placement.z]} onClick={e => {
            e.stopPropagation();
            if (e.delta === 0) {
              dispatch(select({
                scope: "blueprint",
                aspect: "element",
                blueprintId: blueprintId,
                elementId: p.blueprint.id,
              }));
            }
          }}>
            <meshBasicMaterial transparent={true} opacity={0} depthTest={false} />
          </Box>
        ))}
        {mode === CanvasMode.Select && selectContainers && containers.map(c => (
          <Box key={c.id} position={[c.element.placement.xOffset + c.element.placement.x / 2, c.element.placement.yOffset + c.element.placement.y / 2, c.element.placement.zOffset + c.element.placement.z / 2]} scale={[c.element.placement.x, c.element.placement.y, c.element.placement.z]} onClick={e => {
            e.stopPropagation();
            if (e.delta === 0) {
              dispatch(select({
                scope: "blueprint",
                aspect: "element",
                blueprintId: blueprintId,
                elementId: c.id,
              }));
            }
          }}>
            <meshBasicMaterial transparent={true} opacity={0} depthTest={false} />
          </Box>
        ))}
        {mode === CanvasMode.Measure && selectPoints && (
          <SelectionVisualizer lastPoint={lastPoint} currentPoint={currentPoint} />
        )}
      </Canvas>
      <div className="pointer-events-none absolute left-1 top-1 z-50 flex items-start gap-1">
        <div className="pointer-events-auto flex w-36 flex-col gap-1">
          <Button variant="outline" disabled={mode === CanvasMode.Select} size="sm" className="z-30 justify-start disabled:border-primary disabled:opacity-100" onClick={() => {
            setMode(CanvasMode.Select);
            setSelections([]);
            setCurrentPoint(null);
            setLastPoint(null);
          }}>
            <CursorArrowIcon />
            Select
          </Button>
          {mode == CanvasMode.Select && (
            <div className="z-20 flex flex-col gap-1 rounded-sm border bg-control/80 p-2 pt-3" style={{
              marginTop: -8,
            }}>
              <div className="flex items-center gap-2">
                <Switch id="selectPockets" checked={selectPockets} onCheckedChange={c => {
                  setSelectPockets(c);
                  setSelectContainers(!c);
                }} />
                <Label htmlFor="selectPockets" className="font-normal">Pockets</Label>
              </div>
              <div className="flex items-center gap-2">
                <Switch id="selectContainers" checked={selectContainers} onCheckedChange={c => {
                  setSelectPockets(!c);
                  setSelectContainers(c);
                }} />
                <Label htmlFor="selectContainers" className="font-normal">Containers</Label>
              </div>
            </div>
          )}
          <Button disabled={mode == CanvasMode.Measure} className="z-30 justify-start disabled:border-primary disabled:opacity-100" size="sm" onClick={() => setMode(CanvasMode.Measure)}>
            <RulerSquareIcon />
            Measure
          </Button>
          {mode == CanvasMode.Measure && (
            <div className="z-20 flex flex-col gap-1 rounded-sm border bg-control/80 p-2 pt-3" style={{
              marginTop: -8,
            }}>
              <div className="flex items-center gap-2">
                <Switch id="selectEdges" disabled={true} checked={selectEdges} onCheckedChange={c => {
                  setSelectEdges(c);
                  if (c) {
                    setSelectPoints(false);
                    setSelections(selections.filter(s => s.type !== "point"));
                  } else {
                    setSelections(selections.filter(s => s.type !== "edge"));
                  }
                }} />
                <Label htmlFor="selectEdges">Edges</Label>
              </div>
              <div className="flex items-center gap-2">
                <Switch id="selectFaces" disabled={true} checked={selectFaces} onCheckedChange={c => {
                  setSelectFaces(c);
                  if (c) {
                    setSelectPoints(false);
                    setSelections(selections.filter(s => s.type !== "point"));
                  } else {
                    setSelections(selections.filter(s => s.type !== "face"));
                  }
                }} />
                <Label htmlFor="selectFaces">Faces</Label>
              </div>
              <hr className="my-1" />
              <div className="flex items-center gap-2">
                <Switch id="selectPoints" checked={selectPoints} onCheckedChange={c => {
                  setSelectPoints(c);
                  if (c) {
                    setSelectEdges(false);
                    setSelectFaces(false);
                    setSelections(selections.filter(s => s.type === "point"));
                  } else {
                    setSelections(selections.filter(s => s.type !== "face"));
                  }
                }} />
                <Label htmlFor="selectFaces">Points</Label>
              </div>
            </div>
          )}
          {settingCanvasShowClippingTools && (
            <ClippingTools clippingPlaneConfiguration={clippingPlaneConfiguration} setClippingPlaneConfiguration={setClippingPlaneConfiguration} bounds={stageRef.current?.getBounds()} close={() => dispatch(updateSettings({
              settingCanvasShowClippingTools: false,
            }))} />
          )}
          <div className="flex flex-col items-start gap-1">
            {!settingCanvasShowClippingTools && (
              <Button onClick={() => dispatch(updateSettings({
                settingCanvasShowClippingTools: true,
              }))} size="icon" tooltip="Clipping tools">
                <StackIcon className="shrink-0" />
              </Button>
            )}
          </div>
        </div>
        {selections.length > 0 && (
          <div className="flex flex-col gap-1">
            {selections[0].type == "edge" ? (
              <CanvasEdgeInfo edge={selections[0].edge} designator={mode === CanvasMode.Measure ? "A" : undefined} />
            ) : selections[0].type == "face" ? (
              <CanvasFaceInfo face={selections[0].face} designator={mode === CanvasMode.Measure ? "B" : undefined} />
            ) : (
              <CanvasPointInfo point={selections[0].point} designator={mode === CanvasMode.Measure ? "B" : undefined} />
            )}
          </div>
        )}
        {selections.length > 1 && (
          <div className="flex flex-col gap-1">
            {selections[1].type == "edge" ? (
              <CanvasEdgeInfo edge={selections[1].edge} designator={mode === CanvasMode.Measure ? "B" : undefined} />
            ) : selections[1].type == "face" ? (
              <CanvasFaceInfo face={selections[1].face} designator={mode === CanvasMode.Measure ? "B" : undefined} />
            ) : (
              <CanvasPointInfo point={selections[1].point} designator={mode === CanvasMode.Measure ? "B" : undefined} />
            )}
          </div>
        )}
        <SelectionDetails currentPoint={currentPoint} lastPoint={lastPoint} />
      </div>
      <div className="pointer-events-none absolute right-1 top-1 z-10 flex select-none flex-col items-end gap-1">
        <div className="pointer-events-auto flex flex-col gap-2 rounded-sm border bg-control/80 p-2">
          <div className="my-[-8px] flex items-center justify-between gap-2">
            <Label htmlFor="instance" className="py-2">
              Instances
            </Label>
            <DesignerMessagesBar infoCount={infoCount} warningCount={warningCount} errorCount={errorCount} />
          </div>
          <Select onValueChange={v => setTrayId(parseInt(v))}>
            <SelectTrigger id="instance" className="h-fit min-w-48 bg-control">
              <BlueprintCanvasTray tray={tray} blueprint={blueprint} />
            </SelectTrigger>
            <SelectContent>
              {blueprintTrays.map(t => <SelectItem key={t.id} value={t.id.toString()}>
                <BlueprintCanvasTray key={t.id} tray={t} blueprint={blueprint} />
              </SelectItem>)}
            </SelectContent>
          </Select>
          {infoCount + warningCount + errorCount > 0 && (
            <Button variant="outline" size="sm" onClick={() => dispatch(clearTrayMessages({
              blueprint: blueprintId,
            }))}>
              Acknowledge all
            </Button>
          )}
        </div>
        <div className="pointer-events-auto flex flex-col rounded-sm border bg-control/80">
          <div className="flex items-center gap-2 p-2">
            <Switch id="debug" checked={isDebugging} onCheckedChange={setIsDebugging} />
            <Label htmlFor="debug" className="font-normal">Debug</Label>
          </div>
          {models.length > 1 && (
            <>
              {models.map(m => (
                <div key={m.name} className="flex items-center border-t ">
                  <Button onClick={() => setVisibleModels([m.name])} variant="ghost" className="size-8 rounded-none p-0">
                    <Crosshair2Icon />
                  </Button>
                  <Button onClick={() => {
                    if (visibleModels.includes(m.name)) {
                      setVisibleModels(visibleModels.filter(v => v !== m.name))
                    } else {
                      setVisibleModels([...visibleModels, m.name]);
                    }
                  }} variant="outline" className="h-8 flex-auto justify-start rounded-none border-none px-2 py-0">
                    <div className={visibleModels.includes(m.name) ? "text-primary underline" : ""}>
                      {m.name}
                    </div>
                  </Button>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="pointer-events-none absolute bottom-1 left-1 z-20 flex flex-col items-end gap-1">
        <InsertJobs />
      </div>
      <div className="pointer-events-none absolute bottom-1 right-1 z-50 flex select-none flex-col items-end gap-2">
        {selectedElement !== null && (
          <div className="pointer-events-auto flex w-36 flex-col gap-1">
            <Button className="z-30 justify-start" size="sm" onClick={duplicatePocketElement} tooltip="Zoom and pan the camera to make the tray fill the entire canvas.">
              <CopyIcon />
              Duplicate
              <Hotkey absolute>
                D
              </Hotkey>
            </Button>
            <Button className="z-30 justify-start" size="sm" onClick={deletePocketElement} tooltip="Zoom and pan the camera to make the tray fill the entire canvas.">
              <TrashIcon />
              Remove
              <Hotkey absolute>
                Del
              </Hotkey>
            </Button>
          </div>
        )}
        <div className="pointer-events-auto flex w-28 flex-col gap-1">
          <Button className="z-30 justify-start" size="sm" onClick={() => {
            if (stageRef.current !== null) {
              stageRef.current.fit("sphere", true);
            }
          }} tooltip="Zoom and pan the camera to make the tray fill the entire canvas.">
            <CornersIcon />
            Fit
            <Hotkey absolute>
              F
            </Hotkey>
          </Button>
          <Button className="z-30 justify-start" size="sm" onClick={() => {
            if (stageRef.current !== null) {
              stageRef.current.fit("box", true);
            }
          }}>
            <AngleIcon />
            Align
            <Hotkey absolute>
              A
            </Hotkey>
          </Button>
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="outline" size="sm" className="justify-start">
                <GearIcon />
                Settings
              </Button>
            </PopoverTrigger>
            <PopoverContent align="end" className="flex w-fit flex-col gap-2 p-2" >
              <PopoverArrow />
              <div className="flex items-center gap-2">
                <Switch id="wireframe" checked={wireframeMode} onCheckedChange={setWireframeMode} />
                <Label htmlFor="wireframe" className="font-normal">
                  Wireframe mode
                </Label>
              </div>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex items-center gap-2">
                    <Switch id="autoZoom" checked={settingCanvasAutoZoom} onCheckedChange={c => dispatch(updateSettings({
                      settingCanvasAutoZoom: c,
                    }))} />
                    <Label htmlFor="autoZoom" className="font-normal">
                      Auto-zoom
                    </Label>
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  Zoom to fit automatically when the tray changes.
                </TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex items-center gap-2">
                    <Switch id="orthographic" checked={settingCanvasOrthographic} onCheckedChange={c => dispatch(updateSettings({
                      settingCanvasOrthographic: c,
                    }))} />
                    <Label htmlFor="orthographic" className="font-normal">
                      Orthographic
                    </Label>
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  Use an orthographic projection.
                </TooltipContent>
              </Tooltip>
              <div className="flex items-center gap-2">
                <Switch id="center" checked={settingCanvasShowGizmo} onCheckedChange={e => dispatch(updateSettings({
                  settingCanvasShowGizmo: e,
                }))} />
                <Label htmlFor="center" className="font-normal">Show gizmo</Label>
              </div>
              <div className="flex items-center gap-2">
                <Switch id="ports" checked={settingCanvasShowPorts} onCheckedChange={e => dispatch(updateSettings({
                  settingCanvasShowPorts: e,
                }))} />
                <Label htmlFor="ports" className="font-normal">Show stacking ports</Label>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </>
  );
}
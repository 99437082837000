import { CheckCircledIcon, ClockIcon } from "@radix-ui/react-icons";
import { useWorkerPool } from "@/replicadWorkerPool";
import { Section } from "@ui/section";
import { SectionLabel } from "@ui/sectionLabel";
import { Spinner } from "@ui/spinner";
import { getTrayName } from "@/state/store";

export default function InsertJobs() {
  const jobs = useWorkerPool();
  if(jobs.length === 0) {
    return <></>;
  }
  return (
    <Section slim>
      {jobs.map(j => (
        <div key={j.id} className="flex gap-2 items-center">
          {j.isActive ? (
            <Spinner className="size-5"/>
          ) : j.isCompleted ? (
            <CheckCircledIcon className="size-5"/>
          ) : (
            <ClockIcon className="size-5"/>
          )}
          {getTrayName(j.request.blueprint.name, j.request.tray.name)}
        </div>
      ))}
    </Section>
  );
}
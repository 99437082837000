import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from "@ui/popover";
import { Button } from "@ui/button";
import { GearIcon } from "@radix-ui/react-icons";
import DesignerSettings from "../DesignerSettings";

import DesignerHistoryToolbar from "../DesignerHistoryToolbar";
import { Separator } from "@ui/separator";
import { cn } from "@ui/utils";

export default function InsertToolbar({className} : {className?: string}) {
  return (
    <div className={cn("flex items-center gap-2", className)}>
        {/*}
        <Menubar className="w-full">
          <MenubarMenu>
            <MenubarTrigger>
              File
            </MenubarTrigger>
            <MenubarContent>
              <MenubarItem>
                <ResetIcon/>
                Undo
              </MenubarItem>
              <MenubarItem>
                <ReloadIcon/>
                Redo
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>
          <MenubarMenu>
            <MenubarTrigger>
              Settings
            </MenubarTrigger>
            <MenubarContent>
              <DesignerSettings/>
            </MenubarContent>
          </MenubarMenu>
        </Menubar>
        */}
        <DesignerHistoryToolbar/>
        <Separator orientation="vertical"/>
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" size="sm" >
              <GearIcon  />
              Settings
            </Button>
          </PopoverTrigger>
          <PopoverContent side="bottom" align="end" className="w-96">
            <DesignerSettings />
            <PopoverArrow />
          </PopoverContent>
        </Popover>
    </div>
  );
}
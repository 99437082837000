import * as React from "react"
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area"

import { cn } from "./utils"

interface ScrollAreaProps extends React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> {
  section?: boolean,
  slim?: boolean,
}

const ScrollArea = React.forwardRef<React.ElementRef<typeof ScrollAreaPrimitive.Root>, ScrollAreaProps>(
  ({ className, children, section = false, slim = false, ...props }, ref) => (
  <ScrollAreaPrimitive.Root
    ref={ref}
    className={cn("relative overflow-hidden", section && !slim && "-mx-4 px-4", section && slim && "-mx-2 px-2",className)}
    {...props}
  >
    <ScrollAreaPrimitive.Viewport className="size-full rounded">
      {children}
    </ScrollAreaPrimitive.Viewport>
    <ScrollBar section={section} slim={slim}/>
    <ScrollAreaPrimitive.Corner />
  </ScrollAreaPrimitive.Root>
));
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

interface ScrollAreaScrollbarProps extends React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar> {
  section?: boolean,
  slim?: boolean,
}

const ScrollBar = React.forwardRef<React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>, ScrollAreaScrollbarProps>(
  ({ className, orientation = "vertical", section = false, slim = false, ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={cn(
      "flex touch-none select-none transition-colors relative",
      section ? "rounded-l" : "py-2",
      orientation === "vertical" && (slim ? "h-full w-1" : "h-full w-2"),
      orientation === "horizontal" && "h-1 flex-col",
      className
    )}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb className={cn("relative flex-1 bg-border rounded-l")}/>
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar }

import { useAppSelector } from "@/state/store";
import { ArrowRightIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { Button } from "@ui/button";
import { Icon } from "@ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";
import { cn } from "@ui/utils";
import { Link } from "react-router-dom";

interface NavigationInsertProps extends React.HTMLAttributes<HTMLAnchorElement> {
  showBox: boolean,
}
export function NavigationMenuInsert({ children, showBox, className, ...props} : NavigationInsertProps) {
  const insertName = useAppSelector(s => s.model.insert.name);
  const recordsCount = useAppSelector(s => s.model.history.records.length);
  const isBlank = recordsCount === 1;
  if(isBlank) {
    if(showBox) {
      return (
        <div className={cn("flex h-full items-center border-x bg-background px-2", className)}>
          <Link to="/designer" {...props}>
            <Button variant="outline" className="h-8 px-2">
              <Icon name="journal-plus" className="text-[18px]"/>
              Create new insert
            </Button>
          </Link>
        </div>
      );
    } else {
      return (
        <Link to="/designer" {...props}>
          <Button variant="outline" className="h-8 px-2">
            <Icon name="journal-plus" className="text-[18px]"/>
            Create new insert
          </Button>
        </Link>
      );
    }
  } else {
    if(showBox) {
      return (
        <div className="flex h-full items-center gap-3 border-x bg-background px-2 py-1">
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex flex-col justify-between">
                <div className="whitespace-nowrap text-sm font-semibold">
                  {insertName}
                </div>
                <div className="whitespace-nowrap text-sm">
                  Insert ({recordsCount})
                </div>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              sdf
            </TooltipContent>
          </Tooltip>
          <Link to="/designer" {...props}>
            <Button variant="outline" className="h-8 gap-1 px-2">
              Continue
              <Icon name="arrow-right-short" xl/>
            </Button>
          </Link>
        </div>
      );
    } else {
      return (
        <div className="flex gap-3 px-2 py-1">
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex flex-col justify-between">
                <div className="whitespace-nowrap text-sm font-semibold">
                  {insertName}
                </div>
                <div className="whitespace-nowrap text-sm">
                  Insert ({recordsCount})
                </div>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              sdf
            </TooltipContent>
          </Tooltip>
          <Link to="/designer" {...props}>
            <Button variant="outline" className="h-8 gap-1 px-2">
              Continue
              <Icon name="arrow-right-short" xl/>
            </Button>
          </Link>
        </div>
      );
    }
  }
}

import { useGetBoardgameQuery } from "@/state/api/boardgames";
import { useListBoxesQuery } from "@/state/api/boxes";
import { ExternalLinkIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { Badge } from "@ui/badge";
import { Button } from "@ui/button";
import { Section } from "@ui/section";
import { Separator } from "@ui/separator";
import { useNavigate, useParams } from "react-router-dom";
import BoxesCard from "../boxes/BoxesCard";
import { Spinner } from "@ui/spinner";

export default function GameDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isLoading, isSuccess, data } = useGetBoardgameQuery({
    id: parseInt(id!),
  });
  const boxes = useListBoxesQuery({
    gameId: parseInt(id!),
  });
  if(isLoading) {
    return (
      <div className="flex h-8 items-center gap-2 rounded-sm border px-2">
        <Spinner/>
        loading game details...
      </div>
    );
  } else if(isSuccess && data !== undefined) {
    return (
      <>
      <div className="flex flex-col gap-2">
        <div className="relative flex gap-4 rounded border bg-control p-4">
          <img src={data.image} className="absolute inset-0 z-0 size-full rounded object-cover object-right opacity-5"/>
          <img src={data.image} className="z-10 w-[200px] self-start rounded shadow-lg"/>
          <div className="z-10 flex flex-auto flex-col gap-2">
            <div className="flex justify-between">
              <div className="text-xl">
                {data.name} {data.year}
              </div>
              <Button onClick={() => window?.open(`https://boardgamegeek.com/boardgame/${id}`, '_blank')?.focus() } size="sm">
                <ExternalLinkIcon/>
                boardgamegeek.com
              </Button>
            </div>
            <div className="flex gap-2">
              <Badge>
                {data.minPlayers} - {data.maxPlayers} players
              </Badge>
              <Badge>
                {data.minPlaytime} - {data.maxPlaytime} minutes
              </Badge>
              <Badge>
                Age: {data.age}+
              </Badge>
            </div>
            <table className="table self-start text-sm">
              <tbody>
                <tr>
                  <td className="pe-1 align-top font-semibold">Designers:</td>
                  <td>{data.designers.join(", ")}</td>
                </tr>
                <tr>
                  <td className="pe-1 align-top font-semibold">Publishers:</td>
                  <td>{data.publishers[0]} +{data.publishers.length - 1}</td>
                </tr>
                <tr>
                  <td className="pe-1 align-top font-semibold">Categories:</td>
                  <td>{data.categories.join(", ")}</td>
                </tr>
                <tr>
                  <td className="pe-1 align-top font-semibold">Mechanics:</td>
                  <td>{data.mechanics.join(", ")}</td>
                </tr>
                <tr>
                  <td className="pe-1 align-top font-semibold">Expansions:</td>
                  <td>{data.expansions.map(e => e.name).join(", ")}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="font-semibold">
          Inserts
        </div>
        <Section>

        </Section>
        <div className="mt-2 flex items-end justify-between">
          <div className="font-semibold">
            Parts
          </div>
          <Button variant="outline" size="sm" onClick={() => navigate("/parts/new?gameId=" + id)}>
            <PlusCircledIcon/>
            Add part
          </Button>
        </div>
        <Section>

        </Section>
        <div className="mt-2 flex items-end justify-between">
          <div className="font-semibold">
            Boxes
          </div>
          <Button variant="outline" size="sm" onClick={() => navigate("/boxes/new?gameId=" + id)}>
            <PlusCircledIcon/>
            Add box
          </Button>
        </div>
        <Section>
          {boxes.isFetching ? (
            <div>
              loading...
            </div>
          ) : boxes.isSuccess && boxes.data !== undefined ? (
            <div>
              {boxes.data.map(b => <BoxesCard key={b.id} box={b} link/>)}
            </div>
          ) : (
            <div>
              failed to load boxes
            </div>
          )}
        </Section>
      </div> 
      <Separator className="mt-2"/>
      <div className="flex items-center">
        <a href="https://boardgamegeek.com/">
          <img src="https://cf.geekdo-images.com/HZy35cmzmmyV9BarSuk6ug__thumb/img/gbE7sulIurZE_Tx8EQJXnZSKI6w=/fit-in/200x150/filters:strip_icc()/pic7779581.png"/>
        </a>
      </div>
    </>);
  }
}
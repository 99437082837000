import { useState } from "react";
import { selectCurrentElementId, selectElement, useAppDispatch, useAppSelector } from "@/state/store";
import PocketElementTreeNode from "./PocketElementTreeNode";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@ui/collapsible";
import { Button } from "@ui/button";
import { ChevronDownIcon, ChevronUpIcon, PlusCircledIcon, TrashIcon } from "@radix-ui/react-icons";
import { Badge } from "@ui/badge";
import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@ui/dropdown-menu";
import { addContainerElement, addPocketElement, deleteElement, select } from "@/state/model";
import ContainerElementIcon from "./ContainerElementIcon";
import { configurationKeyName } from "@/catalog";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';

export default function ContainerElementTreeNode({ blueprintId, elementId, parentElementId }: { blueprintId: number, elementId: number, parentElementId: number }) {
  const dispatch = useAppDispatch();
  const selectedElementId = useAppSelector(selectCurrentElementId);
  const element = useAppSelector(s => selectElement(elementId, s))!;
  const isSelected = selectedElementId == elementId;
  if (element.type === "pocket") {
    return <PocketElementTreeNode isSelected={isSelected} blueprintId={blueprintId} element={element} parentElementId={parentElementId} />;
  }
  const [open, setOpen] = useState(true);
  const isOpen = open && element.childs.length > 0;
  const { attributes, listeners, setNodeRef: setSortableNodeRef, transform, transition, isDragging } = useSortable({
    id: elementId,
  });
  return (
    <Collapsible disabled={element.childs.length == 0} open={isOpen} onOpenChange={setOpen} style={{
      transform: CSS.Translate.toString(transform),
      transition,
    }} className={"mb-[-1px] rounded-bl-2xl rounded-tl-lg border-r bg-background" + (isDragging ? " z-50" : "")} ref={setSortableNodeRef}>
      <div className={"border border-r-0 rounded-tl-lg peer" + (isOpen ? " border-b-0" : " rounded-bl-2xl") + (isSelected ? " bg-control hover:bg-control" : " hover:bg-accent")}>
        <div className="flex cursor-pointer flex-row items-center p-0.5">
          <CollapsibleTrigger asChild>
            <Button variant="ghost" className="rounded-bl-2xl px-1">
              {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
              <span className="sr-only">Toggle</span>
            </Button>
          </CollapsibleTrigger>
          <button {...listeners} {...attributes}>
            <ContainerElementIcon type={element.type} className={isSelected ? "mx-1 text-primary" : "mx-1"}/>
          </button>
          <Button className="flex flex-auto select-none justify-start p-0" variant="ghost" onClick={() => dispatch(select({
            scope: "blueprint",
            aspect: "element",
            blueprintId: blueprintId,
            elementId: elementId,
          }))}>
            <div className={isSelected ? "text-primary" : ""}>
              {configurationKeyName(element.type)}
              {!open && element.childs.length > 0 && (
                <Badge className="ml-2">{element.childs.length} child{element.childs.length == 1 ? "" : "s"}</Badge>
              )}
            </div>
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="px-2">
                <PlusCircledIcon />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuLabel>Add element</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => dispatch(addContainerElement({
                blueprint: blueprintId,
                parent: elementId,
                element: {
                  type: "stack",
                },
              }))}>
                Stack
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => dispatch(addPocketElement({
                blueprint: blueprintId,
                parent: elementId,
                element: {
                  type: "pocket",
                  name: "my precious tokens",
                  pocketType: "tokens",
                },
              }))}>
                Pocket
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          {elementId !== 0 && (
            <Button onClick={() => dispatch(deleteElement({
              element: elementId,
            }))} variant="ghost" className="px-2">
              <TrashIcon />
            </Button>
          )}
        </div>
      </div>
      <CollapsibleContent className={"border-dashed mr-[-1px] border-l border-b rounded-bl-lg" + (isSelected ? " bg-control peer-hover:bg-control" : " peer-hover:bg-accent")}>
        <SortableContext items={element.childs} id={"container-" + element.id}  strategy={verticalListSortingStrategy}>
          <div className="ml-2.5 flex flex-col">
            {element.childs.map(id => <ContainerElementTreeNode blueprintId={blueprintId} elementId={id} parentElementId={elementId} key={id} />)}
          </div>
        </SortableContext>
      </CollapsibleContent>
    </Collapsible>
  );
}
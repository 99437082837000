import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { produce } from "immer";

export enum MessageSeverity {
  Info = 0,
  Warning = 1,
  Error = 2,
}

export interface Message {
  id: number,
  timestamp: number,
  content: string,
  severity: MessageSeverity,
}

export type WidgetType = "error" | "success" | "neutral";

export interface Widget {
  id: number,
  type: WidgetType,
  content: ReactNode,
  isDismissable: boolean,
}

export interface Messages {
  messages: Message[],
  widgets: Widget[],
}

let nextId = 0;

export let reporter: {
  error: (message: string) => void;
  warning: (message: string) => void;
  info: (message: string) => void;
  widget: (content: ReactNode, isDismissable: boolean, type: WidgetType) => (() => void);
  dismissAll: () => void,
};

const MessagesContext = createContext<{
  state: Messages,
  setState: (handler: (draft: Messages) => void) => void,
  //@ts-expect-error not assignable, will be replaced with correct state immediately
}>(null);

export function MessagesProvider({ children }: { children: React.ReactNode }) {
  const [state, preSetState] = useState<Messages>({
    messages: [],
    widgets: [],
  });
  const setState = useCallback(
    (handler: (draft: Messages) => void) => preSetState(old => produce(old, draft => handler(draft))),
    [ preSetState ],
  );
  useEffect(() => {
    // update reporter
    reporter = {
      error: (message) => setState(draft => {
        draft.messages.push({
          id: nextId++,
          content: message,
          severity: MessageSeverity.Error,
          timestamp: Date.now(),
        });
      }),
      warning: (message) => setState(draft => {
        draft.messages.push({
          id: nextId++,
          content: message,
          severity: MessageSeverity.Warning,
          timestamp: Date.now(),
        });
      }),
      info: (message) => setState(draft => {
        draft.messages.push({
          id: nextId++,
          content: message,
          severity: MessageSeverity.Info,
          timestamp: Date.now(),
        });
      }),
      widget: (content, isDismissable, type) => {
        const id = nextId++;
        setState(draft => {
          draft.widgets.push({
            id,
            content,
            isDismissable,
            type,
          });
        });
        return () => {
          setState(draft => {
            draft.widgets = draft.widgets.filter(w => w.id !== id);
          });
        };
      },
      dismissAll: () => setState(draft => {
        draft.messages.splice(0, draft.messages.length);
        draft.widgets = draft.widgets.filter(w => !w.isDismissable);
      }),
    };
  }, [ setState ]);
  const contextValue = useMemo(() => {
    return { state, setState };
  }, [ state, setState ]);
  return (
    <MessagesContext.Provider value={contextValue}>
      {children}
    </MessagesContext.Provider>
  );
}

export function useMessages() {
  const { state, setState } = useContext(MessagesContext);
  return useMemo(() => ({
    messages: state.messages,
    widgets: state.widgets,
    dismissAll: () => setState(draft => {
      draft.messages.splice(0, draft.messages.length);
      draft.widgets = draft.widgets.filter(w => !w.isDismissable);
    }),
  }), [state]);
}

export function useReporter() {
  const { setState } = useContext(MessagesContext);
  return useMemo(() => ({
    error: (message: string) => setState(draft => {
      draft.messages.push({
        id: nextId++,
        content: message,
        severity: MessageSeverity.Error,
        timestamp: Date.now(),
      });
    }),
    warning: (message: string) => setState(draft => {
      draft.messages.push({
        id: nextId++,
        content: message,
        severity: MessageSeverity.Warning,
        timestamp: Date.now(),
      });
    }),
    info: (message: string) => setState(draft => {
      draft.messages.push({
        id: nextId++,
        content: message,
        severity: MessageSeverity.Info,
        timestamp: Date.now(),
      });
    }),
    widget: (content: ReactNode, isDismissable: boolean, type: WidgetType) => {
      const id = nextId++;
      setState(draft => {
        draft.widgets.push({
          id,
          content,
          isDismissable,
          type
        });
      });
      return () => {
        setState(draft => {
          draft.widgets = draft.widgets.filter(w => w.id !== id);
        });
      };
    },
  }), [setState]);
}
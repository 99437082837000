import React from "react"
import { cn } from "./utils"

export interface HotkeyProps extends React.HTMLAttributes<HTMLSpanElement> {
  absolute?: boolean
}

const Hotkey = React.forwardRef<HTMLSpanElement, HotkeyProps>(
  ({ children, absolute, className, ...props }, ref) => {
    return (
      <span ref={ref} className={cn("rounded-sm border border-b-2 px-1.5 py-0.5 leading-none text-foreground-muted", absolute ? "absolute right-2" : undefined, className)} {...props}>
        {children}
      </span>
    )
  }
);

Hotkey.displayName = "Hotkey";

export { Hotkey };





import { selectBlueprintForCurrentTray, selectCurrentTray, selectTrayAssociation, useAppDispatch, useAppSelector } from "@/state/store";
import { modifyInsert, modifyInsertConfiguration, modifyBlueprint, modifyTray } from "../../../state/model";
import ConfigurationEditor from "../ConfigurationEditor";
import { InputSubmit } from "@ui/inputSubmit";
import { Drawer, Blueprint, Tray } from "@/types";
import { useMemo } from "react";
import { InputNumber } from "@ui/inputNumber";
import { getTrayLayoutOffsets } from "@/catalog";
import { printable, roundNumber } from "@/utils";
import { Label } from "@ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@ui/select";
import { Checkbox } from "@ui/checkbox";
import TrayConfigurationLabel from "../ConfigurationLabel";
import { InsertPropertyDrawerImages } from "./InsertPropertyDrawerImages";

function TrayAssociation({tray} : {tray: Tray}) {
  const dispatch = useAppDispatch();
  const value = useAppSelector(s => selectTrayAssociation(tray.id, s));
  const boardgame = useAppSelector(s => s.model.insert.boardgame);
  const boardgameExpansions = useAppSelector(s => s.model.insert.boardgameExpansions);
  return (
    <Select value={(value?.id ?? 0).toString()} onValueChange={v => dispatch(modifyTray({
      tray: tray.id,
      associatedGameId: parseInt(v),
    }))}>
      <SelectTrigger>
        <SelectValue/>
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={(boardgame?.id ?? 0).toString()}>
          Base game
        </SelectItem>
        {boardgameExpansions.map(e => (
          <SelectItem key={e.id} value={e.id.toString()}>
            {e.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

export default function InsertPropertiesTray({ query }: { query: string }) {
  const tray = useAppSelector(selectCurrentTray)!;
  const blueprint = useAppSelector(selectBlueprintForCurrentTray)!;
  const configuration = useAppSelector(s => s.model.insert.configuration);
  const dispatch = useAppDispatch();
  const offsets = getTrayLayoutOffsets(blueprint);
  const minX = roundNumber(tray.measure.xMin + offsets.start.x + offsets.extent.x);
  const maxX = tray.measure.xLimit !== undefined ? tray.measure.xLimit + offsets.start.x + offsets.extent.x : undefined;
  const minY = roundNumber(tray.measure.yMin + offsets.start.y + offsets.extent.y);
  const maxY = tray.measure.yLimit !== undefined ? tray.measure.yLimit + offsets.start.y + offsets.extent.y : undefined;
  const minZ = roundNumber(tray.measure.zMin + offsets.start.z + offsets.extent.z);
  const maxZ = tray.measure.zLimit !== undefined ? tray.measure.zLimit + offsets.start.z + offsets.extent.z : undefined;
  const drawers = useMemo<Drawer[]>(() => [{
    keys: [ "trayName" ],
    control: <InputSubmit initialValue={tray.name ?? ""} applyValue={v => {
      dispatch(modifyTray({
        tray: tray.id,
        name: v,
      }));
    }} />,
    label: "row",
  }, {
    keys: [ "trayAssociation" ],
    control: <TrayAssociation tray={tray}/>,
    label: "row",
  }, {
    keys: [ "trayImages" ],
    control: <InsertPropertyDrawerImages trayId={tray.id}/>,
    label: "row",
  }, {
    keys: [ "trayOffsetX" ],
    control: <InputNumber unit="mm" min={0} max={configuration.x - tray.size.x} value={tray.offset.x} setValue={v => {
      dispatch(modifyTray({
        tray: tray.id,
        offset: {
          x: v,
          y: tray.offset.y,
          z: tray.offset.z,
        },
      }));
    }} />,
    label: "row",
  }, {
    keys: [ "trayOffsetY" ],
    control: <InputNumber unit="mm" min={0} max={configuration.y - tray.size.y} value={tray.offset.y} setValue={v => {
      dispatch(modifyTray({
        tray: tray.id,
        offset: {
          x: tray.offset.x,
          y: v,
          z: tray.offset.z,
        },
      }));
    }} />,
    label: "row",
  }, {
    keys: [ "trayOffsetZ" ],
    control: <InputNumber unit="mm" min={0} max={configuration.z - tray.size.z} value={tray.offset.z} setValue={v => {
      dispatch(modifyTray({
        tray: tray.id,
        offset: {
          x: tray.offset.x,
          y: tray.offset.y,
          z: v,
        },
      }));
    }} />,
    label: "row",
  }, {
    keys: [ "traySizeX" ],
    control: <InputNumber unit="mm" min={minX} max={maxX} value={tray.size.x} magic={{
      tooltip: "Reset to minimal width.",
      icon: "reset",
      handler: () => minX,
    }} setValue={v => {
      dispatch(modifyTray({
        tray: tray.id,
        size: {
          x: v,
          y: tray.size.y,
          z: tray.size.z,
        },
      }));
    }} />,
    label: "row",
  }, {
    keys: [ "traySizeY" ],
    control: <InputNumber unit="mm" min={minY} max={maxY} value={tray.size.y} magic={{
      tooltip: "Reset to minimal depth.",
      icon: "reset",
      handler: () => minY,
    }} setValue={v => {
      dispatch(modifyTray({
        tray: tray.id,
        size: {
          x: tray.size.x,
          y: v,
          z: tray.size.z,
        },
      }));
    }} />,
    label: "row",
  }, {
    keys: [ "traySizeZ" ],
    control: <InputNumber unit="mm" min={minZ} max={maxZ} value={tray.size.z} magic={{
      tooltip: "Reset to minimal height.",
      icon: "reset",
      handler: () => minZ,
    }} setValue={v => {
      dispatch(modifyTray({
        tray: tray.id,
        size: {
          x: tray.size.x,
          y: tray.size.y,
          z: v,
        },
      }));
    }} />,
    label: "row",
  }, {
    keys: [ "trayLayout" ],
    control: <div className="flex flex-col gap-1 rounded border p-2">
      <Label htmlFor="layout">
        Minimal tray size
      </Label>
      <div className="text-sm text-foreground-muted">
        The minimal tray size is determined by the tray configuration.
      </div>
      <div className="font-mono text-sm">
      {printable(minX)} x {printable(minY)} x {printable(minZ)} mm (width x depth x height)
      </div>
    </div>,
    label: "none",
  }], [ tray ]);
  return (
    <ConfigurationEditor query={query} configuration={{}} modifyConfiguration={c => {}} drawers={drawers}/>
  );
}
import { modifyBlueprintGlobals } from "../../../state/model";
import { selectCurrentBlueprintId, useAppDispatch, useAppSelector } from "@/state/store";
import ConfigurationEditor from "../ConfigurationEditor";

export default function BlueprintPropertiesGlobals({ query, blueprintId }: { query: string, blueprintId: number }) {
  const dispatch = useAppDispatch();
  const globals = useAppSelector(s => s.model.blueprints[blueprintId].globals);
  return <ConfigurationEditor query={query} configuration={globals} modifyConfiguration={c => dispatch(modifyBlueprintGlobals({
    blueprint: selectCurrentBlueprintId()!,
    globals: c
  }))} />
}


import { selectInsertTrays, selectTray, selectTrayColor, selectTrayName, selectTrayPreview, useAppDispatch, useAppSelector } from "@/state/store";
import { ArrowLeftIcon, ArrowRightIcon, PaperPlaneIcon, RocketIcon, UploadIcon } from "@radix-ui/react-icons";
import { Button } from "@ui/button";
import { useEffect, useRef, useState } from "react";
import { Label } from "@ui/label";
import { Input } from "@ui/input";
import { useNavigate } from "react-router-dom";
import { useAddCameraMutation } from "@/state/api/cameras";
import { PageHeader } from "@ui/pageHeader";
import { SectionHeader } from "@ui/sectionHeader";
import { SectionDescription } from "@ui/sectionDescription";
import { Section } from "@ui/section";
import { SectionLabel } from "@ui/sectionLabel";
import { Switch } from "@ui/switch";
import { Editor } from "@ui/editor";
import { ScrollArea } from "@ui/scroll-area";
import { shallowEqual } from "react-redux";
import { Spinner } from "@ui/spinner";
import { Page } from "@ui/page";
import { Tray, TrayModel, TrayType } from "@/types";
import { DialogCloseDefault, DialogDescription, DialogFooter, DialogTitle } from "@ui/dialog";
import { Skeleton } from "@ui/skeleton";
import { Stripes } from "@ui/stripes";
import { Placeholder } from "@ui/placeholder";
import { CanvasCapture } from "../canvas/CanvasCapture";
import { generateTrayGeometries } from "@/replicadWorkerPool";
import { PhotoStageHandle } from "../canvas/PhotoStage";
import { modifyInsert, modifyTrayImages } from "@/state/model";
import { getCachedImage } from "@/imageCache";
import { ImagesDialogImagesAdd, InsertDialogImagesList } from "./InsertPropertyDrawerImages";
import { InputSubmit } from "@ui/inputSubmit";

function InsertDialogPublishTrayCard({ trayId, isSelected, setSelectedTrayId }: { trayId: number, isSelected: boolean, setSelectedTrayId: (trayId: number) => void }) {
  const trayName = useAppSelector(s => selectTrayName(trayId, s));
  const trayColor = useAppSelector(s => selectTrayColor(trayId, s));
  const preview = useAppSelector(s => selectTrayPreview(trayId, s));
  return (
    <div className={"relative rounded border cursor-pointer" + (isSelected ? " border-primary" : "")} onClick={() => setSelectedTrayId(trayId)}>
      {preview === undefined ? (
        <Placeholder className="h-[128px] w-[192px] rounded" type="info">
          No preview
        </Placeholder>
      ) : (
        <img src={getCachedImage(preview)} className="h-[128px] w-[192px] rounded bg-control" />
      )}
      <div className={"absolute -bottom-px -left-px rounded-es rounded-se px-1" + (isSelected ? " bg-primary text-primary-foreground" : " bg-border")}>
        {trayName}
      </div>
    </div>
  );
}

function InsertDialogPublishTray({ trayId }: { trayId: number }) {
  const dispatch = useAppDispatch();
  const selectedTrayImages = useAppSelector(s => selectTray(trayId).images);
  return (
    <>
      <ImagesDialogImagesAdd trayId={trayId} updateImages={images => dispatch(modifyTrayImages({
        tray: trayId,
        images,
      }))} />
      <InsertDialogImagesList images={selectedTrayImages} updateImages={images => dispatch(modifyTrayImages({
        tray: trayId,
        images,
      }))} />
    </>
  );
}


function InsertDialogPublishInsertCapture() {
  /*
  const [models, setModels] = useState<TrayModel[]>();
  useEffect(() => {
    const work = async () => {
      const result = await generateTrayGeometries(trayId, false);
      if (result.length > 0) {
        setModel({
          ...result[0],
          color: selectTrayColor(trayId),
          trayId: trayId,
        });
      }
    };
    work();
  }, [trayId]);
  const captureRef = useRef<PhotoStageHandle>(null);
  if (model === undefined) {
    return (
      <Placeholder>
        Generating preview...
      </Placeholder>
    );
  }
  return (
    <CanvasCapture model={model} width={768} height={512} ref={captureRef} addImage={addImage} />
  );*/
  return <></>;
}

export default function InsertDialogPublish() {
  const dispatch = useAppDispatch();
  const model = useAppSelector(s => s.model);
  const trays = useAppSelector(s => selectInsertTrays(s));
  const [selectedTrayId, setSelectedTrayId] = useState(trays[0].id);
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);
  const inputFile = useRef<HTMLInputElement>(null);
  const [step, setStep] = useState<"tray images" | "insert images" | "insert">("tray images");
  const missingPreviews = trays.filter(t => t.images.length === 0).length;
  /*
  if (session.user === undefined) {
    return <Navigate to="/?login=true&redirect=workbench" />;
  }*/
  return (
    <>
      <DialogTitle>
        Publish insert
      </DialogTitle>
      <DialogDescription>
        Publish your creation to earn fame and fortune. TODO: maybe change this text as there really is no way to make money with publishing inserts.
      </DialogDescription>
      {step === "tray images" && (
        <>
          <SectionLabel>
            Trays
          </SectionLabel>
          <div className="-mx-4 flex flex-col gap-2 border-y bg-background p-4">
            <div className="grid auto-cols-max grid-flow-col gap-2">
              {trays.map(t => <InsertDialogPublishTrayCard key={t.id} trayId={t.id} isSelected={t.id === selectedTrayId} setSelectedTrayId={setSelectedTrayId} />)}
            </div>
          </div>
          <InsertDialogPublishTray trayId={selectedTrayId} />
        </>
      )}
      {step === "insert images" && (
        <>
          <InsertDialogPublishInsertCapture/>
          <input type="file" id="file" multiple accept=".jpg,.jpeg" ref={inputFile} style={{ display: "none" }} />
          <Button disabled={busy} variant="outline" onClick={() => {
            inputFile.current!.click();
          }}>
            <UploadIcon />
            Upload images
          </Button>
          <div className="flex-auto" />
        </>
      )}
      {step === "insert" && (
        <>
          <div className="flex gap-4">
            <div className="flex grow flex-col gap-2">
              <SectionLabel htmlFor="name">
                Insert name
              </SectionLabel>
              <InputSubmit id="name" initialValue={model.insert.name ?? ""} applyValue={v => {
                dispatch(modifyInsert({
                  name: v,
                }));
              }} />
            </div>
            <div className="flex grow flex-col gap-2">
              <SectionLabel htmlFor="path">
                Insert path
              </SectionLabel>
              <InputSubmit id="path" initialValue={model.insert.path ?? ""} applyValue={v => {
                dispatch(modifyInsert({
                  path: v,
                }));
              }} />
            </div>
          </div>
          <SectionLabel htmlFor="description">
            Insert description
          </SectionLabel>
          <Editor id="description" imageResolver={name => ""} className="min-h-96" />
          <SectionLabel>
            Settings
          </SectionLabel>
          <div className="flex items-center gap-2">
            <Switch id="remix" />
            <Label htmlFor="remix" className="">
              Allow others to remix this insert
            </Label>
          </div>
        </>
      )}
      <DialogFooter>
        {step === "tray images" && (
          <>
            <Button disabled={missingPreviews > 0} variant="primary" onClick={() => setStep("insert images")}>
              Next
              <ArrowRightIcon />
            </Button>
            <div className="grow text-error-foreground">
              {missingPreviews > 0 && "Some trays still have no preview image."}
            </div>
            <div className="text-foreground-muted">
              <span className="font-semibold ">Tray images</span> / Insert images / Insert description
            </div>
          </>
        )}
        {step === "insert images" && (
          <>
            <Button onClick={() => setStep("tray images")}>
              <ArrowLeftIcon />
              Back
            </Button>
            <Button disabled={missingPreviews > 0} variant="primary" onClick={() => setStep("insert")}>
              Next
              <ArrowRightIcon />
            </Button>
            <div className="grow text-error-foreground">
              {missingPreviews > 0 && "Some trays still have no preview image."}
            </div>
            <div className="text-foreground-muted">
              Tray images / <span className="font-semibold ">Insert images</span> / Insert description
            </div>
          </>
        )}
        {step === "insert" && (
          <>
            <Button onClick={() => setStep("insert images")}>
              <ArrowLeftIcon />
              Back
            </Button>
            <Button disabled={missingPreviews > 0} variant="primary" onClick={() => setStep("insert")}>
              Publish
              <RocketIcon />
            </Button>
            <div className="grow text-error-foreground">
              {missingPreviews > 0 && "Some trays still have no preview image."}
            </div>
            <div className="text-foreground-muted">
              Tray images / Insert images / <span className="font-semibold ">Insert description</span>
            </div>
          </>
        )}
      </DialogFooter>
      <DialogCloseDefault />
    </>
  );
}
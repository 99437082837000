import { ModelRecoryType } from "@/types"
import InsertToolbarRecordIcon from "./insert/InsertToolbarRecordIcon";
import React from "react";
import { Badge } from "@ui/badge";

export default function HistoryChange({ change, type, inactive }: { change: string, type: ModelRecoryType, inactive: boolean }) {
  const inlines: React.ReactNode[] = [];
  let index = 0;
  while(index < change.length) {
    const startIndices = [change.indexOf("v(", index), change.indexOf("n(", index), change.indexOf("c(", index), change.indexOf("u(", index), change.indexOf("t(", index)].filter(i => i != -1);
    const startIndex = startIndices.length == 0 ? -1 : Math.min(...startIndices);
    if(startIndex == -1) {
      // push remaining text
      inlines.push(<>{change.substring(index)}</>);
      break;
    } else {
      // prepend text
      inlines.push(change.substring(index, startIndex));
      // add styled segment
      const endIndex = change.indexOf(")", startIndex);
      if(endIndex == -1) {
        console.error("invalid formatted string: " + change);
      } else {
        if(change[startIndex] === "v") {
          inlines.push(<span className="italic">{change.substring(startIndex + 2, endIndex)}</span>);
        } else if(change[startIndex] === "n") {
          inlines.push(<span className="font-semibold">{change.substring(startIndex + 2, endIndex)}</span>);
        } else if(change[startIndex] === "u") {
          inlines.push(<span className="underline">{change.substring(startIndex + 2, endIndex)}</span>);
        } else if(change[startIndex] === "t") {
          inlines.push(<Badge className="rounded-t-none">{change.substring(startIndex + 2, endIndex)}</Badge>);
        } else {
          inlines.push(<span className="font-mono italic">{change.substring(startIndex + 2, endIndex)}</span>);
        }
        index = endIndex + 1;
      }
    }
  }
  return (
    <div className={"flex gap-2 items-center" + (inactive ? " text-foreground-muted" : "")}>
      <InsertToolbarRecordIcon type={type}/>
      <div>
        {...inlines}
      </div>
  </div>
  );
}
import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import modelSlice from './model';
import sessionSlice from './session';
import settingsSlice from './settings';
import { setupListeners } from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ContainerElementBlueprint, ElementBlueprint, Insert, PocketElementBlueprint, Tray, TrayElement } from '@/types';
import { getPartColor } from '@/catalog';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ 
    baseUrl: import.meta.env.MODE == "development" ? "http://localhost:3000" : "https://api.boardgameinserts.xyz",
    prepareHeaders: (headers, api) => {
      const token = (api.getState() as RootState).session.token;
      if(token !== undefined) {
        headers.set("Authorization", "Bearer " + token);
      }
      return headers;
    }
  }),
  tagTypes: [ "documents", "cameras", "boxes", "printers", "trays" ],
  endpoints: () => ({
  }),
});

export const store = configureStore({
  reducer: {
    model: modelSlice,
    settings: settingsSlice,
    session: sessionSlice,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export function getState(): RootState {
  return store.getState();
}

//#region tray

export function selectTray(trayId: number, state: RootState = getState()) {
  return state.model.trays[trayId];
}

export function selectCurrentTray(state: RootState = getState()) {
  if(state.model.selection.scope === "insert" && state.model.selection.aspect === "tray") {
    if(state.model.selection.trayId in state.model.trays) {
      return state.model.trays[state.model.selection.trayId];
    }
  }
  return null;
}

export function selectCurrentTrayId(state: RootState = getState()) {
  if(state.model.selection.scope === "insert" && state.model.selection.aspect === "tray") {
    return state.model.selection.trayId;
  }
  return null;
}

/**
 * make sure to use shallowEqual ar another applicable comparator when returning an object
 */
export function selectFromTray<T>(state: RootState, trayId: number, selector: (tray: Tray) => T) {
  return selector(selectTray(trayId, state));
}

export function selectBlueprintForTray(trayId: number, state: RootState = getState()) {
  return selectBlueprint(selectTray(trayId, state).blueprintId);
}

export function selectBlueprintForCurrentTray(state: RootState = getState()) {
  if(state.model.selection.scope === "insert" && state.model.selection.aspect === "tray") {
    if(state.model.selection.trayId in state.model.trays) {
      return state.model.blueprints[state.model.trays[state.model.selection.trayId].blueprintId];
    }
  }
  return null;
}

export function getTrayName(blueprintName: string, trayName: string | undefined) {
  if(trayName === undefined || trayName.trim().length === 0) {
    return blueprintName;
  } else {
    return `${trayName} (${blueprintName})`;
  }
}

export function selectTrayName(trayId: number, state: RootState = getState()) {
  try {
  return getTrayName(state.model.blueprints[state.model.trays[trayId].blueprintId].name, state.model.trays[trayId].name);
  } catch {
    console.error(`failed to select name of tray ${trayId}`);
    return "tray";
  }
}

export function selectTrayPreview(trayId: number, state: RootState = getState()) {
  if(state.model.trays[trayId].images.length > 0) {
    return state.model.trays[trayId].images[0];
  }
  return undefined;
}

export function selectTrayColor(trayId: number, state: RootState = getState()) {
  try {
    const tray = selectTray(trayId, state);
    const insert = selectInsert(state);
    if(tray.color !== undefined) {
      return tray.color;
    }
    const association = getTrayAssociation(tray, insert);
    if(association === null) {
      return getPartColor(0);
    }
    return association.color;
  } catch {
    console.error(`failed to select color of tray ${trayId}`);
    return getPartColor(0);
  }
}

export function getTrayAssociation(tray: Tray, insert: Insert) {
  if(tray.associatedGameId === undefined) {
    if(insert.boardgame !== undefined) {
      return insert.boardgame;
    }
  } else {
    if(insert.boardgame !== undefined && insert.boardgame.id === tray.associatedGameId) {
      return insert.boardgame;
    } else {
      const expansion = insert.boardgameExpansions?.find(e => e.id === tray.associatedGameId);
      if(expansion !== undefined) {
        return expansion;
      }
    }
  }
  return null;
}

export function getTrayAssociationName(tray: Tray, insert: Insert, useBaseGame?: boolean) {
  const association = getTrayAssociation(tray, insert);
  if(association === null) {
    return "Base game";
  } else if(association.id === insert.boardgame?.id) {
    return useBaseGame ? "Base game" : association.name;
  } else {
    return association.name;
  }
}

export function selectTrayAssociation(trayId: number, state: RootState = getState()) {
  return getTrayAssociation(state.model.trays[trayId], state.model.insert);
}

//#endregion tray

//#region blueprint

export function selectBlueprint(blueprintId: number, state: RootState = getState()) {
  return state.model.blueprints[blueprintId];
}

export function selectCurrentBlueprint(state: RootState = getState()) {
  if(state.model.selection.scope === "blueprint") {
    if(state.model.selection.blueprintId in state.model.blueprints) {
      return state.model.blueprints[state.model.selection.blueprintId];
    }
  }
  return null;
}

export function selectCurrentBlueprintId(state: RootState = getState()) {
  if(state.model.selection.scope === "blueprint") {
    return state.model.selection.blueprintId;
  }
  return null;
}

export function selectTraysForBlueprint(blueprintId: number, state: RootState = getState()) {
  return Object.values(state.model.trays).filter(t => t.blueprintId == blueprintId).sort((a, b) => a.type - b.type);
}

//#endregion

//#region element

export function selectCurrentElement(state: RootState = getState()) {
  if(state.model.selection.aspect === "element") {
    if(state.model.selection.blueprintId in state.model.blueprints) {
      if(state.model.selection.elementId in state.model.blueprints[state.model.selection.blueprintId].elements)
      return state.model.blueprints[state.model.selection.blueprintId].elements[state.model.selection.elementId];
    }
  }
  return null;
}

export function selectCurrentElementId(state: RootState = getState()) {
  if(state.model.selection.aspect === "element") {
    return state.model.selection.elementId;
  }
  return null;
}


export function selectElement(elementId: number, state: RootState = getState()) {
  if(state.model.selection.scope === "blueprint") {
    if(state.model.selection.blueprintId in state.model.blueprints) {
      if(elementId in state.model.blueprints[state.model.selection.blueprintId].elements)
      return state.model.blueprints[state.model.selection.blueprintId].elements[elementId];
    }
  }
  return null;
}

export function selectElementParent(elementId: number, blueprintId: number, state: RootState = getState()) {
  if(elementId in state.model.blueprints[blueprintId].elements) {
    for(const id in state.model.blueprints[blueprintId].elements) {
      if(state.model.blueprints[blueprintId].elements[id].type !== "pocket" && state.model.blueprints[blueprintId].elements[id].childs.includes(elementId)) {
        return state.model.blueprints[blueprintId].elements[id].id;
      }
    }
  }
  return null;
}

/**
 * make sure to use shallowEqual ar another applicable comparator when returning an object
 */
export function selectFromCurrentElement<T>(state: RootState, selector: (element: ElementBlueprint) => T) {
  const element = selectCurrentElement(state);
  if(element !== null) {
    return selector(element);
  }
  return null;
} 


//#endregion

//#region insert

export function selectInsert(state: RootState = getState()) {
  return state.model.insert;
}

export function selectInsertTrays(state: RootState = getState()) {
  return state.model.insert.trayIds.map(id => state.model.trays[id]);
}

export function selectInsertName(state: RootState = getState()) {
  const insert = selectInsert(state);
  if(insert.boardgame !== undefined) {
    if(insert.name) {
      return `${insert.name} (${insert.boardgame.name})`;
    } else {
      return insert.boardgame.name;
    }
  } else {
    if(insert.name) {
      return insert.name;
    } else {
      return "unnamed insert";
    }
  }
}

//#endregion

//#region model

export function selectModel(state: RootState = getState()) {
  return state.model;
}

//#endregion
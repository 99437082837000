import React from "react"
import { cn } from "./utils"

export interface SectionDescriptionProps extends React.HTMLAttributes<HTMLParagraphElement> {
}

const SectionDescription = React.forwardRef<HTMLParagraphElement, SectionDescriptionProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <p className={cn("leading-tight text-foreground-muted -mt-1", className)} {...props}>
        {children}
      </p>
    )
  }
)

SectionDescription.displayName = "SectionDescription";

export { SectionDescription }





/* eslint-disable react/no-unknown-property */
import { TrayModel } from "@/types";
import { useEffect, useState } from "react";
import { generateTrayGeometries } from "@/replicadWorkerPool";
import Skeleton from "../canvas/Skeleton";
import { Vector3 } from "three";
import { ReplicadShapeInteractive } from "../canvas/ReplicadShape";
import { selectTrayColor, useAppSelector } from "@/state/store";

export default function InsertCanvasTray({ trayId, selectTray, positionOverride }: { 
  trayId: number,
  positionOverride?: Vector3,
  selectTray?: () => void,
 }) {
  const blueprintModelHash = useAppSelector(s => s.model.blueprints[s.model.trays[trayId].blueprintId].modelHash);
  const trayModelHash = useAppSelector(s => s.model.trays[trayId].modelHash);
  const offset = useAppSelector(s => s.model.trays[trayId].offset);
  const size = useAppSelector(s => s.model.trays[trayId].size);
  const bounds = useAppSelector(s =>s.model.trays[trayId].bounds);
  const color = useAppSelector(s => selectTrayColor(trayId, s));
  const [model, setModel] = useState<TrayModel>();
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const settingCanvasWireframe = useAppSelector(s => s.settings.settingCanvasWireframe);
  // regenerate model
  useEffect(() => {
    const work = async () => {
      setIsGenerating(true);
      const result = await generateTrayGeometries(trayId, false);
      if(result.length > 0) {
        setModel({
        ...result[0],
        color: color,
        trayId: trayId,
        });
      }
      setIsGenerating(false);
    };
    work();
  }, [ trayId, blueprintModelHash, trayModelHash ]);
  // color changes do not require regeneration
  useEffect(() => {
    if(model !== undefined) {
      setModel({
        ...model,
        color: color,
      });
    }
  }, [ color ]);
  return (
    <group position={positionOverride !== undefined ? positionOverride : new Vector3(offset.x, offset.y, offset.z)}>
      {model === undefined || isGenerating ? (
        <Skeleton size={size}/>
      ) : (
        <ReplicadShapeInteractive edgeOpacity={positionOverride === undefined ? 20 : 70} edgeDepthTest={!settingCanvasWireframe && (positionOverride === undefined)} model={model} edgesHighlight={[]} facesHighlight={[]} onEdgeClick={(e, index, edge) => { }} onFaceClick={(e, index, face) => { }} onClick={e => {
          if(selectTray !== undefined) {
            e.stopPropagation();
            if (e.delta === 0) {
              selectTray();
            }
          }
        }}/>
      )}
    </group>
  );
}
import { Button } from "./button"
import { Input } from "./input";
import { MagicWandIcon, MinusIcon, PlusIcon, ResetIcon } from "@radix-ui/react-icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";
import { MagicAction } from "@/types";
import { printable, roundNumber } from "@/utils";
import { cn } from "./utils";
import { isHotkeyPressed, useHotkeys } from 'react-hotkeys-hook'
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";

export interface InputNumberProps extends React.InputHTMLAttributes<HTMLInputElement> {
  validate?: (value: number) => true | string,
  value?: number,
  setValue: (value: number) => void,
  unit?: string,
  min?: number,
  max?: number,
  change?: number,
  magic?: MagicAction,
  alignValue?: boolean,
}

const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  ({ className, value, unit, min, max, magic, alignValue = false, change = 1, setValue, ...props }, ref) => {
    const [currentValue, setCurrentValue] = useState(value);
    const [error, setError] = useState<string | null>(null);
    const updateValue = (v: string | number | undefined, apply = false) => {
      let newError: string | null = null;
      if (typeof v === "string") {
        if (v === "") {
          v = undefined;
        } else {
          v = parseFloat(v);
        }
      }
      if (v !== undefined) {
        v = roundNumber(v);
        if (min !== undefined && v < min) {
          newError = "min " + printable(min);
        }
        if (max !== undefined && v > max) {
          newError = "max " + printable(max);
        }
      }
      setError(newError);
      if (apply && v !== undefined && error === null) {
        // will update current value automatically
        setValue(v);
      } else {
        setCurrentValue(v);
      }
    };
    useEffect(() => {
      updateValue(value);
    }, [ value ]);
    useEffect(() => {
      updateValue(currentValue);
    }, [ unit, min, max ]);
    const preventDecrease = currentValue === undefined || (min !== undefined && currentValue <= min);
    const preventIncrease = currentValue === undefined || (max !== undefined && currentValue >= max);
    return (
      <div className={cn("flex w-full items-center gap-px", className)}>
        <div className="relative grow">
          <Input type="number" inputMode="numeric" step={1} ref={ref} value={currentValue === undefined ? "" : currentValue} onKeyDownCapture={e => {
            if (e.key === "Shift") {
              e.currentTarget.step = "0.1";
            }
          }} onKeyUpCapture={e => {
            if (e.key === "Shift") {
              e.currentTarget.step = "1";
            }
          }} onFocus={e => {
          }} onBlur={e => {
            if (currentValue !== undefined) {
              setValue(currentValue);
            } else if (value !== undefined) {
              setCurrentValue(value);
            }
          }} onChange={e => {
            // sync value attribute to fix wheel behavior
            e.target.setAttribute("value", e.target.value);
            updateValue(e.target.value);
          }} onKeyDown={e => {
            if (e.key == "Enter") {
              if (currentValue !== undefined) {
                setValue(currentValue);
              } else if (value !== undefined) {
                setCurrentValue(value);
              }
            }
          }} className={cn("pe-[90px]", error !== null && "text-error")} {...props} />
          <div className="absolute inset-y-px end-px z-20 flex items-center">
            {error !== null ? (
              <div className="h-full flex items-center select-none px-2 select-none border-l text-error">
                {error}
              </div>
            ) : (
              <>
                {unit !== undefined && (
                  <div className="pointer-events-none flex h-full select-none items-center border-r pr-1 text-gray-500 ">
                    {unit}
                  </div>
                )}
                {magic !== undefined && (
                  <Button tabIndex={-1} onClick={() => {
                    const value = magic.handler();
                    if (value !== null) {
                      updateValue(value, true);
                    }
                  }} variant="ghost" className="h-full w-7 shrink-0 rounded-none p-0" tooltip={magic.tooltip}>
                    {magic.icon === "reset" ? <ResetIcon /> : <MagicWandIcon />}
                  </Button>
                )}
                <Button tabIndex={-1} onFocus={e => {
                  if (e.relatedTarget instanceof HTMLInputElement) {
                    e.relatedTarget.focus();
                  }
                }} onClick={e => {
                  const v = currentValue ?? value;
                  if (v !== undefined) {
                    const floor = Math.floor(v);
                    if (alignValue && floor !== v) {
                      updateValue(roundNumber(floor), true);
                    } else {
                      updateValue(roundNumber(v - change), true);
                    }
                  }
                }} disabled={preventDecrease} variant="ghost" className="h-full w-7 shrink-0 rounded-none p-0">
                  <MinusIcon />
                </Button>
                <Button tabIndex={-1} onFocus={e => {
                  if (e.relatedTarget instanceof HTMLInputElement) {
                    e.relatedTarget.focus();
                  }
                }} onClick={() => {
                  const v = currentValue ?? value;
                  if (v !== undefined) {
                    const ceil = Math.ceil(v);
                    if (alignValue && ceil !== v) {
                      updateValue(roundNumber(ceil), true);
                    } else {
                      updateValue(roundNumber(v + change), true);
                    }
                  }
                }} disabled={preventIncrease} variant="ghost" className="h-full w-7 shrink-0 rounded-e-md rounded-s-none p-0">
                  <PlusIcon />
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
);
InputNumber.displayName = "InputNumber";

export { InputNumber };

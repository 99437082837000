/* eslint-disable react/no-unknown-property */
import { useMemo, useCallback, forwardRef, useRef, useEffect } from "react";
import { ReplicadFacesMesh, ReplicadEdgesMesh } from "./ReplicadMesh";
import ClipPlane from "./ClipPlane";
import { ClippingPlaneConfiguration, GeometryEdge, GeometryFace, TrayModel, Vector } from "@/types";
import { ThreeEvent } from "@react-three/fiber";
import { getEdgeIndex, getFaceIndex } from "replicad-threejs-helper";
import { BufferGeometry, NormalBufferAttributes, Plane, Material, Mesh, Object3DEventMap } from "three";
import { lighten } from "polished";
import { normalFromOrientedAxis } from "@/utils";

export type ReplicadShapeHandle = Mesh<BufferGeometry<NormalBufferAttributes>, Material | Material[], Object3DEventMap>;

interface ReplicadShapeIntaractiveProps {
  model: TrayModel,
  onFaceClick: (e: ThreeEvent<PointerEvent>, index: number, face: GeometryFace) => void,
  onEdgeClick: (e: ThreeEvent<PointerEvent>, index: number, edge: GeometryEdge) => void,
  facesHighlight: number[],
  edgesHighlight: number[],
  clippingPlaneConfiguration?: ClippingPlaneConfiguration,
  edgeOpacity: number,
  edgeDepthTest?: boolean,
  color?: string,
  onClick?: (e: ThreeEvent<MouseEvent>) => void,
}

export function ReplicadShapeInteractive({
  model,
  onFaceClick,
  onEdgeClick,
  facesHighlight,
  edgesHighlight,
  clippingPlaneConfiguration,
  edgeOpacity,
  edgeDepthTest = true,
  color,
  onClick,
} : ReplicadShapeIntaractiveProps) {
  const onFacePointerDown = useCallback((e: ThreeEvent<PointerEvent>) => {
    if (e && e.faceIndex !== undefined) {
      // @ts-expect-error geometry is not known
      const faceIndex = getFaceIndex(e.faceIndex, e.object.geometry);
      if (faceIndex < model.faces.length) {
        onFaceClick(e, faceIndex, model.faces[faceIndex]);
      }
    }
  }, [onFaceClick, model]);
  const onEdgePointerDown = useCallback((e: ThreeEvent<PointerEvent>) => {
    if (e && e.index !== undefined) {
      // @ts-expect-error geometry is not known
      const edgeIndex = getEdgeIndex(e.index, e.object.geometry);
      if (edgeIndex < model.edges.length) {
        onEdgeClick(e, edgeIndex, model.edges[edgeIndex]);
      }
    }
  }, [onEdgeClick, model]);
  return (
    <ClipPlane sideColor={"white"} configuration={clippingPlaneConfiguration}>
      <ReplicadFacesMesh faces={model.faceGeometry} highlight={facesHighlight} onPointerDown={onFacePointerDown} onClick={onClick}>
        <meshMatcapMaterial attach="material-0" color={color ?? model.color} polygonOffset polygonOffsetFactor={0.5} polygonOffsetUnits={0.5} />
        <meshMatcapMaterial attach="material-1" color={color ?? model.color} polygonOffset polygonOffsetFactor={0.5} polygonOffsetUnits={0.5} />
      </ReplicadFacesMesh>
      <ReplicadEdgesMesh edges={model.edgeGeometry} highlight={edgesHighlight} onPointerDown={onEdgePointerDown}>
        <lineBasicMaterial attach="material-0" color="white" opacity={Math.max(0, Math.min(1, edgeOpacity/100))} transparent={true} depthTest={edgeDepthTest}/>
        <lineBasicMaterial attach="material-1" color="white" opacity={Math.max(0, Math.min(1, edgeOpacity/100))} transparent={true}/>
      </ReplicadEdgesMesh>
    </ClipPlane>
  );
}

interface ReplicadShapeProps {
  model: TrayModel,
  color?: string,
  edgeOpacity: number,
  edgeDepthTest?: boolean,
  onClick?: (e: ThreeEvent<MouseEvent>) => void,
}

const ReplicadShape = forwardRef<ReplicadShapeHandle, ReplicadShapeProps>(({ model,
  color,
  edgeOpacity,
  edgeDepthTest = true,
  onClick,
}, ref) => {
  return (
    <>
      <ReplicadFacesMesh ref={ref} faces={model.faceGeometry} onClick={onClick}>
        <meshMatcapMaterial attach="material-0" color={color ?? model.color} polygonOffset polygonOffsetFactor={0.5} polygonOffsetUnits={0.5}/>
      </ReplicadFacesMesh>
      <ReplicadEdgesMesh edges={model.edgeGeometry}>
        <lineBasicMaterial attach="material-0" color="white" opacity={Math.max(0, Math.min(1, edgeOpacity/100))} transparent={true} depthTest={edgeDepthTest}/>
      </ReplicadEdgesMesh>
    </>
  );
});
ReplicadShape.displayName = "ReplicadShape";

export { ReplicadShape };
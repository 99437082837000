import { GameDetails } from "@/state/api/boardgames";
import { CalendarIcon, ClockIcon, CubeIcon, ExternalLinkIcon } from "@radix-ui/react-icons";
import { Badge } from "@ui/badge";
import { Button } from "@ui/button";
import { SectionDescription } from "@ui/sectionDescription";

export function GameCard({ data }: { data: GameDetails }) {
  return (
    <div className="flex">
      <div className="rounded-l border bg-background p-2">
        <img src={data.image} className="w-32 rounded" />
      </div>
      <div className="flex flex-auto flex-col items-start gap-2 rounded-r border-y border-r p-2">
        <div className="text-lg">{data.name}</div>
        <div className="flex flex-wrap gap-2">
          <Badge className="gap-2">
            <CalendarIcon/>
            {data.year}
          </Badge>
          <Button onClick={() => window?.open(`https://boardgamegeek.com/boardgame/${data.id}`, '_blank')?.focus()} size="sm">
            <ExternalLinkIcon />
            {data.id}
          </Button>
        </div>
      </div>
    </div>
  );
}
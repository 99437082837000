import { ChatBubbleIcon, HomeIcon } from "@radix-ui/react-icons";
import { Button } from "@ui/button";
import { Failure } from "@ui/failure";
import { useLocation, useNavigate } from "react-router-dom";

export default function Error404() { 
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Failure message={(
      <>
        <p className="text-center">
          It seems like you ventured deep into the dungeon. 
          <br/>
          And maybe got lost?
        </p>
        <div className="mt-2 flex flex-wrap justify-center gap-2">
          <Button>
            <ChatBubbleIcon/>
            Report a problem
          </Button>
          <Button onClick={() => navigate("/")}>
            <HomeIcon/>
            Back to the surface
          </Button>
        </div>
      </>
    )}>
      <span className="font-mono">{location.pathname}</span> <span className="text-foreground-muted">does not exist</span>
    </Failure>
  );
}
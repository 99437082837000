import * as fflate from 'fflate';

interface Vertex {
  x: number,
  y: number,
  z: number,
}

type Triangle = number[];

interface ModelRelationship {
  id: string, 
  path: string,
  type: string,
}

interface Plate {
  models: PlateModel[],
  width: number,
  height: number,
  name: string,
}

interface PlateModel {
  x: number,
  y: number,
  model: Blob,
  name: string,
  id?: number,
  minZ?: number,
}

export async function exportZip(models: {
  model: Blob,
  name: string,
}[]) {
  const files: { [name: string] : Uint8Array } = {};
  for(const m of models) {
    files[m.name] = new Uint8Array(await m.model.arrayBuffer());
  }
  const archive = fflate.zipSync(files);
  return new Blob([ archive ], {
    type: "application/zip",
  });
}

export async function export3mf(plates: Plate[]) {
  const contentTypesFile = contentTypes();
  const rootRelsFile = rels([{
    id: "rel-1",
    path: "/3D/3dmodel.model",
    type: "http://schemas.microsoft.com/3dmanufacturing/2013/01/3dmodel",
  }]);
  const models = plates.reduce<PlateModel[]>((a, b) => a.concat(b.models), []);
  const modelFiles: fflate.Zippable = {};
  const modelRelationShips: ModelRelationship[] = [];
  let lastId = 1;
  for(const m of models) {
    m.id = lastId;
    const convertedModel = await convertModel(m.model);
    m.minZ = convertedModel.minZ;
    modelFiles[`${m.name}.stl_${lastId}.model`] = new Uint8Array(await convertedModel.blob.arrayBuffer());
    modelRelationShips.push({
      id: `rel-${lastId}`,
      path: `/3D/Objects/${m.name}.stl_${lastId}.model`,
      type: "http://schemas.microsoft.com/3dmanufacturing/2013/01/3dmodel",
    });
    lastId += 2;
  }
  const threeDeeRelsFile = rels(modelRelationShips);
  const threeDeeModelFile = model(models);
  const archive = fflate.zipSync({
    "[Content_Types].xml": new Uint8Array(await contentTypesFile.arrayBuffer()),
    "_rels": {
      ".rels": new Uint8Array(await rootRelsFile.arrayBuffer()),
    },
    "Metadata": {

    },
    "3D": {
      "_rels": {
        "3dmodel.model.rels": new Uint8Array(await threeDeeRelsFile.arrayBuffer()),
      },
      "3dmodel.model": new Uint8Array(await threeDeeModelFile.arrayBuffer()),
      "Objects": modelFiles,
    }
  });
  return new Blob([ archive ], {
    type: "application/zip",
  });
}

async function convertModel(stl: Blob) {
  const start = Date.now();
  const content = await stl.text();
  const lines = content.split("\n");
  const vertexLookup: { [key: string]: number} = {};
  const vertices: Vertex[] = [];
  let minZ = 1000;
  let loopVertices: number[] = [];
  const triangles: Triangle[] = [];
  let xml = '<?xml version="1.0" encoding="UTF-8"?>\n';
  xml += '<model unit="millimeter" xml:lang="en-US" xmlns="http://schemas.microsoft.com/3dmanufacturing/core/2015/02" xmlns:BambuStudio="http://schemas.bambulab.com/package/2021">\n';
  xml += '<resources>\n';
  xml += '<object id="1" type="model">\n';
  xml += '<mesh>\n';
  xml += '<vertices>\n';
  for(const line of lines) {
    const segments = line.split(" ").filter(s => s !== "");
    if(segments.length === 2 && segments[0] === "outer" && segments[1] === "loop") {
      loopVertices = [];
    }
    if(segments.length === 4 && segments[0] === "vertex") {
      const x = parseFloat(segments[1]);
      const y = parseFloat(segments[2]);
      const z = parseFloat(segments[3]);
      if(z < minZ) {
        minZ = z;
      }
      const key = x.toString() + "/" + y.toString() + "/" + z.toString();
      if(key in vertexLookup) {
        loopVertices.push(vertexLookup[key]);
      } else {
        xml += `<vertex x="${x}" y="${y}" z="${z}"/>\n`;
        vertexLookup[key] = vertices.length;
        loopVertices.push(vertices.length);
        vertices.push({
          x, y, z
        });
      }
    }
    if(segments.length === 1 && segments[0] === "endfacet") {
      if(loopVertices.length !== 3) {
        throw "incomplete facet";
      }
      triangles.push(loopVertices);
    }
  }
  xml += '</vertices>\n';
  xml += '<triangles>\n';
  for(const triangle of triangles) {
    xml += `<triangle v1="${triangle[0]}" v2="${triangle[1]}" v3="${triangle[2]}"/>\n`;
  }
  xml += '</triangles>\n';
  xml += '</mesh>\n';
  xml += '</object>\n';
  xml += '</resources>\n';
  xml += '<build/>\n';
  xml += '</model>';
  console.log(`3mf conversion: ${Date.now() - start}ms`);
  return {
    blob: new Blob([ xml ], {
      type: "application/xml"
    }),
    minZ,
  };
}

function contentTypes() {
  return new Blob([ 
`<?xml version="1.0" encoding="UTF-8"?>
<Types xmlns="http://schemas.openxmlformats.org/package/2006/content-types">
 <Default Extension="rels" ContentType="application/vnd.openxmlformats-package.relationships+xml"/>
 <Default Extension="model" ContentType="application/vnd.ms-package.3dmanufacturing-3dmodel+xml"/>
 <Default Extension="png" ContentType="image/png"/>
 <Default Extension="gcode" ContentType="text/x.gcode"/>
</Types>`
  ], {
    type: "application/xml"
  });
}

function rels(relationships: ModelRelationship[]) {
  return new Blob([ 
`<?xml version="1.0" encoding="UTF-8"?>
<Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">
${relationships.map(r => ` <Relationship Target="${r.path}" Id="${r.id}" Type="${r.type}"/>`).join("\n")}  
</Relationships>`
  ], {
    type: "application/xml"
  });
}

function model(models: PlateModel[]) {
  const date = new Date();
  const timestamp = date.toISOString().split('T')[0];
  let content = 
`<?xml version="1.0" encoding="UTF-8"?>
<model unit="millimeter" xml:lang="en-US" xmlns="http://schemas.microsoft.com/3dmanufacturing/core/2015/02" xmlns:BambuStudio="http://schemas.bambulab.com/package/2021">
 <metadata name="Application">boardgameinserts.xyz</metadata>
 <metadata name="CreationDate">${timestamp}</metadata>
 <metadata name="Description"></metadata>
 <metadata name="Designer"></metadata>
 <metadata name="DesignerCover"></metadata>
 <metadata name="DesignerUserId"></metadata>
 <metadata name="License"></metadata>
 <metadata name="ModificationDate">${timestamp}</metadata>
 <metadata name="Origin"></metadata>
 <metadata name="Title"></metadata>
 <resources>
`;
  for(let index = 0; index < models.length; index++) {
    content +=
`  <object id="${index * 2 + 2}" type="model">
   <components>
    <component p:path="/3D/Objects/${models[index].name}.stl_${models[index].id!}.model" objectid="${index * 2 + 1}" transform="1 0 0 0 1 0 0 0 1 0 0 0"  printable="1"/>
   </components>
  </object>
`;
  }
  content +=
` </resources>
 <build>
`;
 for(let index = 0; index < models.length; index++) {
  content += `  <item objectid="${index * 2 + 2}" transform="1 0 0 0 1 0 0 0 1 ${models[index].x} ${models[index].x} ${-models[index].minZ!}" printable="1"/
`;
 }
 content += 
` </build>
</model>`;
  return new Blob([ content ], {
    type: "application/xml"
  });
}


function modelSettings(models: PlateModel[]) {
  let content = "";
  return new Blob([ content ], {
    type: "application/xml"
  });
}

import { Tray, TrayModel } from "@/types";
import { Button } from "@ui/button";
import { Dialog, DialogClose, DialogCloseDefault, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@ui/dialog";
import { CanvasCapture } from "../canvas/CanvasCapture";
import { cacheImage, getCachedImage } from "@/imageCache";
import { selectTray, selectTrayColor, useAppDispatch, useAppSelector } from "@/state/store";
import { modifyTrayImages } from "@/state/model";
import { useEffect, useRef, useState } from "react";
import { generateTrayGeometries } from "@/replicadWorkerPool";
import { Placeholder } from "@ui/placeholder";
import { PhotoStageHandle } from "../canvas/PhotoStage";
import { CameraIcon, Pencil2Icon, PlusCircledIcon, TrashIcon } from "@radix-ui/react-icons";
import { DndContext } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';

function InsertDialogImage({ image, isPrimary, deleteImage }: { image: string, isPrimary: boolean, deleteImage: () => void }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: image,
  });
  return (
    <div ref={setNodeRef} style={{
      transform: CSS.Translate.toString(transform),
      transition,
    }} className={"relative border rounded bg-control" + (isPrimary ? " border-primary" : "") + (isDragging ? " z-20" : "")}>
      <img src={getCachedImage(image)} className="h-[128px] w-[192px] rounded bg-control" />
      {isPrimary && (
        <div className="absolute -bottom-px -left-px rounded-es rounded-se bg-primary px-1 font-semibold text-primary-foreground">
          Preview
        </div>
      )}
      <button {...listeners} {...attributes} className="absolute inset-0" />
      <div className="absolute right-1 top-1 flex gap-1">
        <Button variant="destructive" className="size-7 p-0" onClick={() => deleteImage()}>
          <TrashIcon />
        </Button>
      </div>
    </div>
  );
}

export function InsertDialogImagesList({ images, updateImages }: { images: string[], updateImages: (images: string[]) => void }) {
  const inputFile = useRef<HTMLInputElement>(null);
  return (
    <div className="flex w-full flex-col gap-2">
      <DndContext autoScroll={false} onDragEnd={e => {
        if (e.over === null) {
          return;
        }
        //@ts-expect-error using string items instead of id+data objects
        const activeIndex = images.indexOf(e.active.id);
        //@ts-expect-error using string items instead of id+data objects
        const overIndex = images.indexOf(e.over.id);
        const current = [...images];
        const removed = current.splice(activeIndex, 1);
        current.splice(overIndex, 0, ...removed);
        updateImages(current);
      }} modifiers={[]}>
        <SortableContext items={images.map(i => i)} id="trayImages" strategy={rectSortingStrategy}>
          {images.length > 0 ? (
            <>
              <div className="flex flex-wrap gap-2">
                {images.map(image => <InsertDialogImage key={image} image={image} isPrimary={image === images[0]} deleteImage={() => updateImages(images.filter(i => i !== image))} />)}
              </div>
            </>
          ) : (
            <div className="flex w-full items-center justify-center gap-1 p-1 text-sm text-foreground-muted">
              Add images using the <CameraIcon /> button.
            </div>
          )}
        </SortableContext>
      </DndContext>
      <input type="file" id="file" multiple accept=".jpg,.jpeg" ref={inputFile} style={{ display: "none" }} onChange={e => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files![0]);
        reader.onloadend = () => {
          const name = cacheImage(reader.result as string);
          if(!images.includes(name)) {
            updateImages([...images, name]);
          }
        }
      }} />
      <Button className="self-start" onClick={() => {
        inputFile.current!.click();
      }}>
        <PlusCircledIcon className="size-5 shrink-0" />
        Upload photo of printed model
      </Button>
    </div>
  );
}

export function ImagesDialogImagesAdd({ trayId, updateImages }: { trayId: number, updateImages: (images: string[]) => void }) {
  const [model, setModel] = useState<TrayModel>();
  const images = useAppSelector(s => selectTray(trayId, s).images);
  useEffect(() => {
    const work = async () => {
      const result = await generateTrayGeometries(trayId, false);
      if (result.length > 0) {
        setModel({
          ...result[0],
          color: selectTrayColor(trayId),
          trayId: trayId,
        });
      }
    };
    work();
  }, [trayId]);
  if (model === undefined) {
    return (
      <Placeholder>
        Generating preview...
      </Placeholder>
    );
  }
  return (
    <CanvasCapture model={model} width={768} height={512} addImage={image => {
      const name = cacheImage(image);
      if (!images.includes(name)) {
        updateImages([...images, name]);
      }
    }} />
  );
}

export function InsertPropertyDrawerImages({ trayId }: { trayId: number }) {
  const dispatch = useAppDispatch();
  const images = useAppSelector(s => selectTray(trayId, s).images);
  return (
    <div className="flex">
      <Dialog>
        <DialogTrigger asChild>
          <Button size="sm" className="w-full">
            <Pencil2Icon />
            Edit images
            <div className=" grow text-end font-normal text-foreground-muted">
              count: {images.length}
            </div>
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogTitle>
            Tray images
          </DialogTitle>
          <DialogDescription>
            These images are used when publishing to provide more information. Note that these images can also be managed in the publish wizard.
          </DialogDescription>
          <ImagesDialogImagesAdd trayId={trayId} updateImages={images => dispatch(modifyTrayImages({
            tray: trayId,
            images,
          }))} />
          <DialogFooter>
            <InsertDialogImagesList images={images} updateImages={images => dispatch(modifyTrayImages({
              tray: trayId,
              images,
            }))} />
          </DialogFooter>
          <DialogCloseDefault />
        </DialogContent>
      </Dialog>
    </div>
  );
}
import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from "@ui/popover";
import { Message, MessageSeverity, useMessages } from "@/state/messages";
import { getTimestampDescription } from "@/utils";
import { CrossCircledIcon, ExclamationTriangleIcon, InfoCircledIcon, TrashIcon } from "@radix-ui/react-icons";
import { cn } from "@ui/utils";
import { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "@ui/button";
import { useHotkeys } from "react-hotkeys-hook";
import { Icon } from "@ui/icon";
import { Hotkey } from "@ui/hotkey";
import { Separator } from "@ui/separator";

function getMessageSeverityColor(severity: MessageSeverity) {
  switch (severity) {
    case MessageSeverity.Error: return "red-600";
    case MessageSeverity.Warning: return "yellow-600";
    case MessageSeverity.Info: return "foreground";
  }
}

function SeverityIcon({ severity, className }: { severity: MessageSeverity, className?: string }) {
  switch (severity) {
    case MessageSeverity.Error: return  <Icon name="exclamation-diamond" className={cn("text-error-foreground", className)}/>;
    case MessageSeverity.Warning: return <Icon name="exclamation-triangle" className={cn("text-warning-foreground", className)}/>;
    case MessageSeverity.Info: return <Icon name="exclamation-circle" className={cn("text-info-foreground", className)}/>;
  }
}

function MessageItem({ message }: { message: Message }) {
  const severityColor = getMessageSeverityColor(message.severity);
  return (
    <div className="flex items-center gap-2">
      <SeverityIcon severity={message.severity} className="text-[18px]" />
      <div className="flex flex-col">
        <div className="text-sm leading-none text-foreground-muted">
          {getTimestampDescription(message.timestamp)}
        </div>
        <div className={"text-" + severityColor}>
          {message.content}
        </div>
      </div>
    </div>
  );
}

interface NavigationMessagesProps extends React.HTMLAttributes<HTMLAnchorElement> {
  autoHide: boolean,
}

export function NavigationMenuMessages({ children, autoHide, ...props }: NavigationMessagesProps) {
  const messages = useMessages();
  const [open, setOpen] = useState(false);
  const [worst, setWorst] = useState<MessageSeverity>(MessageSeverity.Info);
  useHotkeys("Escape", (e) => messages.dismissAll());
  useLayoutEffect(() => {
    if (messages.messages.length === 0 && messages.widgets.length === 0) {
      setWorst(MessageSeverity.Info);
      setOpen(false);
    } else {
      const hasErrors = messages.messages.some(m => m.severity === MessageSeverity.Error);
      if (hasErrors) {
        setWorst(MessageSeverity.Error);
      } else {
        const hasWarnings = messages.messages.some(m => m.severity === MessageSeverity.Warning);
        if (hasWarnings) {
          setWorst(MessageSeverity.Warning);
        } else {
          setWorst(MessageSeverity.Info);
        }
      }
      setOpen(true);
    }
  }, [messages]);
  if(autoHide && messages.messages.length === 0 && messages.widgets.length === 0) {
    return <></>;
  }
  return (
    <Popover open={open} onOpenChange={v => {
      if (v) {
        if(messages.messages.length > 0 || messages.widgets.length > 0)  {
          setOpen(true);
        }
      } else if(worst == MessageSeverity.Info && messages.widgets.length == 0) {
        setOpen(false);
      }
    }}>
      <PopoverTrigger asChild>
        <Button className="h-8 px-2">
          <SeverityIcon severity={worst}/>
          <div className="font-semibold">
            {messages.messages.length}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="z-40 flex w-fit min-w-72 flex-col p-0">
        <div className="flex rounded-t-lg border-b bg-background p-2">
          <Button onClick={messages.dismissAll} className="relative w-full">
            <TrashIcon />
            Clear all 
            <Hotkey absolute className="max-sm:hidden">
              Esc
            </Hotkey>
          </Button>
        </div>
        <div className="flex flex-col gap-2 p-2">
          {messages.messages.map(m => <MessageItem key={m.id} message={m} />)}
          {messages.messages.length > 0 && messages.widgets.length > 0 && (
            <div className="h-0 w-full border-t border-dashed"/>
          )}
          {messages.widgets.map(w => <div key={w.id} className={"rounded border border-l-[5px] p-2" + (w.type === "success" ? " border-primary bg-primary/5" : w.type === "error" ? " border-error bg-error/5" : " bg-background")}>{w.content}</div>)}
        </div>
        <PopoverArrow />
      </PopoverContent>
    </Popover>
  );
}
import { ResizablePanelGroup, ResizablePanel, ResizableHandle } from "@ui/resizable";
import BlueprintCanvas from "./BlueprintCanvas";
import Properties from "../Properties";
import BlueprintSidebarActions from "./BlueprintSidebarActions";
import { ScrollArea } from "@ui/scroll-area";
import BlueprintSidebarHeader from "./BlueprintSidebarHeader";
import BlueprintSidebarElements from "./BlueprintSidebarElements";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { TrayType, TrayModel } from "@/types";
import { selectTraysForBlueprint, selectCurrentBlueprintId, selectTrayColor, useAppSelector, selectTrayName } from "@/state/store";
import { generateTrayGeometries, inGeometryCache } from "@/replicadWorkerPool";
import CanvasContainer from "../canvas/CanvasContainer";
import BlueprintToolbar from "./BlueprintToolbar";
import BlueprintSidebarMessages from "./BlueprintSidebarMessages";

export default function BlueprintDesigner() {
  const blueprintId = useAppSelector(selectCurrentBlueprintId)!;
  const [trayId, setTrayId] = useState(selectTraysForBlueprint(blueprintId).find(i => i.type === TrayType.Preview)!.id);
  const blueprintModelHash = useAppSelector(s => s.model.blueprints[blueprintId].modelHash);
  const trayModelHash = useAppSelector(s => s.model.trays[trayId].modelHash);
  const color = useAppSelector(s => selectTrayColor(trayId, s));
  const [models, setModels] = useState<TrayModel[]>([]);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isDebugging, setIsDebugging] = useState<boolean>(false);
  const trayName = useAppSelector(s => selectTrayName(trayId, s));
  useEffect(() => {
    document.title = trayName;
  }, [ trayName ]);
  useLayoutEffect(() => {
    setTrayId(selectTraysForBlueprint(blueprintId).find(i => i.type === TrayType.Preview)!.id);
  }, [ blueprintId ]);
  // debounce render requests
  useEffect(() => {
    setIsGenerating(true);
    generateTrayGeometries(trayId, isDebugging).then(result => {
      setModels(result.map(g => ({
        ...g,
        color: color,
        trayId: trayId,
      })));
      setIsGenerating(false);
    }, () => {
      setIsGenerating(false);
    });
  }, [ blueprintId, blueprintModelHash, trayModelHash ]);
  // update color of existing models
  useEffect(() => {
    setModels([
      ...models.map(m => ({
        ...m,
        color: color,
      }))
    ]);
  }, [ color ]);
  return (
    <ResizablePanelGroup direction="horizontal" autoSaveId="blueprint-panels-layout" storage={localStorage}>
      <ResizablePanel className="flex flex-col gap-1 p-1">
        <BlueprintToolbar/>
        <CanvasContainer>
          <BlueprintCanvas isGenerating={isGenerating} isDebugging={isDebugging} setIsDebugging={debug => setIsDebugging(debug)} models={models} blueprintId={blueprintId} trayId={trayId} setTrayId={id => setTrayId(id)}/>
        </CanvasContainer>
      </ResizablePanel>
      <ResizableHandle />
      <ResizablePanel defaultSize={20} className="min-w-[330px] max-w-[600px]">
        <ResizablePanelGroup direction="vertical">
          <ResizablePanel className="flex h-full flex-col p-1">
              <BlueprintSidebarActions blueprintId={blueprintId} models={models} trayId={trayId}/>
              <BlueprintSidebarHeader blueprintId={blueprintId}/>
              <ScrollArea slim className="grow rounded-b border-x border-b bg-control px-2">
                <div className="mb-2">
                  <BlueprintSidebarMessages trayId={trayId}/>
                  <BlueprintSidebarElements blueprintId={blueprintId}/>
                </div>
              </ScrollArea>
          </ResizablePanel>
          <ResizableHandle />
          <ResizablePanel defaultSize={50} className="min-h-[300px]">
            <div className="h-full rounded-sm border bg-control">
              <Properties trayId={trayId}/>
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </ResizablePanel>
    </ResizablePanelGroup>
  );
}